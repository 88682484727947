
// outsource dependencies
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// local dependencies
import defaultImage from './default-image.svg';
import defaultOrganizationLogo from './default-organization-logo.svg';

// configure

export const DefaultImage = memo(function DefaultImage (props) {
    const {
        src, defaultSrc, alt, defaultAlt, title, defaultTitle,
        defaultStyle, style, className, defaultClassName, ...attr
    } = props;

    return <img
        src={src || defaultSrc}
        alt={alt || defaultAlt}
        title={title || defaultTitle}
        className={className || defaultClassName}
        style={Object.assign({}, defaultStyle, style)}
        {...attr}
    />;
});
DefaultImage.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    title: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    defaultSrc: PropTypes.string,
    defaultAlt: PropTypes.string,
    defaultTitle: PropTypes.string,
    defaultStyle: PropTypes.object,
    defaultClassName: PropTypes.string,
};
DefaultImage.defaultProps = {
    style: {},
    src: null,
    alt: null,
    title: null,
    className: null,
    defaultStyle: {},
    defaultTitle: '',
    defaultAlt: 'image',
    defaultClassName: '',
    defaultSrc: defaultImage,
};

export const DefaultOrganizationLogo = props => <DefaultImage
    defaultAlt="Organization Logo"
    defaultTitle="Organization Logo"
    defaultSrc={defaultOrganizationLogo}
    {...props}
/>;
