
// outsource dependencies
import React, { memo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// local dependencies
import * as ROUTES from 'constants/routes';

// pages
import UsersList from './list';
import UsersEdit from './edit';

// configure

const Users = memo(function Users () {
    return <Switch>
        <Route path={ROUTES.USERS_LIST.ROUTE} component={UsersList} />
        <Route path={ROUTES.USERS_EDIT.ROUTE} component={UsersEdit} />
        {/*OTHERWISE*/}
        <Redirect to={{ pathname: ROUTES.USERS_LIST.LINK() }} />
    </Switch>;
});

export default Users;
