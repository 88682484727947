
// outsource dependencies
import _ from 'lodash';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import React, { memo, useCallback, useMemo } from 'react';
import { useControllerActions, useControllerData } from 'redux-saga-controller';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, NavbarToggler, NavItem, Offcanvas, OffcanvasBody, OffcanvasHeader, UncontrolledDropdown } from 'reactstrap';

// root
import { useRole, useSelf } from 'hooks';
import appRootController from 'controller';

// components
import { Humanize } from 'components/filter';

// constants
import * as ROUTES from 'constants/routes';
import config from 'constants/enviroment-config';
import { headerHeight, SYSTEM_ROLE_TYPE } from 'constants/spec';

// common
import { Restricted } from 'common/permission-provider';

// services
import { FilterService } from 'services/filter.service';

// images
import { DefaultImage } from 'images';
import companyLogo from 'images/company-logo.svg';
import defaultAvatar from 'images/default-avatar.svg';

// configure

const Header = memo(function Header () {
    const { showMenu } = useControllerData(appRootController);
    const { updateCtrl, signOut } = useControllerActions(appRootController);
    const role = useRole();
    const user = useSelf();

    // TODO: prepareRole function exist for development mode, to will be possible change role
    const prepareRole = useMemo(() => {
        const filterRole = Object.values(SYSTEM_ROLE_TYPE).filter(systemRole => role !== systemRole);
        return filterRole.map(role => ({
            name: FilterService.humanize(role),
            switchRole: () => updateCtrl({ user: { ...user, role: { name: FilterService.humanize(role), type_name: role } } })
        }));
    }, [role, user, updateCtrl]);
    const handleOpenBurgerMenu = useCallback(() => updateCtrl({ showMenu: true }), [updateCtrl]);
    const handleCloseBurgerMenu = useCallback(() => updateCtrl({ showMenu: false }), [updateCtrl]);

    return <>
        <Navbar
            light
            expand="lg"
            color="light"
            id="AppHeader"
            style={{ height: headerHeight }}
            className="p-0 px-4 border-bottom border-1 border-gray-700-200"
        >
            <Link className="navbar-brand mr-4 p-0" to={ROUTES.DASHBOARD.LINK()}>
                <DefaultImage
                    defaultSrc={companyLogo}
                    defaultAlt="Company logo"
                    defaultTitle="Company Logo"
                />
            </Link>
            <Nav navbar className="d-none d-lg-flex">
                <NavItems />
            </Nav>
            <UncontrolledDropdown inNavbar className="me-5 d-none d-lg-block">
                <DropdownToggle
                    nav
                    caret
                    className="d-flex justify-content-center align-items-center fw-regular fz-16 font-family-alternative text-secondary"
                >
                    <DefaultImage
                        defaultAlt="User Avatar"
                        defaultSrc={defaultAvatar}
                        defaultTitle="User Avatar"
                        className="avatar-image me-2"
                    />
                    { `${_.get(user, 'first_name')} ${_.get(user, 'last_name')}` }
                </DropdownToggle>
                <DropdownMenu className="end-0">
                    <DropdownItem header className="fz-16 text-gray-700">
                    You current role is <Humanize tag="strong" value={role} />
                    </DropdownItem>
                    { config.DEBUG && <>
                        <DropdownItem divider />
                        { prepareRole.map(({ name, switchRole }) => <DropdownItem
                            onClick={switchRole}
                            key={name}
                        >
                        Switch to <strong>{ name }</strong> role
                        </DropdownItem>) }
                    </> }
                    <DropdownItem divider />
                    <DropdownItem onClick={signOut}>
                    Sign Out
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
            <NavbarToggler onClick={handleOpenBurgerMenu} />
        </Navbar>
        <Offcanvas
            direction="end"
            isOpen={showMenu}
            toggle={handleCloseBurgerMenu}
        >
            <OffcanvasHeader toggle={handleCloseBurgerMenu}>
            </OffcanvasHeader>
            <OffcanvasBody>
                <Navbar id="AppHeader">
                    <Nav navbar>
                        <NavItems closeMenu={handleCloseBurgerMenu} />
                    </Nav>
                </Navbar>
                <UncontrolledDropdown>
                    <DropdownToggle
                        nav
                        caret
                        className="fw-regular fz-16 font-family-alternative text-secondary"
                    >
                        <DefaultImage
                            defaultAlt="User Avatar"
                            defaultSrc={defaultAvatar}
                            defaultTitle="User Avatar"
                            className="avatar-image me-2"
                        />
                        { `${_.get(user, 'first_name')} ${_.get(user, 'last_name')}` }
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem header className="fz-16 text-gray-700">
                            You current role is <Humanize tag="strong" value={role} />
                        </DropdownItem>
                        { config.DEBUG && <>
                            <DropdownItem divider />
                            { prepareRole.map(({ name, switchRole }) => <DropdownItem
                                onClick={switchRole}
                                key={name}
                            >
                                Switch to <strong>{ name }</strong> role
                            </DropdownItem>) }
                        </> }
                        <DropdownItem divider />
                        <DropdownItem onClick={signOut}>
                            Sign Out
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </OffcanvasBody>
        </Offcanvas>
    </>;
});

export default Header;

const NavItems = memo(function NavItems ({ closeMenu }) {
    return <>
        <NavItem className="mx-1" onClick={closeMenu}>
            <NavLink
                to={ROUTES.DASHBOARD.LINK()}
                className={isActive => (
                    cx('nav-link fw-regular fz-16 font-family-alternative', isActive ? 'fw-bold text-secondary' : 'text-gray-700')
                )}
            >
            Dashboard
            </NavLink>
        </NavItem>
        <NavItem className="mx-1" onClick={closeMenu}>
            <NavLink
                to={ROUTES.ISSUES.LINK()}
                className={isActive => cx(
                    'nav-link fw-regular fz-16 font-family-alternative',
                    isActive ? 'fw-bold text-secondary' : 'text-gray-700'
                )}
            >
                Complaints
            </NavLink>
        </NavItem>
        <NavItem className="mx-1" onClick={closeMenu}>
            <NavLink
                to={ROUTES.SUGGESTIONS.LINK()}
                className={isActive => cx(
                    'nav-link fw-regular fz-16 font-family-alternative',
                    isActive ? 'fw-bold text-secondary' : 'text-gray-700'
                )}
            >
                Idea Toolbox
            </NavLink>
        </NavItem>
        <NavItem className="mx-1" onClick={closeMenu}>
            <NavLink
                to={ROUTES.ANALYTICS.LINK()}
                className={isActive => cx(
                    'nav-link fw-regular fz-16 font-family-alternative',
                    isActive ? 'fw-bold text-secondary' : 'text-gray-700'
                )}
            >
            Analytics
            </NavLink>
        </NavItem>
        <NavItem className="mx-1" onClick={closeMenu}>
            <NavLink
                to={ROUTES.ORGANIZATION.LINK()}
                className={isActive => cx(
                    'nav-link fw-regular fz-16 font-family-alternative',
                    isActive ? 'fw-bold text-secondary' : 'text-gray-700'
                )}
            >
                <Restricted
                    fallback={'Organizations'}
                    role={SYSTEM_ROLE_TYPE.ORGANIZATION_ADMIN}
                >
                Organization
                </Restricted>
            </NavLink>
        </NavItem>
        <NavItem className="mx-1" onClick={closeMenu}>
            <NavLink
                to={ROUTES.USERS.LINK()}
                className={isActive => cx(
                    'nav-link fw-regular fz-16 font-family-alternative',
                    isActive ? 'fw-bold text-secondary' : 'text-gray-700'
                )}
            >
            Users
            </NavLink>
        </NavItem>
    </>;
});

NavItems.propTypes = {
    closeMenu: PropTypes.func
};

NavItems.defaultProps = {
    closeMenu: e => e
};
