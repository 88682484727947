
// outsource dependencies
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Field, getFormValues } from 'redux-form';
import React, { memo, useCallback, useState } from 'react';
import { Button, Card, CardBody, Col, Collapse, Row } from 'reactstrap';
import { useControllerActions, useControllerData } from 'redux-saga-controller';

// local dependencies
import { useSelf } from 'hooks';
import controller from './controller';
import { instanceAPI } from 'services';
import { Restricted } from 'common/permission-provider';
import { FilterService } from 'services/filter.service';
import { STATUS_USER_TYPE, SYSTEM_ROLE_TYPE } from 'constants/spec';
import { RFInput, AppIcon, FasIcon, SortIcon, ReduxForm, RFSelect, getOptionId, getOptionName, RFAsyncSelect } from 'components';

// configure
export const FORM_NAME = 'usersFilterForm';

export const statusOptions = Object.values(STATUS_USER_TYPE).map(role => ({
    value: role, label: FilterService.humanize(role)
}));

const Filter = memo(function Filter () {
    const user = useSelf();
    const { initialFilter, disabled } = useControllerData(controller);
    const { updateData, resetForm, resetField } = useControllerActions(controller);

    const { department, id, email, role, name, status, organization } = useSelector(getFormValues(FORM_NAME)) || {};

    const [isOpenFilter, setOpenFilter] = useState(false);

    const handleToggleFilter = useCallback(() => setOpenFilter(isOpenFilter => !isOpenFilter), []);
    const handleSubmitSearch = useCallback(() => {
        updateData({ page: 1 });
        handleToggleFilter();
    }, [handleToggleFilter, updateData]);
    const handleResetField = useCallback(({ field }) => {
        resetField({ field });
        updateData({ page: 1 });
    }, [updateData, resetField]);
    const handleResetForm = useCallback(() => {
        resetForm();
        updateData({ page: 1 });
        handleToggleFilter();
    }, [resetForm, updateData, handleToggleFilter]);

    const loadRoles = useCallback(() => instanceAPI({
        method: 'GET',
        url: 'role',
    }), []);

    const loadDepartments = useCallback(() => {
        if (_.get(user, 'role.type_name') === SYSTEM_ROLE_TYPE.ORGANIZATION_ADMIN) {
            return instanceAPI({ method: 'GET', url: `organization/${_.get(user, 'organization.id')}/department` })
                .then(({ data }) => data);
        }
        return instanceAPI({ method: 'GET', url: 'department/withoutPagination' });
    }, [user]);

    const loadOrganizations = useCallback(() => instanceAPI({
        method: 'GET',
        url: 'organization',
    }), []);

    return <ReduxForm
        form={FORM_NAME}
        enableReinitialize
        initialValues={initialFilter}
        onSubmit={handleSubmitSearch}
    >
        <Row className="position-relative" style={{ zIndex: 2 }}>
            <Col xs="12" className="d-flex align-items-center">
                <Button
                    onClick={handleToggleFilter}
                    className="d-flex align-items-center justify-content-center fz-18 text-gray-700 bg-transparent border-0 rounded-3 p-2 me-4"
                >
                    <span className="fw-600 me-4">Search</span>
                    <SortIcon status={isOpenFilter} className="fz-18" />
                </Button>
                <div className="d-flex flex-wrap">
                    { id ? <SearchAttribute
                        name="User ID"
                        search={id}
                        onChange={() => handleResetField({ field: 'id' })}
                    /> : null }
                    { name ? <SearchAttribute
                        name="Name"
                        search={name}
                        onChange={() => handleResetField({ field: 'name' })}
                    /> : null }
                    { email ? <SearchAttribute
                        name="Email"
                        search={email}
                        onChange={() => handleResetField({ field: 'email' })}
                    /> : null }
                    { role ? <SearchAttribute
                        name="Role"
                        search={role.name}
                        onChange={() => handleResetField({ field: 'role' })}
                    /> : null }
                    { organization ? <SearchAttribute
                        name="Organization"
                        search={organization.name}
                        onChange={() => handleResetField({ field: 'organization' })}
                    /> : null }
                    { department ? <SearchAttribute
                        name="Department"
                        search={department.name}
                        onChange={() => handleResetField({ field: 'department' })}
                    /> : null }
                    { status ? <SearchAttribute
                        name="Status"
                        search={status.label}
                        onChange={() => handleResetField({ field: 'status' })}
                    /> : null }
                </div>
            </Col>
            <Col tag={Collapse} isOpen={isOpenFilter} className="position-absolute top-100 mt-2">
                <Card className="shadow border-0" style={{ width: '45%' }}>
                    <CardBody>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <Button
                                color="transparent"
                                onClick={handleResetForm}
                                className="d-flex align-items-center justify-content-center font-family-alternative fz-14 text-secondary rounded-3 px-1"
                            >
                                <AppIcon icon="trash-filter" className="fz-18 me-3" />
                                Clear all
                            </Button>
                            <Button
                                color="transparent"
                                onClick={handleToggleFilter}
                                className="d-flex align-items-center justify-content-center fz-18 text-white rounded-3 p-1"
                            >
                                <FasIcon icon="times" className="text-gray-700" />
                            </Button>
                        </div>
                        <Field
                            name="id"
                            type="text"
                            label="User ID"
                            component={RFInput}
                            disabled={disabled}
                            placeholder="00000000"
                            classNameLabel="w-50 m-0"
                            classNameInputWrapper="w-50"
                            classNameGroup="d-flex align-items-center mb-2"
                        />
                        <Field
                            type="text"
                            name="name"
                            label="Name"
                            component={RFInput}
                            disabled={disabled}
                            classNameLabel="w-50 m-0"
                            classNameInputWrapper="w-50"
                            placeholder="First and Family Name"
                            classNameGroup="d-flex align-items-center mb-2"
                        />
                        <Field
                            type="email"
                            name="email"
                            label="Email"
                            component={RFInput}
                            disabled={disabled}
                            classNameLabel="w-50 m-0"
                            classNameInputWrapper="w-50"
                            placeholder="email@gmail.com"
                            classNameGroup="d-flex align-items-center mb-2"
                        />
                        <Field
                            isSimple
                            name="role"
                            label="Role"
                            cacheOptions
                            defaultOptions
                            placeholder="Role"
                            disabled={disabled}
                            loadOptions={loadRoles}
                            component={RFAsyncSelect}
                            styles={prepareSelectStyle}
                            getOptionValue={getOptionId}
                            getOptionLabel={getOptionName}
                            classNameLabel="flex-shrink-0 w-50 m-0"
                            classNameGroup="d-flex align-items-center mb-2"
                        />
                        <Restricted role={SYSTEM_ROLE_TYPE.PLATFORM_ADMIN}>
                            <Field
                                isSimple
                                cacheOptions
                                defaultOptions
                                disabled={disabled}
                                name="organization"
                                label="Organization"
                                component={RFAsyncSelect}
                                placeholder="Organization"
                                styles={prepareSelectStyle}
                                getOptionValue={getOptionId}
                                getOptionLabel={getOptionName}
                                loadOptions={loadOrganizations}
                                classNameLabel="flex-shrink-0 w-50 m-0"
                                classNameGroup="d-flex align-items-center mb-2"
                            />
                        </Restricted>
                        <Field
                            isSimple
                            cacheOptions
                            defaultOptions
                            name="department"
                            label="Department"
                            disabled={disabled}
                            placeholder="Department"
                            component={RFAsyncSelect}
                            styles={prepareSelectStyle}
                            getOptionValue={getOptionId}
                            loadOptions={loadDepartments}
                            getOptionLabel={getOptionName}
                            classNameLabel="flex-shrink-0 w-50 m-0"
                            classNameGroup="d-flex align-items-center mb-2"
                        />
                        <Field
                            isSimple
                            name="status"
                            label="Status"
                            disabled={disabled}
                            component={RFSelect}
                            placeholder="Status"
                            options={statusOptions}
                            styles={prepareSelectStyle}
                            classNameLabel="flex-shrink-0 w-50 m-0"
                            classNameGroup="d-flex align-items-center mb-4"
                        />
                        <Button
                            type="submit"
                            color="primary"
                            disabled={disabled}
                            className="text-white w-100"
                        >
                            Search
                        </Button>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </ReduxForm>;
});

export default Filter;

const SearchAttribute = memo(function SearchAttribute ({ name, search, onChange }) {
    return <p className="d-flex align-items-center text-gray-500 mb-0 me-2">
        { name }
        :&nbsp;
        <span className="text-gray text-gray-700 mb-0 me-1">
            { search }
        </span>
        <Button
            onClick={onChange}
            color="transparent"
            className="d-flex align-items-center justify-content-center fz-12 text-white rounded-3 p-1"
        >
            <FasIcon icon="times" className="text-gray-500" />
        </Button>
    </p>;
});
SearchAttribute.propTypes = {
    name: PropTypes.string,
    search: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};
SearchAttribute.defaultProps = {
    search: '',
    name: '',
};

const prepareSelectStyle = {
    container: base => ({
        ...base,
        width: '50%'
    }),
};
