
// outsource dependencies
import { toastr } from 'react-redux-toastr';
import { create } from 'redux-saga-controller';
import { takeEvery, put, call } from 'redux-saga/effects';

// local dependencies
import { instanceAPI } from 'services';
import { history } from 'constants/history';
import { STATUS_USER_TYPE } from 'constants/spec';
import { ORGANIZATION_LIST } from 'constants/routes';
import config from 'constants/enviroment-config';

// configure
export const TABS_TYPE = {
    DETAILS: 'DETAILS',
};

export const controller = create({
    prefix: 'organization-create',
    actions: {
        initialize: 'INITIALIZE',
        submitData: 'SUBMIT_DATA',
    },
    initial: {
        disabled: false,
        initialized: false,
        errorMessage: null,

        activeTab: TABS_TYPE.DETAILS,
        initialValues: {
            status: STATUS_USER_TYPE.ACTIVE
        }
    },
    subscriber: function * () {
        yield takeEvery(controller.action.initialize.TYPE, initializeSaga);
        yield takeEvery(controller.action.submitData.TYPE, submitDataSaga);
    }
});

export default controller;

function * initializeSaga ({ type, payload }) {
    // console.log(`%c ${type} `, 'color: #FF6766; font-weight: bolder; font-size: 12px;'
    //   , '\n payload:', payload
    // );
    yield put(controller.action.updateCtrl({ initialized: true }));
}

function * submitDataSaga ({ type, payload }) {
    yield put(controller.action.updateCtrl({ disabled: true }));
    try {
        yield call(instanceAPI, { method: 'POST', url: '/organization/create', data: {
            ...payload,
            url: `${config.WEBSITE_URL}/${payload.keyword}`
        } });
        yield call(history.push, ORGANIZATION_LIST.LINK());
        yield call(toastr.success, 'Organization', 'Organization was successfully created');
    } catch ({ message }) {
        yield call(toastr.error, 'Organization Create Submit Data', message);
        yield put(controller.action.updateCtrl({ errorMessage: message }));
    }
    yield put(controller.action.updateCtrl({ disabled: false }));
}
