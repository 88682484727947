
// outsource dependencies
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import React, { memo } from 'react';

// local dependencies
import { FilterService } from 'services/filter.service';
import { STATUS_TYPE, STATUS_USER_TYPE } from 'constants/spec';

// configure

export const BadgeStatus = memo(function StatusBadge ({ type }) {
    switch (type) {
        default: return <Badge className="border border-gray-700 bg-gray-700 bg-opacity-25 text-gray-700">
            No Selected
        </Badge>;
        case STATUS_TYPE.NEW: return <Badge className="border border-orange bg-orange bg-opacity-25 text-orange fw-400">
            { FilterService.humanize(type) }
        </Badge>;
        case STATUS_TYPE.ADDRESSED: return <Badge className="border border-green bg-green bg-opacity-25 text-green fw-400">
            { FilterService.humanize(type) }
        </Badge>;
        case STATUS_TYPE.REVIEWED: return <Badge className="border border-purple bg-purple bg-opacity-25 text-purple fw-400">
            { FilterService.humanize(type) }
        </Badge>;
        case STATUS_TYPE.APPROVED: return <Badge className="border border-info bg-info bg-opacity-25 text-info fw-400">
            { FilterService.humanize(type) }
        </Badge>;
        case STATUS_USER_TYPE.ACTIVE: return <Badge className="border border-green bg-green bg-opacity-25 text-green fw-400">
            { FilterService.humanize(type) }
        </Badge>;
        case STATUS_USER_TYPE.DISABLED: return <Badge className="border border-gray-600 bg-gray-600 bg-opacity-25 text-gray-600 fw-400">
            { FilterService.humanize(type) }
        </Badge>;
    }
});
BadgeStatus.propTypes = {
    type: PropTypes.oneOf([...Object.values(STATUS_TYPE), ...Object.values(STATUS_USER_TYPE)]),
};
BadgeStatus.defaultProps = {
    type: null,
};

export function prepareBadgeStatusStyleForReactSelect (base, { data }) {
    const BASE_STYLE = {
        ...base,
        width: 'fit-content',
        fontWeight: 700,
        fontSize: '0.75em',
        borderWidth: 1,
        borderRadius: '0.5em',
        borderStyle: 'solid',
        padding: '0.35em 0.65em',
    };

    switch (_.get(data, 'value')) {
        default: return {
            ...BASE_STYLE,
            color: 'rgb(123,123,123)',
            borderColor: 'rgb(123,123,123)',
            backgroundColor: 'rgba(123,123,123, 0.25)',
        };
        case STATUS_TYPE.NEW: return {
            ...BASE_STYLE,
            color: 'rgb(233,105,54)',
            borderColor: 'rgb(233,105,54)',
            backgroundColor: 'rgba(233,105,54, 0.25)',
        };
        case STATUS_TYPE.ADDRESSED: return {
            ...BASE_STYLE,
            color: 'rgb(112,197,152)',
            borderColor: 'rgb(112,197,152)',
            backgroundColor: 'rgba(112,197,152, 0.25)',
        };
        case STATUS_TYPE.REVIEWED: return {
            ...BASE_STYLE,
            color: 'rgb(140,120,219)',
            borderColor: 'rgb(140,120,219)',
            backgroundColor: 'rgba(140,120,219, 0.25)',
        };
        case STATUS_TYPE.APPROVED: return {
            ...BASE_STYLE,
            color: 'rgb(35,183,229)',
            borderColor: 'rgb(35,183,229)',
            backgroundColor: 'rgba(35,183,229, 0.25)',
        };
        case STATUS_USER_TYPE.ACTIVE: return {
            ...BASE_STYLE,
            color: 'rgb(112,197,152)',
            borderColor: 'rgb(112,197,152)',
            backgroundColor: 'rgba(112,197,152, 0.25)',
        };
        case STATUS_USER_TYPE.DISABLED: return {
            ...BASE_STYLE,
            color: 'rgb(123, 123, 123)',
            borderColor: 'rgb(123, 123, 123)',
            backgroundColor: 'rgba(123, 123, 123, 0.25)',
        };
    }
}

export default BadgeStatus;
