
// outsource dependencies
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { create } from 'redux-saga-controller';
import { takeEvery, put, call, select } from 'redux-saga/effects';

// local dependencies
import { signIn } from 'services';
import appRootController from 'controller';
import { history } from 'constants/history';
import { DASHBOARD } from 'constants/routes';

// configure

export const controller = create({
    prefix: 'sign-in',
    actions: {
        initialize: 'INITIALIZE',
        submitData: 'SUBMIT_DATA',
    },
    initial: {
        disabled: false,
        initialValues: {},
        initialized: false,
        errorMessage: null,
    },
    subscriber: function * () {
        yield takeEvery(controller.action.initialize.TYPE, initializeSaga);
        yield takeEvery(controller.action.submitData.TYPE, submitDataSaga);
    }
});

export default controller;

function * initializeSaga ({ type, payload }) {
    // console.log(`%c ${type} `, 'color: #FF6766; font-weight: bolder; font-size: 12px;'
    //   , '\n payload:', payload
    // );
    const { organization } = yield select(appRootController.select);
    if (_.isEmpty(organization)) {
        yield put(appRootController.action.getOrganization());
    }
    yield put(controller.action.updateCtrl({ initialized: true }));
}

function * submitDataSaga ({ type, payload }) {
    yield put(controller.action.updateCtrl({ disabled: true, errorMessage: null }));
    try {
        const { organization } = yield select(appRootController.select);
        const user = yield call(signIn, { ...payload, organization_id: organization.id });
        yield put(appRootController.action.updateCtrl({ user }));
        yield call(history.push, DASHBOARD.LINK());
    } catch ({ message }) {
        yield call(toastr.error, 'Sign In Submit Data', message);
        yield put(controller.action.updateCtrl({ errorMessage: message }));
    }
    yield put(controller.action.updateCtrl({ disabled: false }));
}
