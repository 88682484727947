
// outsource dependencies
import _ from 'lodash';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import ReactSelect from 'react-select';
import React, { memo, useCallback, useMemo } from 'react';

// local dependencies
import { getOptionLabel, getOptionValue, prepareCustomStyle, prepareValue } from './configure';

// configure

const Select = memo(function Select (props) {
    const {
        name, label, isClearable, isSearchable, options, components, styles, disabled, onBlur, closeMenuOnSelect,
        getOptionValue, getOptionLabel, className, classNameLabel, classNameGroup, message, ...attributes
    } = props;
    const uid = _.kebabCase(name);

    const handleBlur = useCallback(event => {
        event.preventDefault();
        onBlur(event);
    }, [onBlur]);

    return <div className={classNameGroup}>
        { label ? <Label
            htmlFor={uid}
            className={cx('fw-400', classNameLabel, { 'app-invalid-feedback': message })}
        >
            { label }
        </Label> : null }
        <ReactSelect
            id={uid}
            name={name}
            options={options}
            onBlur={handleBlur}
            className={className}
            isDisabled={disabled}
            components={components}
            isClearable={isClearable}
            isSearchable={isSearchable}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            styles={prepareCustomStyle(styles)}
            blurInputOnSelect={closeMenuOnSelect}
            closeMenuOnSelect={closeMenuOnSelect}
            {...attributes}
        />
        { message ? <Label htmlFor={uid} className="d-inline app-invalid-feedback">
            { message }
        </Label> : null }
    </div>;
});
Select.propTypes = {
    onBlur: PropTypes.func,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    styles: PropTypes.object,
    message: PropTypes.string,
    components: PropTypes.node,
    isClearable: PropTypes.bool,
    className: PropTypes.string,
    isSearchable: PropTypes.bool,
    getOptionValue: PropTypes.func,
    getOptionLabel: PropTypes.func,
    classNameLabel: PropTypes.string,
    classNameGroup: PropTypes.string,
    closeMenuOnSelect: PropTypes.bool,
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array]),
};
Select.defaultProps = {
    getOptionValue: getOptionValue,
    getOptionLabel: getOptionLabel,
    closeMenuOnSelect: true,
    isSearchable: false,
    isClearable: false,
    classNameLabel: '',
    classNameGroup: '',
    components: null,
    disabled: false,
    onBlur: e => e,
    className: '',
    message: '',
    styles: {},
    value: '',
    label: '',
};

export default Select;

export const RFSelect = memo(function RFSelect (props) {
    const { isSimple, isMulti, skipTouch, input, meta, getOptionValue, options, prepareValue, ...attributes } = props;

    const handleBlur = useCallback(event => event.preventDefault(), []);
    const handleChange = useCallback(value => {
        if (isSimple) {
            return input.onChange(value);
        }
        if (isMulti) {
            return input.onChange(value.map(getOptionValue));
        }
        return input.onChange(getOptionValue(value));
    }, [isMulti, isSimple, input, getOptionValue]);

    const value = useMemo(() => (
        isSimple ? input.value : prepareValue(input.value, options)
    ), [isSimple, input, prepareValue, options]);

    let message = '';
    if (skipTouch || (meta.touched && meta.error)) {
        message = meta.error;
        attributes.className += meta.valid ? ' is-valid' : ' is-invalid';
    }

    return <Select
        {...input}
        {...attributes}
        isMulti={isMulti}
        value={value}
        options={options}
        message={message}
        onBlur={handleBlur}
        onChange={handleChange}
        getOptionValue={getOptionValue}
    />;
});
RFSelect.propTypes = {
    isMulti: PropTypes.bool,
    isSimple: PropTypes.bool,
    skipTouch: PropTypes.bool,
    prepareValue: PropTypes.func,
    getOptionValue: PropTypes.func,
    meta: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
    options: PropTypes.array.isRequired,
};
RFSelect.defaultProps = {
    isMulti: false,
    isSimple: false,
    skipTouch: false,
    prepareValue: prepareValue,
    getOptionValue: getOptionValue,
};
