
// outsource dependencies
import _ from 'lodash';
import { Field } from 'redux-form';
import { Link } from 'react-router-dom';
import { useController } from 'redux-saga-controller';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Button, Col, Container, Row } from 'reactstrap';
import React, { memo, useCallback, useEffect, useState } from 'react';

// root
import { useRefCallback } from 'hooks';

// components
import { BoxLoader } from 'components/preloader';
import { FasIcon, ReduxForm, RFInput } from 'components';

// constants
import * as ROUTES from 'constants/routes';
import { FULL_HEIGHT_SCREEN } from 'constants/spec';

// local dependencies
import controller from './controller';

// images
import { DefaultImage } from 'images';
import welcomePageImage from 'images/welcome-page-image.jpg';
import companyLogoLarge from 'images/company-logo-large.svg';

// configure
export const FORM_NAME = 'welcomeForm';

const formValidation = values => {
    const errors = {};
    if (!values.access_keywords) {
        errors.access_keywords = 'Keyword is required';
    }
    return errors;
};

const Welcome = memo(function Welcome () {
    const [
        { initialized, disabled, initialValues },
        { initialize, submitData },
    ] = useController(controller);

    const [form, formRef] = useRefCallback();

    const [scrollTop, setScrollTop] = useState(0);
    const [imageHeight, setImageHeight] = useState(0);
    const [isVisibleScrollButton, setVisibleScrollButton] = useState(false);

    // NOTE initialize business logic
    useEffect(() => { initialize(); }, [initialize]);
    // NOTE listener for scroll button change
    useEffect(() => {
        const formOffsetTop = _.get(form, 'offsetTop', 0);
        const heightScreen = window.screen.height;
        if ((heightScreen + scrollTop) < formOffsetTop) {
            setVisibleScrollButton(true);
        } else {
            setVisibleScrollButton(false);
        }
    }, [imageHeight, initialized, scrollTop, form]);

    // NOTE scroll to form
    const handleScrollToForm = useCallback(() => form.scrollIntoView({ behavior: 'smooth', block: 'center' }), [form]);
    // NOTE listener for receive current position
    const handleScroll = useCallback(values => setScrollTop(values.scrollTop), []);
    // NOTE listener which get image height when image will be load
    const getImageHeight = useCallback(event => setImageHeight(_.get(event, 'target.clientHeight')), []);

    return <Scrollbars style={{ height: FULL_HEIGHT_SCREEN }} onScrollFrame={handleScroll}>
        <BoxLoader id="AppWelcome" active={!initialized}>
            <Container className="position-relative d-lg-flex flex-column py-4 h-lg-100">
                { isVisibleScrollButton && <div className="d-lg-none position-fixed top-25 end-0 bg-transparent p-3">
                    <Button
                        color="primary"
                        onClick={handleScrollToForm}
                        className="rounded-circle"
                        style={{ height: 40, width: 40 }}
                    >
                        <FasIcon icon="angle-down" className="text-white fz-20" />
                    </Button>
                </div> }
                <Row className="g-0 justify-content-center m-lg-5">
                    <Col xs="12" tag={Row} className="g-0 align-items-center mb-3">
                        <Col xs="12" lg="6" className="pe-sm-5 px-3 mb-3">
                            <DefaultImage className="w-100" src={companyLogoLarge} alt="Logo Company" title="Logo Company" />
                        </Col>
                    </Col>
                    <Col lg="6" xs="12" tag={Row} className="p-0 p-lg-3 mb-4 mb-lg-0">
                        <Col xs="12" className="d-flex mb-4">
                            <DefaultImage
                                height={250}
                                title="Title"
                                className="w-100 rounded-4"
                                alt="Description"
                                src={welcomePageImage}
                                onLoad={getImageHeight}
                                style={{ objectFit: 'cover' }}
                            />
                        </Col>
                        <Col xs="12" className="font-family-alternative fz-16 fz-lg-18">
                            <p className="fw-bold">
                                It’s Anonymous is an anonymous reporting AI platform committed to empowering all
                                voices and unleashing the hidden potential of organizations and communities.
                            </p>
                            <p className="fst-italic">
                                We are failing to capitalize on new ideas and innovation without a proper avenue to express them.
                            </p>
                            <p className="fst-italic">
                                With all the rules, regulations, and policies that guide our schools, programs,
                                institutions, companies, and governments, we still see countless incidents of harassment,
                                intimidation, bullying, discrimination, and corruption.
                            </p>
                            <p className="fw-bold">
                                It’s Anonymous creates a better tomorrow. A tomorrow that unleashes
                                the power of inclusivity and potential.
                            </p>
                        </Col>
                    </Col>
                    <Col
                        lg="6"
                        xs="12"
                        tag={ReduxForm}
                        form={FORM_NAME}
                        enableReinitialize
                        onSubmit={submitData}
                        className="p-0 p-lg-3"
                        validate={formValidation}
                        initialValues={initialValues}
                    >
                        <Row className="g-0 border border-primary rounded-4 p-3 p-lg-5">
                            <Col xs="12" tag="h2" className="text-center text-lg-start fz-26 fz-lg-42 fw-700 mb-4 mb-sm-5">
                                START HERE
                            </Col>
                            <Col xs="12" tag="p" className="text-center text-lg-start fz-18 fw-600 mb-4">
                                Type the KEY WORDS of your Organization in the field below
                            </Col>
                            <Col xs="12" className="mb-4">
                                <div id="form" ref={formRef}>
                                    <Field
                                        type="text"
                                        component={RFInput}
                                        disabled={disabled}
                                        className="rounded-4"
                                        name="access_keywords"
                                        placeholder="Type KEY WORDS"
                                    />
                                </div>
                            </Col>
                            <Col xs="12" lg="6" className="mb-4 mb-sm-5">
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="font-family-alternative text-white fz-16 fw-500 text-center text-secondary rounded-4 w-100"
                                >
                                    Go!
                                </Button>
                            </Col>
                            <Col xs="12" tag="p" className="font-family-alternative fz-14 fz-lg-18 text-gray-600 mb-4">
                                Alternatively, please go directly to the URL provided in the welcome email, or
                                follow the QR code.
                            </Col>
                            <Col xs="12" tag="p" className="font-family-alternative fz-14 text-gray-600 mb-0">
                                By using the Anonymous Platform, you consent to our
                                &nbsp;
                                <Link to={ROUTES.SIGN_IN.LINK()} className="text-blue fw-500 text-decoration-none">
                                    Terms of Service
                                </Link>
                                ,&nbsp;
                                <Link to={ROUTES.SIGN_IN.LINK()} className="text-blue fw-500 text-decoration-none">
                                    Privacy Policy
                                </Link>
                                &nbsp;and&nbsp;
                                <Link to={ROUTES.SIGN_IN.LINK()} className="text-blue fw-500 text-decoration-none">
                                    Cookie Policy
                                </Link>
                                .
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </BoxLoader>
    </Scrollbars>;
});

export default Welcome;
