
// outsource dependencies
import React, { memo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// local dependencies
import * as ROUTES from 'constants/routes';
import { useMainOrganization } from 'hooks';
import { SYSTEM_ROLE_TYPE } from 'constants/spec';
import { Restricted } from 'common/permission-provider';

// pages
import OrganizationList from './list';
import OrganizationEdit from './edit';
import OrganizationCreate from './create';
import OrganizationDepartment from './department';

// configure

const Organization = memo(function Organization () {
    const organization = useMainOrganization();
    return <Switch>
        <Restricted
            role={SYSTEM_ROLE_TYPE.ORGANIZATION_ADMIN}
            fallback={<Switch>
                <Route path={ROUTES.ORGANIZATION_LIST.ROUTE} component={OrganizationList} />
                <Route path={ROUTES.ORGANIZATION_CREATE.ROUTE} component={OrganizationCreate} />
                <Route exact path={ROUTES.ORGANIZATION_PAGE.ROUTE} component={OrganizationEdit} />
                <Route path={ROUTES.ORGANIZATION_DEPARTMENT.ROUTE} component={OrganizationDepartment} />

                <Redirect to={{ pathname: ROUTES.ORGANIZATION_LIST.LINK() }} />
            </Switch>}
        >
            <Switch>
                <Route exact path={ROUTES.ORGANIZATION_PAGE.ROUTE} component={OrganizationEdit} />
                <Route path={ROUTES.ORGANIZATION_DEPARTMENT.ROUTE} component={OrganizationDepartment} />

                <Redirect to={{ pathname: ROUTES.ORGANIZATION_PAGE.LINK({ id: organization.id }) }} />
            </Switch>
        </Restricted>
    </Switch>;
});

export default Organization;
