
// outsource dependencies
import React, { memo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

// local dependencies
import { NEW } from 'constants/spec';
import { FasIcon } from 'components/icon';
import * as ROUTES from 'constants/routes';

// configure

const BreadcrumbCustom = memo(function BreadcrumbCustom () {
    const { id, departmentId } = useParams();
    return <Breadcrumb listClassName="d-flex align-items-center mb-0">
        <BreadcrumbItem>
            <Link to={ROUTES.ORGANIZATION_PAGE.LINK({ id })} className="font-family-alternative fz-18 text-gray-700 text-decoration-none">
                Organization
            </Link>
        </BreadcrumbItem>
        <BreadcrumbItem className="text-center fz-18">
            <FasIcon icon="angle-right" />
        </BreadcrumbItem>
        <BreadcrumbItem active className="font-family-alternative fz-18 text-gray-700">
            { departmentId === NEW ? 'Add Department' : 'Update Department' }
        </BreadcrumbItem>
    </Breadcrumb>;
});

export default BreadcrumbCustom;
