// outsource dependencies
import _ from 'lodash';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { memo, forwardRef, useCallback, useEffect } from 'react';
import { useController } from 'redux-saga-controller';
import { Card, Col, Row, InputGroup, InputGroupText, Input, CardBody } from 'reactstrap';


// local dependencies
import controller from './controller';
import { AppIcon, BoxLoader } from '../../../../components';
import DatePickerCustom from '../../../../components/date-picker';
import { SatisfactionFeedbackChart } from './satisfaction-feedback-chart';

// configure
/*const excludeDates = [
    new Date('01-01-2021'),
    moment().startOf('year').add(1, 'year').toDate()
];*/
export const SatisfactionFeedback = memo(function SatisfactionFeedback ({ className }) {
    const [
        { initialized, filter, analyticsData },
        { initialize, getStatistic, updateCtrl },
    ] = useController(controller);
    const { startDate, endDate } = filter;

    // NOTE initialize business logic
    useEffect(() => { initialize(); }, [initialize]);

    const handleDateUpdate = useCallback((dates) => {
        const filterData = {
            filter: {
                ...filter,
                startDate: _.first(dates) ? moment(_.first(dates)).startOf('year').format('YYYY-MM-DD').toString() : null,
                endDate: _.last(dates) ? moment(_.last(dates)).endOf('year').format('YYYY-MM-DD').toString() : null
            }
        };
        updateCtrl(filterData);
        if (_.first(dates) && _.last(dates)) {
            getStatistic();
        }
    }, [updateCtrl, filter, getStatistic]);

    const InputCustom = forwardRef(({ value, onClick }, ref) => <InputGroup
        className="border border-primary rounded-3 cursor-pointer"
        onClick={onClick}
    >
        <InputGroupText className="border-0 bg-white">
            <AppIcon icon="calendar" className="text-blue" />
        </InputGroupText>
        <Input
            ref={ref}
            value={value}
            onChange={e => e}
            placeholder="YYYY - YYYY"
            className="border-transparent border-2 rounded-0 p-2"
        />
        <InputGroupText className="border-0 bg-white">
            <AppIcon icon="angle-down" className="fz-18 text-secondary" />
        </InputGroupText>
    </InputGroup>);

    return (<div className={cx('satisfaction-feedback h-100', className)}>
        <BoxLoader id="AppDashboard" active={!initialized}>
            <Row className="g-0">
                <Col xs="12" className="d-flex mb-4">
                    <DatePickerCustom
                        selectsRange
                        showYearPicker
                        dateFormat="YYYY"
                        name="timeInterval"
                        label="Time Interval"
                        classNameGroup="me-3"
                        endDate={endDate}
                        startDate={startDate}
                        onChange={handleDateUpdate}
                        customInput={<InputCustom />}
                        minDate={new Date('01-01-2021')}
                        maxDate={moment().endOf('year').toDate()}
                    />
                </Col>
            </Row>
            <Row className="g-0">
                <Col xs="12" tag={Card} className="rounded-3">
                    <CardBody className="d-flex flex-column">
                        <h4 className="font-family-primary fz-20 fw-700 text-secondary">Satisfaction Feedback</h4>
                        <p className="font-family-alternative fz-14 text-gray mb-1">Student Satisfaction on the Platform</p>
                        { _.size(analyticsData) > 0 ? <SatisfactionFeedbackChart /> : <>
                            Satisfaction Feedback analytics not available.
                        </> }
                    </CardBody>
                </Col>
            </Row>
        </BoxLoader>
    </div>);
});

SatisfactionFeedback.propTypes = {
    className: PropTypes.string,
};

SatisfactionFeedback.defaultProps = {
    className: '',
};
