
// outsource dependencies
import React, { memo, useEffect } from 'react';
import { useController } from 'redux-saga-controller';
import { Route, Switch, Redirect, Router, useLocation } from 'react-router-dom';

// root
import { useSelf } from 'hooks';
import controller from 'controller';
import { Maintenance, Restricted } from 'common';

// components
import Preloader from 'components/preloader';

// constants
import * as ROUTES from 'constants/routes';
import { history } from 'constants/history';
import { SYSTEM_ROLE_TYPE } from 'constants/spec';

// pages
import Users from './users';
import Cases from './cases';
import Report from './report';
import Issues from './issues';
import Survey from './survey';
import SignIn from './sign-in';
import SignUp from './sign-up';
import Welcome from './welcome';
import Dashboard from './dashboard';
import Analytics from './analytics';
import EmailSent from './email-sent';
import Suggestions from './suggestions';
import Organization from './organization';
import ResetPassword from './reset-password';
import RequestAccount from './request-account';
import ForgotPassword from './forgot-password';
import ChangePassword from './change-password';
import SubmitSuggestion from './submit-suggestion';

const App = memo(function App () {
    // NOTE subscribe app controller
    const [{ initialized, health }, { initialize }] = useController(controller);
    // NOTE initialize business logic
    useEffect(() => { initialize(); }, [initialize]);
    // NOTE select view based on application state
    if (!health) { return <Maintenance />; }
    if (!initialized) { return <Preloader active />; }
    return <Router history={history}>
        <Switch>
            <Route exact path={ROUTES.SURVEY.ROUTE} component={Survey} />
            <Route path={ROUTES.ROOT} component={Root} />
            <Route path={ROUTES.HOME} component={Welcome} />
        </Switch>
    </Router>;
});

export default App;

const Root = memo(function Root () {
    const location = useLocation();
    const user = useSelf();

    if (user) {
        return <Restricted
            role={SYSTEM_ROLE_TYPE.USER}
            fallback={<Switch>
                <Route path={ROUTES.USERS.ROUTE} component={Users} />
                <Route path={ROUTES.ISSUES.ROUTE} component={Issues} />
                <Route exact path={ROUTES.SURVEY.ROUTE} component={Survey} />
                <Route path={ROUTES.DASHBOARD.ROUTE} component={Dashboard} />
                <Route path={ROUTES.ANALYTICS.ROUTE} component={Analytics} />
                <Route path={ROUTES.SUGGESTIONS.ROUTE} component={Suggestions} />
                <Route path={ROUTES.ORGANIZATION.ROUTE} component={Organization} />
                <Route exact path={ROUTES.SET_PASSWORD.ROUTE} component={ChangePassword} />
                <Redirect to={{ pathname: ROUTES.DASHBOARD.LINK(), state: { from: location } }} />
            </Switch>}
        >
            <Switch>
                <Route path={ROUTES.CASES.ROUTE} component={Cases} />
                <Route path={ROUTES.REPORT.ROUTE} component={Report} />
                <Route exact path={ROUTES.SURVEY.ROUTE} component={Survey} />
                <Route path={ROUTES.DASHBOARD.ROUTE} component={Dashboard} />
                <Route exact path={ROUTES.SET_PASSWORD.ROUTE} component={ChangePassword} />
                <Route path={ROUTES.SUBMIT_SUGGESTIONS.ROUTE} component={SubmitSuggestion} />
                <Redirect to={{ pathname: ROUTES.DASHBOARD.LINK(), state: { from: location } }} />
            </Switch>
        </Restricted>;
    }
    return <Switch>
        <Route path={ROUTES.REPORT.ROUTE} component={Report} />
        <Route exact path={ROUTES.SURVEY.ROUTE} component={Survey} />
        <Route exact path={ROUTES.SIGN_IN.ROUTE} component={SignIn} />
        <Route exact path={ROUTES.SIGN_UP.ROUTE} component={SignUp} />
        <Route exact path={ROUTES.EMAIL_SENT.ROUTE} component={EmailSent} />
        <Route exact path={ROUTES.SET_PASSWORD.ROUTE} component={ChangePassword} />
        <Route exact path={ROUTES.RESET_PASSWORD.ROUTE} component={ResetPassword} />
        <Route path={ROUTES.SUBMIT_SUGGESTIONS.ROUTE} component={SubmitSuggestion} />
        <Route exact path={ROUTES.REQUEST_ACCOUNT.ROUTE} component={RequestAccount} />
        <Route exact path={ROUTES.FORGOT_PASSWORD.ROUTE} component={ForgotPassword} />
        <Redirect to={{ pathname: ROUTES.SIGN_IN.LINK(), state: { from: history.location } }} />
    </Switch>;
});
