
// edit own instance of env variables
export const environmentConfig = {
    SID: varString(process.env.REACT_APP_SID),
    NAME: varString(process.env.REACT_APP_NAME),
    VERSION: process.env.REACT_APP_VERSION,
    API_PATH: process.env.REACT_APP_API_PATH,
    BASE_URL: process.env.REACT_APP_BASE_URL,
    DEBUG: varBoolean(process.env.REACT_APP_DEBUG),
    SERVICE_URL: process.env.REACT_APP_SERVICE_URL,
    DATE_FORMAT: process.env.REACT_APP_DATE_FORMAT,
    WEBSITE_URL: process.env.REACT_APP_WEBSITE_URL,
    PRODUCTION: varBoolean(process.env.REACT_APP_PRODUCTION),
};

// Log for PROD
environmentConfig.PRODUCTION && console.info(`%c Version: ${environmentConfig.VERSION} `
    , 'background: #00609C; color: #fff; font-weight: bolder; font-size: 14px;');

// ON debug mode for production using url params
environmentConfig.DEBUG = environmentConfig.DEBUG || /show_DEBUG/.test(window.location.href);

environmentConfig.DEBUG
&& console.info('%c CONFIG ', 'background: #EC1B24; color: #000; font-weight: bolder; font-size: 30px;'
    , '\n full ENV:', process.env
    , '\n enviromentConfig:', environmentConfig
);

export default environmentConfig;

function varBoolean (value) {
    return /^(true|1)$/i.test(value);
}
function varString (value) {
    return /^(null|undefined)$/i.test(value) ? void 0 : value;
}
