
// services
import { defineRoute, ANNOTATION } from 'services/route';

export const HOME = '/';
export const ROOT = '/:organization';

/*-------------------------------------------------
        public routes
---------------------------------------------------*/
export const SURVEY = defineRoute('/user-survey/:surveyId');
export const WELCOME = defineRoute(HOME);
export const REPORT = defineRoute(`${ROOT}/report`, {
    query: [{ short: 'a', defaults: '', name: 'anonymous' }]
});
export const SIGN_IN = defineRoute(`${ROOT}/sign-in`);
export const SIGN_UP = defineRoute(`${ROOT}/sign-up`);
export const EMAIL_SENT = defineRoute(`${ROOT}/email-sent`);
export const REQUEST_ACCOUNT = defineRoute(`${ROOT}/request-account`);
export const FORGOT_PASSWORD = defineRoute(`${ROOT}/forgot-password`);
export const SET_PASSWORD = defineRoute(`${ROOT}/set-password`, {
    query: [ANNOTATION.TOKEN()]
});
export const RESET_PASSWORD = defineRoute(`${ROOT}/reset-password`, {
    query: [ANNOTATION.TOKEN()]
});
export const CHANGE_PASSWORD = defineRoute(`${ROOT}/change-password`, {
    query: [ANNOTATION.TOKEN()]
});

/*-------------------------------------------------
        private routes
---------------------------------------------------*/
// common
export const DASHBOARD = defineRoute(`${ROOT}/dashboard`);
// only admin roles
export const ISSUES = defineRoute(`${ROOT}/issues`);
export const ISSUES_LIST = defineRoute(`${ROOT}/issues/list`);
export const ISSUES_PAGE = defineRoute(`${ROOT}/issues/page/:id`);
export const SUGGESTIONS = defineRoute(`${ROOT}/suggestions`);
export const SUGGESTIONS_LIST = defineRoute(`${ROOT}/suggestions/list`);
export const SUGGESTIONS_PAGE = defineRoute(`${ROOT}/suggestions/page/:id`);
export const ANALYTICS = defineRoute(`${ROOT}/analytics`);
export const ANALYTICS_CHART = defineRoute(`${ROOT}/analytics/:analyticsChart`);
export const ORGANIZATION = defineRoute(`${ROOT}/organization`);
export const ORGANIZATION_LIST = defineRoute(`${ROOT}/organization/list`);
export const ORGANIZATION_PAGE = defineRoute(`${ROOT}/organization/page/:id`);
export const ORGANIZATION_CREATE = defineRoute(`${ROOT}/organization/create`);
export const ORGANIZATION_DEPARTMENT = defineRoute(`${ROOT}/organization/page/:id/department/:departmentId`);
export const USERS = defineRoute(`${ROOT}/users`);
export const USERS_LIST = defineRoute(`${ROOT}/users/list`);
export const USERS_EDIT = defineRoute(`${ROOT}/users/edit/:id`);
// only user role
export const CASES = defineRoute(`${ROOT}/cases`);
export const CASES_LIST = defineRoute(`${ROOT}/cases/list`);
export const CASES_PAGE = defineRoute(`${ROOT}/cases/page/:id`);
export const SUBMIT_SUGGESTIONS = defineRoute(`${ROOT}/submit-suggestions`, {
    query: [{ short: 'a', defaults: '', name: 'anonymous' }],
    params: [ANNOTATION.TOKEN({ name: 'organization', defaults: 'DEFAULT' })]
});
