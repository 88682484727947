
// outsource dependencies
import _ from 'lodash';

// local dependencies

// configure
// Bootstrap color. Look at src/style/scss/variables
export const textColor = 'rgb(86, 86, 86)'; // $gray-700
export const dangerColor = 'rgb(249, 31, 31)'; // $success
export const successColor = 'rgb(39, 194, 76)'; // $success
export const primaryColor = 'rgb(125, 198, 251)'; // $primary
export const secondaryColor = 'rgb(6, 115, 193)'; // $secondary
export const grayDarkColor = 'rgb(202, 202, 202)'; // $gray-300

export const getOptionId = item => _.get(item, 'id', item);
export const getOptionName = item => _.get(item, 'name', item);
export const getOptionLabel = item => _.get(item, 'label', item);
export const getOptionValue = item => _.get(item, 'value', item);
export const getOptionFullName = ({ first_name, last_name }) => `${first_name} ${last_name}`;
export const getOptionSeverity = item => _.get(item, 'value') ? _.get(item, 'label') : 'None Selected';

export const prepareValueId = (value, options) => options.find(({ id }) => id === value);
export const prepareValue = (currentValue, options) => options.find(({ value }) => value === currentValue);
export const prepareMultiValues = (value, options) => (options || []).filter(({ id }) => (value || []).includes(id));

export const prepareCustomStyle = styles => ({
    container: base => ({
        ...base,
        ..._.get(styles, 'container', {}),
    }),
    control: (base, options) => ({
        ...base,
        ...prepareControlStyle(options),
        ..._.get(styles, 'control', {}),
    }),
    multiValue: (base, options) => ({
        ...base,
        ...prepareMultiValue(options),
        ..._.get(styles, 'multiValue', {}),
    }),
    multiValueLabel: (base, options) => ({
        ...base,
        ...prepareMultiValueLabel(options),
        ..._.get(styles, 'multiValueLabel', {}),
    }),
    multiValueRemove: (base, options) => ({
        ...base,
        ...prepareMultiValueRemove(options),
        ..._.get(styles, 'multiValueRemove', {}),
    }),
    placeholder: (base, options) => ({
        ...base,
        ...preparePlaceholder(options),
        ..._.get(styles, 'placeholder', {}),
    }),
    input: (base, options) => ({
        ...base,
        ...prepareInput(options),
        ..._.get(styles, 'input', {}),
    }),
    singleValue: (base, options) => ({
        ...base,
        ...prepareSingleValue(options),
        ..._.get(styles, 'singleValue', {}),
    }),
    dropdownIndicator: (base, options) => ({
        ...base,
        ...prepareDropdownIndicator(options),
        ..._.get(styles, 'dropdownIndicator', {}),
    }),
    indicatorSeparator: base => ({
        ...base,
        display: 'none',
        ..._.get(styles, 'indicatorSeparator', {}),
    }),
    menu: (base, options) => ({
        ...base,
        ...prepareMenuList(options),
        ..._.get(styles, 'menu', {}),
    }),
    ...styles
});

export const stylesReactSelect = isInvalid => theme => ({
    ...theme,
    colors: isInvalid ? {
        ...theme.colors,
        primary75: 'rgba(35, 183, 229, 0.75)',
        primary50: 'rgba(35, 183, 229, 0.5)',
        primary25: 'rgba(35, 183, 229, 0.25)',
        primary: '#f05050',
        neutral10: '#f05050',
        neutral15: '#f05050',
        neutral20: '#f05050',
        neutral30: '#f05050',
        neutral40: '#f05050',
        neutral50: '#f05050',
        neutral60: '#f05050',
        neutral70: '#f05050',
        neutral80: '#f05050',
        neutral90: '#f05050',
    } : { ...theme.colors },
});

function prepareControlStyle ({ isDisabled, isFocused, menuIsOpen, selectProps }) {
    const borderRadius = 10;
    const isInvalid = selectProps.className.includes('is-invalid');
    const isValid = selectProps.className.includes('is-valid');
    let borderColor = grayDarkColor;
    if (isFocused) {
        borderColor = primaryColor;
    } else if (isValid) {
        borderColor = successColor;
    } else if (isInvalid) {
        borderColor = dangerColor;
    }
    return isDisabled ? {
        borderRadius,
        // #CACACA
        paddingTop: 5,
        paddingBottom: 5,
        borderColor: grayDarkColor,
        backgroundColor: 'rgb(250, 248, 248)',
    } : {
        borderColor,
        paddingTop: 5,
        paddingBottom: 5,
        boxShadow: 'none',
        borderRadius,
        ...(menuIsOpen ? {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        } : {}),
        '&:hover': {
            borderColor,
        },
    };
}

function prepareMultiValue ({ isDisabled }) {
    return isDisabled ? {
        borderRadius: 10,
        margin: 5,
        padding: '0.35em',
        backgroundColor: 'rgb(255, 255, 255)',
    } : {};
}

function prepareMultiValueLabel ({ isDisabled }) {
    return isDisabled ? {
        padding: 0,
        fontSize: '0.8em',
        color: 'rgb(86, 86, 86)',
    } : {};
}

function prepareMultiValueRemove ({ isDisabled }) {
    return isDisabled ? {
        display: 'none'
    } : {};
}

function prepareDropdownIndicator ({ isDisabled, isFocused }) {
    return isDisabled ? {
        display: 'none'
    } : {
        color: isFocused ? secondaryColor : grayDarkColor,
    };
}

function prepareMenuList ({ isDisabled }) {
    return isDisabled ? {
        display: 'none'
    } : {
        boxShadow: 'none',
        marginTop: '-1px',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: primaryColor,
        borderRadius: 10,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
    };
}

function prepareInput ({ isDisabled }) {
    return {
        paddingTop: 3,
        paddingBottom: 3,
        color: textColor,
    };
}

function preparePlaceholder ({ isDisabled }) {
    return {
        paddingTop: 6,
        paddingBottom: 6,
        fontSize: 14,
        color: grayDarkColor,
    };
}

function prepareSingleValue ({ isDisabled }) {
    return {
        paddingTop: 6,
        paddingBottom: 6,
        fontSize: 14,
    };
}
