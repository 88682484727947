
// outsource dependencies
import React, { memo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// local dependencies
import * as ROUTES from 'constants/routes';

// pages
import SuggestionsPage from './page';
import SuggestionsList from './list';

// configure

const Suggestions = memo(function Suggestions () {
    return <Switch>
        <Route path={ROUTES.SUGGESTIONS_LIST.ROUTE} component={SuggestionsList} />
        <Route path={ROUTES.SUGGESTIONS_PAGE.ROUTE} component={SuggestionsPage} />
        {/*OTHERWISE*/}
        <Redirect to={{ pathname: ROUTES.SUGGESTIONS_LIST.LINK() }} />
    </Switch>;
});

export default Suggestions;
