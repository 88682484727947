
// outsource dependencies
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useControllerActions, useControllerData } from 'redux-saga-controller';

// local dependencies
import appRootController from 'controller';

/**
 * get user role
 */
export const useRole = () => _.get(useControllerData(appRootController), 'user.role.type_name', null);

/**
 * get main organization
 */
export const useMainOrganization = () => _.get(useControllerData(appRootController), 'organization', null);

/**
 * logged user or null if it was not logged
 */
export const useSelf = () => _.get(useControllerData(appRootController), 'user', null);
export const useSelfId = () => _.get(useControllerData(appRootController), 'user.id');

/**
 * common detection of authorization
 */
export const useIsAuthorized = () => {
    const self = useSelf();
    return useMemo(() => Boolean(self), [self]);
};

//
/**
 * common list of incident categories
 */
export const useIncidentCategories = () => {
    const { getIncidentCategories } = useControllerActions(appRootController);
    useEffect(() => getIncidentCategories({}), [getIncidentCategories]);
    return useControllerData(appRootController).incidentCategories;
};

/**
 * correct extract ref to provide ability use ref with "useEffect" hook
 */
export const useRefCallback = () => {
    const [stored, set] = useState(null);
    // NOTE prevent update "reference" within render
    const ref = useCallback(api => api && set(api), []);
    return [stored, ref];
};
