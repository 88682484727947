
// outsource dependencies
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { useController } from 'redux-saga-controller';
import { Button, Col, Container, Row } from 'reactstrap';
import React, { memo, useCallback, useEffect, useMemo } from 'react';

// local dependencies
import Filter from './filter';
import Layout from 'pages/layout';
import controller from './controller';
import * as ROUTES from 'constants/routes';
import { SYSTEM_ROLE_TYPE, NEW } from 'constants/spec';
import { Restricted } from 'common/permission-provider';
import { Table, BadgeStatus, BoxLoader, Humanize, FasIcon, Pagination } from 'components';

// configure

const UsersList = memo(function UsersList () {
    const [
        { initialized, disabled, list, totalItems, sizePage, page, sortF, sortD },
        { initialize, updateData },
    ] = useController(controller);
    // NOTE initialize business logic
    useEffect(() => { initialize(); }, [initialize]);

    const handleChangePage = useCallback(page => updateData({ page }), [updateData]);
    const handleChangeSort = useCallback(({ sortF, sortD }) => updateData({ page: 1, sortF, sortD }), [updateData]);

    const prepareList = useMemo(() => (list || []).map(item => {
        const { id, first_name, last_name, email, role, organization, departments, status } = item;
        return {
            id,
            status,
            email: email || '...',
            name: `${first_name} ${last_name}`,
            role: _.get(role, 'name') || '...',
            department: _.get(departments, '0.name') || '...',
            organization: _.get(organization, 'name') || '...',
        };
    }), [list]);

    return <Layout>
        <Container id="AppUsers" className="mt-4 mb-5 h-100">
            <BoxLoader active={!initialized}>
                <Row className="g-0">
                    <Col tag="h4" xs="6" className="fw-bolder fz-24 text-gray-800 mb-4">
                        Users
                    </Col>
                    <Col xs="6" className="d-flex justify-content-end p-0 mb-4">
                        <Button
                            tag={Link}
                            color="primary"
                            disabled={disabled}
                            className="px-4 text-white"
                            to={ROUTES.USERS_EDIT.LINK({ id: NEW })}
                        >
                            <FasIcon icon="plus" className="fz-16 me-2" />
                            Add New User
                        </Button>
                    </Col>
                    <Col xs="12" className="bg-white p-3">
                        <Filter />
                    </Col>
                    { _.size(prepareList) ? <>
                        <Col xs="12">
                            <Restricted
                                role={SYSTEM_ROLE_TYPE.ORGANIZATION_ADMIN}
                                fallback={<Table
                                    sortD={sortD}
                                    sortF={sortF}
                                    data={prepareList}
                                    disabled={disabled}
                                    handleSort={handleChangeSort}
                                    link={ROUTES.USERS_EDIT.LINK}
                                >
                                    <Table.Column width={13} sortName="sort_id" title="User ID">{ ({ id }) => id }</Table.Column>
                                    <Table.Column width={18} sortName="sort_name" title="Name">{ ({ name }) => name }</Table.Column>
                                    <Table.Column width={20} sortName="sort_email" title="Email">{ ({ email }) => email }</Table.Column>
                                    <Table.Column width={12} sortName="sort_role_id" title="Role">{ ({ role }) => <Humanize tag="span" value={role} /> }</Table.Column>
                                    <Table.Column width={12} sortName="sort_organization_id" title="Organization">{ ({ organization }) => organization }</Table.Column>
                                    <Table.Column width={14} sortName="sort_department_id" title="Department">{ ({ department }) => department }</Table.Column>
                                    <Table.Column width={11} sortName="sort_status" title="Status">{ ({ status }) => <BadgeStatus type={status} /> }</Table.Column>
                                </Table>}
                            >
                                <Table
                                    sortD={sortD}
                                    sortF={sortF}
                                    data={prepareList}
                                    disabled={disabled}
                                    handleSort={handleChangeSort}
                                    link={ROUTES.USERS_EDIT.LINK}
                                >
                                    <Table.Column width={15} sortName="sort_id" title="User ID">{ ({ id }) => id }</Table.Column>
                                    <Table.Column width={20} sortName="sort_name" title="Name">{ ({ name }) => name }</Table.Column>
                                    <Table.Column width={22} sortName="sort_email" title="Email">{ ({ email }) => email }</Table.Column>
                                    <Table.Column width={15} sortName="sort_role_id" title="Role">{ ({ role }) => <Humanize tag="span" value={role} /> }</Table.Column>
                                    <Table.Column width={15} sortName="sort_department_id" title="Department">{ ({ department }) => department }</Table.Column>
                                    <Table.Column width={13} sortName="sort_status" title="Status">{ ({ status }) => <BadgeStatus type={status} /> }</Table.Column>
                                </Table>
                            </Restricted>
                        </Col>
                        <Col xs="12" className="d-flex justify-content-between bg-white p-4">
                            <Pagination
                                currentPage={page}
                                disabled={disabled}
                                sizePage={sizePage}
                                totalItems={totalItems}
                                onChange={handleChangePage}
                            />
                        </Col>
                    </> : <Col xs="12" tag="p" className="fz-32 fw-700 text-gray-700 text-center mb-4">
                        List is empty
                    </Col> }
                </Row>
            </BoxLoader>
        </Container>
    </Layout>;
});

export default UsersList;
