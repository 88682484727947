
// outsource dependencies
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';

// local dependencies

// configure

const TooltipCustom = memo(function TooltipCustom ({ message, placement, ...props }) {
    const configure = { placement };
    const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip(configure);
    const children = { ...props.children, ref: setTriggerRef };

    return <>
        { children }
        { visible && (
            <div
                ref={setTooltipRef}
                {...getTooltipProps({ className: 'tooltip-container text-gray-700 rounded-3 px-3' })}
            >
                <div {...getArrowProps({ className: 'tooltip-arrow' })} />
                { message }
            </div>
        )}
    </>;
});
TooltipCustom.propTypes = {
    message: PropTypes.string.isRequired,
    placement: PropTypes.oneOf(['auto', 'auto-start', 'auto-end', 'top', 'top-start', 'top-end', 'bottom', 'bottom-start', 'bottom-end', 'right', 'right-start', 'right-end', 'left', 'left-start', 'left-end']),
};
TooltipCustom.defaultProps = {
    placement: 'top',
};

export default TooltipCustom;
