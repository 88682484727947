
// outsource dependencies
import React, { memo, useEffect } from 'react';
import { useController } from 'redux-saga-controller';

// local dependencies
import controller from './controller';
import { BoxLoader } from 'components/preloader';

// configure

const SignUp = memo(function SignUp () {
    const [
        { initialized },
        { initialize },
    ] = useController(controller);
    // NOTE initialize business logic
    useEffect(() => { initialize(); }, [initialize]);

    return <BoxLoader active={!initialized}>
        <h1>Sign Up Page</h1>
    </BoxLoader>;
});

export default SignUp;
