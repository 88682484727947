
// outsource dependencies
import _ from 'lodash';
import { Field } from 'redux-form';
import { useWindowSize } from 'react-use';
import { Link, useParams } from 'react-router-dom';
import Scrollbars from 'react-custom-scrollbars-2';
import { useController } from 'redux-saga-controller';
import { Button, Col, Container, Row } from 'reactstrap';
import React, { memo, useCallback, useEffect, useMemo } from 'react';

// root
import { useIsAuthorized } from 'hooks';

// components
import { BoxLoader, Mailto as MailToLink, ReduxForm, RFInputRange } from 'components';

// constants
import * as ROUTES from 'constants/routes';

// local dependencies
import controller from './controller';

// images
import { DefaultImage } from 'images';
import frame from 'images/survey/frame.svg';
import companyLogo from 'images/company-logo.svg';
import mehSmiley from 'images/survey/meh-smiley.svg';
import sadSmiley from 'images/survey/sad-smiley.svg';
import leftArrow from 'images/survey/score-left-arrow.svg';
import rightArrow from 'images/survey/score-right-arrow.svg';
import smilingSmiley from 'images/survey/smiling-smiley.svg';

const defaultText = `Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore
veritatis et quasi architecto. Sed ut perspiciatis unde omnis iste natus error
sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
quae ab illo inventore veritatis et quasi architecto. Sed ut perspiciatis unde
omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam
rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto.`;

const Survey = memo(function Survey () {
    const { surveyId } = useParams();
    const isAuthorized = useIsAuthorized();
    const [
        { initialized, initialValues, disabled, survey },
        { initialize, updateCtrl, submitData }
    ] = useController(controller);

    const logo = _.get(survey, 'logo', null);
    const keyword = _.get(survey, 'keyword', 'admin');
    const background = _.get(survey, 'background', null);
    const description = _.get(survey, 'description', defaultText);
    const organizationName = _.get(survey, 'name', 'Organization');
    const feedbackTemplateTitle = _.get(survey, 'feedbackTemplateTitle', 'Feedback Template Title');
    const feedbackTemplateDescription = _.get(survey, 'feedbackTemplateDescription', defaultText);

    const { height } = useWindowSize();
    const actualHeight = useMemo(() => height - 1, [height]);

    useEffect(() => initialize({ surveyId }) && updateCtrl, [surveyId, initialize, updateCtrl]);

    return <Scrollbars style={{ height: actualHeight }}>
        <BoxLoader active={!initialized}>
            <Container fluid className="survey">
                <Row tag="section" className="survey-header d-flex justify-content-center">
                    <Col xs={12} sm={10} className="text-center text-sm-start">
                        <div className="survey-logo-wrapper d-inline-block" style={{ maxWidth: 120 }}>
                            <Link
                                className="navbar-brand w-100"
                                to={isAuthorized
                                    ? ROUTES.DASHBOARD.LINK({ organization: keyword })
                                    : ROUTES.SIGN_IN.LINK({ organization: keyword })}
                            >
                                <DefaultImage
                                    src={logo}
                                    className="w-100"
                                    alt={organizationName}
                                    title={organizationName}
                                    defaultSrc={companyLogo}
                                />
                            </Link>
                        </div>
                    </Col>
                </Row>
                <Row tag="section" className="survey-feedback d-flex justify-content-center">
                    <Col xs={12} sm={10} md={8} lg={6}>
                        <h3 className="survey-feedback-title text-secondary text-uppercase">
                            { feedbackTemplateTitle }
                        </h3>
                        <span className="feedback-details fz-18 text-pre-wrap">
                            { feedbackTemplateDescription }
                        </span>
                    </Col>
                </Row>
                <Row tag="section" className="survey-jumbotron d-flex justify-content-center">
                    <Col xs={12} md={10} className="d-flex justify-content-center">
                        <DefaultImage
                            className="w-100"
                            defaultSrc={frame}
                            defaultAlt="Frame"
                        />
                    </Col>
                </Row>
                <Row tag="section" className="survey-organization d-flex justify-content-center align-items-top flex-lg-row-reverse">
                    <Col xs={12} sm={10} lg={4}>
                        <div className="organization-image-wrapper mb-4 mb-lg-0">
                            <DefaultImage
                                width={488}
                                height={360}
                                src={background}
                                alt={organizationName}
                                title={organizationName}
                                style={{ objectFit: 'cover' }}
                                className="w-100 rounded-6 mb-3"
                            />
                        </div>
                    </Col>
                    <Col xs={12} sm={10} lg={5} className="pe-lg-5">
                        <h3 className="organization-title text-secondary">
                            { organizationName }
                        </h3>
                        <span className="organization-details fz-18 text-pre-wrap">
                            { description }
                        </span>
                    </Col>
                </Row>
                <ReduxForm
                    form="score"
                    enableReinitialize
                    onSubmit={submitData}
                    initialValues={initialValues}
                >
                    <Row tag="section" className="survey-score d-flex justify-content-center">
                        <h2 className="survey-score-title text-center text-uppercase">
                            how do you grade US from 1 to 10
                        </h2>
                        <Col xs={12} sm={11} lg={9}>
                            <Field
                                min={1}
                                step={1}
                                max={10}
                                name="score"
                                disabled={disabled}
                                component={ScoreSlider}
                            />
                        </Col>
                        <Col xs={12} className="text-center">
                            <Button
                                type="submit"
                                color="primary"
                                disabled={disabled}
                                className="btn-score-submit font-family-alternative text-white fz-26 fw-500 text-center rounded-4"
                            >
                                Submit Feedback
                            </Button>
                        </Col>
                    </Row>
                </ReduxForm>
                <section className="survey-report">
                    <Row tag="div" className="bg-primary d-flex justify-content-center">
                        <Col xs={12} sm={10} md={5} xl={4}>
                            <div className="report-callout-message-wrapper position-relative">
                                <div className="report-callout-message">
                                    <span className="fz-18 text-pre-wrap">
                                        { defaultText }
                                    </span>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} sm={10} md={5} xl={6}>
                            <div className="report-actions d-flex flex-column justify-content-center pb-4 pb-md-0">
                                <h2 className="report-actions-title font-family-alternative text-uppercase fw-600 text-center">
                                    Call to action template
                                </h2>
                                <div className="report-actions-list d-flex w-100 flex-column flex-xl-row">
                                    <Button
                                        block
                                        outline
                                        tag={Link}
                                        color="primary-white"
                                        to={isAuthorized
                                            ? ROUTES.SUBMIT_SUGGESTIONS.LINK({ organization: keyword })
                                            : ROUTES.SIGN_IN.LINK({ organization: keyword })
                                        }
                                        className="btn-extended btn-suggestion-action mb-2 mb-xl-0 me-xl-4 py-4 font-family-alternative fw-500 text-center rounded-2"
                                    >
                                        Submit an Idea
                                    </Button>
                                    <Button
                                        block
                                        outline
                                        tag={Link}
                                        color="primary-white"
                                        to={ROUTES.REPORT.LINK({ organization: keyword }, { anonymous: !isAuthorized })}
                                        className="btn-extended btn-issue-action ms-xl-4 py-4 font-family-alternative fw-500 text-center rounded-2"
                                    >
                                        File a Complaint
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </section>
                <Row tag="footer" className="survey-footer">
                    <span className="text-center">
                        &copy; 2022 &ndash; Its Anonymous &#183; Email questions to <MailToLink email="support@itsanonymous.com" obfuscate={true}>connect@rabblehealth.com</MailToLink> &#183;
                                                  Terms & Conditions &#183; Privacy Policy
                    </span>
                </Row>
            </Container>
        </BoxLoader>
    </Scrollbars>;
});

export default Survey;

const ScoreSlider = memo(function ScoreSlider (props) {
    const { min, max, step, disabled, input } = props;

    const labels = useMemo(() => {
        const labels = [];
        for (let i = min; i <= max; i += step) {
            labels.push(i);
        }
        return labels;
    }, [min, max, step]);

    const handleScoreDecrease = useCallback(() => input.onChange(input.value > min ? input.value - step : input.value), [input, min, step]);
    const handleScoreIncrease = useCallback(() => input.onChange(input.value < max ? input.value + step : input.value), [input, max, step]);

    return <div className="score-area w-100 d-flex align-items-start">
        <Button
            color="primary"
            disabled={disabled}
            onClick={handleScoreDecrease}
            className="btn-score-arrow"
        >
            <DefaultImage
                defaultSrc={leftArrow}
                defaultAlt="Score left arrow"
            />
        </Button>
        <div className="slider-area-wrapper score-ranger-area-wrapper w-100">
            <div className="slider-area score-ranger-area w-100">
                <RFInputRange {...props} />
                <div className="slider-ruler-wrapper">
                    <div className="slider-ruler d-flex justify-content-between">
                        {labels.map(item => <div className="ruler-vertical-line" key={item}>
                            <span className="vertical-line-label fz-26">{item}</span>
                        </div>)}
                    </div>
                </div>
            </div>
            <div className="slider-area-emojis d-flex justify-content-between">
                <DefaultImage
                    defaultSrc={sadSmiley}
                    defaultAlt="Sad smiley"
                    className="slider-smiley"
                />
                <DefaultImage
                    defaultSrc={mehSmiley}
                    defaultAlt="Meh smiley"
                    className="slider-smiley"
                />
                <DefaultImage
                    defaultSrc={smilingSmiley}
                    defaultAlt="Smiling smiley"
                    className="slider-smiley"
                />
            </div>
        </div>
        <Button
            color="primary"
            disabled={disabled}
            onClick={handleScoreIncrease}
            className="btn-score-arrow"
        >
            <DefaultImage
                defaultSrc={rightArrow}
                defaultAlt="Score left arrow"
            />
        </Button>
    </div>;
});
