// outsource dependencies
import _ from 'lodash';
import { Field } from 'redux-form';
import { Link, useHistory } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useController, useControllerData } from 'redux-saga-controller';
import { Button, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

// root
import appRootController from 'controller';

// components
import { BoxLoader } from 'components/preloader';
import { AppIcon, FasIcon, ReduxForm, RFInput } from 'components';

// constants
import * as ROUTES from 'constants/routes';
import { FULL_HEIGHT_SCREEN } from 'constants/spec';

// local dependencies
import controller from './controller';

// images
import { DefaultImage } from 'images';
import defaultLogo from 'images/default-logo.svg';
import companyLogo from 'images/company-logo.svg';
import sentEmail from 'images/forgot-password/dashed-line.svg';

// configure

const FORM_NAME = 'resetPassword';

const formValidation = values => {
    const errors = {};
    if (!values.password) {
        errors.password = 'Password is required';
    } else if (!/[a-z]/i.test(values.password)) {
        errors.password = 'Password must contain at least 1 letter character';
    } else if (!/[0-9]/i.test(values.password)) {
        errors.password = 'Password must contain at least 1 digit character';
    } else if (values.password.length < 8) {
        errors.password = 'Password must contain at least 8 characters long';
    }

    if (!values.password_confirmation) {
        errors.password_confirmation = 'Confirm Password is required';
    } else if (values.password_confirmation !== values.password) {
        errors.password_confirmation = 'Both passwords must match';
    }
    return errors;
};

const ResetPassword = memo(function ForgotPassword () {
    const { goBack } = useHistory();

    const [
        { initialized, disabled, initialValues, showModal },
        { initialize, submitData, updateCtrl },
    ] = useController(controller);
    // NOTE initialize business logic
    useEffect(() => { initialize(); }, [initialize]);

    const { organization } = useControllerData(appRootController);
    const logo = _.get(organization, 'logo');
    const background = _.get(organization, 'background');
    const name = _.get(organization, 'name', 'Organization Name');
    const description = _.get(organization, 'description', 'Default description');

    const handleModalToggle = useCallback(() => updateCtrl({ showModal: !showModal }), [showModal, updateCtrl]);

    return <Scrollbars style={{ height: FULL_HEIGHT_SCREEN + 1 }}>
        <BoxLoader active={!initialized}>
            <Container fluid tag={Row} className="g-0 justify-content-center h-100">
                <Col lg="6" xs="12" className="g-0 bg-lg-platform p-3 p-lg-5 d-flex flex-column">
                    <Row>
                        <Col xs="12" className="d-flex mb-5 flex-wrap flex-sm-nowrap align-items-center">
                            <DefaultImage
                                src={logo}
                                alt={`Logo ${name}`}
                                title={`Logo ${name}`}
                                defaultSrc={defaultLogo}
                                defaultAlt="Logo Company"
                                defaultTitle="Logo Company"
                                className="me-sm-5 mb-4 mb-sm-0"
                                style={{ maxWidth: 120, maxHeight: 120 }}
                            />
                            <h1 className="fz-24 fw-lg-600 fw-700 text-secondary mb-0">
                                { name }
                            </h1>
                        </Col>
                    </Row>
                    <Row className="d-none d-lg-flex justify-content-start flex-grow-1">
                        <Col xs="12" className="mb-5">
                            <DefaultImage
                                src={background}
                                alt={`Main Image ${name}`}
                                title={`Main Image ${name}`}
                                defaultAlt="Default organization"
                                defaultTitle="Default organization"
                                className="rounded-6 w-50 me-4 float-start"
                            />
                            <p dangerouslySetInnerHTML={{ __html: description }} className="font-family-alternative fz-18 text-gray-700 text-pre-wrap" />
                        </Col>
                    </Row>
                    <Row className="d-flex d-lg-none justify-content-center mb-5">
                        <Col xs="12" sm="6" className="mb-4">
                            <DefaultImage
                                src={background}
                                alt={`Main Image ${name}`}
                                className="rounded-6 w-100"
                                title={`Main Image ${name}`}
                                defaultAlt="Default organization"
                                defaultTitle="Default organization"
                            />
                        </Col>
                        <Col xs="12">
                            <p dangerouslySetInnerHTML={{ __html: description }} className="font-family-alternative fz-18 text-gray-700 text-pre-wrap" />
                        </Col>
                    </Row>
                    <Row className="justify-content-center mb-5 mb-lg-0">
                        <Col xs="10" sm="4" md="4">
                            <DefaultImage
                                src={companyLogo}
                                className="w-100"
                                alt={`Main Image ${name}`}
                                defaultAlt="Anonymous Logo"
                                title={`Main Image ${name}`}
                                defaultTitle="Anonymous Logo"
                            />
                        </Col>
                    </Row>
                </Col>
                <Col
                    lg="6"
                    xs="12"
                    tag={ReduxForm}
                    form={FORM_NAME}
                    enableReinitialize
                    onSubmit={submitData}
                    validate={formValidation}
                    initialValues={initialValues}
                    className="mt-sm-0 d-flex flex-column vh-100 justify-content-sm-center"
                >
                    <Row className="g-0 d-block d-sm-none px-3">
                        <Col xs="12">
                            <Button
                                onClick={goBack}
                                className="d-flex justify-content-center align-items-center border-0 bg-transparent p-2 mt-3"
                            >
                                <AppIcon icon="back" className="fz-20 text-secondary" />
                            </Button>
                        </Col>
                    </Row>
                    <Row className="g-0 align-content-center justify-content-center px-3 p-lg-5 flex-grow-1 flex-sm-grow-0 ">
                        <Col xs="12" sm="8" lg="8" tag="h2" className="fz-24 fw-600 text-secondary text-center mb-5">
                            Reset Password
                        </Col>
                        <Col xs="12" sm="8" lg="8" tag="p" className="font-family-alternative fz-18 mb-4">
                            Your new password must be different from previous used passwords.
                        </Col>
                        <Col xs="12" sm="8" lg="8" className="mb-3">
                            <Field
                                name="password"
                                label="New Password"
                                disabled={disabled}
                                component={PasswordInput}
                                placeholder="New Password"
                            />
                        </Col>
                        <Col xs="12" sm="8" lg="8" className="mb-3">
                            <Field
                                disabled={disabled}
                                component={PasswordInput}
                                label="Confirm Password"
                                name="password_confirmation"
                                placeholder="Confirm Password"
                            />
                        </Col>
                        <Col xs="12" sm="8" lg="8" className="d-flex justify-content-center mb-4">
                            <span className="me-1 fz-16 fw-500">Remember password?</span>
                            <Link to={ROUTES.SIGN_IN.LINK()}
                                className="font-family-alternative fz-16 fw-500 text-blue text-decoration-none text-end"
                            >
                                Log In
                            </Link>
                        </Col>
                        <Col xs="12" sm="8" lg="8" className="mb-4">
                            <Button
                                type="submit"
                                color="primary"
                                className="font-family-alternative text-white fz-16 fw-500 text-center text-secondary w-100"
                            >
                                Reset Password
                            </Button>
                        </Col>
                        <Col xs="12" sm="8" lg="8" className="mb-4">
                            <Button
                                outline
                                type="submit"
                                onClick={goBack}
                                color="primary-white"
                                className="font-family-alternative btn-extended fz-16 fw-500 w-100"
                            >
                                Back
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <ConfirmModal isOpen={showModal} dismiss={handleModalToggle} />
            </Container>
        </BoxLoader>
    </Scrollbars>;
});

export default ResetPassword;

const PasswordInput = memo(function PasswordInput (props) {
    const [isPasswordType, setPasswordType] = useState(true);

    const changeType = useCallback(() => setPasswordType(type => !type), [setPasswordType]);

    return <RFInput
        {...props}
        type={isPasswordType ? 'password' : 'text'}
        icon={<Button
            onClick={changeType}
            style={{ width: 20, height: 20 }}
            className="d-flex justify-content-center align-items-center border-0 bg-transparent p-1"
        >
            <FasIcon icon={isPasswordType ? 'eye' : 'eye-slash'} className="fz-18 text-gray-500" />
        </Button>}
    />;
});

const ConfirmModal = memo(function ConfirmModal (props) {
    const { isOpen, dismiss, success } = props;
    return <Modal
        centered
        isOpen={isOpen}
        style={{ maxWidth: 640 }}
        modalClassName="reset-password-confirmation-modal"
    >
        <ModalHeader className="border-bottom-0 justify-content-end">
            <Button
                onClick={dismiss}
                className="bg-transparent border-0 p-0 shadow-none d-flex justify-content-center me-3 mt-3 mb-5"
            >
                <FasIcon icon="times" className="fz-24 opacity-75" color="#989797" />
            </Button>
        </ModalHeader>
        <ModalBody className="confirm-modal-body">
            <Container fluid>
                <Row className="d-flex justify-content-center">
                    <Col xs={8}>
                        <h2 className="confirm-modal-message text-secondary text-center mb-5">
                            New password has been successfully set!
                        </h2>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                    <Col xs={6}>
                        <div className="actions d-flex justify-content-between mb-3">
                            <Button
                                block
                                outline
                                tag={Link}
                                onClick={success}
                                to={ROUTES.SIGN_IN.LINK()}
                                color="primary-white" className="fw-500 btn-extended btn-confirm-modal font-family-alternative"
                            >
                                Back to Log In
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </ModalBody>
        <ModalFooter className="border-top-0 justify-content-center p-0">
            <DefaultImage
                src={sentEmail}
                alt="Sent"
                className="ms-0 me-0 mb-4 mt-4"
            />
        </ModalFooter>
    </Modal>;
});
