
// outsource dependencies
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

// local dependencies

// configure

const TdLink = memo(function TdLink ({ link, title, id, tabIndex }) {
    return <td className="text-break">
        <NavLink tag={Link} tabIndex={tabIndex} to={link({ id })}>
            { title }
        </NavLink>
    </td>;
});
TdLink.propTypes = {
    link: PropTypes.func,
    id: PropTypes.number,
    tabIndex: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
TdLink.defaultProps = {
    tabIndex: '-1',
    link: e => e,
    title: '',
    id: null,
};

export default TdLink;
