
// outsource dependencies
import _ from 'lodash';
import cx from 'classnames';
import { Field } from 'redux-form';
import { Button, Col, Row } from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import React, { memo, useCallback, useState } from 'react';
import { useControllerActions, useControllerData } from 'redux-saga-controller';

// root
import { useRole, useSelf } from 'hooks';

// components
import { FasIcon, getOptionId, getOptionName, prepareMultiValues, prepareValueId, ReduxForm, RFAsyncSelect, RFInput, RFInputNumber, RFSelect } from 'components';

// constants
import { NEW, STATUS_USER_TYPE, SYSTEM_ROLE_TYPE } from 'constants/spec';

// services
import { instanceAPI } from 'services/request';
import { FilterService } from 'services/filter.service';

// common
import { Restricted } from 'common/permission-provider';

// local dependencies
import controller from './controller';

// configure
export const FORM_NAME = 'usersEditDetailsForm';
export const statusOptions = Object.values(STATUS_USER_TYPE).map(status => ({
    value: status, label: FilterService.humanize(status)
}));
const prepareBadgeInternalCategoryForReactSelect = {
    multiValue: base => ({
        ...base,
        borderRadius: '0.4em',
        margin: 2,
        padding: '0.35em',
        backgroundColor: 'rgba(125, 198, 251, 0.2)',
    }),
    multiValueLabel: base => ({
        ...base,
        padding: 0,
        fontSize: 12,
        color: 'rgb(86, 86, 86)',
    }),
    multiValueRemove: base => ({
        ...base,
        color: 'rgb(6, 115, 193)',
        '&:hover': {
            color: 'rgb(6, 115, 193)',
            backgroundColor: 'rgba(86, 86, 86, 0.2)',
        }
    }),
    input: base => ({
        ...base,
        fontSize: 14,
        paddingTop: 0,
        paddingBottom: 0,
        input: {
            padding: '0 !important',
        }
    }),
};
const formValidation = values => {
    const errors = {};
    if (!values.first_name) {
        errors.first_name = 'First Name is required';
    }
    if (!values.last_name) {
        errors.last_name = 'Last Name is required';
    }
    if (!values.role_id) {
        errors.role_id = 'User\'s Role is required';
    }
    if (values.external_id && !/^\w+$/gi.test(values.external_id)) {
        errors.external_id = 'User ID must be alphanumeric';
    }
    /*if (values.phone && values.phone.length < 10) {
        errors.phone = 'Phone to consist of 10 figures';
    }*/
    return errors;
};

const Details = memo(function Details () {
    const user = useSelf();
    const role = useRole();
    const { id } = useParams();
    const { goBack } = useHistory();

    const isNew = _.isEqual(id, NEW);

    const { initialValues, disabled } = useControllerData(controller);
    const { submitData, resetPassword } = useControllerActions(controller);

    const loadUserRoles = useCallback(() => instanceAPI({
        method: 'GET',
        url: 'role',
    }).then(response => {
        const includeListRole = [SYSTEM_ROLE_TYPE.ORGANIZATION_ADMIN, SYSTEM_ROLE_TYPE.USER];
        if (_.isEqual(role, SYSTEM_ROLE_TYPE.ORGANIZATION_ADMIN)) {
            return (response || []).filter(item => includeListRole.includes(_.get(item, 'type_name')));
        }
        return (response || []).filter(item => [...includeListRole, SYSTEM_ROLE_TYPE.PLATFORM_ADMIN].includes(_.get(item, 'type_name')));
    }), [role]);

    const loadDepartments = useCallback(() => {
        if (_.isEqual(role, SYSTEM_ROLE_TYPE.ORGANIZATION_ADMIN)) {
            return instanceAPI({ method: 'GET', url: `organization/${_.get(user, 'organization.id')}/department` })
                .then(({ data }) => data);
        }
        return instanceAPI({ method: 'GET', url: 'department/withoutPagination' });
    }, [role, user]);

    const loadOrganizations = useCallback(() => instanceAPI({
        method: 'GET',
        url: 'organization',
    }), []);

    return <ReduxForm
        form={FORM_NAME}
        enableReinitialize
        onSubmit={submitData}
        validate={formValidation}
        initialValues={initialValues}
    >
        <Row>
            <Col xs="12" className="mb-4">
                <Field
                    type="text"
                    name="first_name"
                    label="First Name"
                    disabled={disabled}
                    component={RFInput}
                    placeholder="First Name"
                />
            </Col>
        </Row>
        <Row>
            <Col xs="12" className="mb-4">
                <Field
                    type="text"
                    name="last_name"
                    label="Last Name"
                    disabled={disabled}
                    component={RFInput}
                    placeholder="Last Name"
                />
            </Col>
        </Row>
        <Row>
            <Col xs="12" className="mb-4">
                <Field
                    name="email"
                    type="email"
                    disabled={disabled}
                    component={RFInput}
                    label="User's Email"
                    placeholder="User's Email"
                />
            </Col>
        </Row>
        <Row>
            <Col xs="12" className="mb-4">
                <Field
                    type="tel"
                    name="phone"
                    disabled={disabled}
                    label="Phone Number (Optional)"
                    component={RFInputNumber}
                    placeholder="(xxx) xxx-xxxx"
                />
            </Col>
        </Row>
        <Row>
            <Col xs="12" className="mb-4">
                <Field
                    type="text"
                    name="external_id"
                    component={RFInput}
                    disabled={disabled}
                    placeholder="Organization User ID"
                    label="Organization User ID (Optional)"
                />
            </Col>
        </Row>
        <Row>
            <Col xs="12" className="mb-4">
                <Field
                    cacheOptions
                    defaultOptions
                    name="role_id"
                    label="User's Role"
                    disabled={disabled}
                    placeholder="User's Role"
                    component={RFAsyncSelect}
                    loadOptions={loadUserRoles}
                    getOptionValue={getOptionId}
                    prepareValue={prepareValueId}
                    getOptionLabel={getOptionName}
                />
            </Col>
        </Row>
        { !isNew && <Row>
            <Col xs="12" className="mb-4">
                <Field
                    name="status"
                    disabled={disabled}
                    component={RFSelect}
                    label="User's Status"
                    options={statusOptions}
                    placeholder="User's Status"
                />
            </Col>
        </Row> }
        <Row>
            <Col xs="12" className="mb-4">
                <Field
                    isMulti
                    isSearchable
                    cacheOptions
                    defaultOptions
                    name="departments"
                    disabled={disabled}
                    component={RFAsyncSelect}
                    closeMenuOnSelect={false}
                    getOptionValue={getOptionId}
                    loadOptions={loadDepartments}
                    getOptionLabel={getOptionName}
                    prepareValue={prepareMultiValues}
                    label="User’s Department/Faculty"
                    placeholder="User’s Department/Faculty"
                    styles={prepareBadgeInternalCategoryForReactSelect}
                />
            </Col>
        </Row>
        <Restricted role={SYSTEM_ROLE_TYPE.PLATFORM_ADMIN}>
            <Row>
                <Col xs="12" className="mb-4">
                    <Field
                        cacheOptions
                        defaultOptions
                        disabled={disabled}
                        label="Organization"
                        name="organization_id"
                        component={RFAsyncSelect}
                        placeholder="Organization"
                        getOptionValue={getOptionId}
                        prepareValue={prepareValueId}
                        getOptionLabel={getOptionName}
                        loadOptions={loadOrganizations}
                    />
                </Col>
            </Row>
        </Restricted>
        { !(isNew && _.isEqual(role, SYSTEM_ROLE_TYPE.ORGANIZATION_ADMIN)) && <Row className="g-0 justify-content-between mb-4">
            <Col xs="12" className={cx({ 'col-sm-8': !isNew })}>
                <Field
                    name="password"
                    label="Password"
                    placeholder="Password"
                    component={PasswordInput}
                    disabled={!_.isEqual(role, SYSTEM_ROLE_TYPE.PLATFORM_ADMIN) || disabled}
                />
            </Col>
            { !(isNew && _.isEqual(role, SYSTEM_ROLE_TYPE.PLATFORM_ADMIN)) && <Col xs="12" sm="auto" className="d-flex align-items-end">
                <Button
                    block
                    outline
                    color="primary-white"
                    onClick={resetPassword}
                    className="btn-extended"
                >
                    Reset Password
                </Button>
            </Col> }
        </Row> }
        <Row>
            <Col xs="12" className="d-flex justify-content-end">
                <Button
                    outline
                    onClick={goBack}
                    color="primary-white"
                    className="btn-extended px-5 me-3"
                >
                        Cancel
                </Button>
                <Button
                    type="submit"
                    color="primary"
                    className="text-white px-5"
                >
                    { isNew ? 'Create' : 'Update' }
                </Button>
            </Col>
        </Row>
    </ReduxForm>;
});

export default Details;

const PasswordInput = memo(function PasswordInput (props) {
    const [isPasswordType, setPasswordType] = useState(true);

    const changeType = useCallback(() => setPasswordType(type => !type), [setPasswordType]);

    return <RFInput
        {...props}
        type={isPasswordType ? 'password' : 'text'}
        icon={<Button
            onClick={changeType}
            style={{ width: 20, height: 20 }}
            className="d-flex justify-content-center align-items-center border-0 bg-transparent p-1"
        >
            <FasIcon icon={isPasswordType ? 'eye' : 'eye-slash'} className="fz-18 text-gray-500" />
        </Button>}
    />;
});

