
// outsource dependencies
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { create } from 'redux-saga-controller';
import { takeEvery, put, call, select } from 'redux-saga/effects';

// root
import appRootController from 'controller';

// constants
import * as ROUTES from 'constants/routes';

// services
import { instanceAPI } from 'services/request';

// configure

export const controller = create({
    prefix: 'welcome',
    actions: {
        initialize: 'INITIALIZE',
        submitData: 'SUBMIT_DATA',
    },
    initial: {
        disabled: false,
        initialized: false,
        errorMessage: null,

        initialValues: {},
    },
    subscriber: function * () {
        yield takeEvery(controller.action.initialize.TYPE, initializeSaga);
        yield takeEvery(controller.action.submitData.TYPE, submitDataSaga);
    }
});

export default controller;

function * initializeSaga () {
    // console.log(`%c ${type} `, 'color: #FF6766; font-weight: bolder; font-size: 12px;'
    //   , '\n payload:', payload
    // );
    const { organization } = yield select(appRootController.select);
    if (organization) {
        yield call(ROUTES.DASHBOARD.PUSH, { organization: _.get(organization, 'keyword') });
    }
    yield put(controller.action.updateCtrl({ initialized: true }));
}

function * submitDataSaga ({ payload }) {
    yield put(controller.action.updateCtrl({ disabled: true, errorMessage: null }));
    try {
        const organization = yield call(instanceAPI, { method: 'GET', params: payload, url: 'organization/access-keys/find' });
        yield put(appRootController.action.updateCtrl({ organization }));
        yield call(ROUTES.SIGN_IN.PUSH, { organization: _.get(organization, 'keyword', '') });
    } catch ({ message }) {
        yield call(toastr.error, message);
        yield put(controller.action.updateCtrl({ errorMessage: message }));
    }
    yield put(controller.action.updateCtrl({ disabled: false }));
}
