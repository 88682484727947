// outsource dependencies
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Field, getFormValues } from 'redux-form';
import React, { memo, useCallback } from 'react';
import { useStateWithCallbackLazy } from 'use-state-with-callback';

// components
import { AppIcon, isFile, RFInputImageFile } from 'components';

// services
import { isUrl } from 'services/validation.service';

// local dependencies
import { FORM_NAME } from '../controller';

// images
import { DefaultOrganizationLogo } from 'images';

// configure
const useFormValues = () => useSelector(getFormValues(FORM_NAME)) || {};
// eslint-disable-next-line max-len
const OrganizationInfoLogo = memo(function OrganizationInfoLogo ({ className, editable, disabled, organizationName, onFileSave, onFileChange, ...attr }) {
    const [fileData, setFileData] = useStateWithCallbackLazy({});
    const { logo } = useFormValues();

    // NOTE prepare actions
    const handleActionSave = useCallback(() => {
        const { fileRaw } = fileData;
        if (!_.isEmpty(fileRaw) && isFile(fileRaw)) {
            onFileSave({ ...fileData });
        }
    }, [onFileSave, fileData]);

    return <div className={cx('organization-image', className, {
        'organization-image-disabled': disabled
    })}>
        { !editable && <DefaultOrganizationLogo
            src={logo}
            width={120}
            height={120}
            className="me-5"
            alt={`Logo ${organizationName}`}
            title={`Logo ${organizationName}`}
        /> }
        { editable && <Field
            name="logo"
            disabled={disabled}
            classNameGroup="mb-3"
            component={RFInputImageFile}
            View={OrganizationLogoPreview}
            placeholder={organizationName}
            normalize={(value) => {
                setFileData((prevFile) => ({
                    ...prevFile,
                    ...value
                }), (data) => onFileChange(data));
                return _.get(value, 'filePreviewUrl', null);
            }}
            {...attr}
        /> }
    </div>;
});

OrganizationInfoLogo.propTypes = {
    editable: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    onFileSave: PropTypes.func,
    onFileChange: PropTypes.func,
    organizationName: PropTypes.string,
};

OrganizationInfoLogo.defaultProps = {
    className: '',
    editable: true,
    disabled: false,
    organizationName: '',
    onFileSave: () => false,
    onFileChange: () => false
};

export default OrganizationInfoLogo;

const OrganizationLogoPreview = memo(function OrganizationLogoPreview (props) {
    const { src, className } = props;
    const isAvailable = !_.isEmpty(src) && (isUrl(src) || src.startsWith('blob:'));

    return <div className={className}>
        { isAvailable
            ? <DefaultOrganizationLogo
                className="form-dropzone-preview-image"
                style={{ objectFit: 'scale-down' }}
                { ...props }
            />
            : <div className="form-dropzone-preview-message text-center">
                <h4 className="fz-12 fw-400 font-family-alternative text-gray-700"> Upload Logo </h4>
                <p className="fz-12 fw-400 font-family-alternative text-gray-600 text-left">
                    *JPEG, SVG, PNG are allowed (up to 2 MB) < br /> 120px х 120px
                </p>
                <AppIcon icon="upload-dnd-arrow" className="text-secondary fz-24" />
            </div> }
    </div>;
});
