
// outsource dependencies
import _ from 'lodash';
import React, { memo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { useControllerData } from 'redux-saga-controller';

// local dependencies
import { NEW } from 'constants/spec';
import controller from './controller';
import { FasIcon } from 'components/icon';
import * as ROUTES from 'constants/routes';

// configure

const BreadcrumbCustom = memo(function BreadcrumbCustom () {
    const { id } = useParams();
    const { user } = useControllerData(controller);
    return <Breadcrumb listClassName="d-flex align-items-center mb-0">
        <BreadcrumbItem>
            <Link to={ROUTES.USERS_LIST.LINK()} className="font-family-alternative fz-18 text-gray-700 text-decoration-none">
                Users
            </Link>
        </BreadcrumbItem>
        <BreadcrumbItem className="text-center fz-18">
            <FasIcon icon="angle-right" />
        </BreadcrumbItem>
        <BreadcrumbItem active className="font-family-alternative fz-18 text-gray-700">
            { id === NEW ? 'Add New User' : `${_.get(user, 'first_name') || 'username'} ${_.get(user, 'last_name') || ''}` }
        </BreadcrumbItem>
    </Breadcrumb>;
});

export default BreadcrumbCustom;
