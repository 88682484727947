
// outsource dependencies
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { useController } from 'redux-saga-controller';
import { Scrollbars } from 'react-custom-scrollbars-2';
import React, { memo, useCallback, useEffect, useMemo } from 'react';

// components
import { BoxLoader } from 'components';

// constants
import * as ROUTES from 'constants/routes';
import { FULL_HEIGHT_SCREEN } from 'constants/spec';

// local dependencies
import controller from './controller';

// configure
const dateFormat = 'MMMM D, YYYY';

const Cases = memo(function Cases () {
    const [
        { initialized, disabled, page, totalItems, list },
        { initialize, loadMore },
    ] = useController(controller);
    // NOTE initialize business logic
    useEffect(() => { initialize(); }, [initialize]);

    const handleScrollChange = useCallback(scroll => {
        if (scroll.top >= 0.98 && !disabled && list.length < totalItems) {
            loadMore({ page: page + 1 });
        }
    }, [page, loadMore, disabled, list, totalItems]);

    const prepareList = useMemo(() => (_.chain(list)
        .orderBy(['date', 'id'], ['desc', 'asc'])
        .map(item => ({
            id: _.get(item, 'id', null),
            status: _.get(item, 'status', null),
            title: _.get(item, 'brief_summary', _.get(item, 'description', '')),
            date: moment(item.date, 'YYYY-MM-DD').format(dateFormat),
        }))
        .groupBy('date')
        .thru(list => {
            const result = [];
            _.forIn(list, (value, name) => result.push({ name: prepareDateValue(name), value }));
            return result;
        })
        .value()
    ), [list]);

    return <Scrollbars style={{ height: FULL_HEIGHT_SCREEN }} onUpdate={handleScrollChange}>
        <BoxLoader id="AppCases" active={!initialized}>
            <Container className="h-100 mt-3">
                <Row>
                    { prepareList.map(({ value, name }) => <Col key={name} xs="12" className="mb-4">
                        <p className="text-secondary mb-2">
                            { name }
                        </p>
                        { value.map(({ id, status, date, title }) => <CaseBox
                            status={status}
                            title={title}
                            date={date}
                            key={id}
                            id={id}
                        />) }
                    </Col>) }
                </Row>
                {(list.length < totalItems) && <Row>
                    <span className="text-center text-gray-600">Scroll to load more items</span>
                </Row>}
            </Container>
        </BoxLoader>
    </Scrollbars>;
});

export default Cases;

const CaseBox = memo(function CaseBox ({ id, title, status, date }) {
    return <Row className="g-0 mb-3">
        <Col xs="11">
            <Link
                to={ROUTES.CASES_PAGE.LINK({ id })}
                className="text-gray-700 text-decoration-none"
            >
                <Row className="g-0">
                    <Col xs="auto">
                        <div style={{ width: 40, height: 40 }} className="bg-primary-25 rounded-5 me-3" />
                    </Col>
                    <Col xs="8" sm="10">
                        <Row className="g-0 mt-1 me-2">
                            <Col xs="12" sm="4" className="text-truncate">{ title }</Col>
                            <Col xs="12" className="fz-12 text-gray-600 mb-0">
                                <span className="me-1">{ status }</span>
                                <span className="text-secondary me-1">&#8226;</span>
                                <span>{ date }</span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Link>
        </Col>
        {/*<Col xs="1">
            <Button onClick={handleAction} className="d-flex align-items-center bg-transparent border-transparent p-2 m-0">
                <FasIcon icon="ellipsis-v" className="fz-16 text-secondary" />
            </Button>
        </Col>*/}
    </Row>;
});
CaseBox.propTypes = {
    date: PropTypes.string,
    title: PropTypes.string,
    status: PropTypes.string,
};
CaseBox.defaultProps = {
    date: '',
    title: '',
    status: '',
};

function prepareDateValue (date) {
    const range = moment().diff(moment(date, dateFormat), 'day');
    switch (range) {
        default: return date;
        case 0: return 'Today';
        case 1: return 'Yesterday';
    }
}
