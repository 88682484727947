
import _ from 'lodash';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';

// outsource dependencies
import { create } from 'redux-saga-controller';
import { takeEvery, put, call, select } from 'redux-saga/effects';


// local dependencies
import { ANALYTICS_CHART, ANALYTICS_CHART_KEYS } from '../../../../constants';
import { instanceAPI } from '../../../../services';

// configure
const dummy = [
    {
        count: 1,
        avg_rate: 1,
        month: '2021-10'
    },
    {
        count: 3,
        avg_rate: 4.8,
        month: '2021-11'
    },
    {
        count: 1,
        avg_rate: 9.38,
        month: '2021-12'
    },
    {
        count: 2,
        avg_rate: 7.4,
        month: '2022-01'
    }
];
const filterInitialize = {
    startDate: moment().startOf('year').subtract(2, 'months').format('YYYY-MM-DD').toString(),
    endDate: moment().endOf('year').format('YYYY-MM-DD').toString(),
    group: '',
};
export const ANALYTICS_CHART_REQUESTS = {
    [ANALYTICS_CHART_KEYS.SATISFACTION_FEEDBACK]: '/user-survey/feedback-statistics'
};
export const controller = create({
    prefix: 'analytics-satisfaction-feedback',
    actions: {
        initialize: 'INITIALIZE',
        getStatistic: 'GET_STATISTIC',
    },
    initial: {
        disabled: false,
        initialized: false,
        errorMessage: null,

        analyticsData: [],
        filter: filterInitialize,
    },
    subscriber: function * () {
        yield takeEvery(controller.action.initialize.TYPE, initializeSaga);
        yield takeEvery(controller.action.getStatistic.TYPE, getStatisticSaga);
    }
});

export default controller;

function * initializeSaga ({ type, payload }) {
    yield call(getStatisticSaga, {});
    yield put(controller.action.updateCtrl({ initialized: true }));
}

function * getStatisticSaga ({ type, payload }) {
    const { analyticsChart } = yield call(ANALYTICS_CHART.PARAMS);
    const analyticsRequestUrl = ANALYTICS_CHART_REQUESTS[analyticsChart];
    const { filter } = yield select(controller.select);

    if (!_.isEmpty(analyticsRequestUrl)) {
        try {
            const { data } = yield call(instanceAPI, { method: 'GET', url: analyticsRequestUrl, params: {
                date_start: filter.startDate,
                date_end: filter.endDate
            } });
            yield put(controller.action.updateCtrl({ analyticsData: !_.isEmpty(data) ? data : [...dummy] }));
        } catch ({ message }) {
            yield call(toastr.error, 'Organization List Initialize', message);
            yield put(controller.action.updateCtrl({ errorMessage: message }));
        }
    }
}
