// outsource dependencies
import _ from 'lodash';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { memo, useCallback, forwardRef } from 'react';
import { useControllerActions, useControllerData } from 'redux-saga-controller';
import { Card, CardBody, Col, Input, InputGroup, InputGroupText, Row } from 'reactstrap';


// local dependencies
import { DefaultImage } from 'images';
import controller from '../../controller';
import { BoxLoader } from '../../../../components';
import DatePickerCustom from 'components/date-picker';
import SelectCustom from 'components/select/select-custom';

import { AppIcon } from 'components/icon';

import _graph from '../../_mock/graph.png';

// configure
const fieldWidth = 280;
const prepareDisabledBadgeInternalCategoryForReactSelect = {
    container: base => ({
        ...base,
        width: fieldWidth,
    }),
    control: base => ({
        ...base,
        borderColor: 'rgb(125, 198, 251)',
    }),
    dropdownIndicator: base => ({
        ...base,
        color: 'rgb(6, 115, 193)',
    }),
};
export const CategoryAnalysis = memo(function CategoryAnalysis ({ className }) {
    const { updateCtrl } = useControllerActions(controller);
    const { initialized, filter } = useControllerData(controller);
    const { startDate, endDate, group } = filter;

    const handleDateUpdate = useCallback(date => {
        updateCtrl({ filter: { ...filter, startDate: _.first(date), endDate: _.last(date) } });
    }, [updateCtrl, filter]);

    const InputCustom = forwardRef(({ value, onClick }, ref) => <InputGroup
        className="border border-primary rounded-3 cursor-pointer"
        onClick={onClick}
    >
        <InputGroupText className="border-0 bg-white">
            <AppIcon icon="calendar" className="text-blue" />
        </InputGroupText>
        <Input
            ref={ref}
            value={value}
            onChange={e => e}
            placeholder="MM/DD/YYYY - MM/DD/YYYY"
            className="border-transparent border-2 rounded-0 p-2"
        />
        <InputGroupText className="border-0 bg-white">
            <AppIcon icon="angle-down" className="fz-18 text-secondary" />
        </InputGroupText>
    </InputGroup>);

    return (<div className={cx('', className)}>
        <BoxLoader active={!initialized}>
            <Row className="g-0">
                <Col xs="12" className="d-flex mb-4">
                    <DatePickerCustom
                        selectsRange
                        name="timeInterval"
                        label="Time Interval"
                        classNameGroup="me-3"
                        startDate={startDate}
                        endDate={endDate}
                        customInput={<InputCustom />}
                        onChange={handleDateUpdate}
                    />
                    <SelectCustom
                        value={group}
                        onChange={() => null}
                        name="groupingCategories"
                        label="Grouping Categories"
                        placeholder="Select category"
                        options={[{ value: '', label: '' }]}
                        styles={prepareDisabledBadgeInternalCategoryForReactSelect}
                    />
                </Col>
                <Col xs="12" tag={Card} className="rounded-3">
                    <CardBody className="d-flex flex-column">
                        <h4 className="fz-18 fw-bold text-secondary">Category analysis</h4>
                        {/*<p className="fz-12 fw-600 text-gray mb-5">&nbsp;</p>*/}
                        <DefaultImage
                            className="w-100"
                            defaultAlt="Graph"
                            defaultSrc={_graph}
                            defaultTitle="Graph"
                            style={{ height: 176 }}
                        />
                    </CardBody>
                </Col>
            </Row>
        </BoxLoader>
    </div>);
});

CategoryAnalysis.propTypes = {
    className: PropTypes.string,
};

CategoryAnalysis.defaultProps = {
    className: '',
};
