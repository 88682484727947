
// outsource dependencies
import _ from 'lodash';

/**
 * checks - is this string can be url
 *
 * @param {String} string
 * @returns {Boolean}
 */
export const isUrl = (string = '') => urlRegExp.test(string);

/**
 * checks - is this string can be email
 *
 * @param {String} string
 * @returns {Boolean}
 */
export const isEmail = (string = '') => emailRegExp.test(string);

/**
 * checks - is this string can be correct phone number
 *
 * @param {String} string
 * @returns {Boolean}
 */
export const isPhoneNumber = (string = '') => phoneNumberRegExp.test(string);

/**
 * checks - text
 *
 * @param {String} string
 * @param {Object} [options] options
 * @return {Boolean}
 */
export const isValidText = (string = '', options) => {
    options = _.defaults(options, { maxLength: null, noOnlySpace: true, minLength: 3, noSpace: false });
    // console.log(`%c isValidText ${string} `, 'color: #FF6766; font-weight: bolder; font-size: 12px;'
    //     , '\n noSpace:', /\s+/.test(string)
    //     , '\n noOnlySpace:', /^\s*$/.test(string)
    //     , '\n minLength:', _.size(string) < options.minLength
    //     , '\n maxLength:', _.size(string) > options.maxLength
    //     , '\n options:', options
    // );
    if (options.noSpace && /\s+/.test(string)) { return false; }
    if (options.noOnlySpace && /^\s*$/.test(string)) { return false; }
    if (options.minLength && _.size(string) < options.minLength) { return false; }
    return !(options.maxLength && _.size(string) > options.maxLength);
};
/**
 * checks - is this string can be valid name
 *
 * @param {String} string
 * @param {Object} [options] options
 * @return {Boolean}
 */
export const isName = (string, options) => isValidText(String(string), { noOnlySpace: true, minLength: 3, ...options });

/**
 * Validation service provide validation functionality within application
 */
export const ValidationService = {
    isUrl,
    isName,
    isEmail,
    isValidText,
};
export default ValidationService;

/**
 * @description Is number integer
 * @param {Number} number
 * @returns {Boolean}
 */
export const isInteger = number => Number(number) === number && number % 1 === 0;

/**
 * @description Is number float
 * @param {Number} number
 * @returns {Boolean}
 */
export const isFloat = number => Number(number) === number && number % 1 !== 0;

// default configurations
export const phoneNumberRegExp = /^(\d{3}-|\(\d{3}\)\s)\d{3}-\d{4}$/;
export const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
// eslint-disable-next-line
export const urlRegExp = /^(https?:\/\/)(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)$/;
