
// outsource dependencies
import _ from 'lodash';
import { Col, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import React, { memo, useCallback, useEffect } from 'react';
import { useController, useControllerData } from 'redux-saga-controller';

// root
import { useRefCallback } from 'hooks';

// components
import { WizardStep, BoxLoader } from 'components';

// services
import { instanceAPI } from 'services/request';

// local dependencies
import Breadcrumb from './breadcrumb';
import LayoutFallback from '../layout-fallback';
import controller, { REPORT_STEP_TYPE } from './controller';

// wizard page
import Summary from './summary';
import Preview from './preview';
import SelectIssue from './select-issue';
import AdditionalDetails from './additional-details';

// configure
export const FORM_NAME = 'reportForm';
export const loadCategories = () => instanceAPI({
    method: 'GET',
    url: 'incident/category',
}).then(response => _.sortBy(response, 'priority'));

const Report = memo(function Report () {
    const { goBack } = useHistory();
    const [
        { initialized, stepForm },
        { initialize, clearCtrl, updateCtrl }
    ] = useController(controller);

    const stepList = Object.values(REPORT_STEP_TYPE);

    const [scroll, scrollRef] = useRefCallback();

    // NOTE initialize business logic
    useEffect(() => initialize() && clearCtrl, [initialize, clearCtrl]);
    // NOTE when change new wizard screen, scroll to top
    useEffect(() => { scroll && scroll.scrollToTop(); }, [stepForm, scroll]);

    const handleBack = useCallback(() => {
        if (stepForm === REPORT_STEP_TYPE.SUMMARY) {
            return goBack();
        }
        const currentIndex = stepList.indexOf(stepForm);
        return updateCtrl({ stepForm: stepList[currentIndex - 1] });

    }, [stepForm, stepList, updateCtrl, goBack]);

    return <>
        <LayoutFallback ref={scrollRef}>
            <BoxLoader id="AppReport" active={!initialized}>
                <Row className="g-0">
                    <Col xs="12" className="d-none d-lg-inline px-5 my-4">
                        <Breadcrumb goBack={handleBack} />
                    </Col>
                    <Col xs="12" lg={{ offset: 1, size: 10 }} tag={Row} className="g-0">
                        <Col tag="h2" xs="12" className="d-none d-lg-inline fw-600 fz-24 text-gray-700 mb-4">
                            File a Complaint
                        </Col>
                        <Col xs="12" tag={Row} className="g-0 bg-white p-3 p-lg-5 mb-lg-4">
                            <Col tag="h1" xs="12" className="d-none d-lg-inline fw-700 fz-32 text-gray-700 text-center mb-5">
                                File a Complaint
                            </Col>
                            <Col xs="12" className="d-none d-lg-block mb-4">
                                <WizardStep currentStep={stepForm} list={stepList} />
                            </Col>
                            <Col xs="12" lg={{ offset: 3, size: 6 }}>
                                <WizardFormSwitch />
                            </Col>
                        </Col>
                    </Col>
                </Row>
            </BoxLoader>
        </LayoutFallback>
    </>;
});

export default Report;

const WizardFormSwitch = memo(function WizardFormSwitch () {
    const { stepForm } = useControllerData(controller);

    switch (stepForm) {
        default:
            console.error('No implement step form: ', stepForm);
            break;
        case REPORT_STEP_TYPE.SUMMARY: return <Summary />;
        case REPORT_STEP_TYPE.PREVIEW: return <Preview />;
        case REPORT_STEP_TYPE.SELECT_CATEGORY: return <SelectIssue />;
        case REPORT_STEP_TYPE.ADDITIONAL_DETAILS: return <AdditionalDetails />;
    }
});
