
// outsource dependencies
import _ from 'lodash';
import { getFormValues } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import { create } from 'redux-saga-controller';
import { takeEvery, put, call, select } from 'redux-saga/effects';

// constants
import { ANONYMOUS } from 'constants/spec';
import { ISSUES_PAGE } from 'constants/routes';

// services
import { instanceAPI } from 'services';

// local dependencies
import { FORM_NAME } from './index';

// configure

export const controller = create({
    prefix: 'users-edit',
    actions: {
        unsealed: 'UNSEALED',
        initialize: 'INITIALIZE',
        submitData: 'SUBMIT_DATA',
    },
    initial: {
        disabled: false,
        initialized: false,
        errorMessage: null,

        id: null,
        comment: [],
        initialValues: {
            offenders: [{}],
        },
    },
    subscriber: function * () {
        yield takeEvery(controller.action.initialize.TYPE, initializeSaga);
        yield takeEvery(controller.action.submitData.TYPE, submitDataSaga);
        yield takeEvery(controller.action.unsealed.TYPE, unsealedSaga);
    }
});

export default controller;

function * initializeSaga () {
    // console.log(`%c ${type} `, 'color: #FF6766; font-weight: bolder; font-size: 12px;'
    //   , '\n payload:', payload
    // );
    const { id } = yield call(ISSUES_PAGE.PARAMS);
    yield put(controller.action.updateCtrl({ id }));
    try {
        const data = yield call(instanceAPI, { method: 'GET', url: `/incident/${id}` });
        const firstName = _.get(data, 'user.first_name', '');
        const lastName = _.get(data, 'user.last_name', '');
        const userName = _.isEmpty(firstName) && _.isEmpty(lastName) ? ANONYMOUS : `${firstName} ${lastName}`;
        yield put(controller.action.updateCtrl({ initialValues: {
            offenders: [{}],
            ...data,
            user: userName,
            department_id: _.get(data, 'department.id'),
            organization_id: _.get(data, 'organization.id'),
            categories: (_.get(data, 'categories', [])).map(({ id }) => id),
            internal_categories: (_.get(data, 'internal_categories', [])).map(({ id }) => id),
        } }));
    } catch ({ message }) {
        yield call(toastr.error, 'Isues Page Initialize', message);
        yield put(controller.action.updateCtrl({ errorMessage: message }));
    }
    yield put(controller.action.updateCtrl({ initialized: true }));
}

function * unsealedSaga () {
    yield put(controller.action.updateCtrl({ disabled: true, showModal: false }));
    try {
        const { id, initialValues } = yield select(controller.select);
        yield call(instanceAPI, { method: 'PUT', url: `/incident/unseal/${id}` });
        yield put(controller.action.updateCtrl({ initialValues: { ...initialValues, unsealed: 1 } }));
    } catch ({ message }) {
        yield call(toastr.error, 'Complaint', message);
        yield put(controller.action.updateCtrl({ errorMessage: message }));
    }
    yield put(controller.action.updateCtrl({ disabled: false }));
}

function * submitDataSaga () {
    yield put(controller.action.updateCtrl({ disabled: true }));
    try {
        const { id } = yield select(controller.select);
        const formData = yield select(getFormValues(FORM_NAME));
        const data = _.omit(formData, 'files');
        yield call(instanceAPI, { method: 'POST', url: `/incident/update/${id}`, data });
    } catch ({ message }) {
        yield call(toastr.error, 'Issue Page Submit Data', message);
        yield put(controller.action.updateCtrl({ errorMessage: message }));
    }
    yield put(controller.action.updateCtrl({ disabled: false }));
}
