
import { cookieStorage, Storage } from './storage';

/**
 * Prepared storages for specific things
 */
const local = new Storage(window.localStorage);
const cookieIgnored = new Storage(cookieStorage);
const sessionIgnored = new Storage(window.sessionStorage);
/**
 * Ready to use storages for specific things
 */
export const AccessTokenStorage = local.bindToPath('ats');
export const RefreshTokenStorage = local.bindToPath('rts');
