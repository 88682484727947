
// outsource dependencies
import React, { memo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// local dependencies
import * as ROUTES from 'constants/routes';

// pages
import CasesPage from './page';
import CasesList from './list';

// configure

const Cases = memo(function Cases () {
    return <Switch>
        <Route path={ROUTES.CASES_LIST.ROUTE} component={CasesList} />
        <Route path={ROUTES.CASES_PAGE.ROUTE} component={CasesPage} />
        {/*OTHERWISE*/}
        <Redirect to={{ pathname: ROUTES.CASES_LIST.LINK() }} />
    </Switch>;
});

export default Cases;
