
// outsource dependencies
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { create } from 'redux-saga-controller';
import { takeEvery, put, call, select } from 'redux-saga/effects';

// root
import appController from 'controller';

// constants
import { NEW } from 'constants/spec';
import { history } from 'constants/history';
import { USERS_EDIT, USERS_LIST } from 'constants/routes';

// services
import { instanceAPI } from 'services/request';

// configure
export const TABS_TYPE = {
    DETAILS: 'DETAILS',
};

export const controller = create({
    prefix: 'users-edit',
    actions: {
        initialize: 'INITIALIZE',
        submitData: 'SUBMIT_DATA',
        resetPassword: 'RESET_PASSWORD',
    },
    initial: {
        disabled: false,
        initialized: false,
        errorMessage: null,

        activeTab: TABS_TYPE.DETAILS
    },
    subscriber: function * () {
        yield takeEvery(controller.action.initialize.TYPE, initializeSaga);
        yield takeEvery(controller.action.submitData.TYPE, submitDataSaga);
        yield takeEvery(controller.action.resetPassword.TYPE, resetPasswordSaga);
    }
});

export default controller;

function * initializeSaga () {
    // console.log(`%c ${type} `, 'color: #FF6766; font-weight: bolder; font-size: 12px;'
    //   , '\n payload:', payload
    // );
    try {
        const { id } = yield call(USERS_EDIT.PARAMS);
        if (id !== NEW) {
            const { data } = yield call(instanceAPI, { method: 'GET', url: `/organization/user/${id}` });
            const user = {
                ...data,
                role_id: _.get(data, 'role.id'),
                departments: _.get(data, 'departments', []).map(({ id }) => id),
                organization_id: _.get(data, 'organization.id')
            };
            yield put(controller.action.updateCtrl({ user, initialValues: user }));
        }
    } catch ({ message }) {
        yield call(toastr.error, 'Users Edit Page Initialize', message);
        yield put(controller.action.updateCtrl({ errorMessage: message }));
    }
    yield put(controller.action.updateCtrl({ initialized: true }));
}

function * submitDataSaga ({ payload }) {
    yield put(controller.action.updateCtrl({ disabled: true }));
    const { id } = yield call(USERS_EDIT.PARAMS);
    const { organization } = yield select(appController.select);
    const data = {
        ...payload,
        organization_id: payload.organization_id
            ? payload.organization_id
            : organization.id
    };
    try {
        if (_.isEqual(id, NEW)) {
            yield call(instanceAPI, { method: 'POST', url: '/organization/user/create', data });
            yield call(toastr.success, 'User', 'User was successfully created');
        } else {
            yield call(instanceAPI, { method: 'PUT', url: `/organization/user/${id}`, data });
            yield call(toastr.success, 'User', 'User was successfully updated');
        }
        yield call(history.push, USERS_LIST.LINK());
    } catch ({ message }) {
        yield call(toastr.error, 'Users Edit Submit Data', message);
        yield put(controller.action.updateCtrl({ errorMessage: message }));
    }
    yield put(controller.action.updateCtrl({ disabled: false }));
}

function * resetPasswordSaga () {
    yield put(controller.action.updateCtrl({ disabled: true }));
    try {
        const { user } = yield select(controller.select);
        const params = { email: _.get(user, 'email') };
        const { message } = yield call(instanceAPI, { method: 'GET', url: '/password/reset', params });
        yield call(toastr.success, 'Reset Password', message);
    } catch ({ message }) {
        yield call(toastr.error, 'Reset Password', message);
        yield put(controller.action.updateCtrl({ errorMessage: message }));
    }
    yield put(controller.action.updateCtrl({ disabled: false }));
}
