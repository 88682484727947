
import moment from 'moment';

// outsource dependencies
import { create } from 'redux-saga-controller';
import { takeEvery, put, call, select } from 'redux-saga/effects';

// configure
const filterInitialize = {
    startDate: moment().startOf('year').format('YYYY-MM-DD').toString(),
    endDate: moment().endOf('year').format('YYYY-MM-DD').toString(),
    group: '',
};

export const controller = create({
    prefix: 'analytics',
    actions: {
        initialize: 'INITIALIZE',
    },
    initial: {
        disabled: false,
        initialized: false,
        errorMessage: null,

        filter: filterInitialize,
    },
    subscriber: function * () {
        yield takeEvery(controller.action.initialize.TYPE, initializeSaga);
    }
});

export default controller;

function * initializeSaga ({ type, payload }) {
    yield put(controller.action.updateCtrl({ initialized: true }));
}
