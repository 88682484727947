
// outsource dependencies
import _ from 'lodash';
import { Field, FieldArray } from 'redux-form';
import { useController } from 'redux-saga-controller';
import { useHistory, useParams } from 'react-router-dom';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { Button, Col, Container, Label, Modal, ModalBody, Row } from 'reactstrap';

// root
import { useSelf } from 'hooks';
import Layout from 'pages/layout';

// components
import {
    getOptionName, getOptionSeverity, prepareMultiValues, RFAsyncSelect, BoxLoader,
    RFInput, FasIcon, RFDatePicker, ReduxForm, RFSelect, CommentField, CommentsBlock,
    prepareBadgeStatusStyleForReactSelect, prepareBadgeSeverityStyleForReactSelect,
    prepareValueId, getOptionId, AlertError, AppIcon, InputDropAreaFileList,
} from 'components';

// constants
import { STATUS_TYPE, SYSTEM_ROLE_TYPE, severityOptions } from 'constants/spec';

// common
import { Restricted } from 'common/permission-provider';

// services
import { FilterService, instanceAPI } from 'services';

// local dependencies
import controller from './controller';
import Breadcrumb from './breadcrumb';

// configure
const loadOrganizations = () => instanceAPI({ method: 'GET', url: 'organization' });
const loadCategories = () => instanceAPI({ method: 'GET', url: 'incident/category' });

export const statusOptions = Object.values(STATUS_TYPE).map(status => ({
    value: status, label: FilterService.humanize(status)
}));

export const FORM_NAME = 'issuePageForm';

const IssuesPage = memo(function IssuesPage () {
    const user = useSelf();
    const { id } = useParams();
    const { goBack } = useHistory();
    const [
        { initialized, disabled, initialValues, comment, showModal },
        { initialize, submitData, updateCtrl, unsealed },
    ] = useController(controller);
    // NOTE initialize business logic
    useEffect(() => { initialize(); }, [initialize]);

    const isPlatformCEO = Boolean(_.get(user, 'is_super', 0));
    const isDescriptionUnsealed = Boolean(_.get(initialValues, 'unsealed', 0));
    const hasSecretDescription = Boolean(_.get(initialValues, 'have_secret', 0));

    const handleUpdate = useCallback(_.debounce(submitData, 5e2), [submitData]);

    const handleUpdateComment = useCallback(item => {
        return updateCtrl({ comment: comment.concat(item) });
    }, [comment, updateCtrl]);

    const loadDepartments = useCallback(() => {
        if (_.get(user, 'role.type_name') === SYSTEM_ROLE_TYPE.ORGANIZATION_ADMIN) {
            return instanceAPI({ method: 'GET', url: `organization/${_.get(user, 'organization.id')}/department` })
                .then(({ data }) => data);
        }
        return instanceAPI({ method: 'GET', url: 'department/withoutPagination' });
    }, [user]);

    const handleModalToggle = useCallback(() => updateCtrl({ showModal: !showModal }), [updateCtrl, showModal]);

    return <Layout>
        <BoxLoader id="AppIssuePage" active={!initialized}>
            <ReduxForm
                form={FORM_NAME}
                enableReinitialize
                onSubmit={submitData}
                initialValues={initialValues}
            >
                <Row className="mx-5 my-4">
                    <Col xs="12" className="d-flex align-items-center">
                        <Button color="primary" onClick={goBack} className="text-white px-4 me-4">
                            <FasIcon icon="arrow-left" />
                        </Button>
                        <Breadcrumb />
                    </Col>
                </Row>
                <Container className="mt-4 mb-5">
                    <Row className="g-0">
                        <Col tag="h1" xs="12" className="fw-bolder fz-24 text-gray-700 mb-4">
                            Complaint { id }
                        </Col>
                        <Col xs="12">
                            <Row className="bg-white p-4">
                                <Col xs="6">
                                    <Row>
                                        <Col tag="h2" xs="12" className="fw-600 fz-24 text-secondary text-center mb-4">
                                            Internal Notes
                                        </Col>
                                        <Col xs="6" className="mb-4">
                                            <Field
                                                name="status"
                                                label="Status"
                                                disabled={disabled}
                                                placeholder="Status"
                                                component={RFSelect}
                                                onChange={handleUpdate}
                                                options={statusOptions}
                                                styles={{ singleValue: prepareBadgeStatusStyleForReactSelect }}
                                            />
                                        </Col>
                                        <Col xs="6" className="mb-4">
                                            <Field
                                                name="severity"
                                                label="Severity"
                                                disabled={disabled}
                                                component={RFSelect}
                                                onChange={handleUpdate}
                                                options={severityOptions}
                                                placeholder="None Selected"
                                                getOptionLabel={getOptionSeverity}
                                                styles={{ singleValue: prepareBadgeSeverityStyleForReactSelect }}
                                            />
                                        </Col>
                                        <Restricted
                                            role={SYSTEM_ROLE_TYPE.ORGANIZATION_ADMIN}
                                            fallback={<>
                                                <Col xs="6" className="mb-4">
                                                    <Field
                                                        cacheOptions
                                                        defaultOptions
                                                        disabled={disabled}
                                                        label="Organization"
                                                        name="organization_id"
                                                        onChange={handleUpdate}
                                                        component={RFAsyncSelect}
                                                        placeholder="Organization"
                                                        getOptionValue={getOptionId}
                                                        prepareValue={prepareValueId}
                                                        getOptionLabel={getOptionName}
                                                        loadOptions={loadOrganizations}
                                                    />
                                                </Col>
                                                <Col xs="6" className="mb-4">
                                                    <Field
                                                        cacheOptions
                                                        defaultOptions
                                                        label="Department"
                                                        disabled={disabled}
                                                        name="department_id"
                                                        onChange={handleUpdate}
                                                        placeholder="Department"
                                                        component={RFAsyncSelect}
                                                        getOptionValue={getOptionId}
                                                        prepareValue={prepareValueId}
                                                        loadOptions={loadDepartments}
                                                        getOptionLabel={getOptionName}
                                                    />
                                                </Col>
                                                <Col xs="12" className="mb-4">
                                                    <Field
                                                        type="text"
                                                        label="Referred to"
                                                        disabled={disabled}
                                                        component={RFInput}
                                                        name="assigned_user"
                                                        onChange={handleUpdate}
                                                        placeholder="Referred to"
                                                    />
                                                </Col>
                                                <Col xs="12" className="mb-4">
                                                    <Field
                                                        isMulti
                                                        cacheOptions
                                                        isSearchable
                                                        defaultOptions
                                                        label="Category"
                                                        disabled={disabled}
                                                        placeholder="Category"
                                                        onChange={handleUpdate}
                                                        component={RFAsyncSelect}
                                                        closeMenuOnSelect={false}
                                                        name="internal_categories"
                                                        getOptionValue={getOptionId}
                                                        loadOptions={loadCategories}
                                                        getOptionLabel={getOptionName}
                                                        prepareValue={prepareMultiValues}
                                                        styles={prepareBadgeInternalCategoryForReactSelect}
                                                    />
                                                </Col>
                                            </>}
                                        >
                                            <Col xs="6" className="mb-4">
                                                <Field
                                                    cacheOptions
                                                    defaultOptions
                                                    label="Department"
                                                    disabled={disabled}
                                                    name="department_id"
                                                    onChange={handleUpdate}
                                                    placeholder="Department"
                                                    component={RFAsyncSelect}
                                                    getOptionValue={getOptionId}
                                                    prepareValue={prepareValueId}
                                                    loadOptions={loadDepartments}
                                                    getOptionLabel={getOptionName}
                                                />
                                            </Col>
                                            <Col xs="6" className="mb-4">
                                                <Field
                                                    type="text"
                                                    label="Referred to"
                                                    disabled={disabled}
                                                    component={RFInput}
                                                    name="assigned_user"
                                                    onChange={handleUpdate}
                                                    placeholder="Referred to"
                                                />
                                            </Col>
                                            <Col xs="12" className="mb-4">
                                                <Field
                                                    isMulti
                                                    cacheOptions
                                                    isSearchable
                                                    defaultOptions
                                                    label="Category"
                                                    disabled={disabled}
                                                    placeholder="Category"
                                                    onChange={handleUpdate}
                                                    component={RFAsyncSelect}
                                                    closeMenuOnSelect={false}
                                                    name="internal_categories"
                                                    getOptionValue={getOptionId}
                                                    loadOptions={loadCategories}
                                                    getOptionLabel={getOptionName}
                                                    prepareValue={prepareMultiValues}
                                                    styles={prepareBadgeInternalCategoryForReactSelect}
                                                />
                                            </Col>
                                        </Restricted>
                                        <Col xs="12" className="mb-4">
                                            <Field
                                                rows={6}
                                                className="p-3"
                                                type="textarea"
                                                disabled={disabled}
                                                component={RFInput}
                                                name="internal_summary"
                                                onChange={handleUpdate}
                                                label="Internal Summary"
                                                placeholder="Internal Summary"
                                            />
                                        </Col>
                                        <Col xs="12" className="mb-4">
                                            <CommentField onChange={handleUpdateComment} />
                                        </Col>
                                        { _.size(comment) ? <Col xs="12" className="ps-5">
                                            <p className="font-family-alternative fw-500 text-gray-500">Comments</p>
                                            <CommentsBlock list={comment} />
                                        </Col>: null }
                                    </Row>
                                </Col>
                                <Col xs="6">
                                    <Row>
                                        <Col tag="h2" xs="12" className="fw-600 fz-24 text-gray-700 text-center mb-4">
                                            Original Submission
                                        </Col>
                                        <Col xs="6" className="mb-4">
                                            <Field
                                                disabled
                                                type="text"
                                                name="user"
                                                label="Originator"
                                                component={RFInput}
                                                placeholder="Originator"
                                            />
                                        </Col>
                                        <Col xs="6" className="mb-4">
                                            <Field
                                                disabled
                                                name="date"
                                                label="Date"
                                                component={RFDatePicker}
                                            />
                                        </Col>
                                    </Row>
                                    <FieldArray
                                        name="offenders"
                                        disabled={disabled}
                                        component={Offenders}
                                    />
                                    <Row>
                                        <Col xs="12" className="mb-4">
                                            <Field
                                                isMulti
                                                disabled
                                                cacheOptions
                                                defaultOptions
                                                label="Category"
                                                name="categories"
                                                placeholder="Category"
                                                component={RFAsyncSelect}
                                                closeMenuOnSelect={false}
                                                getOptionValue={getOptionId}
                                                loadOptions={loadCategories}
                                                getOptionLabel={getOptionName}
                                                prepareValue={prepareMultiValues}
                                            />
                                        </Col>
                                        <Col xs="12" className="mb-4">
                                            <Field
                                                disabled
                                                rows={3}
                                                type="textarea"
                                                component={RFInput}
                                                name="brief_summary"
                                                label="Brief Summary"
                                                placeholder="Brief Summary"
                                            />
                                        </Col>
                                        <Col xs="12" className="mb-4">
                                            <Field
                                                disabled
                                                rows={12}
                                                type="textarea"
                                                name="description"
                                                label="Description"
                                                component={RFInput}
                                                placeholder="Description"
                                            />
                                        </Col>
                                        { hasSecretDescription && <Col xs="12" className="mb-4">
                                            { isDescriptionUnsealed
                                                ? <Field
                                                    disabled
                                                    rows={3}
                                                    type="textarea"
                                                    component={RFInput}
                                                    name="secret_description"
                                                    label="Sealed description"
                                                    className="form-control-sealed"
                                                    placeholder="Sealed description"
                                                />
                                                : <div className="mb-4 unseal-record">
                                                    <div className="unseal-record-alert">
                                                        <div className="unseal-record-label">
                                                            <Label>Sealed Description</Label>
                                                        </div>
                                                        <AlertError
                                                            title=""
                                                            color="sealed"
                                                            message={<div className="d-flex justify-content-start align-items-center">
                                                                <AppIcon icon={'sealed-shield'} className="fz-24 me-2" />
                                                                <span className="font-family-alternative fz-12 text-gray-700">There is a Sealed Description to this Complaint.</span>
                                                            </div>}
                                                        />
                                                        <ConfirmModal
                                                            isOpen={showModal}
                                                            success={unsealed}
                                                            dismiss={handleModalToggle}
                                                        />
                                                    </div>
                                                    <div className="text-end">
                                                        { isPlatformCEO && <Button
                                                            outline
                                                            color="primary-white"
                                                            onClick={handleModalToggle}
                                                            className="fw-500 btn-unseal btn-extended font-family-alternative"
                                                        >
                                                                Unseal the Report
                                                        </Button> }
                                                    </div>
                                                </div>
                                            }
                                        </Col> }

                                        <Col xs="12" className="mb-4">
                                            <Field
                                                name="files"
                                                downloadable
                                                editable={false}
                                                disabled={disabled}
                                                classNameGroup="mb-3"
                                                allowSealFile={false}
                                                dropAreaVisible={false}
                                                component={InputDropAreaFileList}
                                            />
                                        </Col>

                                        {/*<Col xs="12" className="mb-4">*/}
                                        {/*    <DownloadAttachFile name="2156644.pdf" size="1,2 MB" />*/}
                                        {/*</Col>*/}
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </ReduxForm>
        </BoxLoader>
    </Layout>;
});

export default IssuesPage;

const Offenders = memo(function Offenders (props) {
    const { fields } = props;

    const items = useMemo(() => fields.map((mKey, index) => ({
        mKey,
        index,
        value: fields.get(index),
    })), [fields]);

    return <>
        {!_.isEmpty(items) && <Row>
            <Col xs="12" tag="p" className="fz-14 mb-2">
                Name of offender(s)
            </Col>
        </Row>}
        {items.map(({ mKey }) => <Row
            key={mKey}
            className="justify-content-between mb-2 border-1 border-secondary rounded-1 p-1 p-lg-0 mb-3"
        >
            <Col xs="12" lg="6" className="mb-2">
                <Field
                    disabled
                    type="text"
                    label="Name"
                    component={RFInput}
                    name={`${mKey}.name`}
                    placeholder="Name of offender"
                />
            </Col>
            <Col xs="12" lg="6" className="mb-2">
                <Field
                    disabled
                    type="text"
                    label="Relation"
                    component={RFInput}
                    name={`${mKey}.relation`}
                    placeholder="Colleague/Mid or Top Management"
                />
            </Col>
        </Row>)}
    </>;
});

const ConfirmModal = memo(function ConfirmModal (props) {
    const { isOpen, dismiss, success } = props;
    return <Modal centered isOpen={isOpen} modalClassName="confirmation-modal" >
        <ModalBody className="confirm-modal-body">
            <Container fluid>
                <Row className="d-flex justify-content-center">
                    <Col xs={8}>
                        <h2 className="confirm-modal-message text-secondary text-center">Are you sure you want to unseal this description?</h2>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                    <Col xs={10}>
                        <div className="actions d-flex justify-content-between">
                            <Button onClick={dismiss} outline color="primary-white" className="fw-500 btn-extended btn-confirm-modal font-family-alternative" >
                                No
                            </Button>
                            <Button onClick={success} outline color="primary-white" className="fw-500 btn-extended btn-confirm-modal font-family-alternative">
                                Yes
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </ModalBody>
    </Modal>;
});

const prepareBadgeInternalCategoryForReactSelect = {
    multiValue: base => ({
        ...base,
        borderRadius: '0.4em',
        margin: 5,
        padding: '0.35em',
        backgroundColor: 'rgba(125, 198, 251, 0.2)',
    }),
    multiValueLabel: base => ({
        ...base,
        padding: 0,
        fontSize: '0.8em',
        color: 'rgb(86, 86, 86)',
    }),
    multiValueRemove: base => ({
        ...base,
        color: 'rgb(6, 115, 193)',
        '&:hover': {
            color: 'rgb(6, 115, 193)',
            backgroundColor: 'rgba(86, 86, 86, 0.2)',
        }
    }),
    input: base => ({
        ...base,
        fontSize: '0.8em',
        paddingTop: 0,
        paddingBottom: 0,
        input: {
            padding: '0 !important',
        }
    }),
};
