/* eslint react/prop-types: "off" */

// outsource dependencies
import React from 'react';
import { Col, Row } from 'reactstrap';
import { toastr } from 'react-redux-toastr';

// local dependencies

// NOTE developer component to test router features
function NoMatch (props) {
    return <div className="container-fluid">
        <Row>
            <Col tag="h3" xs="10" className="offset-1 text-center top-indent-4">
        No Match page for&nbsp;
                <code> { props.location.pathname } </code>
            </Col>
        </Row>
        <Row>
            <Col tag="h3" xs="10" className="offset-1 text-center top-indent-4">
        Props
                <hr/>
                <code> { JSON.stringify(props) } </code>
                <hr/>
            </Col>
        </Row>
        <Row>
            <Col tag="h1" xs="12">
        Toastr
            </Col>
        </Row>
        <Row className="align-items-center" style={{ height: '200px' }}>
            <Col xs="2">
                <button className="btn btn-block btn-dark" onClick={e => toastr.message('title', '1')}>
          1
                </button>
            </Col>
            <Col xs="2">
                <button className="btn btn-block btn-light" onClick={e => toastr.light('title', '2')}>
          2
                </button>
            </Col>
            <Col xs="2">
                <button className="btn btn-block btn-warning" onClick={e => toastr.warning('title', '3')}>
          3
                </button>
            </Col>
            <Col xs="2">
                <button className="btn btn-block btn-danger" onClick={e => toastr.error('title', '4')}>
          4
                </button>
            </Col>
            <Col xs="2">
                <button className="btn btn-block btn-info" onClick={e => toastr.info('title', '5')}>
          5
                </button>
            </Col>
            <Col xs="2">
                <button className="btn btn-block btn-success" onClick={e => toastr.success('title', '6')}>
          6
                </button>
            </Col>
        </Row>
    </div>;
}

export default NoMatch;
