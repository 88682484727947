
import _ from 'lodash';

// outsource dependencies
import { Field } from 'redux-form';
import { Button, Col, Row } from 'reactstrap';
import React, { memo, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useControllerActions, useControllerData } from 'redux-saga-controller';

// local dependencies
import controller from './controller';
import { NEW, STATUS_USER_TYPE } from 'constants/spec';
import { FilterService, instanceAPI, isEmail } from 'services';
import { getOptionFullName, getOptionId, prepareMultiValues, ReduxForm, RFAsyncSelect, RFInput, RFInputNumber, RFSelect } from 'components';

// configure
export const FORM_NAME = 'organizationDepartmentDetailsForm';
export const statusOptions = Object.values(STATUS_USER_TYPE).map(status => ({
    value: status, label: FilterService.humanize(status)
}));

const formValidation = values => {
    const errors = {};
    if (!values.name) {
        errors.name = 'Department Name is required';
    }
    if (!values.status) {
        errors.status = 'Status name is required';
    }
    if (values.phone && values.phone.length < 10) {
        errors.phone = 'Phone to consist of 10 figures';
    }
    return errors;
};

const Details = memo(function Details () {
    const { goBack } = useHistory();
    const { departmentId } = useParams();
    const { initialValues, disabled } = useControllerData(controller);
    const { submitData } = useControllerActions(controller);
    const isNew = _.isEqual(departmentId, NEW);

    const loadManagers = useCallback(() => instanceAPI({
        method: 'GET',
        url: 'department/admin',
    }), []);

    return <ReduxForm
        form={FORM_NAME}
        enableReinitialize
        onSubmit={submitData}
        validate={formValidation}
        initialValues={initialValues}
    >
        <Row>
            <Col xs="12" className="mb-4">
                <Field
                    type="text"
                    name="name"
                    placeholder="Name"
                    disabled={disabled}
                    component={RFInput}
                    label="Department Name"
                />
            </Col>
            <Col xs="12" className="mb-4">
                <Field
                    type="tel"
                    name="phone"
                    disabled={disabled}
                    label="Phone Number"
                    component={RFInputNumber}
                    placeholder="(xxx) xxx-xxxx"
                />
            </Col>
            <Col xs="12" className="mb-4">
                <Field
                    type="email"
                    name="email"
                    placeholder="Email"
                    disabled={disabled}
                    component={RFInput}
                    label="Department Email"
                />
            </Col>
            <Col xs="12" className="mb-4">
                <Field
                    type="text"
                    name="address.street1"
                    label="Address"
                    disabled={disabled}
                    component={RFInput}
                    placeholder="Line 1"
                />
            </Col>
            <Col xs="12" className="mb-4">
                <Field
                    type="text"
                    name="address.street2"
                    disabled={disabled}
                    component={RFInput}
                    placeholder="Line 2"
                />
            </Col>
            <Col xs="12" className="mb-4">
                <Field
                    type="text"
                    name="address.city"
                    placeholder="City"
                    disabled={disabled}
                    component={RFInput}
                />
            </Col>
            <Col xs="6" className="mb-4">
                <Field
                    type="text"
                    name="address.state"
                    placeholder="State"
                    disabled={disabled}
                    component={RFInput}
                />
            </Col>
            <Col xs="6" className="mb-4">
                <Field
                    type="number"
                    name="address.zip"
                    disabled={disabled}
                    component={RFInput}
                    placeholder="ZIP code"
                />
            </Col>
            <Col xs="12" className="mb-4">
                <Field
                    type="text"
                    name="address.country"
                    placeholder="Country"
                    disabled={disabled}
                    component={RFInput}
                />
            </Col>
            { departmentId !== NEW && <Col xs="12" className="mb-4">
                <Field
                    name="status"
                    label="Status"
                    disabled={disabled}
                    placeholder="Status"
                    component={RFSelect}
                    options={statusOptions}
                />
            </Col> }
            <Col xs="12" className="mb-4">
                <Field
                    isMulti
                    isSearchable
                    cacheOptions
                    defaultOptions
                    name="managers"
                    disabled={disabled}
                    component={RFAsyncSelect}
                    loadOptions={loadManagers}
                    label="Department Managers"
                    getOptionValue={getOptionId}
                    prepareValue={prepareMultiValues}
                    placeholder="Department Managers"
                    getOptionLabel={getOptionFullName}
                />
            </Col>
            <Col xs="12" className="d-flex justify-content-end">
                <Button
                    outline
                    type="submit"
                    onClick={goBack}
                    color="primary-white"
                    className="btn-extended px-5 me-3"
                >
                    Cancel
                </Button>
                <Button type="submit" color="primary" className="text-white px-5">
                    { isNew ? 'Create' : 'Update' }
                </Button>
            </Col>
        </Row>
    </ReduxForm>;
});

export default Details;
