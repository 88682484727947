
// outsource dependencies
import moment from 'moment';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import React, { Fragment, memo, useMemo } from 'react';

import { DefaultImage } from 'images';
// local dependencies

// configure

export const CommentsBlock = memo(function CommentsBlock ({ list }) {
    const prepareList = useMemo(() => list.map(({ id, first_name, last_name, date, comment, image }) => ({
        imageBadge: `${first_name.charAt(0)}${last_name.charAt(0)}`,
        date: moment(date).format('hh:mm A | MM/DD/YYYY'),
        name: `${first_name} ${last_name}`,
        comment,
        image,
        id,
    })), [list]);

    return <Row className="g-0 ps-2">
        {prepareList.map(({ name, date, comment, id, image, imageBadge }, index) => <Fragment key={index}>
            <Col xs="12" className="d-flex align-items-center mb-3">
                { image ? <DefaultImage
                    className="w-100"
                    defaultAlt={name}
                    defaultSrc={image}
                    defaultTitle={name}
                    style={{ width: 35, height: 35 }}
                /> : <div
                    style={{ width: 35, height: 35 }}
                    className="d-flex justify-content-center align-items-center rounded-6 bg-primary-25 me-3">
                    { imageBadge }
                </div>}
                <p className="font-family-alternative fz-16 fw-500 mb-0 me-3">{ name }</p>
                <p className="font-family-alternative fz-12 mb-0">{ date }</p>
            </Col>
            <Col tag="p" xs="12" className="font-family-alternative fz-14 mb-3">{ comment }</Col>
        </Fragment>)}
    </Row>;
});
CommentsBlock.propTypes = {
    list: PropTypes.array,
};
CommentsBlock.defaultProps = {
    list: [],
};

export default CommentsBlock;
