
// outsource dependencies
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { create } from 'redux-saga-controller';
import { takeEvery, takeLeading, put, call, delay, select } from 'redux-saga/effects';

// components
import { getOrganization, instanceAPI, onAuthFailApplicationAction, restoreSessionFromStore, signOut } from 'services';

// constants
import { store } from 'constants/store';
import * as ROUTES from 'constants/routes';

// configure
onAuthFailApplicationAction(() => store.dispatch(controller.action.initialize()));

const getOrganizationById = id => instanceAPI({ method: 'GET', url: `organization/${id}` });

const initial = {
    user: null,         // logged user information,
    health: true,       // prevent redirect from page and show instead current page and it behavior - maintenance page
    showMenu: false,    // is open header menu
    organization: null, // information about main organization
    initialized: false, // prevent redirect from page and show instead current page and it behavior - global preloader
    incidentCategories: [] // preload incident categories list
};

const controller = create({
    initial,
    prefix: 'app',
    actions: {
        getSelf: 'GET_SELF',
        signOut: 'SIGN_OUT',
        initialize: 'INITIALIZE',
        getOrganization: 'GET_ORGANIZATION',
        getIncidentCategories: 'GET_INCIDENT_CATEGORIES',
    },
    subscriber: function * () {
        yield takeEvery(controller.action.signOut.TYPE, signOutSaga);
        yield takeEvery(controller.action.initialize.TYPE, initializeSaga);
        yield takeEvery(controller.action.getOrganization.TYPE, getOrganizationSaga);
        yield takeLeading(controller.action.getIncidentCategories.TYPE, getIncidentCategoriesSaga);
    }
});

export default controller;

function * initializeSaga () {
    // yield put(controller.action.clearCtrl());
    // console.log(`%c ${type} `, 'color: #FF6766; font-weight: bolder; font-size: 12px;'
    //     , '\n payload:', payload
    // );
    // NOTE check health of API
    try {
        const { status } = { status: 'UP', instanceAPI };
        // const { status } = yield call(instancePUB, { method: 'GET', url: '/actuator/health' });
        // NOTE API may answer "DOWN" (not ready yet)
        if (status !== 'UP') { throw new Error('API down for maintenance'); }
        yield put(controller.action.updateCtrl({ health: true }));
    } catch ({ message: error1 }) {
        yield put(controller.action.updateCtrl({ health: false }));
        // NOTE try again another time
        yield delay(10 * 1000);
        yield put(controller.action.initialize());
        return;
    }
    // NOTE try to restore user auth
    try {
        const hasSession = yield call(restoreSessionFromStore);
        if (hasSession) {
            const user = yield call(instanceAPI, { method: 'GET', url: 'user' });
            yield put(controller.action.updateCtrl({ user }));
        }
    } catch ({ message }) {
        yield call(signOutSaga, {});
    }
    // NOTE get information by organization
    yield call(getOrganizationSaga);
    // NOTE initialization done - other initialization things should run in background
    yield put(controller.action.updateCtrl({ initialized: true }));
}

export function * getOrganizationSaga () {
    try {
        const keyword = window.location.pathname.split('/')[1];
        const hasKeyword = !_.isEmpty(keyword);
        const isUserSurveyPage = _.includes(keyword, 'user-survey');
        let organization = null;
        if (!hasKeyword || isUserSurveyPage) {
            const { user } = yield select(controller.select);
            const id = _.get(user, 'organization.id');
            if (id) {
                organization = yield call(getOrganizationById, id);
            }
        }
        if (hasKeyword && !isUserSurveyPage) {
            organization = yield call(getOrganization, keyword);
        }
        yield put(controller.action.updateCtrl({ organization }));
    } catch (error) {
        yield call(ROUTES.WELCOME.PUSH);
        yield call(toastr.error, 'Initialize', error.message);
        yield put(controller.action.updateCtrl({ errorMessage: error.message, organization: null }));
    }
}

function * signOutSaga () {
    // console.log(`%c ${type} `, 'color: #FF6766; font-weight: bolder; font-size: 12px;'
    //   , '\n payload:', payload
    // );
    yield call(signOut);
    yield call(ROUTES.WELCOME.REPLACE);
    // NOTE clear client side session from store
    yield put(controller.action.updateCtrl({ ...initial, initialized: true }));
}

function * getIncidentCategoriesSaga () {
    const { getIncidentCategories: current } = yield select(controller.select);
    if (_.size(current)) { return null; }
    const { data: incidentCategories } = yield call(instanceAPI, {
        url: 'incident/category',
        method: 'GET',
        data: {}
    });
    yield put(controller.action.updateCtrl({ incidentCategories }));
}
