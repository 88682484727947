
// outsource dependencies
import { Container } from 'reactstrap';
import React, { memo, useEffect } from 'react';
import { useController } from 'redux-saga-controller';

// root
import Layout from 'pages/layout';

// components
import { BoxLoader } from 'components';

// local dependencies
import controller from './controller';
import OrganizationInfoForm from './organization-info-form';
import OrganizationEmailForm from './organization-email-form';
import OrganizationDepartmentsList from './organization-departments-list';


const OrganizationEdit = memo(function OrganizationEdit () {
    const [
        { initialized },
        { initialize },
    ] = useController(controller);

    // NOTE initialize business logic
    useEffect(() => { initialize(); }, [initialize]);

    return <Layout className="organization-edit">
        <Container className="mt-4 mb-5 h-100">
            <BoxLoader id="AppOrganizationEdit" active={!initialized}>
                <OrganizationInfoForm className="mb-2"/>
                <OrganizationEmailForm className="mb-2" />
                <OrganizationDepartmentsList className="mb-2" />
            </BoxLoader>
        </Container>
    </Layout>;
});

export default OrganizationEdit;
