import React, { Component } from 'react';
import PropTypes from 'prop-types';

const NEWTAB = '_blank';
const MIDDLECLICK = 1;

export default class ExternalLink extends Component {
    handleClick = (event) => {
        const { target, to, onClick } = this.props;
        const sameTarget = target !== NEWTAB;
        const normalClick = !(
            event.ctrlKey
            || event.shiftKey
            || event.metaKey
            || event.button === MIDDLECLICK
        );

        if (sameTarget && normalClick) {
            event.preventDefault();
        }

        if (onClick) {
            onClick(event);
        }
    };

    render () {
        const { to: href, target, ...oldProps } = this.props;
        const props = {
            ...oldProps,
            target,
            href,
            onClick: this.handleClick
        };

        if (target === NEWTAB) {
            props.rel = `${props.rel ? props.rel : ''} noopener noreferrer`.trim();
        }

        delete props.eventLabel;
        delete props.trackerNames;
        return React.createElement('a', props);
    }
}

ExternalLink.propTypes = {
    target: PropTypes.string,
    to: PropTypes.string,
    onClick: PropTypes.func,
};

ExternalLink.defaultProps = {
    target: null,
    to: null,
    onClick: null,
};
