// outsource dependencies
import _ from 'lodash';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import prettyBytes from 'pretty-bytes';
import { toastr } from 'react-redux-toastr';
import { Button, Label, ListGroup } from 'reactstrap';
import React, { memo, useCallback, useMemo } from 'react';

// constants
import { ACCEPTED_FILE_TYPES } from 'constants/spec';

// local dependencies
import { AppIcon, FasIcon } from './icon';
import ExternalLink from './external-link';
import { CheckboxCustom } from './checkbox';
import { destroyPreview, prepareSelectedFile } from './input-file-helper';

// configure
export const InputDropAreaFileList = memo(function InputDropAreaFileList (props) {
    const { input, meta, label, disabled, showFileList,
        skipTouch, downloadable, allowSealFile, editable, validator,
        dropAreaVisible, dropZoneOptions, className, classNameGroup,
        classNameLabel, classNameIcon, classNameDropAreaWrapper, ...attr
    } = props;

    const uid = _.kebabCase(`file-${input.name}-${Date.now()}`);
    let message = '';
    if (skipTouch || meta.touched) {
        message = meta.error;
        attr.className += meta.valid ? ' is-valid' : 'is-invalid';
    }

    const computedClassName = cx('form-dropzone-control', attr.className);

    // NOTE actions
    const handleChange = useCallback(value => input.onChange(value), [input]);
    const handleDrop = useCallback(async (accepted, rejected) => {
        rejected.forEach(({ errors, file }) => toastr.error(file.name, _.head(errors)));
        const files = await Promise.all(accepted.map(async file => await prepareSelectedFile(file)));
        handleChange([].concat(input.value, files));
    }, [handleChange, input]);

    const handleFileRemove = useCallback(fileUUID => {
        const filtered = input.value.filter(current => {
            const prevUUID = _.get(current, 'fileMeta.fileUUID');
            // if needed for already upload with meta blob preview
            if (_.isEqual(fileUUID, prevUUID)) {
                destroyPreview(current, 'fileItem.filePreviewUrl');
                destroyPreview(current, 'fileItem.fileBlobRawData');
            }

            return fileUUID !== prevUUID;
        });

        handleChange(filtered);
    }, [handleChange, input]);

    const handleSealChange = useCallback((value, fileId) => {
        const list = input.value.map(item => {
            const fileUUID = _.get(item, 'fileMeta.fileUUID');
            return _.isEqual(fileUUID, fileId) ? { ...item, fileSealed: value } : item;
        });
        handleChange(list);
    }, [handleChange, input]);

    return <div className={cx('form-dropzone-group', classNameGroup)}>
        {label ? <Label
            htmlFor={uid}
            className={cx(classNameLabel, { 'app-invalid-feedback': message })}
        >
            {label}
        </Label> : null}
        <div className={cx('position-relative', classNameDropAreaWrapper)}>
            {dropAreaVisible ? <Dropzone {...dropZoneOptions} onDrop={handleDrop} validator={validator}>
                {({
                    getRootProps,
                    getInputProps,
                    isFocused,
                    isFileDialogActive,
                    isDragActive,
                    isDragAccept,
                    isDragReject
                }) => <div {...getRootProps()}
                    className={cx(`${computedClassName}`, {
                        isDragAccept,
                        isDragReject,
                        isFileDialogActive,
                        active: isFocused || isDragActive,
                    })}>
                    <input id={uid} name={input.name} {...getInputProps()} />
                    <DropAreaPlaceholderView className={cx({ 'border-danger': isDragReject })} />
                </div>}
            </Dropzone> : null }
            { allowSealFile && <SealWarningView /> }
            { showFileList && <ListGroup className="flex-wrap">
                {(input.value || []).map((file, index) =>
                    <li key={file.fileMeta?.fileUUID ?? file.fileMeta?.filePreviewUrl ?? file.file_url ?? index}
                        className={cx('list-group-item border-0 py-0 px-0 mb-3 position-relative w-100', {
                            'list-group-item-first': index === 0,
                        })}
                    >
                        <FileItemView
                            file={file}
                            disabled={disabled}
                            editable={editable}
                            downloadable={downloadable}
                            allowSealFile={allowSealFile}
                            onSealChange={handleSealChange}
                            handleFileRemove={handleFileRemove}
                        />
                    </li>
                )}
            </ListGroup> }
        </div>
        {message ? <Label htmlFor={uid} className="app-invalid-feedback">
            {message}
        </Label> : null}
    </div>;
});

InputDropAreaFileList.propTypes = {
    editable: PropTypes.bool,
    validator: PropTypes.func,
    className: PropTypes.string,
    showFileList: PropTypes.bool,
    downloadable: PropTypes.bool,
    allowSealFile: PropTypes.bool,
    classNameIcon: PropTypes.string,
    dropAreaVisible: PropTypes.bool,
    classNameLabel: PropTypes.string,
    classNameGroup: PropTypes.string,
    meta: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
    classNameDropAreaWrapper: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.element]),
    dropZoneOptions: PropTypes.shape({
        multiple: PropTypes.bool,
        maxFiles: PropTypes.number,
        accept: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string])
    }),
};
InputDropAreaFileList.defaultProps = {
    label: null,
    className: '',
    editable: false,
    classNameIcon: '',
    showFileList: true,
    classNameGroup: '',
    classNameLabel: '',
    downloadable: false,
    allowSealFile: false,
    validator: () => null,
    dropAreaVisible: true,
    classNameDropAreaWrapper: '',
    dropZoneOptions: {
        maxFiles: 0,
        multiple: true,
        accept: [...ACCEPTED_FILE_TYPES.IMAGES, ...ACCEPTED_FILE_TYPES.VIDEOS]
    },
};

export default InputDropAreaFileList;

const FileItemView = memo(function FileItemView (props) {
    const {
        width, height, className, disabled, editable, allowSealFile,
        downloadable, handleFileRemove, onSealChange, file
    } = props;

    const fileId = _.get(file, 'fileMeta.fileUUID', _.get(file, 'id'));
    const fileSealed = _.get(file, 'fileSealed', _.get(file, 'file_sealed'));
    const fileName = _.get(file, 'fileMeta.fileName', _.get(file, 'file_name'));
    const fileType = _.get(file, 'fileMeta.fileType', _.get(file, 'file_type'));
    const fileSize = _.get(file, 'fileMeta.fileSize', _.get(file, 'file_size'));
    const filePreviewUrl = _.get(file, 'fileMeta.filePreviewUrl', _.get(file, 'file_url'));

    const fileCheckboxId = `${fileId}${Date.now()}`;

    const fileSizeHumanize = useMemo(
        () => _.isNumber(fileSize) ? prettyBytes(fileSize) : fileSize,
        [fileSize]
    );

    const handleRemove = useCallback(() => handleFileRemove(fileId), [fileId, handleFileRemove]);
    const handleSealChange = useCallback(value => onSealChange(value, fileId), [fileId, onSealChange]);

    return <div className={cx('d-flex w-100', className)}>
        <div style={{ width, height }} className="d-flex justify-content-center align-items-center rounded-5 bg-platform p-3 me-2">
            <FasIcon icon="paperclip" className="fz-18 text-gray-600" />
        </div>
        <div className="d-flex flex-column w-100 justify-content-center">
            <div className="d-flex">
                <div className="d-flex align-items-center text-blue fz-12 mb-0 text-pre-wrap text-break w-100">
                    { fileName }
                </div>
                <div className="d-flex align-items-center text-nowrap ms-3">
                    { fileSize && <p className="fz-12 text-gray-600 m-0 me-3">
                        { fileSizeHumanize }
                    </p> }
                    { editable && <Button
                        onClick={handleRemove}
                        className="d-flex align-items-center bg-transparent border-transparent p-0 m-0"
                    >
                        <AppIcon icon="trash" className="fz-16 text-gray-600" />
                    </Button> }
                    { downloadable && filePreviewUrl && <ExternalLink
                        className="d-flex align-items-center bg-transparent border-transparent p-0 m-0"
                        title={`Download ${fileName}`}
                        download={fileName}
                        to={filePreviewUrl}
                        rel="noreferrer"
                        target="_blank"
                    >
                        <AppIcon icon="download-dnd-arrow" className="fz-16 text-gray-600" />
                    </ExternalLink> }
                </div>
            </div>
            { allowSealFile && <CheckboxCustom
                type="checkbox"
                value={fileSealed}
                disabled={disabled}
                label="Seal document"
                classNameGroup="mb-0"
                name={fileCheckboxId}
                onChange={handleSealChange}
            /> }
        </div>
    </div>;
});
FileItemView.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    disabled: PropTypes.bool,
    editable: PropTypes.bool,
    className: PropTypes.string,
    onSealChange: PropTypes.func,
    downloadable: PropTypes.bool,
    allowSealFile: PropTypes.bool,
    handleFileRemove: PropTypes.func,
};
FileItemView.defaultProps = {
    width: 32,
    height: 32,
    className: '',
    disabled: false,
    editable: false,
    downloadable: false,
    allowSealFile: false,
    onSealChange: e => e,
    handleFileRemove: e => e,
};

const SealWarningView = memo(function SealWarningView () {
    return <div className="d-flex rounded-6 mb-4 border border-danger p-3">
        <AppIcon icon="lock" className="fz-24 text-danger me-3" />
        <p className="fz-12 mb-0">
            * If you wish an attached file to be revealed only in case of the legal reason check “Seal
            document” Checkbox under it.
        </p>
    </div>;
});

const DropAreaPlaceholderView = memo(function DropAreaPlaceholderView ({ className }) {
    return <div
        style={{ borderStyle: 'dashed' }}
        className={cx('form-dropzone-preview d-flex flex-column justify-content-center align-items-center border-primary rounded-6 py-3 py-lg-5 w-100 border-1 bg-platform mb-4 cursor-pointer', className)}
    >
        <div className=" d-block text-center">
            <FasIcon icon="folder-open" className="fz-42 text-blue mb-3" />
            <p className="fz-14 mb-0">
                Drag & Drop or
                &nbsp;
                <span className="text-blue text-decoration-underline">browse</span>
            </p>
        </div>
    </div>;
});
