// outsource dependencies
import _ from 'lodash';
import React, { memo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Button, Col, Container, Row } from 'reactstrap';
import { useControllerData } from 'redux-saga-controller';

// components
import { AppIcon } from 'components';

// constants
import * as ROUTES from 'constants/routes';
import { FULL_HEIGHT_SCREEN } from 'constants/spec';

// local dependencies
import appRootController from 'controller';

// images
import { DefaultImage } from 'images';
import defaultLogo from 'images/default-logo.svg';
import letterSent from 'images/forgot-password/letter-sent.svg';

// configure

const EmailSent = memo(function EmailSent () {
    const { goBack } = useHistory();

    const { organization } = useControllerData(appRootController);
    const name = _.get(organization, 'name');
    const logo = _.get(organization, 'logo');
    const message = _.get(organization, 'message');
    const background = _.get(organization, 'background');
    const description = _.get(organization, 'description');

    return <Scrollbars style={{ height: FULL_HEIGHT_SCREEN }}>
        <Container fluid tag={Row} className="g-0 justify-content-center h-100">
            <Col xs="12" lg="6" tag={Row} className="g-0 d-none d-sm-flex align-content-start bg-lg-platform p-3 p-lg-5">
                <Row>
                    <Col xs="12" className="d-flex mb-5">
                        <DefaultImage
                            src={logo}
                            width={110}
                            className="me-5"
                            alt={`Logo ${name}`}
                            title={`Logo ${name}`}
                            defaultSrc={defaultLogo}
                            defaultAlt="Logo Company"
                            defaultTitle="Logo Company"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" tag="h1" className="fz-lg-24 fz-14 fw-lg-600 fw-700 text-secondary mb-5">
                        { name || 'Organization Name' }
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" lg="6" className="mb-5">
                        <DefaultImage
                            src={background}
                            alt={`Main Image ${name}`}
                            className="w-100 rounded-6"
                            title={`Main Image ${name}`}
                            defaultAlt="Default organization"
                            defaultTitle="Default organization"
                        />
                    </Col>
                    <Col xs="12" lg="6" tag="p" className="font-family-alternative fz-18 text-gray-700 mb-5 text-pre-wrap">
                        { description || 'Default description' }
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <p className="fz-32 fw-700 text-gray-700 mb-4 text-pre-wrap">{ message || 'Welcome message' }</p>
                    </Col>
                </Row>
            </Col>
            <Col
                lg="6"
                xs="12"
                className="mt-sm-0 d-flex flex-column vh-100 justify-content-sm-center"
            >
                <Row className="g-0 d-block d-sm-none px-3">
                    <Col xs="12">
                        <Button
                            onClick={goBack}
                            className="d-flex justify-content-center align-items-center border-0 bg-transparent p-2 mt-3"
                        >
                            <AppIcon icon="back" className="fz-20 text-secondary" />
                        </Button>
                    </Col>
                </Row>
                <Row className="g-0 align-content-center justify-content-center px-3 p-lg-5 flex-grow-1 flex-sm-grow-0">
                    <Col xs="12" sm="10" lg="8" tag="h2" className="fz-24 fw-600 text-secondary text-center mb-5">
                            Email has been sent!
                    </Col>
                    <Col xs="12" sm="10" lg="8" className="d-flex justify-content-center mb-5">
                        <DefaultImage
                            src={letterSent}
                            alt="Letter sent"
                        />
                    </Col>
                    <Col xs="12" sm="10" lg="8" tag="p" className="font-family-alternative fz-18 mb-4">
                            Please check your inbox and follow the received link to reset your password.
                    </Col>
                    <Col xs="12" sm="10" lg="8" className="mb-2 font-family-alternative fz-18 mb-4">
                            Did not receive the email? Check your spam folder or {' '}
                        <Link to={ROUTES.FORGOT_PASSWORD.LINK()} className="text-secondary text-decoration-none">
                                try another email address
                        </Link>
                    </Col>
                    <Col xs="12" sm="10" lg="8" className="mb-4">
                        <Button
                            outline
                            type="submit"
                            onClick={goBack}
                            color="primary-white"
                            className="font-family-alternative btn-extended fz-16 fw-500 w-100"
                        >
                                Back
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Container>
    </Scrollbars>;
});

export default EmailSent;
