// outsource dependencies
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

export const AlertError = memo(({ message, title, active, color, onClear, ...attr }) => {
    return <Alert fade color={color} {...attr} isOpen={Boolean(message)} toggle={!active ? null : onClear}>
        { title && <h5 className="alert-heading font-weight-bold my-0">{ title }</h5> }
        { message }
    </Alert>;
});
AlertError.propTypes = {
    active: PropTypes.bool,
    title: PropTypes.string,
    onClear: PropTypes.func,
    color: PropTypes.string,
    message: PropTypes.node,
    className: PropTypes.string,
};
AlertError.defaultProps = {
    className: '',
    message: null,
    active: false,
    onClear: null,
    color: 'danger',
    title: 'Error: ',
};
