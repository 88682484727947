
// outsource dependencies
import _ from 'lodash';
import React, { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useControllerActions } from 'redux-saga-controller';
import { DropdownItem, DropdownMenu, DropdownToggle, Navbar, NavbarBrand, UncontrolledDropdown } from 'reactstrap';

// local dependencies
import controller from 'controller';
import { Humanize } from 'components';
import { DefaultImage } from 'images';
import { useRole, useSelf } from 'hooks';
import * as ROUTES from 'constants/routes';
import { FilterService } from '../../services';
import config from 'constants/enviroment-config';
import { headerHeight, SYSTEM_ROLE_TYPE } from 'constants/spec';

// images
import defaultAvatar from 'images/default-avatar.svg';
import companyLogoSmall from 'images/company-logo-small.svg';

// configure

const HeaderUser = memo(function HeaderUser () {
    const { updateCtrl, signOut } = useControllerActions(controller);
    const role = useRole();
    const user = useSelf();

    // TODO: prepareRole function exist for development mode, to will be possible change role
    const prepareRole = useMemo(() => {
        const filterRole = Object.values(SYSTEM_ROLE_TYPE).filter(systemRole => role !== systemRole);
        return filterRole.map(role => ({
            name: FilterService.humanize(role),
            switchRole: () => updateCtrl({ user: { ...user, role: { name: FilterService.humanize(role), type_name: role } } })
        }));
    }, [role, user, updateCtrl]);

    return <Navbar
        light
        expand
        color="light"
        id="AppHeader"
        container="fluid"
        style={{ height: headerHeight }}
        className="p-0 px-4 border-bottom border-1 border-gray-700-200"
    >
        <NavbarBrand className="mr-4 p-0" tag={Link} to={ROUTES.DASHBOARD.LINK()}>
            <DefaultImage
                defaultSrc={companyLogoSmall}
                defaultAlt="Company logo"
                defaultTitle="Company Logo"
            />
        </NavbarBrand>
        <UncontrolledDropdown inNavbar className="me-5">
            <DropdownToggle
                nav
                caret
                className="d-flex justify-content-center align-items-center fw-regular fz-16 font-family-alternative text-secondary"
            >
                <DefaultImage
                    defaultAlt="User Avatar"
                    defaultSrc={defaultAvatar}
                    defaultTitle="User Avatar"
                    className="avatar-image me-2"
                />
                { `${_.get(user, 'first_name')} ${_.get(user, 'last_name')}` }
            </DropdownToggle>
            <DropdownMenu className="end-0">
                <DropdownItem header className="fz-16 text-gray-700">
                    You current role is <Humanize tag="strong" value={role} />
                </DropdownItem>
                { config.DEBUG && <>
                    <DropdownItem divider />
                    { prepareRole.map(({ name, switchRole }) => <DropdownItem
                        onClick={switchRole}
                        key={name}
                    >
                        Switch to <strong>{ name }</strong> role
                    </DropdownItem>) }
                </> }
                <DropdownItem divider />
                <DropdownItem onClick={signOut}>
                    Sign Out
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    </Navbar>;
});

export default HeaderUser;
