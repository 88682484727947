// outsource dependencies
import _ from 'lodash';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Title,
    Legend
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';


// local dependencies
import { FilterService } from 'services';
import { STATUS_TYPE } from 'constants/spec';

// configure
ChartJS.register(
    ArcElement,
    Tooltip,
    Title,
    Legend
);
const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
        easing: 'easeInOutQuad',
        duration: 520
    },
    plugins: {
        legend: {
            display: true,
            position: 'top',
            align: 'end',
            title: {
                font: {
                    family: 'Roboto',
                    weight: 'normal',
                    style: 'normal',
                    size: 12
                },
            },
            labels: {
                boxWidth: 40,
                usePointStyle: false,
                pointStyle: 'rectRounded',
            }
        },
        title: {
            display: true,
            text: 'Number of Complaints by Status',
            align: 'end',
            font: {
                family: 'Roboto',
                weight: 'bold',
                style: 'normal',
                size: 14
            },
        },
        /*tooltip: {
            callbacks: {
                title: (context) => {
                    const parsed = moment.localeData().monthsParse(_.get(_.first(context), 'label', 0)) + 1
                    console.log(parsed)
                    return moment(parsed, 'M').format('MMMM');
                }
            }
        }*/
    }/*,
    scales: {
        x: {
            title: {
                display: false,
            },
            grid: {
                offset: true,
                color: 'transparent',
                tickColor: '#929292',
                borderColor: '#929292',
                borderWidth: 2,
                tickLength: 8,
                tickWidth: 2,
            },
            ticks: {
                display: true,
                font: {
                    family: 'Roboto',
                    weight: 'normal',
                    style: 'normal',
                    size: 12
                },
                // color: 'red'
            }
        },
        y: {
            beginAtZero: true,
            title: {
                display: false,
            },
            grid: {
                color: 'transparent',
                tickColor: '#929292',
                borderColor: '#929292',
                borderWidth: 2,
                tickLength: 8,
                tickWidth: 2,
            },
            ticks: {
                // forces step size to be 1000 units
                stepSize: 1000,
                display: true,
                font: {
                    family: 'Roboto',
                    weight: 'normal',
                    style: 'normal',
                    size: 12
                },
                // color: 'red'
            }
        }
    },
    elements: {
        bar: {
            borderRadius: 2,
            inflateAmount: 'auto',
            // pointStyle: 'rectRounded'
        }
    },
    */
    /*point: {
        backgroundColor: 'white'
    },
    tooltips: {
        titleFontFamily: 'Open Sans',
        backgroundColor: 'rgba(0,0,0,0.3)',
        titleFontColor: 'red',
        caretSize: 5,
        cornerRadius: 2,
        xPadding: 10,
        yPadding: 10
    }*/
};
const STATUS_TYPE_COLORS = {
    [STATUS_TYPE.NEW]: {
        backgroundColor: 'rgba(252, 233, 225, 1)',
        borderColor: 'rgba(246, 195, 174, 1)'
    },
    [STATUS_TYPE.REVIEWED]: {
        backgroundColor: 'rgba(227, 245, 255, 1)',
        borderColor: 'rgba(144, 213, 255, 1)'
    },
    [STATUS_TYPE.APPROVED]: {
        backgroundColor: 'rgba(242, 239, 255, 1)',
        borderColor: 'rgba(184, 168, 246, 1)'
    },
    [STATUS_TYPE.ADDRESSED]: {
        backgroundColor: 'rgba(234, 246, 240, 1)',
        borderColor: 'rgba(197, 231, 214, 1)'
    },
};
const STATUS_TYPE_OPTIONS = Object.values(STATUS_TYPE).map(value => ({
    label: FilterService.humanize(value),
    ...STATUS_TYPE_COLORS[value]
}));
export const IssuesStatusesChart = memo(function IssuesStatusesChart ({ className }) {
    const chartRef = useRef(null);
    const [chartData, setChartData] = useState({
        datasets: []
    });

    const data = useMemo(() => ({
        labels: _.map(STATUS_TYPE_OPTIONS, (item) => item.label),
        datasets: [
            {
                label: 'Complaints',
                data: [1400, 3250, 3999, 1500],
                backgroundColor: [
                    ..._.map(STATUS_TYPE_OPTIONS, (item) => item.backgroundColor)
                    // 'rgba(252, 233, 225, 1)',
                    // 'rgba(227, 245, 255, 1)',
                    // 'rgba(242, 239, 255, 1)',
                    // 'rgba(234, 246, 240, 1)',
                ],
                borderColor: [
                    ..._.map(STATUS_TYPE_OPTIONS, (item) => item.borderColor)
                    // 'rgba(246, 195, 174, 1)',
                    // 'rgba(144, 213, 255, 1)',
                    // 'rgba(184, 168, 246, 1)',
                    // 'rgba(197, 231, 214, 1)',
                ],
                borderWidth: 1,
                pointBorderWidth: 1,
                pointHoverRadius: 3,
                pointHoverBorderWidth: 1,
            }
        ]
    }), []);

    useEffect(() => {
        const chart = chartRef.current;

        if (!chart) {
            return;
        }

        const chartData = {
            ...data,
            datasets: data.datasets.map((dataset) => ({
                ...dataset,
                tension: 0.4,
                fill: true
                // borderColor: createGradient(chart.ctx, chart.chartArea),
                // backgroundColor: createGradient(chart.ctx, chart.chartArea),
                // fill: true
            }))
        };

        setChartData(chartData);
    }, [data]);

    return <div className={cx('', className)}>
        <Doughnut ref={chartRef} options={chartOptions} data={chartData} />
    </div>;
});
IssuesStatusesChart.propTypes = {
    className: PropTypes.string,
};

IssuesStatusesChart.defaultProps = {
    className: '',
};
