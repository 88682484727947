
// outsource dependencies
import _ from 'lodash';
import moment from 'moment';
import cx from 'classnames';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { Input, Label } from 'reactstrap';
import React, { forwardRef, memo, useCallback, useMemo } from 'react';

// local dependencies
import environmentConfig from 'constants/enviroment-config';

// configure
const DefaultInput = forwardRef(({ value, onClick, dateFormat, ...attributes }, ref) => {
    return <Input
        ref={ref}
        value={value}
        onClick={onClick}
        onChange={e => e}
        {...attributes}
    />;
});

const DatePickerCustom = memo(function DatePickerCustom (props) {
    const {
        type, value, startDate, endDate, label, CustomInput, message, onChange, isUseDateFormat,
        dateFormat, className, classNameLabel, classNameGroup, selectsRange, ...attributes
    } = props;
    const uid = _.kebabCase(name);
    const values = selectsRange
        ? { startDate: startDate && new Date(startDate), endDate: endDate && new Date(endDate) }
        : { selected: value && new Date(value) };

    const handleChange = useCallback(value => {
        return isUseDateFormat ? onChange(moment(value).format(dateFormat)) : onChange(value);
    }, [isUseDateFormat, onChange, dateFormat]);

    const format = useMemo(() => dateFormat.replace(/d+|m+|y+/gi, value => {
        const format = value.toLowerCase();
        if (format.includes('d')) { return value.toLowerCase(); }
        if (format.includes('m')) { return value.toUpperCase(); }
        if (format.includes('y')) { return value.toLowerCase(); }
    }), [dateFormat]);

    const DatePickerInput = CustomInput ? CustomInput : DefaultInput;
    return <div className={classNameGroup}>
        { label ? <Label
            htmlFor={uid}
            className={cx('fw-400', classNameLabel, { 'app-invalid-feedback': message })}
        >
            { label }
        </Label> : null }
        <DatePicker
            id={uid}
            selected={value}
            dateFormat={format}
            className={className}
            selectsRange={selectsRange}
            onChange={handleChange}
            customInput={<DatePickerInput dateFormat={dateFormat} className={className} {...attributes} />}
            {...values}
            {...attributes}
        />
        { message ? <Label htmlFor={uid} className="d-inline app-invalid-feedback">
            { message }
        </Label> : null }
    </div>;
});
DatePickerCustom.propTypes = {
    label: PropTypes.string,
    message: PropTypes.string,
    className: PropTypes.string,
    CustomInput: PropTypes.node,
    selectsRange: PropTypes.bool,
    dateFormat: PropTypes.string,
    isUseDateFormat: PropTypes.bool,
    classNameLabel: PropTypes.string,
    classNameGroup: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
DatePickerCustom.defaultProps = {
    label: '',
    endDate: '',
    message: '',
    startDate: '',
    className: '',
    value: new Date(),
    CustomInput: null,
    classNameLabel: '',
    classNameGroup: '',
    selectsRange: false,
    isUseDateFormat: false,
    dateFormat: environmentConfig.DATE_FORMAT,
};

export default DatePickerCustom;

export const RFDatePicker = memo(function RFDatePicker ({ skipTouch, input, meta, className, ...attributes }) {
    const handleChange = useCallback(value => input.onChange(value), [input]);
    const handleBlur = useCallback(value => input.onBlur(value), [input]);
    let message = '';
    if (skipTouch || meta.touched) {
        message = meta.error;
        attributes.className += meta.valid ? ' is-valid' : ' is-invalid';
    }

    return <DatePickerCustom
        {...input}
        {...attributes}
        message={message}
        onBlur={handleBlur}
        onChange={handleChange}
    />;
});
RFDatePicker.propTypes = {
    skipTouch: PropTypes.bool,
    meta: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
};
RFDatePicker.defaultProps = {
    skipTouch: false,
};
