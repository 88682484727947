
// outsource dependencies
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import React, { memo, useCallback } from 'react';

// local dependencies
import { SortIcon } from 'components/icon';
import TooltipCustom from 'components/tooltip';

// configure

const ThSort = memo(function ThSort (props) {
    const { disabled, sortF, sortD, sortName, handleSort, title, width, classNameTitle, classNameButton } = props;

    const changeSortDirection = useCallback(() => {
        const inverseSortD = props.sortD === 0 ? 1 : 0;
        return handleSort({ sortF: sortName, sortD: sortName === sortF ? inverseSortD : 1 });
    }, [props.sortD, handleSort, sortName, sortF]);

    return <th style={{ width }} className="p-0">
        { sortName
            ? <TooltipCustom message={`Click sort by ${sortD ? 'ascend' : 'descend'}`}>
                <div className="p-1 w-100 h-100">
                    <Button
                        disabled={disabled}
                        color="transparent"
                        onClick={changeSortDirection}
                        className={cx('d-flex justify-content-between align-items-center w-100 h-100 border-transparent bg-transparent text-gray-700 fz-14 rounded-0', classNameButton)}
                    >
                        <span className={classNameTitle}>{ title }</span>
                        <SortIcon status={(sortName === sortF) && sortD} className="fz-18 fw-normal" />
                    </Button>
                </div>
            </TooltipCustom>
            : <div className="p-1 w-100 h-100">
                <Button
                    disabled={disabled}
                    className={cx('d-flex justify-content-between align-items-center w-100 h-100 border-transparent bg-transparent text-gray-700 fz-14 rounded-0', classNameButton)}
                >
                    <span className={classNameTitle}>{ title }</span>
                    { sortF ? <SortIcon status={false} className="fz-18 fw-normal" /> : null }
                </Button>
            </div>
        }
    </th>;
});
ThSort.propTypes = {
    sortD: PropTypes.number,
    sortF: PropTypes.string,
    disabled: PropTypes.bool,
    sortName: PropTypes.string,
    handleSort: PropTypes.func,
    classNameTitle: PropTypes.string,
    classNameButton: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
ThSort.defaultProps = {
    width: 0,
    sortD: 1,
    sortF: '',
    title: '',
    sortName: '',
    handleSort: e => e,
    classNameTitle: '',
    classNameButton: '',
    disabled: PropTypes.false,
};

export default ThSort;
