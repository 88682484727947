
// outsource dependencies
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment, memo, useMemo } from 'react';

// local dependencies
import { FilterService } from 'services';

// configure

export const WizardStep = memo(function WizardStep ({ list, currentStep, onChange }) {
    const prepareList = useMemo(() => {
        const currentIndex = list.indexOf(currentStep);
        return list.map((step, index) => ({
            isFirstStep: !index,
            stepIndex: index + 1,
            isActive: index <= currentIndex,
            name: FilterService.humanize(step),
            handleChange: (index <= currentIndex && onChange) ? () => onChange(step) : null,
        }));
    }, [list, currentStep, onChange]);

    return <div className="d-flex">
        { prepareList.map(({ name, isActive, stepIndex, isFirstStep, handleChange }) => <Fragment key={stepIndex}>
            { !isFirstStep && <div style={{ height: 1 }} className="bg-gray-300 w-100 mt-3" /> }
            <div
                onClick={handleChange}
                className={cx('d-flex flex-column align-items-center mx-3', { 'cursor-pointer': handleChange })}
            >
                <div
                    style={{ width: 36, height: 36 }}
                    className={cx(
                        'd-flex justify-content-center align-items-center text-white fz-24 fw-600 rounded-circle mb-4',
                        isActive ? 'bg-primary' : 'bg-gray-300',
                    )}
                >
                    { stepIndex }
                </div>
                <p className={cx('fw-600 fz-18 text-nowrap', isActive ? 'text-secondary' : 'text-gray-300')}>
                    { name }
                </p>
            </div>
        </Fragment>) }
    </div>;
});
WizardStep.propTypes = {
    onChange: PropTypes.func,
    list: PropTypes.array.isRequired,
    currentStep: PropTypes.string.isRequired,
};
WizardStep.defaultProps = {
    onChange: null,
};

export default WizardStep;
