
// outsource dependencies
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { create } from 'redux-saga-controller';
import { change, getFormValues } from 'redux-form';
import { takeEvery, put, call, select, delay } from 'redux-saga/effects';

// components
import { fileS3Upload, getFileNameFromUrl, PromiseAllSettled } from 'components';

// services
import { instanceAPI } from 'services/request';
import { isUrl } from 'services/validation.service';

// constants
import { ORGANIZATION_PAGE } from 'constants/routes';

// configure
const formOrganizationImageName = (payload) => {
    let newData = { ...payload };
    if (isUrl(_.get(newData, 'logo'))) {
        newData = {
            ...newData,
            logo: getFileNameFromUrl(_.get(newData, 'logo'))
        };
    }
    if (isUrl(_.get(newData, 'background'))) {
        newData = {
            ...newData,
            background: getFileNameFromUrl(_.get(newData, 'background'))
        };
    }
    return newData;
};
export const FORM_NAME = 'organizationEditForm';
export const FORM_EMAIL_NAME = 'organizationEmailForm';
// export const ORGANIZATION_IMAGE_FORM_NAME = 'OrganizationImageForm';
export const controller = create({
    prefix: 'organization-edit',
    actions: {
        loadMore: 'LOAD_MORE',
        initialize: 'INITIALIZE',
        submitData: 'SUBMIT_DATA',
        submitEmailData: 'SUBMIT_EMAIL_DATA',
        removeDepartment: 'REMOVE_DEPARTMENT',
        uploadOrganizationLogo: 'UPLOAD_ORGANIZATION_LOGO',
        uploadOrganizationBackground: 'UPLOAD_ORGANIZATION_BACKGROUND'
    },
    initial: {
        disabled: false,
        initialized: false,
        errorMessage: null,

        page: 1,
        sizePage: 10,
        totalItems: 0,

        list: [],

        id: null,
        initialValues: {},
        initialEmailValues: {},

        // delete department confirm modal
        showModal: false,
        departmentId: null,
    },
    subscriber: function * () {
        yield takeEvery(controller.action.loadMore.TYPE, loadMoreSaga);
        yield takeEvery(controller.action.initialize.TYPE, initializeSaga);
        yield takeEvery(controller.action.submitData.TYPE, submitDataSaga);
        yield takeEvery(controller.action.submitEmailData.TYPE, submitEmailDataSaga);
        yield takeEvery(controller.action.removeDepartment.TYPE, removeDepartmentSaga);
        yield takeEvery(controller.action.uploadOrganizationLogo.TYPE, uploadOrganizationLogoSaga);
        yield takeEvery(controller.action.uploadOrganizationBackground.TYPE, uploadOrganizationBackgroundSaga);
    }
});

export default controller;

function * initializeSaga ({ type, payload }) {
    // console.log(`%c ${type} `, 'color: #FF6766; font-weight: bolder; font-size: 12px;'
    //   , '\n payload:', payload
    // );
    const { id } = yield call(ORGANIZATION_PAGE.PARAMS);

    try {
        const organization = yield call(instanceAPI, { method: 'GET', url: `/organization/${id}` });
        const access_keywords = _.size(organization.access_keywords) ? organization.access_keywords : [''];
        const { data, total, per_page, current_page } = yield call(instanceAPI, { method: 'GET', url: `organization/${id}/department` });
        yield put(controller.action.updateCtrl({
            list: data,
            totalItems: total,
            sizePage: per_page,
            page: current_page,
            initialValues: { ...organization, access_keywords }
        }));
    } catch ({ message }) {
        yield call(toastr.error, 'Organization Page Initialize', message);
        yield put(controller.action.updateCtrl({ errorMessage: message }));
    }

    try {
        const organizationSurveyData = yield call(instanceAPI, { method: 'GET', url: `/organization/${id}/email/user_survey` });
        yield put(controller.action.updateCtrl({
            initialEmailValues: { ...organizationSurveyData }
        }));
    } catch (error) {
        yield call(toastr.error, 'Organization Page Initialize', error.message);
        yield put(controller.action.updateCtrl({ errorMessage: error.message }));
    }

    yield put(controller.action.updateCtrl({ initialized: true, id }));
}

function * removeDepartmentSaga () {
    yield put(controller.action.updateCtrl({ disabled: true, showModal: false }));
    try {
        const { id, departmentId } = yield select(controller.select);
        yield call(instanceAPI, { method: 'DELETE', url: `department/${departmentId}` });
        yield call(toastr.success, 'Department is successfully deleted');
        const { data, total, per_page, current_page } = yield call(instanceAPI, { method: 'GET', url: `organization/${id}/department` });
        yield put(controller.action.updateCtrl({
            list: data,
            totalItems: total,
            sizePage: per_page,
            page: current_page,
        }));
    } catch ({ message }) {
        yield call(toastr.error, message);
        yield put(controller.action.updateCtrl({ errorMessage: message }));
    }
    yield put(controller.action.updateCtrl({ disabled: false, departmentId: null }));
}

function * submitDataSaga ({ type, payload }) {
    yield put(controller.action.updateCtrl({ disabled: true }));
    try {
        const { id } = yield select(controller.select);
        // const keyword = new URL(payload.url);

        let data = { ...payload.formValues };
        let organizationImages = { success: [] };
        if (!_.isEmpty(payload.files)) {
            organizationImages = yield call(uploadAttachmentDocuments, {
                payload: {
                    files: payload.files
                }
            });
        }

        if (_.size(organizationImages.success) > 0) {
            data = {
                ...data,
                ...organizationImages.success
                    .map(item => ({ [item.property]: item.file.fileName }))
                    .reduce((prevAcc, current) => ({ ...prevAcc, ...current }), {})
            };
        }
        const formattedData = formOrganizationImageName(data);
        yield call(instanceAPI, { method: 'PUT', url: `/organization/${id}`, data: { ...formattedData } });
        yield put(controller.action.updateCtrl({
            initialValues: {
                ...payload.formValues,
                ..._.omit(data, organizationImages.success.map((s) => s.property))
            }
        }));
        // { formValues: formOrganizationImageName(formValues) }
        yield put(controller.action.updateCtrl({ successSubmitTrigger: true }));
        yield delay(1e2);
        yield put(controller.action.updateCtrl({ successSubmitTrigger: false }));
        yield call(initializeSaga, {});
        yield call(toastr.success, 'Organization', 'Organization was successfully updated');
    } catch ({ message }) {
        yield call(toastr.error, 'Organization Page Submit Data', message);
        yield put(controller.action.updateCtrl({ errorMessage: message }));
    }

    yield put(controller.action.updateCtrl({ disabled: false }));
}

function * submitEmailDataSaga ({ type, payload }) {
    yield put(controller.action.updateCtrl({ disabled: true }));
    try {
        const { id } = yield select(controller.select);
        // const keyword = new URL(payload.url);
        const data = {
            type: 'user_survey',
            ...payload
        };
        yield call(instanceAPI, { method: 'PUT', url: `/organization/${id}/email/save`, data });
        yield put(controller.action.updateCtrl({
            initialEmailValues: { ...data }
        }));
        yield call(toastr.success, 'Organization', 'Organization Email settings were successfully updated');
    } catch ({ message }) {
        yield call(toastr.error, 'Organization Email Data', message);
        yield put(controller.action.updateCtrl({ errorMessage: message }));
    }
    yield put(controller.action.updateCtrl({ disabled: false }));
}

function * loadMoreSaga ({ type, payload }) {
    yield put(controller.action.updateCtrl({ disabled: true }));
    try {
        const { list, page, id } = yield select(controller.select);
        const { data, total, per_page, current_page } = yield call(instanceAPI, {
            method: 'GET',
            url: `organization/${id}/department`,
            params: { page: page + 1 }
        });
        const concatList = list.concat(data);
        yield put(controller.action.updateCtrl({
            list: concatList,
            totalItems: total,
            sizePage: per_page,
            page: current_page
        }));
    } catch ({ message }) {
        yield call(toastr.error, 'Organization Page Load More Data', message);
        yield put(controller.action.updateCtrl({ errorMessage: message }));
    }
    yield put(controller.action.updateCtrl({ disabled: false }));
}
// TODO: need to refactor, migrate to unified submit
function * uploadOrganizationLogoSaga ({ type, payload }) {
    yield put(controller.action.updateCtrl({ disabled: true, errorMessage: null }));
    try {
        const signedUrl = yield call(instanceAPI, {
            method: 'GET',
            url: `organization/upload-link/${payload.fileName}`
        });
        yield call(fileS3Upload, {
            file: payload.fileRaw,
            dir: '',
            url: _.get(signedUrl, 'url'),
            method: 'PUT'
        });
        // const actualUrl = new URL(_.get(signedUrl, 'url')).origin + new URL(_.get(signedUrl, 'url')).pathname;
        yield put(change(FORM_NAME, 'logo', payload.fileName));
        yield delay(1e2);
        const formValues = yield select(getFormValues(FORM_NAME));
        // console.log(formValues);
        yield call(submitDataSaga, { payload: { formValues: formOrganizationImageName(formValues) } });
        // yield put(submit(FORM_NAME));
    } catch (error) {
        console.error(error);
        yield call(toastr.error, 'Upload image for Organization', error.message);
        yield put(controller.action.updateCtrl({ disabled: false, errorMessage: error.message }));
    }

    yield put(controller.action.updateCtrl({ disabled: false }));
}
// TODO: need to refactor, migrate to unified submit
function * uploadOrganizationBackgroundSaga ({ type, payload }) {
    yield put(controller.action.updateCtrl({ disabled: true, errorMessage: null }));
    try {
        const signedUrl = yield call(instanceAPI, {
            method: 'GET',
            url: `organization/upload-link/${payload.fileName}`
        });
        yield call(fileS3Upload, {
            dir: '',
            method: 'PUT',
            file: payload.fileRaw,
            url: _.get(signedUrl, 'url')
        });
        // console.log(documentData);
        // const actualUrl = new URL(_.get(signedUrl, 'url')).origin + new URL(_.get(signedUrl, 'url')).pathname;
        yield put(change(FORM_NAME, 'background', payload.fileName));
        yield delay(1e2);
        const formValues = yield select(getFormValues(FORM_NAME));
        yield call(submitDataSaga, { payload: { formValues: formOrganizationImageName(formValues) } });
        // yield put(submit(FORM_NAME));
    } catch (error) {
        console.error(error);
        yield call(toastr.error, 'Upload image for Organization', error.message);
        yield put(controller.action.updateCtrl({ disabled: false, errorMessage: error.message }));
    }

    yield put(controller.action.updateCtrl({ disabled: false }));
}

function * uploadAttachmentDocuments ({ payload }) {
    const { files } = payload;

    const uploadedDocuments = [];
    const rejectedDocuments = [];

    if (_.isEmpty(files)) {
        return {
            success: [...uploadedDocuments],
            rejected: [...rejectedDocuments]
        };
    }

    const uploadPromises = Object.keys(files)
        .map((key) => ({ property: key, file: files[key] }))
        .map((item) => new Promise((resolve, reject) => {
            return instanceAPI({
                method: 'GET',
                url: `organization/upload-link/${item.file.fileName}`
            }).then(signedUrl => fileS3Upload({
                method: 'PUT',
                file: item.file.fileRaw,
                url: _.get(signedUrl, 'url')
            }).then((answer) => resolve({
                answer,
                signedUrl: _.get(signedUrl, 'url'),
                url: new URL(_.get(signedUrl, 'url')).origin + new URL(_.get(signedUrl, 'url')).pathname,
                ...item
            })).catch(reject)).catch(reject);
        }));
    // console.log(uploadPromises);
    const documentsResults = yield call(PromiseAllSettled, uploadPromises);
    // console.log(documentsResults);

    const filteredSucceedDocs = documentsResults.filter(uploadedItem => _.isEqual(uploadedItem.status, 'fulfilled'));
    const filteredRejectedDocs = documentsResults.filter(uploadedItem => _.isEqual(uploadedItem.status, 'rejected'));

    uploadedDocuments.push(...filteredSucceedDocs.map(filteredItem => filteredItem.result));
    rejectedDocuments.push(...filteredRejectedDocs);

    return {
        success: [...uploadedDocuments],
        rejected: [...rejectedDocuments]
    };
}
