// outsource dependencies
import _ from 'lodash';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';

// local dependencies


// configure
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);
const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
        easing: 'easeInOutQuad',
        duration: 520
    },
    plugins: {
        legend: {
            position: 'top',
            display: false
        },
        title: {
            display: true,
            text: 'Last 12 months',
            align: 'end',
            font: {
                family: 'Roboto',
                weight: 'bold',
                style: 'normal',
                size: 14
            },
        },
        tooltip: {
            callbacks: {
                title: (context) => {
                    const value = Number(_.get(_.first(context), 'label'));
                    return moment(value, 'M').format('MMMM');
                }
            }
        }
    },
    scales: {
        x: {
            grid: {
                color: '#e9e9e9',
                tickColor: '#929292',
                borderColor: '#929292',
                borderWidth: 2,
                tickWidth: 2,
                tickLength: 8,
            }
        },
        y: {
            beginAtZero: true,
            grid: {
                color: '#e9e9e9',
                tickColor: '#929292',
                borderColor: '#929292',
                borderWidth: 2,
                tickWidth: 2,
                tickLength: 8,
            },
            ticks: {
                // forces step size to be 1000 units
                stepSize: 1000
            }
        }
    },
    /*elements: {
        line: {
            tension: 0.4
        }
    },
    point: {
        backgroundColor: 'white'
    },
    tooltips: {
        titleFontFamily: 'Open Sans',
        backgroundColor: 'rgba(0,0,0,0.3)',
        titleFontColor: 'red',
        caretSize: 5,
        cornerRadius: 2,
        xPadding: 10,
        yPadding: 10
    }*/
};

export const UserEngagementChart = memo(function UserEngagementChart ({ className }) {
    const chartRef = useRef(null);
    const [chartData, setChartData] = useState({
        datasets: []
    });

    const data = useMemo(() => ({
        // labels: _.map(moment.months(), (_, index) => index + 1),
        labels: moment.monthsShort(),
        datasets: [
            {
                label: 'New users',
                data: [900, 1500, 2000, 2300, 2400, 2350, 2300, 2400, 2800, 3250, 3750, 3999],
                // borderColor: 'rgb(255, 99, 132)',
                // backgroundColor: 'rgba(255, 99, 132, 0.5)',
                borderColor: 'rgba(233, 175, 144, 1)',
                backgroundColor: 'rgba(233, 175, 144, 0.1)',
                borderWidth: 1,
                pointBorderWidth: 1,
                pointHoverRadius: 3,
                pointHoverBorderWidth: 1,
            }
        ]
    }), []);

    useEffect(() => {
        const chart = chartRef.current;

        if (!chart) {
            return;
        }

        const chartData = {
            ...data,
            datasets: data.datasets.map((dataset) => ({
                ...dataset,
                tension: 0.4,
                fill: true
                // borderColor: createGradient(chart.ctx, chart.chartArea),
                // backgroundColor: createGradient(chart.ctx, chart.chartArea),
                // fill: true
            }))
        };

        setChartData(chartData);
    }, [data]);

    return <div className={cx('', className)}>
        <Line ref={chartRef} options={chartOptions} data={chartData} />
    </div>;
});
UserEngagementChart.propTypes = {
    className: PropTypes.string,
};

UserEngagementChart.defaultProps = {
    className: '',
};
