
// outsource dependencies
import _ from 'lodash';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import React, { memo, useCallback, useMemo } from 'react';
import { Button, Col, Container, Modal, ModalBody, Row } from 'reactstrap';
import { useControllerActions, useControllerData } from 'redux-saga-controller';

// components
import { FasIcon } from 'components';

// constants
import { NEW } from 'constants/spec';
import * as ROUTES from 'constants/routes';

// local dependencies
import { controller } from './controller';
import DepartmentsTable from './components/departments-table';

const OrganizationDepartmentsList = memo(function OrganizationDepartmentsList ({ className }) {
    const { id } = useParams();
    const { list, page, sizePage, totalItems, showModal, disabled } = useControllerData(controller);
    const { loadMore, removeDepartment, updateCtrl } = useControllerActions(controller);

    const prepareList = useMemo(() => (list || []).map(({ name, department_users_count, status, id, organization_id }) => ({
        id,
        name,
        status,
        organization_id,
        users: department_users_count || 0,
        showConfirmModal: () => updateCtrl({ showModal: true, departmentId: id })
    })), [list, updateCtrl]);

    const isShowShowMoreButton = page < Math.ceil(totalItems / sizePage);

    const handleToggleModalConfirm = useCallback(() => updateCtrl({ showModal: !showModal }), [showModal, updateCtrl]);

    return <Row tag="section" className={cx('organization-departments-list', className)}>
        <Col xs="12" className="d-flex justify-content-between align-items-center mb-4">
            <h4 className="fw-bolder fz-24 text-gray-800 mb-0">
                Departments
            </h4>
            <Button
                outline
                tag={Link}
                disabled={disabled}
                color="primary-white"
                className="px-4 btn-extended"
                to={ROUTES.ORGANIZATION_DEPARTMENT.LINK({ id, departmentId: NEW })}
            >
                <FasIcon icon="plus" className="fz-16 me-2" />
                Add Department
            </Button>
        </Col>
        { _.size(prepareList) ? <>
            <Col xs="12" className="mb-4">
                <DepartmentsTable data={prepareList} />
                { showModal && <ConfirmModal
                    isOpen={showModal}
                    success={removeDepartment}
                    dismiss={handleToggleModalConfirm}
                /> }
            </Col>
            { isShowShowMoreButton && <Col xs="12" className="d-flex justify-content-end mb-5">
                <Button
                    color="link"
                    onClick={loadMore}
                    disabled={disabled}
                    className="fz-16 fw-500 font-family-alternative text-decoration-none"
                >
                    Show More
                </Button>
            </Col>}
        </> : <Col xs="12" tag="p" className="fz-32 fw-700 text-gray-700 text-center mb-4">
            List is empty
        </Col>}
    </Row>;
});

OrganizationDepartmentsList.propTypes = {
    className: PropTypes.string,
};

OrganizationDepartmentsList.defaultProps = {
    className: ''
};

export default OrganizationDepartmentsList;

const ConfirmModal = memo(function ConfirmModal (props) {
    const { isOpen, dismiss, success } = props;
    return <Modal centered isOpen={isOpen} modalClassName="confirmation-modal">
        <ModalBody className="confirm-modal-body">
            <Container fluid>
                <Row className="d-flex justify-content-center">
                    <Col xs="8">
                        <h2 className="confirm-modal-message text-secondary text-center">Are you sure you want to delete this Department?</h2>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                    <Col xs="4" sm="3">
                        <Button
                            block
                            outline
                            onClick={success}
                            color="primary-white" className="fw-500 btn-extended font-family-alternative"
                        >
                            Yes
                        </Button>
                    </Col>
                    <Col xs="4" sm="3">
                        <Button
                            block
                            outline
                            onClick={dismiss}
                            color="primary-white" className="fw-500 btn-extended font-family-alternative"
                        >
                            No
                        </Button>
                    </Col>
                </Row>
            </Container>
        </ModalBody>
    </Modal>;
});

ConfirmModal.propTypes = {
    isOpen: PropTypes.bool,
    dismiss: PropTypes.func.isRequired,
    success: PropTypes.func.isRequired,
};

ConfirmModal.defaultProps = {
    isOpen: false,
};
