
// outsource dependencies
import { Field } from 'redux-form';
import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { useControllerActions, useControllerData } from 'redux-saga-controller';

// local dependencies
import controller from './controller';
import { ReduxForm, RFInput, RFInputNumber } from 'components';

// configure
export const FORM_NAME = 'organizationDepartmentDetailsForm';

const formValidation = values => {
    const errors = {};
    if (!values.name) {
        errors.name = 'Organization name is required';
    }
    if (!values.keyword) {
        errors.keyword = 'Url Keyword is required';
    } else if (values.keyword.replace(/([A-Za-z-])/ig, '').length) {
        errors.keyword = 'Url Keyword to consist of alphabet or dash characters only';
    }
    if (values.phone && values.phone.length < 10) {
        errors.phone = 'Phone to consist of 10 figures';
    }
    return errors;
};

const Details = memo(function Details () {
    const { goBack } = useHistory();
    const { initialValues, disabled } = useControllerData(controller);
    const { submitData } = useControllerActions(controller);

    return <ReduxForm
        form={FORM_NAME}
        enableReinitialize
        onSubmit={submitData}
        validate={formValidation}
        initialValues={initialValues}
    >
        <Row>
            <Col xs="12" className="mb-4">
                <Field
                    type="text"
                    name="name"
                    placeholder="Name"
                    disabled={disabled}
                    component={RFInput}
                    label="Organization Name"
                />
            </Col>
            <Col xs="12" className="mb-4">
                <Field
                    type="text"
                    name="keyword"
                    disabled={disabled}
                    component={RFInput}
                    placeholder="Url Keyword"
                    label="Organization Url Keyword"
                />
            </Col>
            <Col xs="12" className="mb-4">
                <Field
                    type="text"
                    name="street1"
                    label="Address"
                    disabled={disabled}
                    component={RFInput}
                    placeholder="Line 1"
                />
            </Col>
            <Col xs="12" className="mb-4">
                <Field
                    type="text"
                    name="street2"
                    disabled={disabled}
                    component={RFInput}
                    placeholder="Line 2"
                />
            </Col>
            <Col xs="12" className="mb-4">
                <Field
                    type="text"
                    name="city"
                    placeholder="City"
                    disabled={disabled}
                    component={RFInput}
                />
            </Col>
            <Col xs="6" className="mb-4">
                <Field
                    type="text"
                    name="state"
                    placeholder="State"
                    disabled={disabled}
                    component={RFInput}
                />
            </Col>
            <Col xs="6" className="mb-4">
                <Field
                    name="zip"
                    type="number"
                    disabled={disabled}
                    component={RFInput}
                    placeholder="ZIP code"
                />
            </Col>
            <Col xs="12" className="mb-4">
                <Field
                    type="tel"
                    name="phone"
                    disabled={disabled}
                    label="Phone Number"
                    component={RFInputNumber}
                    placeholder="(xxx) xxx-xxxx"
                />
            </Col>
            <Col xs="12" className="mb-4">
                <Field
                    type="email"
                    name="сontactEmail"
                    disabled={disabled}
                    component={RFInput}
                    label="Contact Email"
                    placeholder="Contact Email"
                />
            </Col>
            <Col xs="12" className="d-flex justify-content-end">
                <Button
                    outline
                    type="submit"
                    onClick={goBack}
                    color="primary-white"
                    className="btn-extended px-5 me-3"
                >
                    Cancel
                </Button>
                <Button type="submit" color="primary" className="text-white px-5">
                    Update
                </Button>
            </Col>
        </Row>
    </ReduxForm>;
});

export default Details;
