
// outsource dependencies
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { create } from 'redux-saga-controller';
import { change, getFormValues, reset } from 'redux-form';
import { takeEvery, put, call, select } from 'redux-saga/effects';

// local dependencies
import { FORM_NAME } from './filter';
import { instanceAPI } from 'services';
import appController from 'controller';
import { SYSTEM_ROLE_TYPE } from 'constants/spec';

// configure

export const controller = create({
    prefix: 'users-list',
    actions: {
        initialize: 'INITIALIZE',
        updateData: 'UPDATE_DATA',
    },
    initial: {
        disabled: false,
        initialized: false,
        errorMessage: null,

        sortF: '',
        sortD: 0,
        initialFilter: {},

        page: 1,
        sizePage: 10,
        totalItems: 0,

        list: [],
    },
    subscriber: function * () {
        yield takeEvery(controller.action.initialize.TYPE, initializeSaga);
        yield takeEvery(controller.action.updateData.TYPE, updateDataSaga);
    }
});

controller.action.resetForm = () => reset(FORM_NAME);
controller.action.resetField = ({ field }) => change(FORM_NAME, field, '');

export default controller;

function * initializeSaga ({ type, payload }) {
    // console.log(`%c ${type} `, 'color: #FF6766; font-weight: bolder; font-size: 12px;'
    //   , '\n payload:', payload
    // );
    try {
        const { data, meta: { total, per_page, current_page } } = yield call(getListExecutor);
        yield put(controller.action.updateCtrl({
            page: current_page,
            sizePage: per_page,
            totalItems: total,
            list: data,
        }));
    } catch ({ message }) {
        yield call(toastr.error, 'Users List', message);
        yield put(controller.action.updateCtrl({ errorMessage: message }));
    }
    yield put(controller.action.updateCtrl({ initialized: true }));
}

function * updateDataSaga ({ type, payload }) {
    yield put(controller.action.updateCtrl({ ...payload, disabled: true }));
    try {
        const { page, sortF, sortD } = yield select(controller.select);
        const filter = yield select(getFormValues(FORM_NAME));
        const { data, meta: { total, per_page, current_page } } = yield call(getListExecutor, {
            page, sortF, sortD, ...filter
        });
        yield put(controller.action.updateCtrl({
            sizePage: per_page,
            page: current_page,
            totalItems: total,
            list: data,
            sortF,
            sortD,
        }));
    } catch ({ message }) {
        yield call(toastr.error, 'Users List Update', message);
        yield put(controller.action.updateCtrl({ errorMessage: message }));
    }
    yield put(controller.action.updateCtrl({ disabled: false }));
}

function * getListExecutor ({ sortF, sortD, role, department, status, organization, ...data } = {}) {
    const { user } = yield select(appController.select);
    const params = {
        ...data,
        role_id: _.get(role, 'id'),
        status: _.get(status, 'value'),
        department: _.get(department, 'id'),
    };
    if (sortF) { params[sortF] = sortD; }
    if (_.get(user, 'role.type_name') === SYSTEM_ROLE_TYPE.ORGANIZATION_ADMIN) {
        const { organization } = yield select(appController.select);
        params.organization_id = organization.id;
    } else {
        params.organization_id = _.get(organization, 'id');
    }
    return yield call(instanceAPI, { method: 'GET', url: '/organization/user/search', params });
}
