
// outsource dependencies
import _ from 'lodash';
import { Field } from 'redux-form';
import { Link } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { Button, Col, Row } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useController, useControllerData } from 'redux-saga-controller';

// root
import appRootController from 'controller';
import { useRefCallback, useSelf } from 'hooks';

// components
import { BoxLoader } from 'components/preloader';
import { AlertError, FasIcon, ReduxForm, RFInput } from 'components';

// constants
import * as ROUTES from 'constants/routes';
import { FULL_HEIGHT_SCREEN } from 'constants/spec';

// services
import { isEmail } from 'services';

// local dependencies
import controller from './controller';

// images
import { DefaultImage } from 'images';
import defaultLogo from 'images/default-logo.svg';
import companyLogo from 'images/company-logo.svg';

// configure
export const FORM_NAME = 'signInForm';

const formValidation = values => {
    const errors = {};
    if (!values.email) {
        errors.email = 'Email is required';
    } else if (!isEmail(values.email)) {
        errors.email = 'Invalid email address';
    }
    if (!values.password) {
        errors.password = 'Password is required';
    } else if (values.password.length < 8) {
        errors.password = 'Password must contain at least 8 symbol character';
    }
    return errors;
};

const SignIn = memo(function SignIn () {
    const [
        { initialized, disabled, initialValues, errorMessage },
        { initialize, submitData, updateCtrl },
    ] = useController(controller);
    const user = useSelf();

    const [form, formRef] = useRefCallback();

    const [scrollTop, setScrollTop] = useState(0);
    const [imageHeight, setImageHeight] = useState(0);
    const [isVisibleScrollButton, setVisibleScrollButton] = useState(false);

    // NOTE initialize business logic
    useEffect(() => { initialize(); }, [initialize]);

    const { organization } = useControllerData(appRootController);
    const logo = _.get(organization, 'logo');
    const background = _.get(organization, 'background');
    const name = _.get(organization, 'name', 'Organization Name');
    const description = _.get(organization, 'description', 'Default description');

    // NOTE show message to suggest proceed as detected user
    useEffect(() => {
        // user && toastr.message('Authorization detected', 'Proceed as:', { component: UserProceed });
        // NOTE remove toastr automatically if we go out from public layout
        return () => toastr.removeByType('message');
    }, [user]);
    // NOTE listener for scroll button change
    useEffect(() => {
        const formOffsetTop = _.get(form, 'offsetTop', 0);
        const heightScreen = window.screen.height;
        if ((heightScreen + scrollTop) < formOffsetTop) {
            setVisibleScrollButton(true);
        } else {
            setVisibleScrollButton(false);
        }
    }, [imageHeight, initialized, scrollTop, form]);

    // NOTE scroll to form
    const handleScrollToForm = useCallback(() => form.scrollIntoView({ behavior: 'smooth', block: 'center' }), [form]);
    // NOTE listener for receive current position
    const handleScroll = useCallback(values => setScrollTop(values.scrollTop), []);
    // NOTE listener which get image height when image will be load
    const getImageHeight = useCallback(event => setImageHeight(_.get(event, 'target.clientHeight')), []);
    const clearError = useCallback(() => updateCtrl({ errorMessage: null }), [updateCtrl]);

    return <Scrollbars style={{ height: FULL_HEIGHT_SCREEN }} onScrollFrame={handleScroll}>
        <BoxLoader id="AppSignIn" active={!initialized}>
            <Row className="g-0 position-relative justify-content-center h-100">
                { isVisibleScrollButton && <Col className="d-lg-none position-fixed start-0 end-0 bottom-0 bg-transparent p-3">
                    <Button
                        color="primary"
                        onClick={handleScrollToForm}
                        className="font-family-alternative text-white fz-16 fw-500 text-center text-secondary rounded-5 w-100"
                    >
                        Log in
                    </Button>
                </Col> }
                <Col lg="6" xs="12" className="g-0 bg-lg-platform p-3 p-lg-5 d-flex flex-column">
                    <Row>
                        <Col xs="12" className="d-flex mb-5 flex-wrap flex-sm-nowrap align-items-center">
                            <DefaultImage
                                src={logo}
                                alt={`Logo ${name}`}
                                title={`Logo ${name}`}
                                defaultSrc={defaultLogo}
                                defaultAlt="Logo Company"
                                defaultTitle="Logo Company"
                                className="me-sm-5 mb-4 mb-sm-0"
                                style={{ maxWidth: 120, maxHeight: 120 }}
                            />
                            <h1 className="fz-24 fw-lg-600 fw-700 text-secondary mb-0">
                                { name }
                            </h1>
                        </Col>
                    </Row>
                    <Row className="d-none d-lg-flex justify-content-start flex-grow-1">
                        <Col xs="12" className="mb-5">
                            <DefaultImage
                                src={background}
                                onLoad={getImageHeight}
                                alt={`Main Image ${name}`}
                                title={`Main Image ${name}`}
                                defaultAlt="Default organization"
                                defaultTitle="Default organization"
                                className="rounded-6 w-50 me-4 float-start"
                            />
                            <p dangerouslySetInnerHTML={{ __html: description }} className="font-family-alternative fz-18 text-gray-700 text-pre-wrap" />
                        </Col>
                    </Row>
                    <Row className="d-flex d-lg-none justify-content-center mb-5">
                        <Col xs="12" sm="6" className="mb-4">
                            <DefaultImage
                                src={background}
                                onLoad={getImageHeight}
                                alt={`Main Image ${name}`}
                                className="rounded-6 w-100"
                                title={`Main Image ${name}`}
                                defaultAlt="Default organization"
                                defaultTitle="Default organization"
                            />
                        </Col>
                        <Col xs="12">
                            <p dangerouslySetInnerHTML={{ __html: description }} className="font-family-alternative fz-18 text-gray-700 text-pre-wrap" />
                        </Col>
                    </Row>
                    <Row className="justify-content-center mb-5 mb-lg-0">
                        <Col xs="10" sm="4" md="4">
                            <DefaultImage
                                src={companyLogo}
                                className="w-100"
                                onLoad={getImageHeight}
                                alt={`Main Image ${name}`}
                                defaultAlt="Anonymous Logo"
                                title={`Main Image ${name}`}
                                defaultTitle="Anonymous Logo"
                            />
                        </Col>
                    </Row>
                </Col>
                <Col
                    lg="6"
                    xs="12"
                    tag={ReduxForm}
                    form={FORM_NAME}
                    enableReinitialize
                    onSubmit={submitData}
                    validate={formValidation}
                    initialValues={initialValues}
                    className="align-items-center"
                >
                    <Row className="g-0 align-items-center justify-content-center p-3 p-lg-5 border-bottom border-gray">
                        <Col xs="12" lg="8" tag="h2" className="fz-24 fw-600 text-secondary text-center mb-4">
                            Submit an Idea or File a Complaint
                        </Col>
                        <Col xs="12" lg="11" tag="p" className="font-family-alternative fz-18 mb-4">
                            If you would like to submit an idea anonymously or file a complaint anonymously,
                            please, Press “I would like to submit an idea anonymously” or “I would like to file
                            a complaint anonymously” buttons below to access the Platform.
                        </Col>
                        <Col xs="12" lg="8" className="mb-4">
                            <Button
                                outline
                                tag={Link}
                                color="primary-white"
                                to={ROUTES.SUBMIT_SUGGESTIONS.LINK(null, { anonymous: true })}
                                className="font-family-alternative fz-16 fw-500 text-center w-100 btn-extended"
                            >
                                I would like to submit an idea anonymously
                            </Button>
                        </Col>
                        <Col xs="12" lg="8" className="mb-3">
                            <Button
                                outline
                                tag={Link}
                                color="primary-white"
                                to={ROUTES.REPORT.LINK(null, { anonymous: true })}
                                className="font-family-alternative fz-16 fw-500 text-center w-100 btn-extended"
                            >
                                I would like to file a complaint anonymously
                            </Button>
                        </Col>
                        <Col xs="12" tag="p" className="font-family-alternative fw-700 fz-14 text-center mb-4">
                            Don&apos;t have an account?
                            &nbsp;
                            <Link to={ROUTES.REQUEST_ACCOUNT.LINK()} className="text-primary-white text-decoration-none">
                                Request Account
                            </Link>
                        </Col>
                    </Row>
                    <Row className="g-0 align-items-center justify-content-center p-3 p-lg-5">
                        <Col xs="12" lg="8" tag="h2" className="fz-24 fw-600 text-secondary text-center mb-4">
                            Log In
                        </Col>
                        <Col xs="12" lg="11" tag="p" className="font-family-alternative fz-18 mb-4">
                            If you would like to manage and track your complaint reports and get more functionality
                            Log In and access the Platform.
                        </Col>
                        <Col xs="12" lg="8" className="mb-3">
                            <div id="form" ref={formRef}>
                                <Field
                                    type="email"
                                    name="email"
                                    label="Your Email"
                                    component={RFInput}
                                    disabled={disabled}
                                    placeholder="Email"
                                    classNameGroup="mb-3"
                                />
                                <Field
                                    name="password"
                                    label="Password"
                                    disabled={disabled}
                                    placeholder="Password"
                                    component={PasswordInput}
                                />
                            </div>
                        </Col>
                        <Col
                            xs="12"
                            lg="8"
                            className="d-flex justify-content-center justify-content-lg-end mb-3"
                        >
                            <Link to={ROUTES.FORGOT_PASSWORD.LINK()}
                                className="font-family-alternative fz-14 text-blue text-decoration-none text-end"
                            >
                                Forgot Password?
                            </Link>
                        </Col>
                        <Col xs="12" lg="8" className="mb-5">
                            <Button
                                type="submit"
                                color="primary"
                                className="font-family-alternative text-white fz-16 fw-500 text-center text-secondary w-100"
                            >
                                Log In
                            </Button>
                            <AlertError
                                active
                                color="danger"
                                onClear={clearError}
                                message={errorMessage}
                                title="Something went wrong!"
                                className="text-left alert-dismissible submission-message mt-2"
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </BoxLoader>
    </Scrollbars>;
});

export default SignIn;

const PasswordInput = memo(function PasswordInput (props) {
    const [isPasswordType, setPasswordType] = useState(true);

    const changeType = useCallback(() => setPasswordType(type => !type), [setPasswordType]);

    return <RFInput
        {...props}
        type={isPasswordType ? 'password' : 'text'}
        icon={<Button
            onClick={changeType}
            style={{ width: 20, height: 20 }}
            className="d-flex justify-content-center align-items-center border-0 bg-transparent p-1"
        >
            <FasIcon icon={isPasswordType ? 'eye' : 'eye-slash'} className="fz-18 text-gray-500" />
        </Button>}
    />;
});

/*const UserProceed = memo(function UserProceed (props) {
    const name = _.get(props, 'name', 'User Name');
    return <div className="d-flex align-items-center">
        <Button color="link" href={ROUTES.DASHBOARD.LINK()}>
            <strong> { name } </strong>
        </Button>
    </div>;
});*/
