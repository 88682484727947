
// outsource dependencies
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useWindowSize } from 'react-use';

// local dependencies
import Header from './header';
import { headerHeight } from 'constants/spec';

// configure

const Layout = memo(function Layout ({ children, className }) {
    const { height } = useWindowSize();
    const actualHeight = useMemo(() => height - headerHeight, [height]);

    return <>
        <Header />
        <main id="AppMain" className={cx('bg-platform pb-3', className)}>
            <Scrollbars style={{ height: actualHeight }}>
                { children }
            </Scrollbars>
        </main>
    </>;
});
Layout.propTypes = {
    className: PropTypes.string,
    children: PropTypes.element.isRequired
};
Layout.defaultProps = {
    className: ''
};

export default Layout;
