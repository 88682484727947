
// outsource dependencies
import _ from 'lodash';
import { useController } from 'redux-saga-controller';
import React, { forwardRef, memo, useCallback, useEffect, useMemo } from 'react';
import { NavLink as RRDNavLink, Route, Redirect, Switch, useParams } from 'react-router-dom';
import { Col, Container, Nav, NavItem, Row, NavLink, InputGroup, InputGroupText, Input } from 'reactstrap';

// local dependencies
import Layout from 'pages/layout';
import controller from './controller';
import { BoxLoader } from 'components/preloader';
import { ANALYTICS_CHART, ANALYTICS_CHART_KEYS } from '../../constants';
import { ComplaintsStats } from './analytics-charts/complaints-stats';
import { DepartmentUsers } from './analytics-charts/department-users';
import { UsersEngagement } from './analytics-charts/users-engagement';
import { CategoryAnalysis } from './analytics-charts/category-analysis';
import { IndustryComparison } from './analytics-charts/industry-comparison';
import { SatisfactionFeedback } from './analytics-charts/satisfaction-feedback';

// configure
const DEFAULT_TAB_CHART_INDEX = 3;
export const tabChartList = [
    { name: 'Complaints Stats', key: ANALYTICS_CHART_KEYS.COMPLAINTS_STATS, route: ANALYTICS_CHART, component: ComplaintsStats },
    { name: 'Category Analysis', key: ANALYTICS_CHART_KEYS.CATEGORY_ANALYSIS, route: ANALYTICS_CHART, component: CategoryAnalysis },
    { name: 'Industry Comparison', key: ANALYTICS_CHART_KEYS.INDUSTRY_COMPARISON, route: ANALYTICS_CHART, component: IndustryComparison },
    { name: 'Satisfaction Feedback', key: ANALYTICS_CHART_KEYS.SATISFACTION_FEEDBACK, route: ANALYTICS_CHART, component: SatisfactionFeedback },
    { name: 'Users by Department', key: ANALYTICS_CHART_KEYS.DEPARTMENT_USERS, route: ANALYTICS_CHART, component: DepartmentUsers },
    { name: 'Users Engagement', key: ANALYTICS_CHART_KEYS.USERS_ENGAGEMENT, route: ANALYTICS_CHART, component: UsersEngagement },
];
const Analytics = memo(function Analytics () {
    const [
        { initialized },
        { initialize },
    ] = useController(controller);

    // NOTE initialize business logic
    useEffect(() => { initialize(); }, [initialize]);

    return <Layout className="analytics-page">
        <Container className="mt-4 mb-5 h-100">
            <BoxLoader id="AppAnalytics" active={!initialized}>
                <Col tag="h1" xs="12" className="fw-600 fz-24 text-gray-800 mb-4">
                    Analytics
                </Col>
                <Col className="bg-white rounded-3 p-4 mb-5">
                    <Row>
                        <Col xs="3">
                            <h3 className="fz-18 fw-400 text-secondary px-1 mb-4">
                                Select Chart from  List
                            </h3>
                            <Nav vertical>
                                { tabChartList.map((tab) => <NavItem key={tab.key}>
                                    <NavLink
                                        className="font-family-alternative fz-16 cursor-pointer px-1 mb-2"
                                        to={tab.route.LINK({ analyticsChart: tab.key })}
                                        activeClassName="active"
                                        tag={RRDNavLink}
                                        title={tab.name}
                                        exact
                                    >
                                        { tab.name }
                                    </NavLink>
                                </NavItem>) }
                            </Nav>
                        </Col>
                        <Col xs="9">
                            <Switch>
                                <Route exact path={ANALYTICS_CHART.ROUTE} component={AnalyticsChartBody} />
                                <Redirect to={{
                                    pathname: tabChartList[DEFAULT_TAB_CHART_INDEX].route.LINK({
                                        analyticsChart: tabChartList[DEFAULT_TAB_CHART_INDEX].key
                                    })
                                }} />
                            </Switch>
                        </Col>
                    </Row>
                </Col>
            </BoxLoader>
        </Container>
    </Layout>;
});

export default Analytics;

const AnalyticsChartBody = memo(function AnalyticsChartBody () {
    const { analyticsChart } = useParams();
    const Component = useMemo(
        () => _.get(_.find(tabChartList, (tab) => _.isEqual(tab.key, analyticsChart)), 'component'),
        [analyticsChart]
    );
    return <>{ Component ? <Component /> : <Redirect to={{
        pathname: tabChartList[DEFAULT_TAB_CHART_INDEX].route.LINK({
            analyticsChart: tabChartList[DEFAULT_TAB_CHART_INDEX].key
        })
    }} /> }
    </>;
});
