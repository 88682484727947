
// outsource dependencies
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

// local dependencies
import { useSelf } from 'hooks';
import { useWindowSize } from 'react-use';
import HeaderUser from './header-user';
import { headerHeight } from 'constants/spec';

// configure

const LayoutFallback = memo(function LayoutFallback ({ children, className, ref }) {
    const user = useSelf();
    const { height } = useWindowSize();

    const actualHeight = useMemo(
        () => user ? height - headerHeight : height + 1,
        [height, user]
    );

    return <>
        { user ? <HeaderUser /> : null }
        <main id="AppMain" className={cx('bg-platform pb-3', className)}>
            <Scrollbars style={{ height: actualHeight }} ref={ref}>
                { children }
            </Scrollbars>
        </main>
    </>;
});
LayoutFallback.propTypes = {
    className: PropTypes.string,
    children: PropTypes.element.isRequired
};
LayoutFallback.defaultProps = {
    className: '',
};

export default LayoutFallback;
