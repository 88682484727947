
// outsource dependencies
import { toastr } from 'react-redux-toastr';
import { create } from 'redux-saga-controller';
import { takeEvery, put, call, select } from 'redux-saga/effects';

// local dependencies
import { instanceAPI } from 'services';
import appController from 'controller';
import { INCIDENT_TYPE } from 'constants/spec';

// configure
const type = INCIDENT_TYPE.ALL;

export const controller = create({
    prefix: 'cases-list',
    actions: {
        loadMore: 'LOAD_MORE',
        initialize: 'INITIALIZE',
    },
    initial: {
        disabled: false,
        initialized: false,
        errorMessage: null,

        page: 1,
        sizePage: 10,
        totalItems: 0,

        list: [],
    },
    subscriber: function * () {
        yield takeEvery(controller.action.loadMore.TYPE, loadMoreSaga);
        yield takeEvery(controller.action.initialize.TYPE, initializeSaga);
    }
});

export default controller;

function * initializeSaga () {
    // console.log(`%c ${type} `, 'color: #FF6766; font-weight: bolder; font-size: 12px;'
    //   , '\n payload:', payload
    // );
    try {
        const { data, meta: { total, per_page, current_page } } = yield call(getOrganizationListExecutor);
        yield put(controller.action.updateCtrl({
            list: data,
            totalItems: total,
            sizePage: per_page,
            page: current_page,
        }));
    } catch ({ message }) {
        yield call(toastr.error, 'Cases List Page Initialize', message);
        yield put(controller.action.updateCtrl({ errorMessage: message }));
    }
    yield put(controller.action.updateCtrl({ initialized: true }));
}

function * loadMoreSaga ({ payload }) {
    yield put(controller.action.updateCtrl({ ...payload, disabled: true }));
    try {
        const { list } = yield select(controller.select);
        const { data, meta: { total, per_page, current_page } } = yield call(getOrganizationListExecutor);
        const concatList = list.concat(data);
        yield put(controller.action.updateCtrl({
            sizePage: per_page,
            page: current_page,
            totalItems: total,
            list: concatList,
        }));
    } catch ({ message }) {
        yield call(toastr.error, 'Cases List Page Update', message);
        yield put(controller.action.updateCtrl({ errorMessage: message }));
    }
    yield put(controller.action.updateCtrl({ disabled: false }));
}

function * getOrganizationListExecutor () {
    const { page } = yield select(controller.select);
    const { user } = yield select(appController.select);
    const params = { sort_date: 0, originator_name: `${user.first_name} ${user.last_name}`, type, page };
    return yield call(instanceAPI, { method: 'GET', url: '/incident/search', params });
}
