
// outsource dependencies
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Link } from 'react-router-dom';
import { useController } from 'redux-saga-controller';
import { Scrollbars } from 'react-custom-scrollbars-2';
import React, { memo, useCallback, useEffect } from 'react';
import { Button, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

// root
import { useMainOrganization } from 'hooks';

// components
import { BoxLoader, FasIcon, ReduxForm, RFInput } from 'components';

// constants
import * as ROUTES from 'constants/routes';

// services
import { isEmail } from 'services/validation.service';

// local dependencies
import controller from './controller';

// images
import { DefaultImage } from 'images';
import defaultLogo from 'images/default-logo.svg';
import companyLogo from 'images/company-logo.svg';
import sentEmail from 'images/forgot-password/dashed-line.svg';

// configure
const contentHeight = window.innerHeight;
export const FORM_NAME = 'requestAccountForm';

const formValidation = values => {
    const errors = {};
    if (!values.email) {
        errors.email = 'Email is required';
    } else if (!isEmail(values.email)) {
        errors.email = 'Invalid email address';
    }
    if (!values.name) {
        errors.name = 'Name is required';
    }
    if (!values.organization) {
        errors.organization = 'Organization is required';
    }
    return errors;
};

const RequestAccount = memo(function RequestAccount () {
    const [
        { initialized, initialValues, disabled, showModal },
        { initialize, submitData, updateCtrl }
    ] = useController(controller);
    const { name, logo, background, description } = useMainOrganization();

    // NOTE initialize business logic
    useEffect(() => { initialize(); }, [initialize]);

    const handleCloseToast = useCallback(() => updateCtrl({ showModal: false }), [updateCtrl]);

    return <Scrollbars style={{ height: contentHeight }}>
        <BoxLoader id="AppRequestAccount" active={!initialized}>
            <Row className="g-0 position-relative justify-content-center h-100">
                <SuccessRequestModal isOpen={showModal} success={handleCloseToast} dismiss={handleCloseToast} />
                <Col lg="6" xs="12" className="g-0 bg-lg-platform p-3 p-lg-5 d-flex flex-column">
                    <Row>
                        <Col xs="12" className="d-flex mb-5 flex-wrap flex-sm-nowrap align-items-center">
                            <DefaultImage
                                src={logo}
                                alt={`Logo ${name}`}
                                title={`Logo ${name}`}
                                defaultSrc={defaultLogo}
                                defaultAlt="Logo Company"
                                defaultTitle="Logo Company"
                                className="me-sm-5 mb-4 mb-sm-0"
                                style={{ maxWidth: 120, maxHeight: 120 }}
                            />
                            <h1 className="fz-24 fw-lg-600 fw-700 text-secondary mb-0">
                                { name }
                            </h1>
                        </Col>
                    </Row>
                    <Row className="d-none d-lg-flex justify-content-start flex-grow-1">
                        <Col xs="12" className="mb-5">
                            <DefaultImage
                                src={background}
                                alt={`Main Image ${name}`}
                                title={`Main Image ${name}`}
                                defaultAlt="Default organization"
                                defaultTitle="Default organization"
                                className="rounded-6 w-50 me-4 float-start"
                            />
                            <p dangerouslySetInnerHTML={{ __html: description }} className="font-family-alternative fz-18 text-gray-700 text-pre-wrap" />
                        </Col>
                    </Row>
                    <Row className="d-flex d-lg-none justify-content-center mb-5">
                        <Col xs="12" sm="6" className="mb-4">
                            <DefaultImage
                                src={background}
                                alt={`Main Image ${name}`}
                                className="rounded-6 w-100"
                                title={`Main Image ${name}`}
                                defaultAlt="Default organization"
                                defaultTitle="Default organization"
                            />
                        </Col>
                        <Col xs="12">
                            <p dangerouslySetInnerHTML={{ __html: description }} className="font-family-alternative fz-18 text-gray-700 text-pre-wrap" />
                        </Col>
                    </Row>
                    <Row className="justify-content-center mb-5 mb-lg-0">
                        <Col xs="10" sm="4" md="4">
                            <DefaultImage
                                src={companyLogo}
                                className="w-100"
                                alt={`Main Image ${name}`}
                                defaultAlt="Anonymous Logo"
                                title={`Main Image ${name}`}
                                defaultTitle="Anonymous Logo"
                            />
                        </Col>
                    </Row>
                </Col>
                <Col
                    lg="6"
                    xs="12"
                    tag={ReduxForm}
                    form={FORM_NAME}
                    enableReinitialize
                    onSubmit={submitData}
                    validate={formValidation}
                    initialValues={initialValues}
                    className="d-flex align-items-start align-items-lg-center h-100"
                >
                    <Row className="g-0 p-3 p-lg-5">
                        <Col xs="12" tag="h2" className="fz-24 fw-600 text-secondary text-center mb-4">
                            Request Account
                        </Col>
                        <Col xs="12" className="mb-4">
                            <Field
                                type="text"
                                name="name"
                                label="Your Name"
                                component={RFInput}
                                disabled={disabled}
                                classNameGroup="mb-3"
                                placeholder="Your Name"
                            />
                            <Field
                                type="email"
                                name="email"
                                label="Your Email"
                                component={RFInput}
                                disabled={disabled}
                                placeholder="Email"
                                classNameGroup="mb-3"
                            />
                            <Field
                                type="text"
                                name="organization"
                                component={RFInput}
                                disabled={disabled}
                                label="Organization/Department Name"
                                placeholder="Organization/Department Name"
                            />
                        </Col>
                        <Col xs="12" tag="p" className="fz-14 mb-4">
                            By using the Anonymous Platform, you consent to our
                            &nbsp;
                            <Link to={ROUTES.SIGN_IN.LINK()} className="text-blue text-decoration-none">
                                Terms of Service
                            </Link>
                            ,&nbsp;
                            <Link to={ROUTES.SIGN_IN.LINK()} className="text-blue text-decoration-none">
                                Privacy Policy
                            </Link>
                            &nbsp;and&nbsp;
                            <Link to={ROUTES.SIGN_IN.LINK()} className="text-blue text-decoration-none">
                                Cookie Policy
                            </Link>
                            .
                        </Col>
                        <Col xs="12" className="mb-3">
                            <Button
                                type="submit"
                                color="primary"
                                disabled={disabled}
                                className="font-family-alternative text-white fz-16 fw-500 text-center text-secondary rounded-5 w-100"
                            >
                                Request Account
                            </Button>
                        </Col>
                        <Col xs="12" tag="p" className="fz-12 text-center mb-4">
                            Already have an account?&nbsp;
                            <Link to={ROUTES.SIGN_IN.LINK()} className="text-blue text-decoration-none">
                                Log In
                            </Link>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </BoxLoader>
    </Scrollbars>;
});

export default RequestAccount;

const SuccessRequestModal = memo(function SuccessRequestModal (props) {
    const { isOpen, dismiss, success, modalClassNames } = props;
    return <Modal
        centered
        isOpen={isOpen}
        style={{ maxWidth: 640 }}
        modalClassName={cx('success-request px-1 px-lg-0', modalClassNames)}
    >
        <ModalHeader className="border-bottom-0 justify-content-end">
            <Button
                onClick={dismiss}
                className="bg-transparent border-0 p-0 shadow-none d-flex justify-content-center me-3 mt-3"
            >
                <FasIcon icon="times" className="fz-24 opacity-75" color="#989797" />
            </Button>
        </ModalHeader>
        <ModalBody className="confirm-modal-body">
            <Container fluid>
                <Row className="d-flex justify-content-center">
                    <Col xs="12" sm="8">
                        <h2 className="confirm-modal-message text-secondary text-center mb-4">
                            Thank you!
                        </h2>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                    <Col xs="12" sm="8">
                        <h2 className="confirm-modal-message text-secondary text-center mb-5">
                            Your Account Request has been sent successfully!
                        </h2>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                    <Col xs="8" sm="6">
                        <div className="actions">
                            <Button
                                block
                                outline
                                tag={Link}
                                onClick={success}
                                to={ROUTES.SIGN_IN.LINK()}
                                color="primary-white" className="fw-500 btn-extended btn-confirm-modal font-family-alternative"
                            >
                                Back to Log In
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </ModalBody>
        <ModalFooter className="border-top-0 justify-content-center p-0">
            <DefaultImage
                src={sentEmail}
                className="ms-0 me-0 mb-3 mt-3"
                defaultAlt="Success Request Image"
                defaultTitle="Success Request Image"
            />
        </ModalFooter>
    </Modal>;
});

SuccessRequestModal.propTypes = {
    modalClassNames: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    dismiss: PropTypes.func.isRequired,
    success: PropTypes.func.isRequired,
};

SuccessRequestModal.defaultProps = {
    modalClassNames: '',
};
