
// outsource dependencies
import { toastr } from 'react-redux-toastr';
import { create } from 'redux-saga-controller';
import { takeEvery, put, call } from 'redux-saga/effects';

// constants
import * as ROUTES from 'constants/routes';

// services
import { instanceAPI } from 'services';

// configure
export const controller = create({
    prefix: 'forgot-password',
    actions: {
        initialize: 'INITIALIZE',
        submitData: 'SUBMIT_DATA',
    },
    initial: {
        disabled: false,
        initialValues: {
            email: '',
        },
        initialized: false,
        errorMessage: null,
    },
    subscriber: function * () {
        yield takeEvery(controller.action.initialize.TYPE, initializeSaga);
        yield takeEvery(controller.action.submitData.TYPE, submitDataSaga);
    }
});

export default controller;

function * initializeSaga () {
    // console.log(`%c ${type} `, 'color: #FF6766; font-weight: bolder; font-size: 12px;'
    //   , '\n payload:', payload
    // );
    yield put(controller.action.updateCtrl({ initialized: true }));
}

function * submitDataSaga ({ payload }) {
    yield put(controller.action.updateCtrl({ disabled: true, errorMessage: null }));
    try {
        yield call(instanceAPI, { method: 'GET', url: '/password/reset', params: payload });
        yield call(ROUTES.EMAIL_SENT.PUSH);
    } catch ({ message }) {
        yield call(toastr.error, 'Reset Password', message);
        yield put(controller.action.updateCtrl({ errorMessage: message }));
    }
    yield put(controller.action.updateCtrl({ disabled: false }));
}
