
// outsource dependencies
import _ from 'lodash';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Input, Label } from 'reactstrap';
import NumberFormat from 'react-number-format';
import React, { memo, useCallback } from 'react';

// local dependencies
import { FasIcon } from './icon';

// configure

const InputNumber = memo(function InputNumber (props) {
    const {
        type, value, name, label, placeholder, icon, onChange, message, customInput, format, mask,
        className, classNameLabel, classNameIcon, classNameInputWrapper, classNameGroup, ...attributes
    } = props;
    const uid = _.kebabCase(`${type}-${name}`);
    const handleBlur = useCallback(() => onChange(value), [value, onChange]);
    const handleChange = useCallback(({ value }) => onChange(value), [onChange]);

    return <div className={classNameGroup}>
        { label ? <Label
            htmlFor={uid}
            className={cx(classNameLabel, { 'app-invalid-feedback': message })}
        >
            { label }
        </Label> : null }
        <div className={cx('position-relative', classNameInputWrapper)}>
            <NumberFormat
                id={uid}
                type={type}
                name={name}
                mask={mask}
                format={format}
                customInput={customInput}
                placeholder={placeholder}
                onValueChange={handleChange}
                className={cx(className, { 'pe-5': icon })}
                {...attributes}
                value={value}
                onBlur={handleBlur}
            />
            { icon && <Label
                htmlFor={uid}
                className={cx('position-absolute top-50 bottom-50 end-0 d-flex justify-content-center align-items-center mb-0 me-3', classNameIcon)}
            >
                { icon }
            </Label> }
        </div>
        { message ? <Label htmlFor={uid} className="app-invalid-feedback">
            { message }
        </Label> : null }
    </div>;
});
InputNumber.propTypes = {
    icon: PropTypes.node,
    mask: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    format: PropTypes.string,
    onChange: PropTypes.func,
    message: PropTypes.string,
    className: PropTypes.string,
    customInput: PropTypes.func,
    placeholder: PropTypes.string,
    classNameIcon: PropTypes.string,
    classNameLabel: PropTypes.string,
    classNameGroup: PropTypes.string,
    classNameInputWrapper: PropTypes.string,
    type: PropTypes.oneOf(['text', 'tel', 'password']),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
InputNumber.defaultProps = {
    classNameInputWrapper: '',
    format: '(###) ###-####',
    name: 'inputCustom',
    customInput: Input,
    classNameLabel: '',
    classNameGroup: '',
    classNameIcon: '',
    onChange: e => e,
    placeholder: '',
    className: '',
    type: 'tel',
    message: '',
    icon: null,
    value: '',
    label: '',
    mask: '_',
};

export default InputNumber;

export const InputIcon = memo(function InputIcon () {
    return <div
        style={{ width: 20, height: 20 }}
        className="d-flex justify-content-center align-items-center bg-primary rounded-circle"
    >
        <FasIcon icon="pen" className="fz-10 text-white" />
    </div>;
});

export const RFInputNumber = memo(function RFInputNumber ({ input, meta, skipTouch, ...attributes }) {
    const handleChange = useCallback(value => input.onChange(value), [input]);
    let message = '';
    if (skipTouch || (meta.touched && meta.error)) {
        message = meta.error;
        attributes.className += meta.valid ? ' is-valid' : ' is-invalid';
    }

    return <InputNumber {...input} {...attributes} message={message} onChange={handleChange} />;
});
RFInputNumber.propTypes = {
    skipTouch: PropTypes.bool,
    meta: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
};
RFInputNumber.defaultProps = {
    skipTouch: false,
};
