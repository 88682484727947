
// outsource dependencies
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Breadcrumb, BreadcrumbItem, Button } from 'reactstrap';

// local dependencies
import { FasIcon } from 'components/icon';

// configure

const BreadcrumbCustom = memo(function BreadcrumbCustom ({ goBack, pageName }) {
    return <div className="d-flex align-items-center">
        <Button
            color="primary"
            onClick={goBack}
            className="text-white px-4 me-4"
        >
            <FasIcon icon="arrow-left" />
        </Button>
        <Breadcrumb listClassName="d-flex align-items-center mb-0">
            <BreadcrumbItem onClick={goBack} className="font-family-alternative fz-18 text-gray-700 cursor-pointer">
                My Cases
            </BreadcrumbItem>
            <BreadcrumbItem className="fz-18 d-flex ps-1">
                <FasIcon icon="angle-right" />
            </BreadcrumbItem>
            <BreadcrumbItem active className="font-family-alternative fz-18 text-gray-700">
                {pageName}
            </BreadcrumbItem>
        </Breadcrumb>
    </div>;
});
BreadcrumbCustom.propTypes = {
    pageName: PropTypes.string,
    goBack: PropTypes.func,
};
BreadcrumbCustom.defaultProps = {
    goBack: e => e,
    pageName: ''
};

export default BreadcrumbCustom;
