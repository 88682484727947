// outsource dependencies
import _ from 'lodash';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import Dropzone from 'react-dropzone';
import { toastr } from 'react-redux-toastr';
import React, { memo, useCallback, useEffect } from 'react';

// root
import { useRefCallback } from 'hooks';

// services
import { instanceAPI, publicAPI } from 'services/request';

// local dependencies
import { AppIcon } from './icon';
import { isImage, prepareFileName } from './input-file-helper';

// configure
/**
 *
 * @param {File} file
 * @param {Number} [width=1]
 * @param {Number} [height=1.1]
 * @return {Error|Boolean}
 */
const validateImageFileResolution = async (file, width = 1, height = 1.1) => {
    if (!isFile(file) && !isImage(file)) {
        return new Error('Not a valid file');
    }
    const previewUrl = URL.createObjectURL(file);
    const img = new Image();
    img.src = previewUrl;
    await fileCallbackToPromise(img);
    URL.revokeObjectURL(previewUrl);

    if (img.width > width) {
        return new Error(`Image width is too big (${img.width}), it should be less than ${Math.floor(width)} or equal`);
    }

    if (img.height > height) {
        return new Error(`Image height is too big (${img.height}), it should be less than ${Math.floor(height)} or equal`);
    }

    return false;
};

const RFInputImageFile = memo(function RFInputImageFile (props) {
    const {
        meta, View, input, filter, minKB, maxMB, validateSize, validateResolution, width, height,
        skipTouch, viewWidth, viewHeight, clearOnUnmount, placeholder, dropZoneOptions,
        insteadChange, showDropZoneActionBtn, handleActionClick, className, disabled, ...attr
    } = props;
    const { onChange } = input;
    const [dropzoneRef, setDropzoneRef] = useRefCallback();
    const [organizationLogoFile, setOrganizationLogoFile] = useRefCallback();

    // NOTE controlled feature to clearing redux form field value on component unmount
    useEffect(() => clearOnUnmount ? () => onChange(null) : void 0, [onChange, clearOnUnmount]);

    let message = '';
    if (skipTouch || meta.touched) {
        message = meta.error;
        attr.className += meta.valid ? ' is-valid' : 'is-invalid';
    }

    const showAction = !meta.pristine && meta.dirty;
    const iconName = showAction ? 'save' : 'edit';
    const computedClassName = cx('form-dropzone-control', attr.className);

    // NOTE actions
    const handleDrop = useCallback(async ([file]) => {
        const errorSize = validateSize(file, minKB, maxMB);
        if (_.isError(errorSize)) {
            toastr.error('', errorSize.message);
            return console.error(errorSize.message);
        }

        const errorResolution = await validateResolution(file, viewWidth, viewHeight);
        if (_.isError(errorResolution)) {
            toastr.error('', errorResolution.message);
            return console.error(errorResolution.message);
        }

        return fileToDataUrl(file)
            .then((fileDataUrl) => {
                const data = {
                    fileDataUrl,
                    fileRaw: file,
                    fileName: prepareFileName(file.name),
                    filePreviewUrl: URL.createObjectURL(file),
                };
                setOrganizationLogoFile((prevFile) => ({
                    ...prevFile,
                    ...data
                }));
                onChange(filter(data));
                URL.revokeObjectURL(file);
            });
    }, [validateSize, minKB, maxMB, validateResolution, viewWidth, viewHeight, setOrganizationLogoFile, onChange, filter]);

    const handleDropzoneActionClick = useCallback((event) => {
        event.preventDefault();

        if (meta.pristine && !meta.dirty) {
            return dropzoneRef.open();
        }

        if (!meta.pristine && meta.dirty) {
            return handleActionClick(organizationLogoFile);
        }
    }, [dropzoneRef, handleActionClick, meta.dirty, meta.pristine, organizationLogoFile]);

    return <div className={cx('form-dropzone-container', { 'opacity-50': disabled })}>
        <Dropzone
            {...dropZoneOptions}
            name={input.name}
            className="h-auto"
            disabled={disabled}
            onDrop={handleDrop}
            ref={setDropzoneRef}
        >
            {({
                getRootProps,
                getInputProps,
                isFocused,
                isFileDialogActive,
                isDragActive,
                isDragAccept,
                isDragReject
            }) => <div
                {...getRootProps()}
                className={cx(`${computedClassName}`, {
                    isFileDialogActive: isFileDialogActive,
                    active: isFocused || isDragActive,
                    // isDragActive: isDragActive,
                    isDragAccept: isDragAccept,
                    isDragReject: isDragReject,
                })}>
                <input {...getInputProps()} />
                <View
                    width={width}
                    height={height}
                    src={input.value}
                    alt={placeholder}
                    title={placeholder}
                    className="form-dropzone-preview"
                />
            </div>}
        </Dropzone>
        { showDropZoneActionBtn && showAction && <Button color="dropzone-action"
            title={'Choose a new user picture.'}
            onClick={handleDropzoneActionClick}
            // onClick={() => console.log(input)}
        >
            <AppIcon icon={iconName} tag="i" />
        </Button>}
    </div>;
});
RFInputImageFile.propTypes = {
    minKB: PropTypes.number,
    maxMB: PropTypes.number,
    filter: PropTypes.func,
    validateSize: PropTypes.func,
    validateResolution: PropTypes.func,
    skipTouch: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    insteadChange: PropTypes.func,
    clearOnUnmount: PropTypes.bool,
    meta: PropTypes.object.isRequired,
    dropZoneOptions: PropTypes.shape({
        multiple: PropTypes.bool,
        accept: PropTypes.string,
    }),
    handleActionClick: PropTypes.func,
    input: PropTypes.object.isRequired,
    showDropZoneActionBtn: PropTypes.bool,
    View: PropTypes.elementType.isRequired,
    viewWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    viewHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
RFInputImageFile.defaultProps = {
    minKB: 1,
    maxMB: 2,
    className: '',
    disabled: false,
    viewWidth: '100%',
    viewHeight: '100%',
    filter: e => e,
    skipTouch: false,
    dropZoneOptions: {
        multiple: false,
        // accept: 'image/jpe, image/jpg, image/jpeg, image/png',
        accept: 'image/jpe, image/jpg, image/jpeg, image/gif, image/png'
    },
    insteadChange: null,
    clearOnUnmount: true,
    showDropZoneActionBtn: false,
    handleActionClick: () => false,
    validateSize: validateFileSize,
    validateResolution: validateImageFileResolution,
};

/**
 * create image from file
 *
 * @param {File} file
 * @returns {Promise}
 */
function fileToDataUrl (file) {
    return new Promise(resolve => {
        const reader = new FileReader();
        reader.addEventListener('load', () => resolve(reader.result), false);
        reader.readAsDataURL(file);
    });
}

/**
 * check is object correct interface of file
 * @param file
 * @return {boolean}
 */
function isFile (file) {
    return file instanceof File || (
        // NOTE same interface as File
        _.isObject(file)
        && _.isString(file.name)
        && _.isString(file.type)
        && _.isNumber(file.size)
    );
}

/**
 *
 * @param {File} file
 * @param {Number} [minKB=1]
 * @param {Number} [maxMB=1.1]
 * @return {Error|Boolean}
 */
function validateFileSize (file, minKB = 1, maxMB = 1.1) {
    if (!isFile(file)) {
        return new Error('Not a valid file');
    }
    const size = file.size;
    const KB = Math.floor(Number(size / 1024));
    const MB = Math.ceil(Number(size / (1024 * 1024)));
    if (KB < minKB) {
        return new Error(`File size is too small (${KB}kb), it should be at least ${Math.floor(minKB)}kb`);
    }
    if (MB > maxMB) {
        return new Error(`File size is too big (${MB}Mb), it should be less than ${Math.floor(maxMB)}Mb`);
    }

    return false;
}

const fileCallbackToPromise = (fileObj) => {
    return Promise.race([
        new Promise(resolve => {
            if (fileObj instanceof HTMLImageElement) {
                fileObj.onload = resolve;
            } else {
                fileObj.onloadedmetadata = resolve;
            }
        }),
        new Promise((resolve, reject) => {
            setTimeout(reject, 1000);
        }),
    ]);
};

/**
 * common method to upload files
 * @param {File} file
 * @param {String} dir
 * @param {String} [url=/files/upload]
 * @param {String} method
 * @param {object} params
 * @return {Promise<String>}
 */
function fileUpload ({ file, dir, url, method, params }) {
    const data = new FormData();
    // NOTE allowed for names only ASCII
    const fileName = prepareFileName(file.name);
    data.append('dir', dir);
    data.append('Content-Type', file.type);
    data.append('file', file, fileName || 'image');
    return instanceAPI({
        url,
        data,
        params,
        method: method || 'PUT',
        headers: { 'Content-Type': 'multipart/form-data' },
    }); // .then(({ url }) => url);
}
/**
 * common method to upload files to S3 Bucket
 * @param {File} file
 * @param {String} dir
 * @param {String} [url=/files/upload]
 * @param {String} method
 * @param {object} params
 * @return {Promise<String>}
 */
function fileS3Upload ({ file, dir, url, method, params }) {
    return publicAPI({
        url,
        params,
        data: file,
        method: method || 'PUT',
        headers: { 'Content-Type': file.type },
    });
}

RFInputImageFile.isFile = isFile;
RFInputImageFile.fileToDataUrl = fileToDataUrl;
RFInputImageFile.validateFileSize = validateFileSize;
RFInputImageFile.validateImageFileResolution = validateImageFileResolution;
export { RFInputImageFile, isFile, validateFileSize, fileUpload, fileS3Upload, fileToDataUrl };
