
// outsource dependencies
import { create } from 'redux-saga-controller';
import { takeEvery, put } from 'redux-saga/effects';

// local dependencies

// configure
export const controller = create({
    prefix: 'dashboard',
    actions: {
        initialize: 'INITIALIZE',
    },
    initial: {
        disabled: false,
        initialValues: {},
        initialized: false,
        errorMessage: null,
    },
    subscriber: function * () {
        yield takeEvery(controller.action.initialize.TYPE, initializeSaga);
    }
});

export default controller;

function * initializeSaga ({ type, payload }) {
    // console.log(`%c ${type} `, 'color: #FF6766; font-weight: bolder; font-size: 12px;'
    //   , '\n payload:', payload
    // );
    yield put(controller.action.updateCtrl({ initialized: true }));
}
