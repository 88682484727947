
// outsource dependencies
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { create } from 'redux-saga-controller';
import { takeEvery, put, call, select } from 'redux-saga/effects';

// local dependencies
import { instanceAPI } from 'services';
import appController, { getOrganizationSaga } from 'controller';
import { INCIDENT_TYPE, SYSTEM_ROLE_TYPE } from 'constants/spec';

// configure
export const TABLE_TYPE = {
    ISSUE: 'ISSUE',
    SUGGESTION: 'SUGGESTION',
};

export const controller = create({
    prefix: 'dashboard',
    actions: {
        initialize: 'INITIALIZE',
    },
    initial: {
        disabled: false,
        initialized: false,
        errorMessage: null,

        issuesList: [],
        suggestionsList: [],
    },
    subscriber: function * () {
        yield takeEvery(controller.action.initialize.TYPE, initializeSaga);
    }
});

export default controller;

function * initializeSaga ({ type, payload }) {
    // console.log(`%c ${type} `, 'color: #FF6766; font-weight: bolder; font-size: 12px;'
    //   , '\n payload:', payload
    // );
    const { organization } = yield select(appController.select);
    if (_.isEmpty(organization)) {
        yield call(getOrganizationSaga);
    }
    try {
        const issues = yield call(getListExecutor, { type: INCIDENT_TYPE.ISSUE });
        yield put(controller.action.updateCtrl({ issuesList: issues.data }));
    } catch (error) {
        yield call(toastr.error, 'Complaints List Initialize', error.message);
        yield put(controller.action.updateCtrl({ errorMessage: error.message }));
    }
    try {
        const suggestions = yield call(getListExecutor, { type: INCIDENT_TYPE.SUGGESTION });
        yield put(controller.action.updateCtrl({ suggestionsList: suggestions.data }));
    } catch (error) {
        yield call(toastr.error, 'Ideas List Initialize', error.message);
        yield put(controller.action.updateCtrl({ errorMessage: error.message }));
    }
    yield put(controller.action.updateCtrl({ initialized: true }));
}

function * getListExecutor ({ type }) {
    const SORT_DIRECTION = 0;
    const { organization, user } = yield select(appController.select);
    if (user.role.type_name === SYSTEM_ROLE_TYPE.ORGANIZATION_ADMIN) {
        return yield call(instanceAPI, { method: 'GET', url: '/incident/search', params: { type, organization: organization.id, sort_date: SORT_DIRECTION } });
    }
    return yield call(instanceAPI, { method: 'GET', url: '/incident/search', params: { type, sort_date: SORT_DIRECTION } });
}
