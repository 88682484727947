
// outsource dependencies
import _ from 'lodash';
import cx from 'classnames';
import Slider from 'rc-slider';
import PropTypes from 'prop-types';
import { Input, Label } from 'reactstrap';
import React, { memo, useCallback } from 'react';

// local dependencies
import { FasIcon } from './icon';

// configure

export const InputCustom = memo(function InputCustom (props) {
    const {
        type, value, name, label, placeholder, icon, onBlur, onChange, message,
        className, classNameLabel, classNameIcon, classNameInputWrapper, classNameGroup, ...attributes
    } = props;
    const uid = _.kebabCase(`${type}-${name}`);
    const handleChange = useCallback(({ target }) => onChange(target.value), [onChange]);
    const handleBlur = useCallback(({ target }) => onBlur(target.value), [onBlur]);

    return <div className={classNameGroup}>
        { label ? <Label
            htmlFor={uid}
            className={cx(classNameLabel, { 'app-invalid-feedback': message })}
        >
            { label }
        </Label> : null }
        <div className={cx('position-relative', classNameInputWrapper)}>
            <Input
                id={uid}
                type={type}
                name={name}
                placeholder={placeholder}
                {...attributes}
                className={cx(className, { 'pe-5': icon })}
                onChange={handleChange}
                onBlur={handleBlur}
                value={value}
            />
            { icon && <Label
                htmlFor={uid}
                className={cx('position-absolute top-50 bottom-50 end-0 d-flex justify-content-center align-items-center mb-0 me-3', classNameIcon)}
            >
                { icon }
            </Label> }
        </div>
        { message ? <Label htmlFor={uid} className="app-invalid-feedback">
            { message }
        </Label> : null }
    </div>;
});
InputCustom.propTypes = {
    icon: PropTypes.node,
    onBlur: PropTypes.func,
    type: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    message: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    classNameIcon: PropTypes.string,
    classNameLabel: PropTypes.string,
    classNameGroup: PropTypes.string,
    classNameInputWrapper: PropTypes.string,
};
InputCustom.defaultProps = {
    classNameInputWrapper: '',
    name: 'inputCustom',
    classNameLabel: '',
    classNameGroup: '',
    classNameIcon: '',
    onChange: e => e,
    placeholder: '',
    onBlur: e => e,
    className: '',
    type: 'text',
    message: '',
    icon: null,
    value: '',
    label: '',
};

export default InputCustom;

export const InputIcon = memo(function InputIcon ({ className }) {
    return <div
        style={{ width: 20, height: 20 }}
        className={cx('d-flex justify-content-center align-items-center bg-primary rounded-circle', className)}
    >
        <FasIcon icon="pen" className="fz-10 text-white" />
    </div>;
});
InputIcon.propTypes = {
    className: PropTypes.string,
};
InputIcon.defaultProps = {
    className: '',
};

export const RFInput = memo(function RFInput ({ input, meta, skipTouch, ...attributes }) {
    const handleChange = useCallback(value => input.onChange(value), [input]);
    const handleBlur = useCallback(value => input.onBlur(value), [input]);
    let message = '';
    if (skipTouch || (meta.touched && meta.error)) {
        message = meta.error;
        attributes.className += meta.valid ? ' is-valid' : ' is-invalid';
    }

    return <InputCustom {...input} {...attributes} message={message} onChange={handleChange} onBlur={handleBlur} />;
});
RFInput.propTypes = {
    skipTouch: PropTypes.bool,
    meta: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
};
RFInput.defaultProps = {
    skipTouch: false,
};

export const RFInputRange = memo(function RFSlider ({ input, meta, ...attr }) {
    return <Slider
        {...attr}
        value={input.value}
        onChange={input.onChange}
    />;
});

RFInputRange.propTypes = {
    meta: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
};
