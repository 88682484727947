
// outsource dependencies
import cx from 'classnames';
import { Field } from 'redux-form';
import { useHistory } from 'react-router-dom';
import React, { forwardRef, memo, useCallback } from 'react';
import { useControllerActions, useControllerData } from 'redux-saga-controller';
import { Button, Col, Input, InputGroup, InputGroupText, Row } from 'reactstrap';

// local dependencies
import { FORM_NAME } from './index';
import controller, { REPORT_STEP_TYPE } from './controller';
import { AppIcon, ReduxFormWizard, RFDatePicker, RFTextarea } from 'components';

// configure
const formValidation = values => {
    const errors = {};
    if (!values.brief_summary) {
        errors.brief_summary = 'Brief Summary is required';
    }
    if (!values.date) {
        errors.date = 'Date is required';
    }
    if (!values.description) {
        errors.description = 'Description is required';
    }
    return errors;
};

const DatePickerInput = forwardRef(({ value, onClick, className }, ref) => {
    const isInvalid = className.includes('is-invalid');
    const isValid = className.includes('is-valid');
    return <InputGroup
        onClick={onClick}
        style={{ borderRadius: 10 }}
        className={cx(
            'border cursor-pointer',
            { 'border-danger': isInvalid, 'border-success': isValid, 'border-gray-300': !isInvalid && !isValid }
        )}
    >
        <InputGroupText style={{ borderRadius: 10 }} className="border-0 bg-white">
            <AppIcon icon="calendar" className="text-gray-500" />
        </InputGroupText>
        <Input
            ref={ref}
            value={value}
            onChange={e => e}
            placeholder="MM/DD/YYYY"
            className="bg-white border-transparent border-0 rounded-0"
        />
        <InputGroupText style={{ borderRadius: 10 }} className="border-0 bg-white">
            <AppIcon icon="angle-down" className="fz-18 text-gray-500" />
        </InputGroupText>
    </InputGroup>;
});

const Summary = memo(function Summary () {
    const { goBack } = useHistory();
    const { initialValues, disabled } = useControllerData(controller);
    const { updateCtrl } = useControllerActions(controller);

    const toNextStep = useCallback(() => (
        updateCtrl({ stepForm: REPORT_STEP_TYPE.SELECT_CATEGORY })
    ), [updateCtrl]);

    return <ReduxFormWizard
        form={FORM_NAME}
        onSubmit={toNextStep}
        validate={formValidation}
        initialValues={initialValues}
    >
        <Row>
            <Col xs="12" tag="h1" className="d-inline d-lg-none fw-500 fz-18 text-secondary mb-4">
                Summary
            </Col>
            <Col xs="12" className="mb-3">
                <Field
                    rows={3}
                    size={200}
                    type="textarea"
                    disabled={disabled}
                    name="brief_summary"
                    label="Brief Summary"
                    classNameGroup="mb-3"
                    component={RFTextarea}
                    placeholder="Briefly describe the problem..."
                />
                <Field
                    name="date"
                    label="Date"
                    disabled={disabled}
                    maxDate={new Date()}
                    classNameGroup="mb-3"
                    component={RFDatePicker}
                    showDisabledMonthNavigation
                    customInput={<DatePickerInput />}
                />
                <Field
                    rows={10}
                    size={2000}
                    name="description"
                    disabled={disabled}
                    label="Description"
                    classNameGroup="mb-3"
                    component={RFTextarea}
                    placeholder="Write about the problem..."
                />
                <Field
                    rows={10}
                    size={2000}
                    disabled={disabled}
                    classNameGroup="mb-3"
                    component={RFTextarea}
                    name="secret_description"
                    label="Sealed Description"
                    className="form-control-sealed"
                    placeholder="Enter any information you would like to be revealed only in case of the legal reason..."
                />
            </Col>
            <Col xs="12" className="mb-3">
                <Button
                    type="submit"
                    color="primary"
                    className="font-family-alternative text-white fz-16 fw-500 text-center text-secondary rounded-5 w-100"
                >
                    Next
                </Button>
            </Col>
            <Col xs="12" className="mb-3">
                <Button
                    outline
                    onClick={goBack}
                    color="primary-white"
                    className="font-family-alternative fz-16 fw-500 text-center btn-extended rounded-5 w-100"
                >
                    Back
                </Button>
            </Col>
        </Row>
    </ReduxFormWizard>;
});

export default Summary;
