
// outsource dependencies
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { Table as RSTable } from 'reactstrap';

// local dependencies
import TdLink from './td';
import ThSort from './th';

// configure

export const Table = memo(function Table ({ disabled, sortF, sortD, handleSort, data, type, children, link }) {
    const prepareDate = useMemo(() => {
        const headCell = [];
        const dataCell = [];

        React.Children.forEach(children, ({ props: { sortName, children, title, width } }) => {
            headCell.push({ sortName, title, width: `${width}%` });
            dataCell.push({ key: title, title: children, link });
        });

        return { headCell, dataCell };
    }, [children, link]);

    return <RSTable hover className="table-filter mb-0">
        <thead>
            <tr>
                { prepareDate.headCell.map(({ sortName, title, width }) => <ThSort
                    handleSort={handleSort}
                    sortName={sortName}
                    disabled={disabled}
                    width={width}
                    title={title}
                    sortD={sortD}
                    sortF={sortF}
                    key={title}
                />)}
            </tr>
        </thead>
        <tbody>
            { _.size(data) ? (data || []).map((item, index) => <tr key={index} className="cursor-pointer">
                { prepareDate.dataCell.map((cell, index) => <TdLink
                    title={cell.title(item)}
                    disabled={disabled}
                    id={item.id}
                    tabIndex="0"
                    link={link}
                    key={index}
                    type={type}
                />)}
            </tr>) : <tr>
                <td colSpan={_.size(prepareDate.headCell)} className="fz-34 fw-700 text-gray-700 text-center mb-4">
                    List is empty
                </td>
            </tr>}
        </tbody>
    </RSTable>;
});
Table.propTypes = {
    link: PropTypes.func,
    type: PropTypes.string,
    sortD: PropTypes.number,
    sortF: PropTypes.string,
    disabled: PropTypes.bool,
    handleSort: PropTypes.func,
    data: PropTypes.array.isRequired,
    children: PropTypes.node.isRequired,
};
Table.defaultProps = {
    handleSort: e => e,
    disabled: false,
    link: e => e,
    sortF: '',
    type: '',
    sortD: 1,
};

const Column = memo(function Column ({ children }) {
    return children;
});

export default Object.assign(Table, { Column });
