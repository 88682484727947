
// outsource dependencies
import { toastr } from 'react-redux-toastr';
import { create } from 'redux-saga-controller';
import { takeEvery, put, call, select } from 'redux-saga/effects';

// constants
import * as ROUTES from 'constants/routes';

// services
import { instanceAPI } from 'services';

export const controller = create({
    prefix: 'change-password',
    actions: {
        initialize: 'INITIALIZE',
        submitData: 'SUBMIT_DATA',
    },
    initial: {
        disabled: false,
        initialized: false,
        errorMessage: null,

        initialValues: {
            token: '',
            password: '',
            password_confirmation: '',
        }
    },
    subscriber: function * () {
        yield takeEvery(controller.action.initialize.TYPE, initializeSaga);
        yield takeEvery(controller.action.submitData.TYPE, submitDataSaga);
    }
});

export default controller;

function * initializeSaga () {
    // console.log(`%c ${type} `, 'color: #FF6766; font-weight: bolder; font-size: 12px;'
    //   , '\n payload:', payload
    // );
    const { token } = yield call(ROUTES.SET_PASSWORD.QUERY);
    const { initialValues } = yield select(controller.select);
    yield put(controller.action.updateCtrl({ initialized: true, initialValues: { ...initialValues, token } }));
}

function * submitDataSaga ({ payload }) {
    yield put(controller.action.updateCtrl({ disabled: true, errorMessage: null }));
    try {
        const { message } = yield call(instanceAPI, '/password/set', { method: 'POST', data: payload });
        yield call(toastr.success, 'Set Password', message);
        yield call(ROUTES.SIGN_IN.REPLACE);
    } catch ({ message }) {
        yield call(toastr.error, 'Change Password', message);
        yield put(controller.action.updateCtrl({ errorMessage: message }));
    }
    yield put(controller.action.updateCtrl({ disabled: false }));
}
