
// outsource dependencies
import _ from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useController } from 'redux-saga-controller';
import { Button, Col, Container, Row } from 'reactstrap';
import React, { memo, useCallback, useEffect, useMemo } from 'react';

// root
import Layout from 'pages/layout';

// components
import { FasIcon, Pagination, BoxLoader, Table, BadgeStatus } from 'components';

// constants
import * as ROUTES from 'constants/routes';
import { environmentConfig } from 'constants/enviroment-config';

// local dependencies
import Filter from './filter';
import controller from './controller';

// configure

const OrganizationsList = memo(function OrganizationsList () {
    const [
        { initialized, disabled, list, totalItems, sizePage, page, sortF, sortD },
        { initialize, updateData },
    ] = useController(controller);
    // NOTE initialize business logic
    useEffect(() => { initialize(); }, [initialize]);

    const handleChangePage = useCallback(page => updateData({ page }), [updateData]);
    const handleChangeSort = useCallback(({ sortF, sortD }) => updateData({ page: 1, sortF, sortD }), [updateData]);
    const prepareList = useMemo(() => (list || []).map(item => {
        const { id, name, created_at, suggestions_count, issues_count, users_count, status } = item;
        return {
            id,
            name,
            status,
            users: users_count,
            issues: issues_count,
            suggestions: suggestions_count,
            date: moment(created_at).format(environmentConfig.DATE_FORMAT),
        };
    }), [list]);

    return <Layout>
        <Container id="AppOrganizationList" className="mt-4 mb-5 h-100">
            <BoxLoader active={!initialized}>
                <Row className="g-0">
                    <Col tag="h4" xs="6" className="fw-bolder fz-24 text-gray-800 mb-4">
                        Organizations
                    </Col>
                    <Col xs="6" className="d-flex justify-content-end p-0 mb-4">
                        <Button
                            tag={Link}
                            color="primary"
                            className="text-white px-4"
                            to={ROUTES.ORGANIZATION_CREATE.LINK()}
                        >
                            <FasIcon icon="plus" className="fz-16 text-white me-2" />
                            Add New Organization
                        </Button>
                    </Col>
                    <Col xs="12" className="bg-white p-3">
                        <Filter />
                    </Col>
                    { _.size(prepareList) ? <>
                        <Col xs="12">
                            <Table
                                sortD={sortD}
                                sortF={sortF}
                                data={prepareList}
                                disabled={disabled}
                                handleSort={handleChangeSort}
                                link={ROUTES.ORGANIZATION_PAGE.LINK}
                            >
                                <Table.Column width={15} sortName="sort_created_at" title="Create Date">{ ({ date }) => date }</Table.Column>
                                <Table.Column width={35} sortName="sort_name" sort="name" handleSort title="Organization Name">{ ({ name }) => name }</Table.Column>
                                <Table.Column width={13} sortName="sort_issues_count" title="Complaints">{ ({ issues }) => issues }</Table.Column>
                                <Table.Column width={13} sortName="sort_suggestions_count" title="Ideas">{ ({ suggestions }) => suggestions }</Table.Column>
                                <Table.Column width={13} sortName="sort_users_count" title="Users">{ ({ users }) => users }</Table.Column>
                                <Table.Column width={11} sortName="sort_status" title="Status">{ ({ status }) => <BadgeStatus type={status} /> }</Table.Column>
                            </Table>
                        </Col>
                        <Col xs="12" className="bg-white p-4">
                            <Pagination
                                currentPage={page}
                                sizePage={sizePage}
                                disabled={disabled}
                                totalItems={totalItems}
                                onChange={handleChangePage}
                            />
                        </Col>
                    </> : <Col xs="12" tag="p" className="fz-32 fw-700 text-gray-700 text-center mb-4">
                            List is empty
                    </Col> }
                </Row>
            </BoxLoader>
        </Container>
    </Layout>;
});

export default OrganizationsList;
