// outsource dependencies
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Field, getFormValues } from 'redux-form';
import React, { memo, useCallback } from 'react';
import { useStateWithCallbackLazy } from 'use-state-with-callback';

// components
import { AppIcon, isFile, RFInputImageFile } from 'components';

// services
import { isUrl } from 'services/validation.service';

// local dependencies
import { FORM_NAME } from '../controller';

// images
import { DefaultImage } from 'images';

// configure
const useFormValues = () => useSelector(getFormValues(FORM_NAME)) || {};
// eslint-disable-next-line max-len
const OrganizationInfoBackground = memo(function OrganizationInfoBackground ({ className, editable, disabled, organizationName, onFileSave, onFileChange }) {
    const [fileData, setFileData] = useStateWithCallbackLazy({});
    const { background } = useFormValues();

    // NOTE prepare actions
    const handleActionSave = useCallback(() => {
        const { fileRaw } = fileData;
        if (!_.isEmpty(fileRaw) && isFile(fileRaw)) {
            // onFileChange({ ...fileData });
            onFileSave({ ...fileData });
        }
    }, [onFileSave, fileData]);

    return (<div className={cx('organization-image', className, {
        'organization-image-disabled': disabled
    })}>
        {!editable && <div className="d-flex justify-content-center align-items-center">
            <DefaultImage
                src={background}
                width={488}
                height={360}
                className="me-5"
                alt={`Background ${organizationName}`}
                title={`Background ${organizationName}`}
            />
        </div>}
        { editable && <>
            <Field
                type="text"
                name="background"
                disabled={disabled}
                classNameGroup="mb-3"
                component={RFInputImageFile}
                placeholder={organizationName}
                View={OrganizationBackgroundPreview}
                viewWidth={488}
                viewHeight={360}
                normalize={(value) => {
                    // console.log(value);
                    setFileData((prevFile) => ({
                        ...prevFile,
                        ...value
                    }), (data) => onFileChange(data));
                    return _.get(value, 'filePreviewUrl', null);
                }}
                handleActionClick={handleActionSave}
            />
        </>}
    </div>);
});

OrganizationInfoBackground.propTypes = {
    editable: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    onFileSave: PropTypes.func,
    onFileChange: PropTypes.func,
    organizationName: PropTypes.string,
};

OrganizationInfoBackground.defaultProps = {
    className: '',
    editable: true,
    disabled: false,
    organizationName: '',
    onFileSave: () => false,
    onFileChange: () => false
};

export default OrganizationInfoBackground;

const OrganizationBackgroundPreview = memo(function OrganizationLogoPreview (props) {
    const { src, className, width, height } = props;
    const isAvailable = !_.isEmpty(src) && (isUrl(src) || src.startsWith('blob:'));

    return <div className={cx('d-flex justify-content-center align-content-center', className)}>
        { isAvailable
            ? <DefaultImage className="form-dropzone-preview-image" { ...props } />
            : <div className="form-dropzone-preview-message text-center d-flex flex-column justify-content-center align-items-center">
                <h4 className="fz-12 fw-400 font-family-alternative text-gray-700"> Upload Background </h4>
                <p className="fz-12 fw-400 font-family-alternative text-gray-600 text-left">
                    *JPEG, SVG, PNG are allowed (up to 2 MB) < br /> {`${width} x ${height}`}
                </p>
                <AppIcon icon="upload-dnd-arrow" className="text-secondary fz-24" />
            </div> }
    </div>;
});
