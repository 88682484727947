
// outsource dependencies
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Button, Col, Row } from 'reactstrap';
import React, { memo, useCallback } from 'react';
import { useControllerActions, useControllerData } from 'redux-saga-controller';


// local dependencies
import { controller, FORM_EMAIL_NAME } from './controller';
import { InputIcon, ReduxForm, RFInput } from 'components';

const formEmailValidation = values => {
    const errors = {};

    if (!values.subject) {
        errors.subject = 'Subject is required';
    }
    if (!values.title) {
        errors.title = 'Title is required';
    }
    if (!values.description) {
        errors.description = 'Description is required';
    }
    if (!values.platform_org_description) {
        errors.platform_org_description = 'Platform organization description is required';
    }
    return errors;
};

const OrganizationEmailForm = memo(function OrganizationDepartmentsList ({ className }) {
    const { initialEmailValues, disabled } = useControllerData(controller);
    const { submitEmailData } = useControllerActions(controller);

    // NOTE actions
    const handleEmailOrganizationFormSubmit = useCallback((formValues) => {
        submitEmailData(formValues);
    }, [submitEmailData]);

    return (<Row tag="section" className={cx('organization-email-form', className)}>
        <ReduxForm
            enableReinitialize
            form={FORM_EMAIL_NAME}
            validate={formEmailValidation}
            initialValues={initialEmailValues}
            onSubmit={handleEmailOrganizationFormSubmit}
        >
            <Row>
                <Col xs="12" className="d-flex justify-content-between align-items-center mb-4">
                    <h4 className="fw-bolder fz-24 text-gray-800 mb-0">
                        Survey Email Text Customization
                    </h4>
                </Col>
            </Row>
            <Row>
                <Col xs="12" lg="6">
                    <Field
                        name="subject"
                        type="text"
                        disabled={disabled}
                        component={RFInput}
                        icon={<InputIcon />}
                        classNameGroup="mb-3"
                        placeholder="Title 1"
                        label="Email Subject"
                    />
                </Col>
                <Col xs="12" lg="6">
                    <Field
                        name="title"
                        type="text"
                        disabled={disabled}
                        component={RFInput}
                        icon={<InputIcon />}
                        classNameGroup="mb-3"
                        placeholder="Title 2"
                        label="Feedback Template Title"
                    />
                </Col>
            </Row>
            <Row>
                <Col xs="12" lg="6">
                    <Field
                        rows={9}
                        type="textarea"
                        name="description"
                        disabled={disabled}
                        component={RFInput}
                        icon={<InputIcon />}
                        classNameGroup="mb-3"
                        label="Submit Feedback Description"
                        placeholder="Enter Feedback Description"
                    />
                </Col>
                <Col xs="12" lg="6">
                    <Field
                        rows={9}
                        type="textarea"
                        disabled={disabled}
                        component={RFInput}
                        icon={<InputIcon />}
                        classNameGroup="mb-3"
                        name="platform_org_description"
                        label="Platform Organization Description"
                        placeholder="Enter Platform Organization Description"
                    />
                </Col>
                <Col xs="12" className="d-flex justify-content-end mb-5">
                    <Button
                        type="submit"
                        color="primary"
                        className="text-white px-5"
                    >
                        Save
                    </Button>
                </Col>
            </Row>
        </ReduxForm>
    </Row>);
});

OrganizationEmailForm.propTypes = {
    className: PropTypes.string,
};

OrganizationEmailForm.defaultProps = {
    className: ''
};

export default OrganizationEmailForm;
