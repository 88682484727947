
// outsource dependencies
import _ from 'lodash';
import moment from 'moment';
import { reset } from 'redux-form';
import { create } from 'redux-saga-controller';
import { takeEvery, put, call, select } from 'redux-saga/effects';

// components
import { fileS3Upload, PromiseAllSettled } from 'components';

// constants
import { INCIDENT_TYPE } from 'constants/spec';
import { DASHBOARD, REPORT } from 'constants/routes';

// local dependencies
import { FORM_NAME } from './index';
import { instanceAPI } from 'services';
import appController from 'controller';
import { toastr } from 'react-redux-toastr';

// configure
const initializeValues = {
    // summary step
    date: new Date(),
    description: '',
    manual_category: '',
    // additional details step
    files: [],
    // result step
};

export const SUGGESTION_STEP_TYPE = {
    SUMMARY: 'SUMMARY',
    ADDITIONAL_DETAILS: 'ADDITIONAL_DETAILS',
    PREVIEW: 'PREVIEW',
};

export const controller = create({
    prefix: 'suggestion',
    actions: {
        initialize: 'INITIALIZE',
        submitData: 'SUBMIT_DATA',
    },
    initial: {
        disabled: false,
        initialized: false,
        errorMessage: null,

        isAnonymous: false,
        initialValues: initializeValues,
        stepForm: SUGGESTION_STEP_TYPE.SUMMARY,
    },
    subscriber: function * () {
        yield takeEvery(controller.action.initialize.TYPE, initializeSaga);
        yield takeEvery(controller.action.submitData.TYPE, submitDataSaga);
    }
});

controller.action.resetField = () => reset(FORM_NAME);

export default controller;

function * initializeSaga ({ type, payload }) {
    // console.log(`%c ${type} `, 'color: #FF6766; font-weight: bolder; font-size: 12px;'
    //   , '\n payload:', payload
    // );
    yield put(controller.action.resetField());
    const { organization } = yield select(appController.select);
    if (_.isEmpty(organization)) {
        yield put(appController.action.getOrganization());
    }
    const { anonymous } = yield call(REPORT.QUERY);
    const isAnonymous = /^(true|1)$/i.test(anonymous);
    yield put(controller.action.updateCtrl({ initialized: true, isAnonymous }));
}

function * submitDataSaga ({ type, payload }) {
    yield put(controller.action.updateCtrl({ disabled: true, errorMessage: null }));
    try {
        const { organization, user } = yield select(appController.select);
        const { isAnonymous } = yield select(controller.select);

        const reportFormData = { ...payload, files: [] };
        let reportIssueFiles = { success: [], rejected: [] };
        if (!_.isEmpty(payload.files)) {
            reportIssueFiles = yield call(uploadAttachmentDocuments, {
                payload: {
                    files: payload.files
                }
            });
        }

        if (_.size(reportIssueFiles.success) > 0) {
            reportFormData.files = reportIssueFiles.success.map(item => item.fileMeta.fileName);
        }

        yield call(instanceAPI, {
            method: 'POST',
            url: '/incident/create',
            data: {
                ...reportFormData,
                anonymous: isAnonymous ? 1 : 0,
                type: INCIDENT_TYPE.SUGGESTION,
                organization_id: _.get(organization, 'id'),
                legal_disclaimer: payload.legal_disclaimer ? 1 : 0,
                date: moment(payload.date).format('YYYY/MM/DD'),
                user: isAnonymous ? null : `${user.first_name} ${user.last_name}`,
            }
        });
        yield call(
            toastr.success,
            isAnonymous ? 'Anonymous Idea' : 'Idea',
            'Your idea has been send successfully'
        );
        yield call(DASHBOARD.PUSH);
    } catch ({ message }) {
        yield call(toastr.error, 'Report Submit Data', message);
        yield put(controller.action.updateCtrl({ errorMessage: message }));
    }
    yield put(controller.action.updateCtrl({ disabled: false }));
}

function * uploadAttachmentDocuments ({ payload }) {
    const { files } = payload;

    if (_.isEmpty(files)) {
        return {
            success: [],
            rejected: []
        };
    }

    const filteredFiles = files.filter(fEntity => !_.isUndefined(fEntity.fileRaw));
    const uploadPromises = filteredFiles
        .map(async uploadFileItem => {
            const signedUrl = await instanceAPI({ method: 'GET', url: `incident/upload-link/${uploadFileItem.fileMeta.fileName}` });
            const url = _.get(signedUrl, 'url');
            const { data } = await fileS3Upload({ method: 'PUT', file: uploadFileItem.fileRaw, url });
            const baseUrl = new URL(url);
            return {
                answer: data,
                signedUrl: url,
                url: baseUrl.origin + baseUrl.pathname,
                ...uploadFileItem
            };
        });

    const uploadedFilesResults = yield call(PromiseAllSettled, uploadPromises);

    const fulfilledDocs = uploadedFilesResults.filter(uploadedItem => _.isEqual(uploadedItem.status, 'fulfilled'));
    const rejectedDocs = uploadedFilesResults.filter(uploadedItem => _.isEqual(uploadedItem.status, 'rejected'));

    return {
        success: fulfilledDocs.map(item => item.result),
        rejected: rejectedDocs
    };
}
