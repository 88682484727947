
// outsource dependencies
import _ from 'lodash';
import { Field } from 'redux-form';
import { useController } from 'redux-saga-controller';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import React, { memo, useCallback, useEffect } from 'react';

// root
import { useSelf } from 'hooks';
import Layout from 'pages/layout';
import { Restricted } from 'common/permission-provider';

// components
import {
    getOptionName, getOptionSeverity, prepareMultiValues, RFAsyncSelect,
    BoxLoader, RFInput, FasIcon, RFDatePicker, ReduxForm, RFSelect, CommentField, CommentsBlock,
    prepareBadgeStatusStyleForReactSelect, prepareBadgeSeverityStyleForReactSelect, prepareValueId, getOptionId, InputDropAreaFileList,
} from 'components';

// constants
import { severityOptions, STATUS_TYPE, SYSTEM_ROLE_TYPE } from 'constants/spec';

// services
import { FilterService, instanceAPI } from 'services';

// local dependencies
import controller from './controller';
import Breadcrumb from './breadcrumb';

// configure
export const statusOptions = Object.values(STATUS_TYPE).map(status => ({
    value: status, label: FilterService.humanize(status)
}));

export const FORM_NAME = 'suggestionPageForm';

const SuggestionsPage = memo(function SuggestionsPage () {
    const user = useSelf();
    const { id } = useParams();
    const { goBack } = useHistory();
    const [
        { initialized, disabled, initialValues, comment },
        { initialize, submitData, updateCtrl },
    ] = useController(controller);
    // NOTE initialize business logic
    useEffect(() => { initialize(); }, [initialize]);

    const handleUpdate = useCallback(_.debounce(() => {
        submitData();
    }, 5e2), [submitData]);

    const handleUpdateComment = useCallback(item => {
        return updateCtrl({ comment: comment.concat(item) });
    }, [comment, updateCtrl]);

    const loadDepartments = useCallback(() => {
        if (_.get(user, 'role.type_name') === SYSTEM_ROLE_TYPE.ORGANIZATION_ADMIN) {
            return instanceAPI({ method: 'GET', url: `organization/${_.get(user, 'organization.id')}/department` })
                .then(({ data }) => data);
        }
        return instanceAPI({ method: 'GET', url: 'department/withoutPagination' });
    }, [user]);

    const loadOrganizations = useCallback(() => instanceAPI({
        method: 'GET',
        url: 'organization',
    }), []);

    const loadCategories = useCallback(() => instanceAPI({
        method: 'GET',
        url: 'incident/category',
    }), []);

    return <Layout>
        <BoxLoader id="AppSuggestionPage" active={!initialized}>
            <ReduxForm
                form={FORM_NAME}
                enableReinitialize
                onSubmit={submitData}
                initialValues={initialValues}
            >
                <Row className="mx-5 my-4">
                    <Col xs="12" className="d-flex align-items-center">
                        <Button color="primary" onClick={goBack} className="text-white px-4 me-4">
                            <FasIcon icon="arrow-left" />
                        </Button>
                        <Breadcrumb />
                    </Col>
                </Row>
                <Container className="mt-4 mb-5">
                    <Row className="g-0">
                        <Col tag="h4" xs="12" className="fw-bolder fz-24 text-gray-800 mb-4">
                            Idea { id }
                        </Col>
                        <Col xs="12">
                            <Row className="bg-white p-4">
                                <Col xs="6">
                                    <Row>
                                        <Col tag="h2" xs="12" className="fw-600 fz-24 text-secondary text-center mb-4">
                                            Internal Notes
                                        </Col>
                                        <Col xs="6" className="mb-4">
                                            <Field
                                                name="status"
                                                label="Status"
                                                disabled={disabled}
                                                placeholder="Status"
                                                component={RFSelect}
                                                onChange={handleUpdate}
                                                options={statusOptions}
                                                styles={{ singleValue: prepareBadgeStatusStyleForReactSelect }}
                                            />
                                        </Col>
                                        <Col xs="6" className="mb-4">
                                            <Field
                                                name="severity"
                                                label="Severity"
                                                disabled={disabled}
                                                component={RFSelect}
                                                onChange={handleUpdate}
                                                options={severityOptions}
                                                placeholder="None Selected"
                                                getOptionLabel={getOptionSeverity}
                                                styles={{ singleValue: prepareBadgeSeverityStyleForReactSelect }}
                                            />
                                        </Col>
                                        <Restricted
                                            role={SYSTEM_ROLE_TYPE.ORGANIZATION_ADMIN}
                                            fallback={<>
                                                <Col xs="6" className="mb-4">
                                                    <Field
                                                        cacheOptions
                                                        defaultOptions
                                                        disabled={disabled}
                                                        label="Organization"
                                                        name="organization_id"
                                                        onChange={handleUpdate}
                                                        component={RFAsyncSelect}
                                                        placeholder="Organization"
                                                        getOptionValue={getOptionId}
                                                        prepareValue={prepareValueId}
                                                        getOptionLabel={getOptionName}
                                                        loadOptions={loadOrganizations}
                                                    />
                                                </Col>
                                                <Col xs="6" className="mb-4">
                                                    <Field
                                                        cacheOptions
                                                        defaultOptions
                                                        label="Department"
                                                        disabled={disabled}
                                                        name="department_id"
                                                        onChange={handleUpdate}
                                                        placeholder="Department"
                                                        component={RFAsyncSelect}
                                                        getOptionValue={getOptionId}
                                                        prepareValue={prepareValueId}
                                                        loadOptions={loadDepartments}
                                                        getOptionLabel={getOptionName}
                                                    />
                                                </Col>
                                                <Col xs="6" className="mb-4">
                                                    <Field
                                                        isMulti
                                                        cacheOptions
                                                        isSearchable
                                                        defaultOptions
                                                        label="Category"
                                                        disabled={disabled}
                                                        placeholder="Category"
                                                        onChange={handleUpdate}
                                                        component={RFAsyncSelect}
                                                        closeMenuOnSelect={false}
                                                        name="internal_categories"
                                                        getOptionValue={getOptionId}
                                                        loadOptions={loadCategories}
                                                        getOptionLabel={getOptionName}
                                                        prepareValue={prepareMultiValues}
                                                        styles={prepareBadgeInternalCategoryForReactSelect}
                                                    />
                                                </Col>
                                                <Col xs="6" className="mb-4">
                                                    <Field
                                                        type="text"
                                                        label="Referred to"
                                                        disabled={disabled}
                                                        component={RFInput}
                                                        name="assigned_user"
                                                        onChange={handleUpdate}
                                                        placeholder="Referred to"
                                                    />
                                                </Col>
                                            </>}
                                        >
                                            <Col xs="6" className="mb-4">
                                                <Field
                                                    cacheOptions
                                                    defaultOptions
                                                    label="Department"
                                                    disabled={disabled}
                                                    name="department_id"
                                                    onChange={handleUpdate}
                                                    placeholder="Department"
                                                    component={RFAsyncSelect}
                                                    getOptionValue={getOptionId}
                                                    prepareValue={prepareValueId}
                                                    loadOptions={loadDepartments}
                                                    getOptionLabel={getOptionName}
                                                />
                                            </Col>
                                            <Col xs="6" className="mb-4">
                                                <Field
                                                    type="text"
                                                    label="Referred to"
                                                    disabled={disabled}
                                                    component={RFInput}
                                                    name="assigned_user"
                                                    onChange={handleUpdate}
                                                    placeholder="Referred to"
                                                />
                                            </Col>
                                            <Col xs="12" className="mb-4">
                                                <Field
                                                    isMulti
                                                    cacheOptions
                                                    defaultOptions
                                                    isSearchable
                                                    label="Category"
                                                    disabled={disabled}
                                                    placeholder="Category"
                                                    onChange={handleUpdate}
                                                    component={RFAsyncSelect}
                                                    closeMenuOnSelect={false}
                                                    name="internal_categories"
                                                    getOptionValue={getOptionId}
                                                    loadOptions={loadCategories}
                                                    getOptionLabel={getOptionName}
                                                    prepareValue={prepareMultiValues}
                                                    styles={prepareBadgeInternalCategoryForReactSelect}
                                                />
                                            </Col>
                                        </Restricted>
                                        <Col xs="12" className="mb-4">
                                            <Field
                                                rows={6}
                                                className="p-3"
                                                type="textarea"
                                                disabled={disabled}
                                                component={RFInput}
                                                name="internal_summary"
                                                onChange={handleUpdate}
                                                label="Internal Summary"
                                                placeholder="Internal Summary"
                                            />
                                        </Col>
                                        <Col xs="12" className="mb-4">
                                            <CommentField onChange={handleUpdateComment} />
                                        </Col>
                                        { _.size(comment) ? <Col xs="12" className="ps-5">
                                            <p className="font-family-alternative fw-500 text-gray-500">Comments</p>
                                            <CommentsBlock list={comment} />
                                        </Col>: null }
                                    </Row>
                                </Col>
                                <Col xs="6">
                                    <Row>
                                        <Col tag="h2" xs="12" className="fw-600 fz-24 text-gray-700 text-center mb-4">
                                            Original Submission
                                        </Col>
                                        <Col xs="6" className="mb-4">
                                            <Field
                                                disabled
                                                type="text"
                                                name="user"
                                                label="Originator"
                                                component={RFInput}
                                                placeholder="Originator"
                                            />
                                        </Col>
                                        <Col xs="6" className="mb-4">
                                            <Field
                                                disabled
                                                name="date"
                                                label="Date"
                                                component={RFDatePicker}
                                            />
                                        </Col>
                                        <Col xs="12" className="mb-4">
                                            <Field
                                                disabled
                                                label="Category"
                                                component={RFInput}
                                                name="manual_category"
                                            />
                                        </Col>
                                        <Col xs="12" className="mb-4">
                                            <Field
                                                rows={12}
                                                disabled
                                                type="textarea"
                                                name="description"
                                                label="Description"
                                                component={RFInput}
                                                placeholder="Description"
                                            />
                                        </Col>
                                        <Col xs="12" className="mb-4">
                                            <Field
                                                downloadable
                                                name="files"
                                                editable={false}
                                                disabled={disabled}
                                                classNameGroup="mb-3"
                                                allowSealFile={false}
                                                dropAreaVisible={false}
                                                component={InputDropAreaFileList}
                                            />
                                        </Col>
                                        {/*<Col xs="12" className="mb-4">*/}
                                        {/*<DownloadAttachFile name="2156644.pdf" size="1,2 MB" />*/}
                                        {/*</Col>*/}
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </ReduxForm>
        </BoxLoader>
    </Layout>;
});

export default SuggestionsPage;

const prepareBadgeInternalCategoryForReactSelect = {
    multiValue: base => ({
        ...base,
        borderRadius: '0.4em',
        margin: 5,
        padding: '0.35em',
        backgroundColor: 'rgba(125, 198, 251, 0.2)',
    }),
    multiValueLabel: base => ({
        ...base,
        padding: 0,
        fontSize: '0.8em',
        color: 'rgb(86, 86, 86)',
    }),
    multiValueRemove: base => ({
        ...base,
        color: 'rgb(6, 115, 193)',
        '&:hover': {
            color: 'rgb(6, 115, 193)',
            backgroundColor: 'rgba(86, 86, 86, 0.2)',
        }
    }),
    input: base => ({
        ...base,
        fontSize: '0.8em',
        paddingTop: 0,
        paddingBottom: 0,
        input: {
            padding: '0 !important',
        }
    }),
};
