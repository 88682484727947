
// outsource dependencies
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'reactstrap';
import React, { memo, useEffect } from 'react';
import { useController } from 'redux-saga-controller';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useHistory, useParams } from 'react-router-dom';

// components
import { BoxLoader, ReduxForm } from 'components';

// constants
import { INCIDENT_TYPE } from 'constants/spec';

// local dependencies
import IssueForm from './issue-form';
import controller from './controller';
import Breadcrumb from './breadcrumb';
import SuggestionForm from './suggestion-form';

// configure
const FORM_NAME = 'casesPageForm';
const contentHeight = window.innerHeight;

const Preview = memo(function Preview () {
    const { goBack } = useHistory();
    const { id } = useParams();
    const [
        { initialized, disabled, initialValues },
        { initialize },
    ] = useController(controller);
    // NOTE initialize business logic
    useEffect(() => { initialize({ id }); }, [id, initialize]);

    const type = _.get(initialValues, 'type');
    const anonymous = _.get(initialValues, 'anonymous', false);
    const pageName = preparePageName(type, anonymous);
    const pageShortName = preparePageShortName(type);

    return <Scrollbars style={{ height: contentHeight }} className="bg-platform">
        <BoxLoader id="AppReport" active={!initialized}>
            <Row className="g-0">
                <Col xs="12" className="d-none d-lg-inline px-5 my-4">
                    <Breadcrumb goBack={goBack} pageName={pageShortName} />
                </Col>
                <Col xs="12" lg={{ offset: 1, size: 10 }} tag={Row} className="g-0">
                    <Col tag="h2" xs="12" className="d-none d-lg-inline fw-600 fz-24 text-gray-700 mb-4">
                        {pageName}
                    </Col>
                    <Col xs="12" tag={Row} className="g-0 bg-white p-3 p-lg-5 mb-lg-4">
                        <Col tag="h1" xs="12" className="d-none d-lg-inline fw-700 fz-32 text-gray-700 text-center mb-5">
                            {pageName}
                        </Col>
                        <Col xs="12" lg={{ offset: 3, size: 6 }}>
                            <ReduxForm form={FORM_NAME} initialValues={initialValues}>
                                <Row>
                                    <Col xs="12" tag="h1" className="d-inline d-lg-none fw-500 fz-18 text-secondary mb-4">
                                        {pageName}
                                    </Col>
                                    <WizardForm formType={type} />
                                    <Col xs="12" className="mb-3">
                                        <Button
                                            outline
                                            onClick={goBack}
                                            disabled={disabled}
                                            color="primary-white"
                                            className="btn-extended font-family-alternative fz-16 fw-500 w-100"
                                        >
                                            Back
                                        </Button>
                                    </Col>
                                </Row>
                            </ReduxForm>
                        </Col>
                    </Col>
                </Col>
            </Row>
        </BoxLoader>
    </Scrollbars>;
});

export default Preview;

function preparePageName (type, anonymous) {
    switch (type) {
        case INCIDENT_TYPE.ISSUE: return anonymous ? 'Anonymous Complaint' : 'Complaint';
        case INCIDENT_TYPE.SUGGESTION: return anonymous ? 'Anonymous Idea' : 'Idea';
        default: return null;
    }
}

function preparePageShortName (type) {
    switch (type) {
        case INCIDENT_TYPE.ISSUE: return 'Complaint';
        case INCIDENT_TYPE.SUGGESTION: return 'Idea';
        default: return null;
    }
}

const WizardForm = memo(function WizardForm ({ formType }) {
    switch (formType) {
        case INCIDENT_TYPE.ISSUE: return <IssueForm />;
        case INCIDENT_TYPE.SUGGESTION: return <SuggestionForm />;
        default: return null;
    }
});

WizardForm.propTypes = {
    formType: PropTypes.string,
};

WizardForm.defaultProps = {
    formType: null,
};
