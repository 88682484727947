// outsource dependencies
import { Col } from 'reactstrap';
import { Field } from 'redux-form';
import React, { memo } from 'react';
import { useControllerData } from 'redux-saga-controller';

// components
import TextPreview from 'components/text-preview';
import { InputDropAreaFileList, RFCheckbox } from 'components';

// local dependencies
import controller from './controller';

const SuggestionPage = memo(function SuggestionPage () {
    const { disabled } = useControllerData(controller);

    return <Col xs="12" className="mb-3">
        <Field
            name="manual_category"
            label="Category"
            classNameGroup="mb-3"
            component={TextPreview}
        />
        <Field
            name="description"
            label="Description"
            classNameGroup="mb-3"
            component={TextPreview}
        />
        <Field
            name="files"
            downloadable
            editable={false}
            disabled={disabled}
            classNameGroup="mb-3"
            allowSealFile={false}
            dropAreaVisible={false}
            component={InputDropAreaFileList}
        />
        <Field
            disabled
            component={RFCheckbox}
            name="legal_disclaimer"
            classNameLabel="d-inline"
            label="This is a legal disclaimer that will be changed prior to the release."
        />
    </Col>;
});

export default SuggestionPage;
