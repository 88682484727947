// outsource dependencies
import _ from 'lodash';
import { Field } from 'redux-form';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Button, Col, Container, Row } from 'reactstrap';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useController, useControllerData } from 'redux-saga-controller';

// root
import appRootController from 'controller';

// components
import { BoxLoader } from 'components/preloader';
import { FasIcon, ReduxForm, RFInput } from 'components';

// constants
import { FULL_HEIGHT_SCREEN } from 'constants/spec';

// local dependencies
import controller from './controller';

// images
import { DefaultImage } from 'images';
import defaultLogo from 'images/default-logo.svg';
import companyLogo from 'images/company-logo.svg';

// configure

const FORM_NAME = 'changePassword';

const formValidation = values => {
    const errors = {};
    if (!values.password) {
        errors.password = 'Password is required';
    } else if (!/[a-z]/i.test(values.password)) {
        errors.password = 'Password must contain at least 1 letter character';
    } else if (!/[0-9]/i.test(values.password)) {
        errors.password = 'Password must contain at least 1 digit character';
    } else if (values.password.length < 8) {
        errors.password = 'Password must contain at least 8 characters long';
    }

    if (!values.password_confirmation) {
        errors.password_confirmation = 'Confirm Password is required';
    } else if (values.password_confirmation !== values.password) {
        errors.password_confirmation = 'Both passwords must match';
    }
    return errors;
};

const ResetPassword = memo(function ForgotPassword () {
    const [
        { initialized, disabled, initialValues },
        { initialize, submitData },
    ] = useController(controller);
    // NOTE initialize business logic
    useEffect(() => { initialize(); }, [initialize]);

    const { organization } = useControllerData(appRootController);
    const name = _.get(organization, 'name');
    const logo = _.get(organization, 'logo');
    const background = _.get(organization, 'background');
    const description = _.get(organization, 'description');

    return <Scrollbars style={{ height: FULL_HEIGHT_SCREEN + 1 }}>
        <BoxLoader active={!initialized}>
            <Container fluid tag={Row} className="g-0 justify-content-center h-100">
                <Col lg="6" xs="12" className="g-0 bg-lg-platform p-3 p-lg-5 d-flex flex-column">
                    <Row>
                        <Col xs="12" className="d-flex mb-5 flex-wrap flex-sm-nowrap align-items-center">
                            <DefaultImage
                                src={logo}
                                alt={`Logo ${name}`}
                                title={`Logo ${name}`}
                                defaultSrc={defaultLogo}
                                defaultAlt="Logo Company"
                                defaultTitle="Logo Company"
                                className="me-sm-5 mb-4 mb-sm-0"
                                style={{ maxWidth: 120, maxHeight: 120 }}
                            />
                            <h1 className="fz-24 fw-lg-600 fw-700 text-secondary mb-0">
                                { name }
                            </h1>
                        </Col>
                    </Row>
                    <Row className="d-none d-lg-flex justify-content-start flex-grow-1">
                        <Col xs="12" className="mb-5">
                            <DefaultImage
                                src={background}
                                alt={`Main Image ${name}`}
                                title={`Main Image ${name}`}
                                defaultAlt="Default organization"
                                defaultTitle="Default organization"
                                className="rounded-6 w-50 me-4 float-start"
                            />
                            <p dangerouslySetInnerHTML={{ __html: description }} className="font-family-alternative fz-18 text-gray-700 text-pre-wrap" />
                        </Col>
                    </Row>
                    <Row className="d-flex d-lg-none justify-content-center mb-5">
                        <Col xs="12" sm="6" className="mb-4">
                            <DefaultImage
                                src={background}
                                alt={`Main Image ${name}`}
                                className="rounded-6 w-100"
                                title={`Main Image ${name}`}
                                defaultAlt="Default organization"
                                defaultTitle="Default organization"
                            />
                        </Col>
                        <Col xs="12">
                            <p dangerouslySetInnerHTML={{ __html: description }} className="font-family-alternative fz-18 text-gray-700 text-pre-wrap" />
                        </Col>
                    </Row>
                    <Row className="justify-content-center mb-5 mb-lg-0">
                        <Col xs="10" sm="4" md="4">
                            <DefaultImage
                                src={companyLogo}
                                className="w-100"
                                alt={`Main Image ${name}`}
                                defaultAlt="Anonymous Logo"
                                title={`Main Image ${name}`}
                                defaultTitle="Anonymous Logo"
                            />
                        </Col>
                    </Row>
                </Col>
                <Col
                    lg="6"
                    xs="12"
                    tag={ReduxForm}
                    form={FORM_NAME}
                    enableReinitialize
                    onSubmit={submitData}
                    validate={formValidation}
                    initialValues={initialValues}
                    className="mt-sm-0 d-flex flex-column vh-100 justify-content-sm-center"
                >
                    {/*<Row className="g-0 d-block d-sm-none px-3">
                        <Col xs="12">
                            <Button
                                onClick={goBack}
                                className="d-flex justify-content-center align-items-center border-0 bg-transparent p-2 mt-3"
                            >
                                <AppIcon icon="back" className="fz-20 text-secondary" />
                            </Button>
                        </Col>
                    </Row>*/}
                    <Row className="g-0 align-content-center justify-content-center px-3 p-lg-5 flex-grow-1 flex-sm-grow-0 ">
                        <Col xs="12" sm="8" lg="8" tag="h2" className="fz-24 fw-600 text-secondary text-center mb-5">
                            Change Password
                        </Col>
                        <Col xs="12" sm="8" lg="8" tag="p" className="font-family-alternative fz-18 mb-4">
                            Your account has been created. Please create a password.
                        </Col>
                        <Col xs="12" sm="8" lg="8" className="mb-3">
                            <Field
                                name="password"
                                label="Password"
                                disabled={disabled}
                                placeholder="Password"
                                component={PasswordInput}
                            />
                        </Col>
                        <Col xs="12" sm="8" lg="8" className="mb-4">
                            <Field
                                disabled={disabled}
                                label="Confirm Password"
                                component={PasswordInput}
                                name="password_confirmation"
                                placeholder="Confirm Password"
                            />
                        </Col>
                        <Col xs="12" sm="8" lg="8" className="mb-4">
                            <Button
                                type="submit"
                                color="primary"
                                className="font-family-alternative text-white fz-16 fw-500 text-center text-secondary w-100"
                            >
                                Create Password
                            </Button>
                        </Col>
                        {/*<Col xs="12" sm="8" lg="8" className="mb-4">
                            <Button
                                outline
                                color="primary-white"
                                className="font-family-alternative btn-extended fz-16 fw-500 w-100"
                            >
                                Log In
                            </Button>
                        </Col>*/}
                    </Row>
                </Col>
            </Container>
        </BoxLoader>
    </Scrollbars>;
});

export default ResetPassword;

const PasswordInput = memo(function PasswordInput (props) {
    const [isPasswordType, setPasswordType] = useState(true);

    const changeType = useCallback(() => setPasswordType(type => !type), [setPasswordType]);

    return <RFInput
        {...props}
        type={isPasswordType ? 'password' : 'text'}
        icon={<Button
            onClick={changeType}
            style={{ width: 20, height: 20 }}
            className="d-flex justify-content-center align-items-center border-0 bg-transparent p-1"
        >
            <FasIcon icon={isPasswordType ? 'eye' : 'eye-slash'} className="fz-18 text-gray-500" />
        </Button>}
    />;
});
