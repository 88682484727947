
// outsource dependencies
import _ from 'lodash';
import { memo } from 'react';
import PropTypes from 'prop-types';

// local dependencies
import { useRole } from 'hooks';
import { store } from 'constants/store';

// configure

export const Restricted = memo(function Restricted ({ role, fallback, children }) {
    const currentRole = useRole();
    const rolesArray = Array.isArray(role) ? role : [role];

    return rolesArray.includes(currentRole) ? children : fallback;
});
Restricted.propTypes = {
    fallback: PropTypes.node,
    children: PropTypes.node.isRequired,
    role: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};
Restricted.defaultProps = {
    fallback: null,
};

export default Restricted;

export function restrictedParams (role, children, fallback) {
    const currentRole = _.get(store.getState(), 'controller.app-state.role', null);
    const rolesArray = Array.isArray(role) ? role : [role];

    return rolesArray.includes(currentRole) ? children : fallback;
}
