import _ from 'lodash';
import { isUrl } from '../services';


export const isImage = (type) => _.isString(type) && type.startsWith('image/');
export const isAudio = (type) => _.isString(type) && type.startsWith('audio/');
export const isVideo = (type) => _.isString(type) && type.startsWith('video/');

export const destroyPreview = (file, previewUrl) => {
    _.isEmpty(previewUrl)
        ? URL.revokeObjectURL(file.downloadUrl)
        : URL.revokeObjectURL(_.get(file, `${previewUrl}`));
    // eslint-disable-next-line no-console
    // console.log('destroyed preview', file)
};

const fileCallbackToPromise = (fileObj) => {
    return Promise.race([
        new Promise(resolve => {
            if (fileObj instanceof HTMLImageElement) {
                fileObj.onload = resolve;
            } else {
                fileObj.onloadedmetadata = resolve;
            }
        }),
        new Promise((resolve, reject) => {
            setTimeout(reject, 1000);
        }),
    ]);
};

const dataURLtoBlob = (dataUrl) => {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
};

/**
 * prepare file name
 *
 * @param {string} nameString
 * @param {function} transform
 * @returns {string}
 */
export const prepareFileName = (nameString, transform = (str) => str && str.toLowerCase()) => {
    const name = nameString;
    if (!_.isString(name)) {
        return `image_${Date.now()}`;
    }
    const fileExt = name.slice((Math.max(0, name.lastIndexOf('.')) || Infinity) + 1);
    const fileName = _.isFunction(transform) && transform(name.split('.').slice(0, -1).join('.'));
    // NOTE allowed for names only ASCII
    return `${fileName.replace(/[^\d|A-Z|a-z]/g, '_').replace(/_+/g, '_')}.${fileExt}`;
};

/**
 * get file name from url
 *
 * @param {string} url
 * @returns {string}
 */
export const getFileNameFromUrl = (url) => {
    return isUrl(url)
        ? new URL(decodeURIComponent(url)).pathname.substring(new URL(decodeURIComponent(url)).pathname.lastIndexOf('/')+1)
        : url;
};

/**
 * Wrap file for feed attach
 * @param file
 * @returns {Promise<{sourceFileEntity, meta: {fileName, fileSize, fileHeight: number, filePreview: string, fileType, fileWidth: number}}>}
 */
export const prepareSelectedFile = async (file) => {
    const objectUrl = URL.createObjectURL(file);
    const resultFile = {
        fileRaw: file,
        fileMeta: {
            fileWidth: 0,
            fileHeight: 0,
            fileType: file.type,
            fileSize: file.size,
            filePreviewUrl: objectUrl,
            fileName: prepareFileName(file.name),
            fileUUID: _.first(objectUrl.match(/\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/))
        }
    };

    try {
        if (isImage(resultFile.fileMeta.fileType)) {
            const img = new Image();
            img.src = resultFile.fileMeta.filePreviewUrl;
            await fileCallbackToPromise(img);
            resultFile.fileMeta.fileWidth = img.width;
            resultFile.fileMeta.fileHeight = img.height;
        }

        if (isAudio(resultFile.fileMeta.fileType)) {
            const audio = new Audio();
            audio.src = resultFile.fileMeta.filePreviewUrl;
            await fileCallbackToPromise(audio);
            resultFile.fileMeta.duration = audio.duration;
        }

        if (isVideo(resultFile.fileMeta.fileType)) {
            const video = document.createElement('video');
            const canvas = document.createElement('canvas');
            video.src = resultFile.fileMeta.filePreviewUrl;
            video.muted = true;
            video.playsInline = true;

            await fileCallbackToPromise(video);

            video.currentTime = Math.floor(video.duration / 3);
            await video.play();

            setTimeout(() => video.pause(), 500);

            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

            resultFile.fileMeta.duration = video.duration;
            resultFile.fileMeta.fileWidth = video.videoWidth;
            resultFile.fileMeta.fileHeight = video.videoHeight;
            resultFile.fileMeta.fileDataURL = canvas.toDataURL();

            resultFile.fileMeta.fileBlobRawData = resultFile.fileMeta.filePreviewUrl;
            resultFile.fileMeta.filePreviewUrl = URL.createObjectURL(dataURLtoBlob(canvas.toDataURL()));
        }
        if (!isImage(resultFile.fileMeta.fileType)) {
            destroyPreview(resultFile, 'fileMeta.filePreviewUrl');
            destroyPreview(resultFile, 'fileMeta.fileBlobRawData');
        }
    } catch (e) {
        destroyPreview(resultFile, 'fileMeta.filePreviewUrl');
        return Promise.reject(resultFile);
    }
    return Promise.resolve(resultFile);
};

/**
 * Получить информацию о промисе после того, как дождались его завершения.
 * @param {Promise} promise Промис, для которого нужно получить информацию.
 * @returns {Promise<{status:String,value:Any}|{status:String,reason:Error}>} Промис с объектом информации.
 */
const PromiseReflect = promise => {
    return promise.then(
        (result) => ({ status: 'fulfilled', result }),
        (error) => ({ status: 'rejected', error })
    );
};

/**
 * Promises all settled
 * @param {Promise[]} promises Promises list.
 * @returns {Promise[]}. Return array of reflect promises.
 */
// eslint-disable-next-line no-async-promise-executor
export const PromiseAllSettled = promises => new Promise(async (resolve, reject) => {
    const array = [];
    for (const promise of promises) {
        array.push(await PromiseReflect(promise));
    }
    resolve(array);
});
