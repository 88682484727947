
// outsource dependencies
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import React, { memo, useEffect, useMemo } from 'react';
import { Button, Col, Container, Nav, NavItem, Row } from 'reactstrap';
import { useController, useControllerData } from 'redux-saga-controller';

// local dependencies
import Layout from 'pages/layout';
import Breadcrumb from './breadcrumb';
import { FilterService } from 'services';
import { FasIcon } from 'components/icon';
import { BoxLoader } from 'components/preloader';
import controller, { TABS_TYPE } from './controller';

// tabs
import Details from './details';

// configure

const OrganizationDepartment = memo(function OrganizationDepartment () {
    const { goBack } = useHistory();
    const [
        { initialized, activeTab },
        { initialize, updateCtrl },
    ] = useController(controller);
    // NOTE initialize business logic
    useEffect(() => { initialize(); }, [initialize]);

    const prepareTabs = useMemo(() => Object.values(TABS_TYPE).map(tab => ({
        handleTab: () => updateCtrl({ activeTab: tab }),
        name: FilterService.humanize(tab),
        isActive: tab === activeTab,
    })), [updateCtrl, activeTab]);

    return <Layout>
        <BoxLoader id="AppOrganizationCreate" active={!initialized}>
            <Row className="mx-5 my-4">
                <Col xs="12" className="d-flex align-items-center">
                    <Button color="primary" onClick={goBack} className="text-white px-4 me-4">
                        <FasIcon icon="arrow-left" />
                    </Button>
                    <Breadcrumb />
                </Col>
            </Row>
            <Container className="mt-4 mb-5">
                <Row className="g-0">
                    <Col tag="h1" xs="12" className="fw-600 fz-24 text-gray-700 mb-4">
                        Add New Organization
                    </Col>
                    <Col xs="12">
                        <Row className="justify-content-center bg-white p-4">
                            <Col xs="12" className="mb-4">
                                <Nav tabs className="border-0 border-bottom border-2 border-primary">
                                    { prepareTabs.map(({ name, isActive, handleTab }) => <NavItem key={name}>
                                        <Button
                                            onClick={handleTab}
                                            style={{ marginBottom: '-2px' }}
                                            className={cx(
                                                'font-family-alternative nav-link text-gray-700 border-0 border-bottom border-2 rounded-0',
                                                isActive ? 'fw-500 border-secondary' : 'fw-400 border-primary'
                                            )}
                                        >
                                            { name }
                                        </Button>
                                    </NavItem>)}
                                </Nav>
                            </Col>
                            <Col xs="6" className="mb-4">
                                <SwitchTabs />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </BoxLoader>
    </Layout>;
});

export default OrganizationDepartment;

const SwitchTabs = memo(function () {
    const { activeTab } = useControllerData(controller);
    switch (activeTab) {
        default:
            console.warn(`No implement type - ${activeTab}`);
            break;
        case TABS_TYPE.DETAILS: return <Details />;
    }
});
