
// outsource dependencies
import _ from 'lodash';

// local dependencies

/**
 * try to find explanation of error in specification
 *
 * @param {String[]|String} errors
 * @param {String} [defMessage=null]
 */
export function getMessage (errors, defMessage) {
    // NOTE check and setup default message
    if (!_.isString(defMessage)) {
        defMessage = getMessage('UNKNOWN_ERROR', 'Some thing went wrong ...');
    } else {
        defMessage = MESSAGE[defMessage] ? MESSAGE[defMessage] : defMessage;
    }
    // NOTE try to get message from specification
    let message = '';
    if (_.isArray(errors)) {
        message = errors.map(e => getMessage(e, defMessage)).join(', ');
    } else if (errors) {
        message = MESSAGE[errors];
    }
    return message || defMessage;
}

export const MESSAGE = {
    422: '',
    CODE_NULL: '', // errors which will be displayed as UNKNOWN_ERROR
    NESTED_EXCEPTION: '', // errors which will be displayed as UNKNOWN_ERROR
    FORBIDDEN: 'Access is denied."',
    404: '404: Resources not found',
    UNKNOWN_ERROR: 'Some thing went wrong ...',
    CROSS_DOMAIN_REQUEST: 'Cross domain request not allowed !',
    NOT_IMPLEMENTED: 'Functionality currently unavailable',
};
