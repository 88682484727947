
// outsource dependencies
import { Field } from 'redux-form';
import { Button, Col, Row } from 'reactstrap';
import React, { memo, useCallback } from 'react';
import { useControllerActions, useControllerData } from 'redux-saga-controller';

// components
import TextPreview from 'components/text-preview';
import { InputDropAreaFileList, ReduxFormWizard, RFCheckbox } from 'components';

// local dependencies
import { FORM_NAME } from './index';
import controller, { SUGGESTION_STEP_TYPE } from './controller';

// configure
const formValidation = values => {
    const errors = {};
    if (!values.legal_disclaimer) {
        errors.legal_disclaimer = 'This field is required';
    }
    return errors;
};

const Preview = memo(function Preview () {
    const { initialValues, disabled } = useControllerData(controller);
    const { updateCtrl, submitData } = useControllerActions(controller);

    const toPreviouslyStep = useCallback(() => (
        updateCtrl({ stepForm: SUGGESTION_STEP_TYPE.ADDITIONAL_DETAILS })
    ), [updateCtrl]);

    return <ReduxFormWizard
        form={FORM_NAME}
        onSubmit={submitData}
        validate={formValidation}
        initialValues={initialValues}
    >
        <Row>
            <Col xs="12" tag="h1" className="d-inline d-lg-none fw-500 fz-18 text-secondary mb-4">
                Preview
            </Col>
            <Col xs="12" className="mb-3">
                <Field
                    label="Category"
                    classNameGroup="mb-3"
                    name="manual_category"
                    component={TextPreview}
                />
                <Field
                    name="description"
                    label="Description"
                    classNameGroup="mb-3"
                    component={TextPreview}
                />
                <div className="mb-3">
                    {/*<DownloadAttachFile name="903412132135.pdf" size="1,2 MB" />*/}
                </div>
                <Field
                    name="files"
                    editable={false}
                    disabled={disabled}
                    dropAreaVisible={false}
                    component={InputDropAreaFileList}
                />
                <Field
                    disabled={disabled}
                    component={RFCheckbox}
                    name="legal_disclaimer"
                    classNameGroup="d-flex"
                    className="flex-shrink-0"
                    label="This is a legal disclaimer that will be changed prior to the release."
                />
            </Col>
            <Col xs="12" className="mb-3">
                <Button
                    type="submit"
                    color="primary"
                    className="font-family-alternative text-white fz-16 fw-500 text-center text-secondary rounded-5 w-100"
                >
                    Submit
                </Button>
            </Col>
            <Col xs="12" className="mb-3">
                <Button
                    outline
                    color="primary-white"
                    onClick={toPreviouslyStep}
                    className="font-family-alternative fz-16 fw-500 text-center btn-extended rounded-5 w-100"
                >
                    Back
                </Button>
            </Col>
        </Row>
    </ReduxFormWizard>;
});

export default Preview;
