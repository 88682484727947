
// outsource dependencies
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, NavLink, Table } from 'reactstrap';
import { useControllerData } from 'redux-saga-controller';

// components
import { FasIcon } from 'components';
import Th from 'components/table/th';
import BadgeStatus from 'components/badge/badge-status';

// constants
import * as ROUTES from 'constants/routes';

// local dependencies
import controller from '../controller';

// configure
const DepartmentsTable = memo(function DepartmentsTable ({ data }) {
    const { disabled } = useControllerData(controller);
    return <Table hover className="table-filter mb-0 shadow-sm">
        <thead>
            <tr className="border-bottom-0">
                <Th width="60%" title="Department" />
                <Th width="15%" title="Number of Users" />
                <Th sort width="15%" title="Status" />
                <Th width="10%" title="Action" />
            </tr>
        </thead>
        <tbody>
            { (data || []).map(({ id, name, users, status, organization_id, showConfirmModal }) => <tr id={`org-${organization_id}-department-${id}`}
                key={`org-${organization_id}-department-${id}`}
                className="cursor-pointer"
            >
                <DepartmentsTableTdLink
                    id={id}
                    tabIndex="0"
                    title={name}
                    disabled={disabled}
                    className="justify-content-start"
                />
                <DepartmentsTableTdLink
                    id={id}
                    title={users}
                    disabled={disabled}
                    className="justify-content-center"
                />
                <DepartmentsTableTdLink
                    id={id}
                    disabled={disabled}
                    title={<BadgeStatus type={status} />}
                />
                <td className={cx({ 'opacity-50': disabled })}>
                    <Button
                        disabled={disabled}
                        onClick={showConfirmModal}
                        className="d-flex justify-content-center align-items-center text-white border-transparent bg-primary p-2 shadow-none ms-3"
                    >
                        <FasIcon icon="trash" className="fz-12" />
                    </Button>
                </td>
            </tr>) }
        </tbody>
    </Table>;
});

export default DepartmentsTable;

const DepartmentsTableTdLink = memo(function DepartmentsTableTdLink ({ title, tabIndex, className, id, disabled }) {
    const { id: organizationId } = useParams();
    return <td className={cx({ 'opacity-50': disabled })}>
        <NavLink
            tag={Link}
            tabIndex={tabIndex}
            disabled={disabled}
            className={className}
            to={ROUTES.ORGANIZATION_DEPARTMENT.LINK({ id: organizationId, departmentId: id })}
        >
            { title }
        </NavLink>
    </td>;
});
DepartmentsTableTdLink.propTypes = {
    id: PropTypes.number,
    disabled: PropTypes.bool,
    tabIndex: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
DepartmentsTableTdLink.defaultProps = {
    disabled: false,
    tabIndex: '-1',
    title: '',
    id: 0,
};
