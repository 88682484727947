
// outsource dependencies
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { create } from 'redux-saga-controller';
import { takeEvery, put, call, select } from 'redux-saga/effects';

// root
import appRootController from 'controller';

// services
import { instanceAPI } from 'services';

// configure
export const controller = create({
    prefix: 'request-account',
    actions: {
        initialize: 'INITIALIZE',
        submitData: 'SUBMIT_DATA',
    },
    initial: {
        disabled: false,
        initialized: false,
        errorMessage: null,

        initialValues: {
            name: '',
            email: '',
            organization: '',
        },
        showModal: false,
    },
    subscriber: function * () {
        yield takeEvery(controller.action.initialize.TYPE, initializeSaga);
        yield takeEvery(controller.action.submitData.TYPE, submitDataSaga);
    }
});

export default controller;

function * initializeSaga () {
    // console.log(`%c ${type} `, 'color: #FF6766; font-weight: bolder; font-size: 12px;'
    //   , '\n payload:', payload
    // );
    const { organization } = yield select(appRootController.select);
    const { initialValues } = yield select(controller.select);
    const organizationName = _.get(organization, 'name', '');

    yield put(controller.action.updateCtrl({
        initialized: true,
        initialValues: { ...initialValues, organization: organizationName }
    }));
}

function * submitDataSaga ({ payload }) {
    // console.log(`%c ${type} `, 'color: #FF6766; font-weight: bolder; font-size: 12px;'
    //   , '\n payload:', payload
    // );
    yield put(controller.action.updateCtrl({ disabled: true }));
    try {
        yield call(instanceAPI, { method: 'POST', url: '/auth/request-account', data: payload });
        yield put(controller.action.updateCtrl({ showModal: true }));
    } catch ({ message }) {
        yield call(toastr.error, 'Error', message);
        yield put(controller.action.updateCtrl({ errorMessage: message }));
    }
    yield put(controller.action.updateCtrl({ disabled: false }));
}
