
// outsource dependencies
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';

// injections
import './style';

// local dependencies
import App from 'pages';
import store from 'constants/store';

ReactDOM.render(
    <Provider store={store}>
        <App />
        <ReduxToastr
            progressBar
            timeOut={8000}
            preventDuplicates
            newestOnTop={false}
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
        />
    </Provider>,
    document.getElementById('root')
);
