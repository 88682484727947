
// outsource dependencies
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

// local dependencies
import { FasIcon } from 'components/icon';
import * as ROUTES from 'constants/routes';

// configure

const BreadcrumbCustom = memo(function BreadcrumbCustom () {
    return <Breadcrumb listClassName="d-flex align-items-center mb-0">
        <BreadcrumbItem>
            <Link to={ROUTES.SUGGESTIONS_LIST.LINK()} className="font-family-alternative fz-18 text-gray-700 text-decoration-none">
                Idea Toolbox
            </Link>
        </BreadcrumbItem>
        <BreadcrumbItem className="fz-18 d-flex ps-1">
            <FasIcon icon="angle-right" />
        </BreadcrumbItem>
        <BreadcrumbItem active className="font-family-alternative fz-18 text-gray-700">
            Idea Card
        </BreadcrumbItem>
    </Breadcrumb>;
});

export default BreadcrumbCustom;
