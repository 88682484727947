
// outsource dependencies
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// local dependencies
import { AppIcon, FasIcon } from './icon';

// configure

const DownloadAttachFile = memo(function DownloadAttachFile ({ width, height, name, size, isAccessed, className }) {
    return <div className={cx('d-flex align-items-start w-100 mb-3', className)}>
        <div style={{ width, height }} className="d-flex justify-content-center align-items-center rounded-5 bg-platform p-3 me-2">
            <FasIcon icon="paperclip" className="fz-18 text-gray-600" />
        </div>
        <div className="d-flex flex-column w-100">
            <div style={{ height }} className="d-flex align-items-center justify-content-between">
                <p className="d-flex align-items-center text-blue fz-12 mb-0">
                    { name }
                </p>
                <div className="d-flex align-items-center">
                    <p className="fz-12 text-gray-600 m-0">{ size }</p>
                </div>
            </div>
            { isAccessed ? <div className="d-flex align-items-center">
                <p className="fz-12 text-gray-600 m-0 me-3">Access Sealed</p>
                <AppIcon icon="lock" className="fz-16 text-danger" />
            </div> : null }
        </div>
    </div>;
});
DownloadAttachFile.propTypes = {
    className: PropTypes.string,
    isAccessed: PropTypes.bool,
    height: PropTypes.number,
    width: PropTypes.number,
    name: PropTypes.string,
    size: PropTypes.string,
};
DownloadAttachFile.defaultProps = {
    isAccessed: false,
    className: '',
    height: 32,
    width: 32,
    name: '',
    size: '',
};

export default DownloadAttachFile;
