
// outsource dependencies
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Field, getFormValues } from 'redux-form';
import React, { forwardRef, memo, useCallback, useState } from 'react';
import { Button, Card, CardBody, Col, Collapse, Input, Row } from 'reactstrap';
import { useControllerActions, useControllerData } from 'redux-saga-controller';

// root
import { useSelf } from 'hooks';

// components
import { ReduxForm, RFInput, RFDatePicker, RFSelect, AppIcon, FasIcon, SortIcon, getOptionId, getOptionName, RFAsyncSelect } from 'components';

// constants
import { environmentConfig } from 'constants/enviroment-config';
import { STATUS_TYPE, SYSTEM_ROLE_TYPE, severityOptions } from 'constants/spec';

// services
import { instanceAPI } from 'services/request';
import { FilterService } from 'services/filter.service';

// common
import { Restricted } from 'common/permission-provider';

// local dependencies
import controller from './controller';

// configure
export const statusOptions = Object.values(STATUS_TYPE).map(status => ({
    value: status, label: FilterService.humanize(status)
}));
export const FORM_NAME = 'suggestionFilterForm';
const dateFormat = 'MMMM DD/yyyy';

const Filter = memo(function Filter () {
    const user = useSelf();
    const { initialValues, disabled } = useControllerData(controller);
    const { resetForm, resetField, updateData } = useControllerActions(controller);

    const {
        originator_email, originator_name, originator_external_id, department, organization,
        assigned, date_from, category, severity, status, date_to, case: caseNumber
    } = useSelector(getFormValues(FORM_NAME)) || {};

    const [isOpenFilter, setOpenFilter] = useState(false);

    const handleToggleFilter = useCallback(() => setOpenFilter(isOpenFilter => !isOpenFilter), []);
    const handleSubmitSearch = useCallback(() => {
        updateData({ page: 1 });
        handleToggleFilter();
    }, [handleToggleFilter, updateData]);
    const handleResetField = useCallback(({ field }) => {
        resetField({ field });
        updateData({ page: 1 });
    }, [updateData, resetField]);
    const handleResetForm = useCallback(() => {
        resetForm();
        updateData({ page: 1 });
        handleToggleFilter();
    }, [resetForm, updateData, handleToggleFilter]);

    const loadDepartments = useCallback(() => {
        if (_.get(user, 'role.type_name') === SYSTEM_ROLE_TYPE.ORGANIZATION_ADMIN) {
            return instanceAPI({ method: 'GET', url: `organization/${_.get(user, 'organization.id')}/department` })
                .then(({ data }) => data);
        }
        return instanceAPI({ method: 'GET', url: 'department/withoutPagination' });
    }, [user]);

    const loadOrganizations = useCallback(() => instanceAPI({
        method: 'GET',
        url: 'organization',
    }), []);

    const loadCategories = useCallback(() => instanceAPI({
        method: 'GET',
        url: 'incident/category',
    }), []);

    const DatePickerInput = forwardRef(({ value, onClick }, ref) => <Input
        placeholder={environmentConfig.DATE_FORMAT}
        onChange={e => e}
        onClick={onClick}
        innerRef={ref}
        value={value}
    />);

    return <ReduxForm
        form={FORM_NAME}
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmitSearch}
    >
        <Row className="position-relative" style={{ zIndex: 2 }}>
            <Col xs="12" className="d-flex align-items-center">
                <Button className="d-flex align-items-center justify-content-center fz-18 text-gray-700 bg-transparent border-0 rounded-3 p-2 me-4">
                    <span className="fw-600 me-4">Search</span>
                    <SortIcon status={isOpenFilter} className="fz-18" />
                </Button>
                <div className="d-flex flex-wrap">
                    { caseNumber ? <SearchAttribute
                        name="Case"
                        search={caseNumber}
                        onChange={() => handleResetField({ field: 'case' })}
                    /> : null }
                    { date_from ? <SearchAttribute
                        name="Date from"
                        search={moment(date_from).format(dateFormat)}
                        onChange={() => handleResetField({ field: 'date_from' })}
                    /> : null }
                    { date_to ? <SearchAttribute
                        name="Date to"
                        search={moment(date_to).format(dateFormat)}
                        onChange={() => handleResetField({ field: 'date_to' })}
                    /> : null }
                    { department ? <SearchAttribute
                        name="Department"
                        search={department.name}
                        onChange={() => handleResetField({ field: 'department' })}
                    /> : null }
                    { organization ? <SearchAttribute
                        name="Organization"
                        search={organization.name}
                        onChange={() => handleResetField({ field: 'organization' })}
                    /> : null }
                    { status ? <SearchAttribute
                        name="Status"
                        search={status.label}
                        onChange={() => handleResetField({ field: 'status' })}
                    /> : null }
                    { category ? <SearchAttribute
                        name="Category"
                        search={category.name}
                        onChange={() => handleResetField({ field: 'category' })}
                    /> : null }
                    { severity ? <SearchAttribute
                        name="Severity"
                        search={severity.label}
                        onChange={() => handleResetField({ field: 'severity' })}
                    /> : null }
                    { assigned ? <SearchAttribute
                        name="Assigned"
                        search={assigned}
                        onChange={() => handleResetField({ field: 'assigned' })}
                    /> : null }
                    { originator_external_id ? <SearchAttribute
                        name="Originator ID"
                        search={originator_external_id}
                        onChange={() => handleResetField({ field: 'originator_external_id' })}
                    /> : null }
                    { originator_name ? <SearchAttribute
                        name="Originator Name"
                        search={originator_name}
                        onChange={() => handleResetField({ field: 'originator_name' })}
                    /> : null }
                    { originator_email ? <SearchAttribute
                        name="Originator Email"
                        search={originator_email}
                        onChange={() => handleResetField({ field: 'originator_email' })}
                    /> : null }
                </div>
            </Col>
            <Col tag={Collapse} isOpen={isOpenFilter} className="position-absolute top-100 mt-2">
                <Card className="shadow border-0" style={{ width: '45%' }}>
                    <CardBody>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <Button
                                color="transparent"
                                onClick={resetForm}
                                className="d-flex align-items-center justify-content-center font-family-alternative fz-14 text-secondary rounded-3 px-1"
                            >
                                <AppIcon icon="trash-filter" className="fz-18 me-3" />
                                Clear all
                            </Button>
                            <Button
                                color="transparent"
                                onClick={handleResetForm}
                                className="d-flex align-items-center justify-content-center fz-18 text-white rounded-3 p-1"
                            >
                                <FasIcon icon="times" className="text-gray-700" />
                            </Button>
                        </div>
                        <Field
                            name="case"
                            type="number"
                            label="Case #"
                            component={RFInput}
                            disabled={disabled}
                            placeholder="00000000"
                            classNameLabel="w-50 m-0"
                            classNameInputWrapper="w-50"
                            classNameGroup="d-flex align-items-center mb-2"
                        />
                        <Field
                            name="date_from"
                            label="Date From"
                            disabled={disabled}
                            component={RFDatePicker}
                            customInput={<DatePickerInput />}
                            classNameLabel="flex-shrink-0 w-50 m-0"
                            classNameGroup="d-flex align-items-center mb-2"
                        />
                        <Field
                            name="date_to"
                            label="Date To"
                            minDate={date_from}
                            disabled={disabled}
                            component={RFDatePicker}
                            customInput={<DatePickerInput />}
                            classNameLabel="flex-shrink-0 w-50 m-0"
                            classNameGroup="d-flex align-items-center mb-2"
                        />
                        <Restricted role={SYSTEM_ROLE_TYPE.PLATFORM_ADMIN}>
                            <Field
                                isSimple
                                cacheOptions
                                defaultOptions
                                disabled={disabled}
                                name="organization"
                                label="Organization"
                                component={RFAsyncSelect}
                                placeholder="Organization"
                                styles={prepareSelectStyle}
                                getOptionValue={getOptionId}
                                getOptionLabel={getOptionName}
                                loadOptions={loadOrganizations}
                                classNameLabel="flex-shrink-0 w-50 m-0"
                                classNameGroup="d-flex align-items-center mb-2"
                            />
                        </Restricted>
                        <Field
                            isSimple
                            cacheOptions
                            defaultOptions
                            name="department"
                            label="Department"
                            disabled={disabled}
                            placeholder="Department"
                            component={RFAsyncSelect}
                            styles={prepareSelectStyle}
                            getOptionValue={getOptionId}
                            loadOptions={loadDepartments}
                            getOptionLabel={getOptionName}
                            classNameLabel="flex-shrink-0 w-50 m-0"
                            classNameGroup="d-flex align-items-center mb-2"
                        />
                        <Field
                            isSimple
                            name="status"
                            label="Status"
                            disabled={disabled}
                            placeholder="Status"
                            component={RFSelect}
                            options={statusOptions}
                            styles={prepareSelectStyle}
                            classNameLabel="flex-shrink-0 w-50 m-0"
                            classNameGroup="d-flex align-items-center mb-2"
                        />
                        <Field
                            isSimple
                            cacheOptions
                            defaultOptions
                            name="category"
                            label="Category"
                            disabled={disabled}
                            placeholder="Category"
                            component={RFAsyncSelect}
                            styles={prepareSelectStyle}
                            getOptionValue={getOptionId}
                            loadOptions={loadCategories}
                            getOptionLabel={getOptionName}
                            classNameLabel="flex-shrink-0 w-50 m-0"
                            classNameGroup="d-flex align-items-center mb-2"
                        />
                        <Field
                            isSimple
                            name="severity"
                            label="Severity"
                            disabled={disabled}
                            component={RFSelect}
                            placeholder="None Selected"
                            options={severityOptions}
                            styles={prepareSelectStyle}
                            classNameLabel="flex-shrink-0 w-50 m-0"
                            classNameGroup="d-flex align-items-center mb-2"
                        />
                        <Field
                            type="number"
                            name="assigned_to"
                            label="Referred to"
                            component={RFInput}
                            disabled={disabled}
                            classNameLabel="w-50 m-0"
                            classNameInputWrapper="w-50"
                            placeholder="First and Family Name"
                            classNameGroup="d-flex align-items-center mb-2"
                        />
                        <Field
                            type="text"
                            component={RFInput}
                            disabled={disabled}
                            label="Originator ID"
                            placeholder="00000000"
                            classNameLabel="w-50 m-0"
                            classNameInputWrapper="w-50"
                            name="originator_external_id"
                            classNameGroup="d-flex align-items-center mb-2"
                        />
                        <Field
                            type="text"
                            component={RFInput}
                            disabled={disabled}
                            name="originator_name"
                            label="Originator Name"
                            classNameLabel="w-50 m-0"
                            classNameInputWrapper="w-50"
                            placeholder="First and Family Name"
                            classNameGroup="d-flex align-items-center mb-2"
                        />
                        <Field
                            type="email"
                            component={RFInput}
                            disabled={disabled}
                            name="originator_email"
                            label="Originator Email"
                            classNameLabel="w-50 m-0"
                            classNameInputWrapper="w-50"
                            placeholder="email@gmail.com"
                            classNameGroup="d-flex align-items-center mb-4"
                        />
                        <Button
                            color="primary"
                            disabled={disabled}
                            onClick={handleSubmitSearch}
                            className="text-white w-100"
                        >
                            Search
                        </Button>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </ReduxForm>;
});

export default Filter;

const SearchAttribute = memo(function SearchAttribute ({ name, search, onChange }) {
    return <p className="d-flex align-items-center text-gray-500 mb-0 me-2">
        { name }
        :&nbsp;
        <span className="text-gray text-gray-700 mb-0 me-1">
            { search }
        </span>
        <Button
            onClick={onChange}
            color="transparent"
            className="d-flex align-items-center justify-content-center fz-12 text-white rounded-3 p-1"
        >
            <FasIcon icon="times" className="text-gray-500" />
        </Button>
    </p>;
});
SearchAttribute.propTypes = {
    name: PropTypes.string,
    search: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};
SearchAttribute.defaultProps = {
    search: '',
    name: '',
};

const prepareSelectStyle = {
    container: base => ({
        ...base,
        width: '50%'
    }),
};
