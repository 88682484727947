
// outsource dependencies
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { create } from 'redux-saga-controller';
import { takeEvery, put, call } from 'redux-saga/effects';

// local dependencies
import { instanceAPI } from 'services';
import { history } from 'constants/history';
import { NEW, STATUS_USER_TYPE } from 'constants/spec';
import { ORGANIZATION_DEPARTMENT, ORGANIZATION_PAGE } from 'constants/routes';

// configure
export const TABS_TYPE = {
    DETAILS: 'DETAILS',
};

export const controller = create({
    prefix: 'organization-department',
    actions: {
        initialize: 'INITIALIZE',
        submitData: 'SUBMIT_DATA',
    },
    initial: {
        disabled: false,
        initialized: false,
        errorMessage: null,

        initialValues: {},
        activeTab: TABS_TYPE.DETAILS,
    },
    subscriber: function * () {
        yield takeEvery(controller.action.initialize.TYPE, initializeSaga);
        yield takeEvery(controller.action.submitData.TYPE, submitDataSaga);
    }
});

export default controller;

function * initializeSaga ({ type, payload }) {
    // console.log(`%c ${type} `, 'color: #FF6766; font-weight: bolder; font-size: 12px;'
    //   , '\n payload:', payload
    // );
    try {
        const { departmentId } = yield call(ORGANIZATION_DEPARTMENT.PARAMS);
        if (departmentId !== NEW) {
            const department = yield call(instanceAPI, { method: 'GET', url: `/department/${departmentId}` });
            yield put(controller.action.updateCtrl({ initialValues: {
                ...department,
                managers: (_.get(department, 'department_users') || []).map(({ id }) => id)
            } }));
        }
    } catch ({ message }) {
        yield call(toastr.error, 'Organization Department Initialize', message);
        yield put(controller.action.updateCtrl({ errorMessage: message }));
    }
    yield put(controller.action.updateCtrl({ initialized: true }));
}

function * submitDataSaga ({ type, payload }) {
    yield put(controller.action.updateCtrl({ disabled: true }));
    try {
        const { id, departmentId } = yield call(ORGANIZATION_DEPARTMENT.PARAMS);
        const data = { ...payload, organization_id: Number(id) };
        if (departmentId === NEW) {
            yield call(instanceAPI, { method: 'POST', url: 'department/create', data: { ...data, status: STATUS_USER_TYPE.ACTIVE } });
            yield call(toastr.success, 'Department', 'Department was successfully created');
        } else {
            yield call(instanceAPI, { method: 'PUT', url: `department/${departmentId}`, data });
            yield call(toastr.success, 'Department', 'Department was successfully updated');
        }
        yield call(history.push, ORGANIZATION_PAGE.LINK({ id }));
    } catch ({ message }) {
        yield call(toastr.error, 'Organization Department Submit Data', message);
        yield put(controller.action.updateCtrl({ errorMessage: message }));
    }
    yield put(controller.action.updateCtrl({ disabled: false }));
}
