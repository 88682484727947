
// outsource dependencies
import _ from 'lodash';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

// local dependencies

// configure

const TextareaCustom = memo(function TextareaCustom (props) {
    const {
        value, name, label, placeholder, icon, onChange, size, message, showSize,
        className, classNameLabel, classNameIcon, classNameInputWrapper, classNameGroup, ...attributes
    } = props;
    const uid = _.kebabCase(`textarea-${name}`);

    const handleChange = useCallback(({ target }) => onChange(target.value), [onChange]);

    return <FormGroup className={classNameGroup}>
        { label ? <Label
            htmlFor={uid}
            className={cx('fw-400', classNameLabel, { 'app-invalid-feedback': message })}
        >
            { label }
        </Label> : null }
        <div className={cx('position-relative mb-1', classNameInputWrapper)}>
            <Input
                id={uid}
                name={name}
                type="textarea"
                maxLength={size}
                onChange={handleChange}
                placeholder={placeholder}
                className={cx(className, { 'pe-5': icon })}
                {...attributes}
                value={value}
            />
            { icon && <Label
                htmlFor={uid}
                className={cx('position-absolute top-50 bottom-50 end-0 d-flex justify-content-center align-items-center mb-0 me-3', classNameIcon)}
            >
                { icon }
            </Label> }
        </div>
        { message ? <Label htmlFor={uid} className="d-inline app-invalid-feedback">
            { message }
        </Label> : null }
        { showSize && value ? <span className="fw-300 fz-12 float-end mb-0">
            { _.size(value) } / { size }
        </span> : null }
    </FormGroup>;
});
TextareaCustom.propTypes = {
    icon: PropTypes.node,
    size: PropTypes.number,
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    showSize: PropTypes.bool,
    message: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    classNameIcon: PropTypes.string,
    classNameLabel: PropTypes.string,
    classNameGroup: PropTypes.string,
    classNameInputWrapper: PropTypes.string,
};
TextareaCustom.defaultProps = {
    classNameInputWrapper: '',
    classNameLabel: '',
    classNameGroup: '',
    classNameIcon: '',
    onChange: e => e,
    name: 'textarea',
    placeholder: '',
    showSize: true,
    className: '',
    message: '',
    icon: null,
    value: '',
    label: '',
    size: 100,
};

export default TextareaCustom;

export const RFTextarea = memo(function RFTextarea ({ skipTouch, input, meta, ...attributes }) {
    const handleChange = useCallback(value => input.onChange(value), [input]);
    let message = '';
    if (skipTouch || meta.touched) {
        message = meta.error;
        attributes.className += meta.valid ? ' is-valid' : ' is-invalid';
    }

    return <TextareaCustom {...input} {...attributes} message={message} onChange={handleChange} />;
});
RFTextarea.propTypes = {
    skipTouch: PropTypes.bool,
    meta: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
};
RFTextarea.defaultProps = {
    skipTouch: false,
};

