
// outsource dependencies
import _ from 'lodash';
import moment from 'moment';
import { reset } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import { create } from 'redux-saga-controller';
import { takeEvery, put, call, select } from 'redux-saga/effects';

// root
import appController from 'controller';

// components
import { fileS3Upload, PromiseAllSettled } from 'components';

// constants
import { history } from 'constants/history';
import { INCIDENT_TYPE } from 'constants/spec';
import { DASHBOARD, REPORT } from 'constants/routes';

// services
import { instanceAPI } from 'services/request';

// local dependencies
import { FORM_NAME } from './index';

// configure
const initializeValues = {
    // summary step
    date: new Date(),
    // select issue category step
    // additional details step
    isUnknown: false,
    unknownIndex: -1,
    offenders: [{ name: '', relation: '' }],
    // result step
    files: [],
};
export const REPORT_STEP_TYPE = {
    SUMMARY: 'SUMMARY',
    SELECT_CATEGORY: 'SELECT_CATEGORY',
    ADDITIONAL_DETAILS: 'ADDITIONAL_DETAILS',
    PREVIEW: 'PREVIEW',
};

export const controller = create({
    prefix: 'report',
    actions: {
        initialize: 'INITIALIZE',
        submitData: 'SUBMIT_DATA',
    },
    initial: {
        disabled: false,
        initialized: false,
        errorMessage: null,

        initialValues: initializeValues,
        stepForm: REPORT_STEP_TYPE.SUMMARY,
    },
    subscriber: function * () {
        yield takeEvery(controller.action.initialize.TYPE, initializeSaga);
        yield takeEvery(controller.action.submitData.TYPE, submitDataSaga);
    }
});

controller.action.resetField = () => reset(FORM_NAME);

export default controller;

function * initializeSaga () {
    // console.log(`%c ${type} `, 'color: #FF6766; font-weight: bolder; font-size: 12px;'
    //   , '\n payload:', payload
    // );
    yield put(controller.action.resetField());
    const { organization } = yield select(appController.select);
    if (_.isEmpty(organization)) {
        yield put(appController.action.getOrganization());
    }
    yield put(controller.action.updateCtrl({ initialized: true }));
}

function * submitDataSaga ({ payload }) {
    yield put(controller.action.updateCtrl({ disabled: true, errorMessage: null }));
    try {
        const { organization, user } = yield select(appController.select);
        const { anonymous } = yield call(REPORT.QUERY);
        const isAnonymous = /^(true|1)$/i.test(anonymous);

        let reportFormData = { ...payload, files: [] };
        let reportIssueFiles = { success: [] };
        if (!_.isEmpty(payload.files)) {
            reportIssueFiles = yield call(uploadAttachmentDocuments, {
                payload: {
                    files: payload.files
                }
            });
        }

        if (_.size(reportIssueFiles.success) > 0) {
            reportFormData = {
                ...reportFormData,
                files: reportIssueFiles.success.map(item => item.fileMeta.fileName)
            };
        }

        yield call(instanceAPI, {
            method: 'POST',
            url: '/incident/create',
            data: {
                ...reportFormData,
                type: INCIDENT_TYPE.ISSUE,
                anonymous: isAnonymous ? 1 : 0,
                organization_id: _.get(organization, 'id'),
                legal_disclaimer: payload.legal_disclaimer ? 1 : 0,
                date: moment(payload.date).format('YYYY/MM/DD'),
                user: !isAnonymous && `${user.first_name} ${user.last_name}`,
            }
        });
        yield call(toastr.success,
            isAnonymous ? 'Report Anonymous' : 'File a Complaint',
            isAnonymous ? 'Your anonymous complaint has been reported successfully' : 'Your complaint has been reported successfully'
        );
        yield call(history.push, DASHBOARD.LINK());
        yield put(controller.action.resetField());
    } catch ({ message }) {
        yield call(toastr.error, 'Report Submit Data', message);
        yield put(controller.action.updateCtrl({ errorMessage: message }));
    }
    yield put(controller.action.updateCtrl({ disabled: false }));
}

function * uploadAttachmentDocuments ({ payload }) {
    const { files } = payload;

    const uploadedDocuments = [];
    const rejectedDocuments = [];

    if (_.isEmpty(files)) {
        return {
            success: [...uploadedDocuments],
            rejected: [...rejectedDocuments]
        };
    }

    const uploadPromises = files.filter(fEntity => !_.isUndefined(fEntity.fileRaw))
        .map((uploadFileItem) => new Promise((resolve, reject) => {
            return instanceAPI({
                method: 'GET',
                url: `incident/upload-link/${uploadFileItem.fileMeta.fileName}`
            }).then(signedUrl => fileS3Upload({
                method: 'PUT',
                file: uploadFileItem.fileRaw,
                url: _.get(signedUrl, 'url')
            }).then((answer) => resolve({
                answer,
                signedUrl: _.get(signedUrl, 'url'),
                url: new URL(_.get(signedUrl, 'url')).origin + new URL(_.get(signedUrl, 'url')).pathname,
                ...uploadFileItem
            })).catch(reject)).catch(reject);
        }));
    // console.log(uploadPromises);
    const uploadedFilesResults = yield call(PromiseAllSettled, uploadPromises);
    // console.log(documentsResults);

    const filteredSucceedDocs = uploadedFilesResults.filter(uploadedItem => _.isEqual(uploadedItem.status, 'fulfilled'));
    const filteredRejectedDocs = uploadedFilesResults.filter(uploadedItem => _.isEqual(uploadedItem.status, 'rejected'));

    uploadedDocuments.push(...filteredSucceedDocs.map(filteredItem => filteredItem.result));
    rejectedDocuments.push(...filteredRejectedDocs);

    return {
        success: [...uploadedDocuments],
        rejected: [...rejectedDocuments]
    };
}
