
// outsource dependencies
import _ from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useController } from 'redux-saga-controller';
import React, { memo, useEffect, useMemo } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';

// root
import Layout from 'pages/layout';

// components
import { BoxLoader } from 'components/preloader';
import Table from 'components/table/table-custom';
import BadgeStatus from 'components/badge/badge-status';

// constants
import * as ROUTES from 'constants/routes';
import { SYSTEM_ROLE_TYPE } from 'constants/spec';
import { environmentConfig } from 'constants/enviroment-config';

// common
import { Restricted } from 'common/permission-provider';

// services
import { FilterService } from 'services/filter.service';

// local dependencies
import controller from './controller';
import LayoutFallback from '../layout-fallback';

// other
// import _graph1 from './_mock/graph-1.jpg';
// import _graph2 from './_mock/graph-2.jpg';
// import _graph3 from './_mock/graph-3.jpg';
import _graph4 from './_mock/graph-4.jpg';
import { UserEngagementChart } from './user-engagement-chart';
import { IssuesStatusesChart } from './issues-statuses-chart';
import { IssuesSuggestionsChart } from './issues-suggestions-chart';
// import { StudentSatisfactionChart } from './student-satisfaction-chart';

// images
import { DefaultImage } from 'images';

// configure
const SHOW_DASHBOARD_CHARTS = false;
const Dashboard = memo(function Dashboard () {
    const [
        { initialized, issuesList, suggestionsList },
        { initialize },
    ] = useController(controller);
    // NOTE initialize business logic
    useEffect(() => { initialize(); }, [initialize]);

    const prepareIssuesList = useMemo(() => _.take(issuesList.map((
        { id, date, organization, categories, status, brief_summary }
    ) => ({
        id,
        status,
        brief_summary,
        organization: _.get(organization, 'name'),
        category: _.get(_.first(categories || []), 'name') || '',
        date: moment(date).format(environmentConfig.DATE_FORMAT),
    })), 6), [issuesList]);

    const prepareSuggestionsList = useMemo(() => _.take(suggestionsList.map((
        { id, date, organization, manual_category, status, description }
    ) => ({
        id,
        status,
        description,
        category: manual_category,
        organization: _.get(organization, 'name'),
        date: moment(date).format(environmentConfig.DATE_FORMAT),
    })), 6), [suggestionsList]);

    return <Restricted
        role={[SYSTEM_ROLE_TYPE.ORGANIZATION_ADMIN, SYSTEM_ROLE_TYPE.PLATFORM_ADMIN]}
        fallback={<>
            <LayoutFallback>
                <BoxLoader id="AppDashboard" active={!initialized}>
                    <Container className="h-100 mt-3">
                        <Row>
                            <Col xs="12" tag="h1" className="fw-700 fz-15 text-secondary text-center mb-4">
                                Welcome to It’s Anonymous!
                            </Col>
                            <Col xs="12" className="mb-3">
                                <Button
                                    tag={Link}
                                    color="primary"
                                    to={ROUTES.SUBMIT_SUGGESTIONS.LINK()}
                                    className="font-family-alternative text-white fz-16 fw-500 text-center text-secondary rounded-5 w-100 mb-2"
                                >
                                    I would like to submit an idea
                                </Button>
                            </Col>
                            <Col xs="12" className="mb-3">
                                <Button
                                    tag={Link}
                                    color="primary"
                                    to={ROUTES.REPORT.LINK()}
                                    className="font-family-alternative text-white fz-16 fw-500 text-center text-secondary rounded-5 w-100 mb-2"
                                >
                                    I would like to file a complaint
                                </Button>
                            </Col>
                            <Col xs="12" className="mb-3">
                                <Button
                                    tag={Link}
                                    color="primary"
                                    to={ROUTES.REPORT.LINK(null, { anonymous: true })}
                                    className="font-family-alternative text-white fz-16 fw-500 text-center text-secondary rounded-5 w-100 mb-1"
                                >
                                    I would like to file a complaint anonymously
                                </Button>
                                {/*<UncontrolledDropdown>
                                    <DropdownToggle className="d-flex bg-transparent border-0 p-1">
                                        <AppIcon icon="info" className="fz-16 text-primary me-2" />
                                        <span className="fz-12 text-start text-gray-600">
                                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem.
                                            Quis autem vel eum iure reprehenderit qui in ea voluptate numquam eius modi tempora
                                            incidunt.
                                        </span>
                                    </DropdownToggle>
                                    <DropdownMenu className="p-0 mt-1">
                                        <DropdownItem tag="p" className="text-wrap w-100 p-2 mb-0">
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Temporibus, voluptate.
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>*/}
                            </Col>
                            <Col xs="12" className="mb-3">
                                <Button
                                    tag={Link}
                                    color="primary"
                                    to={ROUTES.CASES.LINK()}
                                    className="font-family-alternative text-white fz-16 fw-500 text-center text-secondary rounded-5 w-100 mb-2"
                                >
                                    Check My Cases
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </BoxLoader>
            </LayoutFallback>
        </> }
    >
        <Layout>
            <BoxLoader id="AppDashboard" active={!initialized}>
                <Container className="mt-4 mb-5">
                    <Row className="mb-5">
                        {/*<Restricted role={SYSTEM_ROLE_TYPE.ORGANIZATION_ADMIN}>
                            <Col xs="12" className="d-flex justify-content-end p-0 mb-4">
                                <Button color="primary" onClick={handleClick} className="px-4 text-white">
                                    Submit Issue
                                </Button>
                            </Col>
                        </Restricted>*/}
                        <Col xs={SHOW_DASHBOARD_CHARTS ? 7: 12} className="mb-5">
                            <Row className="g-0">
                                <Col tag="h4" xs="12" className="fw-bolder fz-24 text-gray-800 mb-4">
                                    Latest Complaints
                                </Col>
                                <Col xs="12">
                                    <Restricted
                                        role={SYSTEM_ROLE_TYPE.ORGANIZATION_ADMIN}
                                        fallback={<Table link={ROUTES.ISSUES_PAGE.LINK} data={prepareIssuesList}>
                                            <Table.Column width={14} title="Date">{ ({ date }) => date }</Table.Column>
                                            <Table.Column width={46} title="Organization Name">{ ({ organization }) => organization }</Table.Column>
                                            <Table.Column width={24} title="Category">{ ({ category }) => category }</Table.Column>
                                            <Table.Column width={16} title="Status">{ ({ status }) => <BadgeStatus type={status} /> }</Table.Column>
                                        </Table>}
                                    >
                                        <Table link={ROUTES.ISSUES_PAGE.LINK} data={prepareIssuesList}>
                                            <Table.Column width={14} title="Date">{ ({ date }) => date }</Table.Column>
                                            <Table.Column width={46} title="Summary">{ ({ brief_summary }) => brief_summary }</Table.Column>
                                            <Table.Column width={24} title="Category">{ ({ category }) => category }</Table.Column>
                                            <Table.Column width={16} title="Status">{ ({ status }) => <BadgeStatus type={status} /> }</Table.Column>
                                        </Table>
                                    </Restricted>
                                </Col>
                            </Row>
                        </Col>
                        { SHOW_DASHBOARD_CHARTS && <Col xs="5" className="mb-5">
                            <Row className="g-0 flex-column justify-content-between">
                                <Col tag="h4" xs="12" className="fw-bolder fz-24 text-gray-800 mb-4">
                                    Complaints & Ideas for the last 12 months
                                </Col>
                                <Col xs="12" className="mb-4">
                                    <IssuesSuggestionsChart className="bg-white ps-3 pe-3 pb-3" />
                                    {/*<DefaultImage
                                        className="w-100"
                                        defaultAlt="Graph"
                                        defaultSrc={_graph1}
                                        defaultTitle="Graph"
                                        style={{ height: 150 }}
                                    />*/}
                                </Col>
                                <Col tag="h4" xs="12" className="fw-bolder fz-24 text-gray-800 mb-4">
                                    User Engagement on a Platform
                                </Col>
                                <Col xs="12" className="mb-4">
                                    <UserEngagementChart className="bg-white ps-3 pe-3 pb-3" />
                                    {/*<DefaultImage
                                        className="w-100"
                                        defaultAlt="Graph"
                                        defaultSrc={_graph2}
                                        defaultTitle="Graph"
                                        style={{ height: 150 }}
                                    />*/}
                                </Col>
                                {/*
                                <Col tag="h4" xs="12" className="fw-bolder fz-24 text-gray-800 mb-4">
                                    Student Satisfaction on a Platform
                                </Col>
                                <Col xs="12">
                                    <StudentSatisfactionChart className="bg-white ps-3 pe-3 pb-3" />
                                    <DefaultImage
                                        className="w-100"
                                        defaultAlt="Graph"
                                        defaultSrc={_graph2}
                                        defaultTitle="Graph"
                                        style={{ height: 150 }}
                                    />
                                </Col>*/}
                            </Row>
                        </Col> }
                        <Col xs={SHOW_DASHBOARD_CHARTS ? 7: 12} className="mb-5">
                            <Row className="g-0">
                                <Col tag="h4" xs="12" className="fw-bolder fz-24 text-gray-800 mb-4">
                                    Ideas
                                </Col>
                                <Col xs="12">
                                    <Restricted
                                        role={SYSTEM_ROLE_TYPE.ORGANIZATION_ADMIN}
                                        fallback={<Table
                                            data={prepareSuggestionsList}
                                            link={ROUTES.SUGGESTIONS_PAGE.LINK}
                                        >
                                            <Table.Column sort={false} width={15} title="Date">{ ({ date }) => date }</Table.Column>
                                            <Table.Column sort={false} width={45} title="Organization Name">{ ({ organization }) => organization }</Table.Column>
                                            <Table.Column sort={false} width={25} title="Category">{ ({ category }) => category }</Table.Column>
                                            <Table.Column sort={false} width={15} title="Status">{ ({ status }) => <BadgeStatus type={status} /> }</Table.Column>
                                        </Table>}
                                    >
                                        <Table
                                            data={prepareSuggestionsList}
                                            link={ROUTES.SUGGESTIONS_PAGE.LINK}
                                        >
                                            <Table.Column sort={false} width={15} title="Date">{ ({ date }) => date }</Table.Column>
                                            <Table.Column sort={false} width={45} title="Description">{ ({ description }) => FilterService.truncate(description, { length: 90 }) }</Table.Column>
                                            <Table.Column sort={false} width={25} title="Category">{ ({ category }) => FilterService.truncate(category, { length: 48 }) }</Table.Column>
                                            <Table.Column sort={false} width={15} title="Status">{ ({ status }) => <BadgeStatus type={status} /> }</Table.Column>
                                        </Table>
                                    </Restricted>
                                </Col>
                            </Row>
                        </Col>
                        { SHOW_DASHBOARD_CHARTS && <Col xs="5">
                            <Row className="g-0 flex-column justify-content-between">
                                <Col tag="h4" xs="12" className="fw-bolder fz-24 text-gray-800 mb-4">
                                    Complaints Status for the last 90 days
                                </Col>
                                <Col xs="12" className="mb-4">
                                    <IssuesStatusesChart className="bg-white ps-3 pe-3 pb-3" />
                                    {/*<DefaultImage
                                        className="w-100"
                                        defaultAlt="Graph"
                                        defaultSrc={_graph3}
                                        defaultTitle="Graph"
                                        style={{ height: 150 }}
                                    />*/}
                                </Col>
                                <Col tag="h4" xs="12" className="fw-bolder fz-24 text-gray-800 mb-4">
                                    Reported Categories for the last 12 months
                                </Col>
                                <Col xs="12">
                                    <DefaultImage
                                        className="w-100"
                                        defaultAlt="Graph"
                                        defaultSrc={_graph4}
                                        defaultTitle="Graph"
                                        style={{ height: 150 }}
                                    />
                                </Col>
                            </Row>
                        </Col> }
                    </Row>
                </Container>
            </BoxLoader>
        </Layout>
    </Restricted>;
});

export default Dashboard;
