
// outsource dependencies
import { fork } from 'redux-saga/effects';
import { reducer as form } from 'redux-form';
import createSagaMiddleware from 'redux-saga';
import { reducer as toastr } from 'react-redux-toastr';
import { reducer, sagas, path } from 'redux-saga-controller';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';

// local dependencies
import config from 'constants/enviroment-config';

// Build the middleware to run our Saga
const sagaMiddleware = createSagaMiddleware();

// Apply redux extension compose for non production environment
const enchantedCompose = config.PRODUCTION ? compose : (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        name: `> It's Anonymous - ${config.SID} - ${config.VERSION}`,
        trace: true
    })
    : compose);

// Create store outside of root to be able dispatch actions from anywhere!
export const store = createStore(
    combineReducers({
        [path]: reducer,
        toastr,
        form,
    }),
    enchantedCompose(applyMiddleware(sagaMiddleware))
);

// initialize application sagas
sagaMiddleware.run(function * () {
    yield fork(sagas);
});

// Export
export default store;
