
// outsource dependencies
import qs from 'qs';
import _ from 'lodash';
import axios from 'axios';

// local dependencies
import { getMessage, MESSAGE } from './error';
import { AccessTokenStorage } from '../storage';
import config from 'constants/enviroment-config';

const AUTH_HEADER = 'Authorization';
const API_PATH = config.SERVICE_URL || null;
const getAuthHeader = () => `Bearer ${AccessTokenStorage.get()}`;

/**
 * Expected session schema
 * @typedef Session
 * @type {object}
 * @param {number} responseCode
 * @param {string} responseMessage
 */
/**
 * update session in storage
 * @param {Session} [session=null]
 */
const updateStoredSession = session => {
    if (_.get(session, 'accessToken', null)) {
        AccessTokenStorage.set(session.accessToken);
        // RefreshTokenStorage.set(session.refreshToken);
    } else {
        AccessTokenStorage.remove();
        // RefreshTokenStorage.remove();
    }
};
/**
 * provide correct way to restore session
 */
const restoreSessionFromStore = () => !hasStoredSession()
    ? (API.defaults.headers[AUTH_HEADER] = void(0))
    : (API.defaults.headers[AUTH_HEADER] = getAuthHeader());

/**
 * provide correct way to setup authorization session
 *
 * @param {Session} [session=null] to kill session within instanceAPI
 */
const setupSession = session => {
    updateStoredSession(session);
    restoreSessionFromStore();
};
/**
 * common way to know session state
 * @return {Boolean}
 */
const hasStoredSession = () => Boolean(AccessTokenStorage.get());

/**
 * override query serializer to define array Format as API needed
 *
 * @param {Object} options
 * @returns {String}
 */
const paramsSerializer = options => qs.stringify(options, { arrayFormat: 'repeat', encode: false });

/**
 * prepare error
 *
 * @param {Object} error
 * @return {Promise}
 */
const prepareError = error => {
    error = {
    // axiosError: error,
        path: _.get(error, 'config.url', null),
        response: _.get(error, 'response.data', null),
        status: _.get(error, 'response.status', null),
        requestData: _.get(error, 'config.data', null),
        method: _.get(error, 'config.method', 'CODE_NULL'),
        requestParams: _.get(error, 'config.params', null),
        errorCode: _.get(error, 'response.data.errorCode', null),
    };
    if (config.DEBUG || false) {
        console.warn('%c Interceptor: ', 'background: #EC1B24; color: #fff; font-size: 14px;', error);
    }
    const code = error.errorCode || _.get(error, 'status', null);
    const message = getMessage([code], error.response ? _.get(error, 'response.message', 'CODE_NULL') : 'CROSS_DOMAIN_REQUEST');
    return Promise.reject({ ...error.response, message });
};

/**
 * Axios instance prepared for app with authorization
 * contain logic for working with authorization and 401 interceptor
 */
const API = axios.create({
    paramsSerializer,
    baseURL: API_PATH,
    withCredentials: false,
    headers: {
        // 'Cache-Control': 'no-cache', // not allowed by CORS
        'Content-Type': 'application/json',
    },
});

/**
 * Axios instance prepared for app with authorization
 * contain logic for working with authorization and 401 interceptor
 */
const API_PUBLIC = axios.create({
    paramsSerializer,
    baseURL: API_PATH,
    withCredentials: false,
    headers: {
        // 'Cache-Control': 'no-cache', // not allowed by CORS
        'Content-Type': 'application/json',
    },
});

/**
 * setup interceptors
 * sync check to known is user logged in
 * NOTE to known more {@link https://gist.github.com/mkjiau/650013a99c341c9f23ca00ccb213db1c | axios-interceptors-refresh-token}
 */
API.interceptors.response.use(
    response => _.get(response, 'data', null),
    error => ((
        hasStoredSession()
        && error.request.status === 401
        // NOTE support request may get 401
        && !/sign-out|\/oauth\/token/.test(error.config.url)
    ) ? handleRefreshSession(error) : prepareError(error))
);

/**
 * local variables to correctness refreshing session process
 */
let isRefreshing = false, stuckRequests = [];

/**
 * store all requests with 401 refresh session and try send request again
 *
 * @param {Object} error
 * @return {Promise}
 */
const handleRefreshSession = error => {
    const { config } = error;
    if (!isRefreshing) {
        isRefreshing = true;
        API.defaults.headers[AUTH_HEADER] = void(0);
        // TODO: when will be refresh/access token, change this
        signOut();
        // API.post('/auth/token/refresh', { refreshToken: RefreshTokenStorage.get() })
        //     .then(session => {
        //         setupSession(session);
        //         // NOTE resend all
        //         stuckRequests.map(({ config, resolve, reject }) => {
        //             // NOTE setup new authentication header in old request config
        //             config.headers[AUTH_HEADER] = getAuthHeader();
        //             API(config).then(resolve).catch(reject);
        //             // NOTE "array-callback-return"
        //             return null;
        //         });
        //         // NOTE start new stuck
        //         stuckRequests = [];
        //         isRefreshing = false;
        //     })
        //     .catch(() => {
        //         // NOTE reject all
        //         stuckRequests.map(({ error, reject }) => reject(error));
        //         // NOTE provide ability to handle this situation
        //         authFail(error);
        //         // NOTE start new stuck
        //         stuckRequests = [];
        //         isRefreshing = false;
        //     });
    }
    // NOTE determine first trying to restore session
    if (!config.wasTryingToRestore) {
        return new Promise((resolve, reject) => {
            config.wasTryingToRestore = true;
            stuckRequests.push({ config, error, resolve, reject });
        });
    }
    return prepareError(error);
};
/*******************************************************
 *              Predefined calls
 *******************************************************/
/**
 * Way to notify app about unexpected losing of session
 * @param fn
 * @return {*}
 */
const onAuthFailApplicationAction = fn => authFail = fn;
let authFail = error => console.warn('authorization is fail. Expected to override this action');
/**
 * checking API health state
 */
const checkAPIHealth = () => instanceAPI({ url: '/actuator/health', method: 'GET' });

/**
 * Sign In flow using regular email
 */
const signIn = ({ email, password, organization_id }) => instanceAPI({
    method: 'POST',
    url: '/auth/login',
    data: { email, password, organization_id },
}).then(({ token, user }) => {
    // TODO: when will be refresh/access token, change this
    setupSession({ accessToken: token });
    return user;
});
/**
 * Sign Out flow
 */
const signOut = () => instanceAPI({ method: 'POST', url: '/auth/logout' }).then(() => setupSession(null));
/**
 * Get organization flow
 */
const getOrganization = keyword => instanceAPI({
    method: 'GET',
    params: { keyword },
    url: 'organization/keyword/search',
}).then(response => {
    if (response) {
        return response;
    }
    signOut();
    throw new Error('Not exist this organization');

});
const instanceAPI = API;
const publicAPI = API_PUBLIC;
const ERROR_MESSAGE = MESSAGE;
// NOTE named export only after all prepare thing
export {
    signIn,
    signOut,
    publicAPI,
    instanceAPI,
    setupSession,
    ERROR_MESSAGE,
    checkAPIHealth,
    getOrganization,
    restoreSessionFromStore,
    onAuthFailApplicationAction,
};
