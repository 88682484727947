
// outsource dependencies
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// NOTE import icons from correct package
import {} from '@fortawesome/free-brands-svg-icons';
import { faTrash, faAngleLeft, faAngleUp, faAngleDown, faDownload, faArrowLeft, faAngleRight, faPaperclip, faTimes, faPen, faPlus, faEyeSlash, faEye, faFolderOpen, faEllipsisV } from '@fortawesome/free-solid-svg-icons';

// outsource dependencies
import * as appIcons from 'components/icon/app-svg-icons';

// NOTE add your icon to library before usage
library.add(
    faTrash, faEllipsisV, faAngleLeft, faFolderOpen, faEye, faEyeSlash, faAngleUp,
    faAngleDown, faAngleRight, faDownload, faArrowLeft, faPaperclip, faTimes, faPen, faPlus
);

// NOTE custom icons which we inject in font awesome
library.add({ ...appIcons });

export default FontAwesomeIcon;

export const FasIcon = memo(({ icon, ...attr }) => <FontAwesomeIcon icon={['fas', icon]} {...attr} />);
FasIcon.propTypes = {
    icon: PropTypes.string.isRequired,
};

export const FarIcon = memo(({ icon, ...attr }) => <FontAwesomeIcon icon={['far', icon]} {...attr} />);
FarIcon.propTypes = {
    icon: PropTypes.string.isRequired,
};

export const FabIcon = memo(({ icon, ...attr }) => <FontAwesomeIcon icon={['fab', icon]} {...attr} />);
FabIcon.propTypes = {
    icon: PropTypes.string.isRequired,
};

export const AppIcon = memo(({ icon, ...attr }) => <FontAwesomeIcon icon={['app', icon]} {...attr} />);
AppIcon.propTypes = {
    icon: PropTypes.string.isRequired,
};

export const SortIcon = memo(function SortIcon ({ className, faPrefix, classMap, statusMap, status, ...attr }) {
    return <FontAwesomeIcon
        {...attr}
        icon={[faPrefix, statusMap(Boolean(status), attr)]}
        className={cx(className, classMap(Boolean(status), attr))}
    />;
});
SortIcon.propTypes = {
    status: PropTypes.any,
    classMap: PropTypes.func,
    statusMap: PropTypes.func,
    faPrefix: PropTypes.string,
    className: PropTypes.string,
};
SortIcon.defaultProps = {
    status: null,
    faPrefix: 'fas',
    className: '',
    statusMap: status => {
        switch (status) {
            default: return 'angle-up';
            case true: return 'angle-up';
            case false: return 'angle-down';
        }
    },
    classMap: (status, { disabled }) => {
        switch (status) {
            default: return `ml-1 mr-1 text-thin ${disabled ? 'text-muted' : 'text-gray-700'}`;
            case true: return `ml-1 mr-1 text-bold ${disabled ? 'text-muted' : 'text-gray-700'}`;
            case false: return `ml-1 mr-1 text-bold ${disabled ? 'text-muted' : 'text-gray-700'}`;
        }
    }
};
