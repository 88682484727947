
// outsource dependencies
import _ from 'lodash';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Input, Label } from 'reactstrap';
import React, { memo, useCallback } from 'react';

// local dependencies

// configure

export const CheckboxCustom = memo(function CheckboxCustom (props) {
    const {
        type, value, name, label, onChange, className, classNameLabel, classNameGroup, message, ...attributes
    } = props;
    const uid = _.kebabCase(`${type}-${name}`);
    const handleChange = useCallback(({ target }) => onChange(target.checked), [onChange]);

    return <div className={classNameGroup}>
        <Input
            id={uid}
            type={type}
            name={name}
            onChange={handleChange}
            className={cx('border-primary me-2', className)}
            {...attributes}
            checked={Boolean(value)}
        />
        { label ? <Label
            check
            htmlFor={uid}
            className={cx('fw-400 mb-0', classNameLabel, { 'app-invalid-feedback': message })}
        >
            { label }
        </Label> : null }
    </div>;
});
CheckboxCustom.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    message: PropTypes.string,
    className: PropTypes.string,
    classNameLabel: PropTypes.string,
    classNameGroup: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
};
CheckboxCustom.defaultProps = {
    name: 'checkboxCustom',
    classNameLabel: '',
    classNameGroup: '',
    type: 'checkbox',
    onChange: e => e,
    className: '',
    value: false,
    message: '',
    label: '',
};

export default CheckboxCustom;

export const RFCheckbox = memo(function RFCheckbox ({ skipTouch, input, meta, ...attributes }) {
    const handleChange = useCallback(value => input.onChange(value), [input]);
    let message = '';
    if (skipTouch || (meta.touched && meta.error)) {
        message = meta.error;
        attributes.className += meta.valid ? ' is-valid' : ' is-invalid';
    }

    return <CheckboxCustom {...input} {...attributes} message={message} onChange={handleChange} />;
});
RFCheckbox.propTypes = {
    skipTouch: PropTypes.bool,
    meta: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
};
RFCheckbox.defaultProps = {
    skipTouch: false,
};

