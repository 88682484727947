
// outsource dependencies
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Field, getFormValues } from 'redux-form';
import React, { forwardRef, memo, useCallback, useState } from 'react';
import { Button, Card, CardBody, Col, Collapse, Row } from 'reactstrap';
import { useControllerActions, useControllerData } from 'redux-saga-controller';

// components
import { RFDatePicker, InputCustom, RFInput, ReduxForm, RFSelect, AppIcon, FasIcon, SortIcon } from 'components';

// constants
import config from 'constants/enviroment-config';
import { STATUS_USER_TYPE } from 'constants/spec';

// services
import { FilterService } from 'services/filter.service';

// local dependencies
import controller from './controller';

// configure
export const FORM_NAME = 'organizationFilterForm';
const statusOptions = Object.values(STATUS_USER_TYPE).map(status => ({
    value: status, label: FilterService.humanize(status)
}));

const Filter = memo(function Filter () {
    const { initialFilter, disabled } = useControllerData(controller);
    const { updateData, resetForm, resetField } = useControllerActions(controller);

    const {
        name, date_from, status, date_to, users_count, issues_count, suggestions_count
    } = useSelector(getFormValues(FORM_NAME)) || {};

    const [isOpenFilter, setOpenFilter] = useState(false);

    const handleToggleFilter = useCallback(() => setOpenFilter(isOpenFilter => !isOpenFilter), []);
    const handleSubmitSearch = useCallback(() => {
        updateData({ page: 1 });
        handleToggleFilter();
    }, [handleToggleFilter, updateData]);
    const handleResetField = useCallback(({ field }) => {
        resetField({ field });
        updateData({ page: 1 });
    }, [updateData, resetField]);
    const handleResetForm = useCallback(() => {
        resetForm();
        updateData({ page: 1 });
        handleToggleFilter();
    }, [resetForm, updateData, handleToggleFilter]);

    const DatePickerInput = forwardRef(({ value, onClick }, ref) => <InputCustom
        icon={<AppIcon icon="calendar" className="fz-18 text-gray-300" />}
        placeholder="MM/DD/YYYY"
        onClick={onClick}
        innerRef={ref}
        value={value}
    />);

    return <ReduxForm
        form={FORM_NAME}
        enableReinitialize
        initialValues={initialFilter}
        onSubmit={handleSubmitSearch}
    >
        <Row className="position-relative" style={{ zIndex: 2 }}>
            <Col xs="12" className="d-flex align-items-center">
                <Button
                    onClick={handleToggleFilter}
                    className="d-flex align-items-center justify-content-center fz-18 text-gray-700 bg-transparent border-0 rounded-3 p-2 me-4"
                >
                    <span className="fw-600 me-4">Search</span>
                    <SortIcon status={isOpenFilter} className="fz-18" />
                </Button>
                <div className="d-flex flex-wrap">
                    { name ? <SearchAttribute
                        search={name}
                        name="Organization Name"
                        onChange={() => handleResetField({ field: 'name' })}
                    /> : null }
                    { date_from ? <SearchAttribute
                        name="Date from"
                        search={moment(date_from).format(config.DATE_FORMAT)}
                        onChange={() => handleResetField({ field: 'date_from' })}
                    /> : null }
                    { date_to ? <SearchAttribute
                        name="Date to"
                        search={moment(date_to).format(config.DATE_FORMAT)}
                        onChange={() => handleResetField({ field: 'date_to' })}
                    /> : null }
                    { issues_count ? <SearchAttribute
                        name="Complaints"
                        search={issues_count}
                        onChange={() => handleResetField({ field: 'issues_count' })}
                    /> : null }
                    { suggestions_count ? <SearchAttribute
                        name="Ideas"
                        search={suggestions_count}
                        onChange={() => handleResetField({ field: 'suggestions_count' })}
                    /> : null }
                    { users_count ? <SearchAttribute
                        name="Users"
                        search={users_count}
                        onChange={() => handleResetField({ field: 'users_count' })}
                    /> : null }
                    { status ? <SearchAttribute
                        name="Status"
                        search={status.label}
                        onChange={() => handleResetField({ field: 'status' })}
                    /> : null }
                </div>
            </Col>
            <Col tag={Collapse} isOpen={isOpenFilter} className="position-absolute top-100 mt-2">
                <Card className="shadow border-0" style={{ width: '45%' }}>
                    <CardBody>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <Button
                                color="transparent"
                                onClick={handleResetForm}
                                className="d-flex align-items-center justify-content-center font-family-alternative fz-14 text-secondary rounded-3 px-1"
                            >
                                <AppIcon icon="trash-filter" className="fz-18 me-3" />
                                Clear all
                            </Button>
                            <Button
                                color="transparent"
                                onClick={handleToggleFilter}
                                className="d-flex align-items-center justify-content-center fz-18 text-white rounded-3 p-1"
                            >
                                <FasIcon icon="times" className="text-gray-700" />
                            </Button>
                        </div>
                        <Field
                            type="text"
                            name="name"
                            component={RFInput}
                            disabled={disabled}
                            classNameLabel="w-50 m-0"
                            label="Organization Name"
                            classNameInputWrapper="w-50"
                            placeholder="Organization Name"
                            classNameGroup="d-flex align-items-center mb-2"
                        />
                        <Field
                            name="date_from"
                            label="Date From"
                            disabled={disabled}
                            component={RFDatePicker}
                            customInput={<DatePickerInput />}
                            classNameLabel="flex-shrink-0 w-50 m-0"
                            classNameGroup="d-flex align-items-center mb-2"
                        />
                        <Field
                            name="date_to"
                            label="Date To"
                            minDate={date_from}
                            disabled={disabled}
                            component={RFDatePicker}
                            customInput={<DatePickerInput />}
                            classNameLabel="flex-shrink-0 w-50 m-0"
                            classNameGroup="d-flex align-items-center mb-2"
                        />
                        <Field
                            type="number"
                            label="Complaints"
                            name="issues_count"
                            component={RFInput}
                            disabled={disabled}
                            placeholder="Complaints"
                            classNameLabel="w-50 m-0"
                            classNameInputWrapper="w-50"
                            classNameGroup="d-flex align-items-center mb-2"
                        />
                        <Field
                            type="number"
                            label="Ideas"
                            component={RFInput}
                            disabled={disabled}
                            name="suggestions_count"
                            placeholder="Ideas"
                            classNameLabel="w-50 m-0"
                            classNameInputWrapper="w-50"
                            classNameGroup="d-flex align-items-center mb-2"
                        />
                        <Field
                            type="number"
                            label="Users"
                            name="users_count"
                            placeholder="Users"
                            component={RFInput}
                            disabled={disabled}
                            classNameLabel="w-50 m-0"
                            classNameInputWrapper="w-50"
                            classNameGroup="d-flex align-items-center mb-2"
                        />
                        <Field
                            isSimple
                            name="status"
                            label="Status"
                            disabled={disabled}
                            component={RFSelect}
                            placeholder="Status"
                            options={statusOptions}
                            classNameLabel="flex-shrink-0 w-50 m-0"
                            classNameGroup="d-flex align-items-center mb-2"
                            styles={{
                                container: base => ({
                                    ...base,
                                    width: '50%'
                                }),
                            }}
                        />
                        <Button
                            type="submit"
                            color="primary"
                            disabled={disabled}
                            className="text-white w-100"
                        >
                            Search
                        </Button>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </ReduxForm>;
});

export default Filter;

const SearchAttribute = memo(function SearchAttribute ({ name, search, onChange }) {
    return <p className="d-flex align-items-center text-gray-500 mb-0 me-2">
        { name }
        :&nbsp;
        <span className="text-gray text-gray-700 mb-0 me-1">
            { search }
        </span>
        <Button
            onClick={onChange}
            color="transparent"
            className="d-flex align-items-center justify-content-center fz-12 text-white rounded-3 p-1"
        >
            <FasIcon icon="times" className="text-gray-500" />
        </Button>
    </p>;
});
SearchAttribute.propTypes = {
    name: PropTypes.string,
    search: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};
SearchAttribute.defaultProps = {
    search: '',
    name: '',
};
