
// outsource dependencies
import _ from 'lodash';
import moment from 'moment';
import { Col, Container, Row } from 'reactstrap';
import { useController } from 'redux-saga-controller';
import React, { memo, useCallback, useEffect, useMemo } from 'react';

// root
import Layout from 'pages/layout';

// components
import { Pagination, BoxLoader, Table, BadgeStatus } from 'components';

// constants
import * as ROUTES from 'constants/routes';
import environment from 'constants/enviroment-config';
import { ANONYMOUS, SYSTEM_ROLE_TYPE } from 'constants/spec';

// common
import { Restricted } from 'common/permission-provider';

// services
import { FilterService } from 'services/filter.service';

// local dependencies
import Filter from './filter';
import controller from './controller';

// configure

const IssuesList = memo(function IssuesList () {
    const [
        { initialized, disabled, list, totalItems, sizePage, page, sortF, sortD },
        { initialize, updateData },
    ] = useController(controller);
    // NOTE initialize business logic
    useEffect(() => { initialize(); }, [initialize]);

    const handleChangePage = useCallback(page => updateData({ page }), [updateData]);
    const handleChangeSort = useCallback(({ sortF, sortD }) => updateData({ page: 1, sortF, sortD }), [updateData]);

    const prepareList = useMemo(() => (list || []).map(item => ({
        id: _.get(item, 'id'),
        status: _.get(item, 'status', ''),
        severity: _.get(item, 'severity', ''),
        assigned: _.get(item, 'assigned_user', ''),
        department: _.get(item, 'department.name', ''),
        organization: _.get(item, 'organization.name', ''),
        category: _.get(_.first(item.categories), 'name', ''),
        date: moment(_.get(item, 'date', ''), 'YYYY-MM-DD').format(environment.DATE_FORMAT),
        name: _.get(item, 'anonymous') && _.isEmpty(_.get(item, 'user'))
            ? FilterService.humanize(ANONYMOUS)
            : !_.isEmpty(_.get(item, 'user'))
                ? `${_.get(item, 'user.first_name', '')} ${_.get(item, 'user.last_name', '')}`
                : 'Not specified'
    })), [list]);

    const getCategory = useCallback(({ category }) => FilterService.truncate(category, { length: 40 }), []);

    return <Layout>
        <Container id="AppIssueList" className="mt-4 mb-5 h-100">
            <BoxLoader active={!initialized}>
                <Row className="g-0">
                    <Col tag="h4" xs="12" className="fw-bolder fz-24 text-gray-800 mb-4">
                        Complaints
                    </Col>
                    <Col xs="12" className="bg-white p-3">
                        <Filter />
                    </Col>
                    <Col xs="12">
                        <Restricted
                            role={SYSTEM_ROLE_TYPE.ORGANIZATION_ADMIN}
                            fallback={<Table
                                sortD={sortD}
                                sortF={sortF}
                                data={prepareList}
                                disabled={disabled}
                                handleSort={handleChangeSort}
                                link={ROUTES.ISSUES_PAGE.LINK}
                            >
                                <Table.Column width={8} sortName="sort_case" title="ID #">{ ({ id }) => id }</Table.Column>
                                <Table.Column width={10} sortName="sort_date" title="Date">{ ({ date }) => date }</Table.Column>
                                <Table.Column width={12} sortName="sort_originator_name" title="Originator">{ ({ name }) => name }</Table.Column>
                                <Table.Column width={15} sortName="sort_organization" title="Organization">{ ({ organization }) => organization }</Table.Column>
                                <Table.Column width={15} sortName="sort_department" title="Department">{ ({ department }) => department }</Table.Column>
                                <Table.Column width={15} sortName="sort_category" title="Category">{ getCategory }</Table.Column>
                                <Table.Column width={10} sortName="sort_status" title="Status">{ ({ status }) => <BadgeStatus type={status} /> }</Table.Column>
                                {/*<Table.Column width={9} sortName="sort_severity" title="Severity">{ ({ severity }) => <BadgeSeverity type={severity} /> }</Table.Column>*/}
                                <Table.Column width={15} sortName="sort_assigned_to" title="Referred to">{ ({ assigned }) => assigned }</Table.Column>
                            </Table>}
                        >
                            <Table
                                sortD={sortD}
                                sortF={sortF}
                                data={prepareList}
                                disabled={disabled}
                                handleSort={handleChangeSort}
                                link={ROUTES.ISSUES_PAGE.LINK}
                            >
                                <Table.Column width={8} sortName="sort_case" title="ID #">{ ({ id }) => id }</Table.Column>
                                <Table.Column width={12} sortName="sort_date" title="Date">{ ({ date }) => date }</Table.Column>
                                <Table.Column width={15} sortName="sort_originator_name" title="Originator">{ ({ name }) => name }</Table.Column>
                                <Table.Column width={20} sortName="sort_department" title="Department">{ ({ department }) => department }</Table.Column>
                                <Table.Column width={15} sortName="sort_category" title="Category">{ getCategory }</Table.Column>
                                <Table.Column width={12} sortName="sort_status" title="Status">{ ({ status }) => <BadgeStatus type={status} /> }</Table.Column>
                                {/*<Table.Column width={10} sortName="sort_severity" title="Severity">{ ({ severity }) => <BadgeSeverity type={severity} /> }</Table.Column>*/}
                                <Table.Column width={18} sortName="sort_assigned_to" title="Referred to">{ ({ assigned }) => assigned }</Table.Column>
                            </Table>
                        </Restricted>
                    </Col>
                    <Col xs="12" className="d-flex justify-content-between bg-white p-4">
                        <Pagination
                            currentPage={page}
                            sizePage={sizePage}
                            disabled={disabled}
                            totalItems={totalItems}
                            onChange={handleChangePage}
                        />
                        {/*<div>
                            <Button disabled={disabled} outline color="secondary" onClick={handleExportClick} className="px-4">
                                <FasIcon icon="download" className="me-2" />
                                Export
                            </Button>
                        </div>*/}
                    </Col>
                </Row>
            </BoxLoader>
        </Container>
    </Layout>;
});

export default IssuesList;
