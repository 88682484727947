
export const NEW = 'NEW';
export const MAIN = 'MAIN';

export const headerHeight = 88;

export const FULL_HEIGHT_SCREEN = window.innerHeight - 1;

export const FREE_HEIGHT = (height => {
    return height - headerHeight;
})(window.innerHeight);

export const ANONYMOUS = 'Anonymous';

export const SYSTEM_ROLE_TYPE = {
    ORGANIZATION_ADMIN: 'ORGANIZATION_ADMIN',
    // ORGANIZATION_USER: 'ORGANIZATION_USER', //reserved
    // DEPARTMENT_ADMIN: 'DEPARTMENT_ADMIN',  //reserved
    PLATFORM_ADMIN: 'PLATFORM_ADMIN',
    USER: 'USER',
};

export const INCIDENT_TYPE = {
    SUGGESTION: 'suggestion',
    ISSUE: 'issue',
    ALL: 'all',
};

export const STATUS_TYPE = {
    NEW: 'new',
    REVIEWED: 'reviewed',
    APPROVED: 'approved',
    ADDRESSED: 'addressed',
};

export const STATUS_USER_TYPE = {
    DISABLED: 'disabled',
    ACTIVE: 'active',
};

export const IMG_DIR = {
    EMPTY: '',
};

export const ANALYTICS_CHART_KEYS = {
    COMPLAINTS_STATS: 'complaints-stats',
    CATEGORY_ANALYSIS: 'category-analysis',
    INDUSTRY_COMPARISON: 'industry-comparison',
    SATISFACTION_FEEDBACK: 'satisfaction-feedback',
    DEPARTMENT_USERS: 'department-users',
    USERS_ENGAGEMENT: 'users-engagement',
};

export const ACCEPTED_FILE_TYPES = {
    IMAGES: ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'],
    VIDEOS: ['video/mp4', 'video/quicktime'],
};

export const severityOptions = [
    { value: '1', label: 'Low' },
    { value: '2', label: 'Middle' },
    { value: '3', label: 'High' },
];

export const SEVERITY = {
    1: 'Low',
    2: 'Middle',
    3: 'High',
};
