
// outsource dependencies
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { create } from 'redux-saga-controller';
import { call, put, select, takeEvery } from 'redux-saga/effects';

// root
import appRootController from 'controller';

// constants
import { SIGN_IN, WELCOME } from 'constants/routes';

// services
import { instanceAPI } from 'services';

export const controller = create({
    prefix: 'survey',
    actions: {
        initialize: 'INITIALIZE',
        submitData: 'SUBMIT_DATA',
    },
    initial: {
        disabled: false,
        initialized: false,
        errorMessage: null,

        survey: {},
        surveyId: null,
        initialValues: { score: 7 },
    },
    subscriber: function * () {
        yield takeEvery(controller.action.initialize.TYPE, initializeSaga);
        yield takeEvery(controller.action.submitData.TYPE, submitDataSaga);
    }
});

export default controller;

function * initializeSaga ({ payload }) {
    // console.log(`%c ${type} `, 'color: #FF6766; font-weight: bolder; font-size: 12px;'
    //   , '\n payload:', payload
    // );
    const surveyId = _.get(payload, 'surveyId');
    yield put(controller.action.updateCtrl({ surveyId }));
    try {
        const { data } = yield call(instanceAPI, { method: 'GET', params: { token: surveyId }, url: 'user-survey/organization' });
        if (_.isEmpty(data)) {
            throw new Error('Survey is empty');
        }
        yield put(controller.action.updateCtrl({ survey: data }));
    } catch (e) {
        const { organization } = yield select(appRootController.select);
        if (_.isEmpty(organization)) {
            yield call(WELCOME.REPLACE);
        } else {
            yield call(SIGN_IN.REPLACE, { organization: _.get(organization, 'keyword', '') });
        }
    }
    yield put(controller.action.updateCtrl({ initialized: true }));
}

function * submitDataSaga ({ payload }) {
    yield put(controller.action.updateCtrl({ disabled: true, errorMessage: null }));
    const { surveyId } = yield select(controller.select);
    try {
        yield call(instanceAPI, {
            method: 'PUT',
            url: 'user-survey/answer',
            data: { token: surveyId, rate: _.get(payload, 'score') },
        });
        yield call(toastr.success, 'Answer', 'Answer was successfully sent');
    } catch ({ message }) {
        yield call(toastr.error, 'Answer', message);
    }
    yield put(controller.action.updateCtrl({ disabled: false }));
}
