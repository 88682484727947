// outsource dependencies
import _ from 'lodash';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useControllerData } from 'redux-saga-controller';


// local dependencies
import controller from './controller';

// configure
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);
const chartGridStyles = {
    color: '#e9e9e9',
    tickColor: '#929292',
    borderColor: '#929292',
    borderWidth: 1,
    tickWidth: 1,
    tickLength: 8,
};
const chartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    animation: {
        easing: 'easeInOutQuad',
        duration: 520
    },
    plugins: {
        legend: {
            position: 'top',
            display: true
        },
        title: {
            display: false,
            text: 'Last 12 months',
            align: 'end',
            font: {
                family: 'Roboto',
                weight: 'bold',
                style: 'normal',
                size: 14
            },
        },
        tooltip: {
            backgroundColor: '#f8fcfe',
            borderColor: '#e4ebef',
            // borderColor: 'rgba(138, 159, 174, 0.1)',
            borderWidth: 1,
            titleColor: '#565656',
            bodyColor: '#565656',
            footerColor: '#565656',
            cornerRadius: 10,
            displayColors: false,
            boxPadding: 10,
            footerMarginTop: 3,
            padding: {
                top: 15,
                right: 18,
                bottom: 15,
                left: 18,
            },
            titleFont: {
                size: 14,
                weight: '500',
                family: '"Helvetica Neue", "Helvetica", "Arial", sans-serif'
            },
            bodyFont: {
                size: 14,
                weight: '400',
                family: 'Roboto, "Helvetica Neue", "Helvetica", "Arial", sans-serif'
            },
            footerFont: {
                size: 14,
                weight: '400',
                family: 'Roboto, "Helvetica Neue", "Helvetica", "Arial", sans-serif'
            },
            callbacks: {
                /*title: (context) => {
                    const parsed = moment.localeData().monthsParse(_.get(_.first(context), 'label', 0)) + 1;
                    return moment(parsed, 'M').format('MMMM');
                },*/
                footer: (tooltipItems) => {
                    let counter = 0;
                    tooltipItems.forEach((tooltipItem) => {
                        counter += tooltipItem.raw.count;
                    });
                    return `Users: ${counter}`;
                }
            }
        }
    },
    scales: {
        x: {
            grid: { ...chartGridStyles }
        },
        y: {
            beginAtZero: true,
            // min: 1,
            max: 10,
            // max: 10,
            // suggestedMin: 0,
            // suggestedMax: 10,
            stacked: false,
            grid: { ...chartGridStyles },
            ticks: {
                // forces step size to be 1000 units
                stepSize: 2
            }
        }
    },
    elements: {
        bar: {
            borderRadius: 2,
            inflateAmount: 'auto',
            // pointStyle: 'rectRounded'
        }
    },
    // point: {
    //     backgroundColor: 'white'
    // },
    /*elements: {
        line: {
            tension: 0.4
        }
    },
    point: {
        backgroundColor: 'white'
    },
    tooltips: {
        titleFontFamily: 'Open Sans',
        backgroundColor: 'rgba(0,0,0,0.3)',
        titleFontColor: 'red',
        caretSize: 5,
        cornerRadius: 2,
        xPadding: 10,
        yPadding: 10
    }*/
};
const getChartLabels = (analyticsData) => {
    const remapped = _.map(analyticsData, (dataItem) => ({ ...dataItem, month: moment(dataItem.month, 'YYYY-MM') }));
    const reordered = remapped.sort((a, b) => a.valueOf() - b.valueOf());
    return reordered.map(aData => {
        const momentMonthIndex = moment(aData.month, 'YYYY-MM').month();
        const monthsName = `${moment().month(momentMonthIndex).format('MMMM') } ${ moment(aData.month).year()}`;
        return monthsName;
    });
};
export const SatisfactionFeedbackChart = memo(function SatisfactionFeedbackChart ({ className }) {
    const chartRef = useRef(null);
    const { analyticsData } = useControllerData(controller);
    const [chartData, setChartData] = useState({
        datasets: []
    });

    const preformatChartData = useMemo(() => {
        const labels = getChartLabels(analyticsData);
        return {
            labels,
            datasets: [
                {
                    label: 'Average',
                    // data: [4.8, 6.15, 6.8, 7.95, 8.5, 7.8, 7.7, 7.5, 6.2, 6.6, 8.6, 8.8],
                    data: analyticsData.map((dI, i) => ({ ...dI, x: labels[i] }))
                }
            ]
        };
    }, [analyticsData]);

    useEffect(() => {
        const chart = chartRef.current;

        if (!chart) {
            return;
        }

        const chartData = {
            ...preformatChartData,
            datasets: preformatChartData.datasets.map((datasetItem) => ({
                ...datasetItem,
                tension: 0.4,
                fill: true,
                borderColor: 'rgba(167, 218, 255, 1)',
                backgroundColor: 'rgba(167, 218, 255, 1)',
                borderWidth: 1,
                pointBorderWidth: 1,
                pointBorderColor: 'rgba(6, 115, 193, 1)',
                pointBackgroundColor: 'rgba(6, 115, 193, 1)',
                pointHoverRadius: 3,
                pointHoverBorderWidth: 1,
                parsing: {
                    yAxisKey: 'avg_rate'
                }
            }))
        };

        setChartData(chartData);
    }, [preformatChartData]);

    return <div className={cx('', className)}>
        <Bar ref={chartRef} options={chartOptions} data={chartData} />
    </div>;
});
SatisfactionFeedbackChart.propTypes = {
    className: PropTypes.string,
};

SatisfactionFeedbackChart.defaultProps = {
    className: '',
};
