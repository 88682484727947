
// outsource dependencies
import _ from 'lodash';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { Button, Pagination as PSPagination, PaginationItem, PaginationLink } from 'reactstrap';

// local dependencies

// configure

export const Pagination = memo(function Pagination ({ disabled, sizePage, totalItems, currentPage, onChange }) {
    const pagesAmount = Math.ceil(totalItems / sizePage);

    const pages = useMemo(() => {
        let page = 0;
        const pages = [];
        const middle = Math.ceil(pagesAmount / 2);

        while (page < pagesAmount) {
            page++;

            if (page === 1) {
                pages.push(page);
            } else if (page === pagesAmount) {
                pages.push(page);
            } else if (page >= middle - 3 && page <= middle + 3) {
                if (page >= middle - 2 && page <= middle + 2) {
                    pages.push(page);
                } else {
                    pages.push('...');
                }
            }
        }

        return pages.map(page => ({
            page,
            isActive: page === currentPage,
            handleChange: (_.isString(page) || page === currentPage) ? null : () => onChange(page),
        }));
    }, [currentPage, onChange, pagesAmount]);

    const handlePrevPageChange = useCallback(() => onChange(currentPage - 1), [currentPage, onChange]);
    const handleNextPageChange = useCallback(() => onChange(currentPage + 1), [currentPage, onChange]);

    return <PSPagination>
        <PaginationItem>
            <PaginationLink
                outline
                previous
                tag={Button}
                color="gray-500"
                onClick={handlePrevPageChange}
                disabled={disabled || currentPage <= 1}
                className="text-gray-500 rounded-3 py-2 px-3 me-2"
            />
        </PaginationItem>
        { pages.map(({ page, isActive, handleChange }, index) => <PaginationItem key={index} className="me-2">
            <PaginationLink
                outline
                tag={Button}
                disabled={disabled}
                onClick={handleChange}
                color={isActive ? 'primary' : 'gray-500'}
                className={cx('rounded-3 py-2 px-3', isActive ? 'text-secondary border-primary' : 'text-gray-500 border-gray-500')}
            >
                { page }
            </PaginationLink>
        </PaginationItem>) }
        <PaginationItem>
            <PaginationLink
                next
                outline
                tag={Button}
                color="gray-500"
                onClick={handleNextPageChange}
                className="text-gray-500 rounded-3 py-2 px-3"
                disabled={disabled || currentPage >= pagesAmount}
            />
        </PaginationItem>
    </PSPagination>;
});
Pagination.propTypes = {
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    sizePage: PropTypes.number.isRequired,
    totalItems: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
};
Pagination.defaultProps = {
    disabled: false,
};

export default Pagination;
