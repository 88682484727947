// outsource dependencies
import _ from 'lodash';
import { Field, FieldArray } from 'redux-form';
import { useControllerData } from 'redux-saga-controller';
import React, { forwardRef, memo, useCallback, useMemo } from 'react';
import { Col, Input, InputGroup, InputGroupText, Row } from 'reactstrap';

// components
import TextPreview from 'components/text-preview';
import {
    AppIcon,
    RFInput,
    RFTextarea,
    RFCheckbox,
    getOptionId,
    RFDatePicker,
    getOptionName,
    RFAsyncSelect,
    InputDropAreaFileList,
} from 'components';

// constants
import { environmentConfig } from 'constants/enviroment-config';

// services
import { instanceAPI } from 'services';

// local dependencies
import controller from './controller';

const IssueForm = memo(function IssuePage () {
    const { disabled } = useControllerData(controller);

    const loadCategories = useCallback(() => instanceAPI({
        method: 'GET',
        url: 'incident/category',
    }).then(response => _.sortBy(response, 'priority')), []);

    return <Col xs="12" className="mb-3">
        <Row>
            <Col>
                <Field
                    disabled
                    name="brief_summary"
                    label="Brief Summary"
                    classNameGroup="mb-3"
                    component={TextPreview}
                />
                <Field
                    disabled
                    label="Date"
                    name="date"
                    classNameGroup="mb-2"
                    component={RFDatePicker}
                    customInput={<DatePickerInput />}
                    dateFormat={environmentConfig.DATE_FORMAT}
                />
                <Field
                    name="description"
                    label="Description"
                    classNameGroup="mb-3"
                    component={TextPreview}
                />
                <Field
                    isMulti
                    isSimple
                    disabled
                    isSearchable
                    cacheOptions
                    defaultOptions
                    label="Category"
                    name="categories"
                    classNameGroup="mb-3"
                    closeMenuOnSelect={false}
                    component={RFAsyncSelect}
                    loadOptions={loadCategories}
                    getOptionValue={getOptionId}
                    getOptionLabel={getOptionName}
                    placeholder="Select Category..."
                    styles={prepareBadgeInternalCategoryForReactSelect}
                />
            </Col>
        </Row>
        <FieldArray
            name="offenders"
            disabled={disabled}
            component={Offenders}
        />
        <Row>
            <Col>
                <Field
                    rows={3}
                    disabled
                    showSize={false}
                    classNameGroup="mb-3"
                    component={RFTextarea}
                    name="secret_description"
                    label="Sealed Description"
                    className="form-control-sealed"
                    placeholder="Enter any information you would like to be revealed only in case of the legal reason..."
                />
                <Field
                    name="files"
                    downloadable
                    editable={false}
                    disabled={disabled}
                    classNameGroup="mb-3"
                    allowSealFile={false}
                    dropAreaVisible={false}
                    component={InputDropAreaFileList}
                />
                <Field
                    disabled
                    component={RFCheckbox}
                    name="legal_disclaimer"
                    classNameLabel="d-inline"
                    label="This is a legal disclaimer that will be changed prior to the release."
                />
            </Col>
        </Row>
    </Col>;
});

export default IssueForm;

const Offenders = memo(function Offenders (props) {
    const { fields } = props;

    const items = useMemo(() => fields.map((mKey, index) => ({
        mKey,
        index,
        value: fields.get(index),
    })), [fields]);

    return <>
        {!_.isEmpty(items) && <Row>
            <Col xs="12" tag="p" className="fz-14 mb-2">
                Name of offender(s)
            </Col>
        </Row>}
        {items.map(({ mKey }) => <Row key={mKey}
            className="justify-content-between mb-2 border-1 border-secondary rounded-1 p-1 p-lg-0 mb-3">
            <Col xs="12" lg="6" className="mb-2">
                <Field
                    disabled
                    type="text"
                    label="Name"
                    component={RFInput}
                    name={`${mKey}.name`}
                    placeholder="Name of offender"
                />
            </Col>
            <Col xs="12" lg="6" className="mb-2">
                <Field
                    disabled
                    type="text"
                    label="Relation"
                    component={RFInput}
                    name={`${mKey}.relation`}
                    placeholder="Colleague/Mid or Top Management"
                />
            </Col>
        </Row>)}
    </>;
});

const DatePickerInput = forwardRef(({ value, onClick, ...attributes }, ref) => <InputGroup
    className="border border-gray-300 cursor-pointer"
    style={{ borderRadius: 10 }}
    onClick={onClick}
>
    <InputGroupText style={{ borderRadius: 10 }} className="border-0 bg-platform">
        <AppIcon icon="calendar" className="text-gray-500" />
    </InputGroupText>
    <Input
        ref={ref}
        value={value}
        onChange={e => e}
        className="bg-platform border-transparent border-0 rounded-0"
    />
    <InputGroupText style={{ borderRadius: 10 }} className="border-0 bg-platform">
        <AppIcon icon="angle-down" className="fz-18 text-gray-500" />
    </InputGroupText>
</InputGroup>);

const prepareBadgeInternalCategoryForReactSelect = {
    multiValue: base => ({
        ...base,
        borderRadius: '0.5em',
        margin: 5,
        padding: '0.35em',
        backgroundColor: 'rgba(125, 198, 251, 0.2)',
    }),
    multiValueLabel: base => ({
        ...base,
        padding: 0,
        paddingLeft: 0,
        fontSize: '0.8em',
        textAlign: 'center',
        color: 'rgb(86, 86, 86)',
    }),
    multiValueRemove: base => ({
        ...base,
        display: 'none'
    }),
};
