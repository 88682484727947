
// outsource dependencies
import _ from 'lodash';
import { Button, Col, Row } from 'reactstrap';
import { Field, FieldArray } from 'redux-form';
import React, { memo, useCallback, useMemo, useEffect, useState } from 'react';
import { useControllerActions, useControllerData } from 'redux-saga-controller';

// components
import { getOptionId, getOptionName, prepareMultiValues, ReduxFormWizard, RFInput, FasIcon, CheckboxCustom, BoxLoader } from 'components';

// local dependencies
import { FORM_NAME, loadCategories } from './index';
import controller, { REPORT_STEP_TYPE } from './controller';
import { RFSelect } from 'components/select/select-custom';


// configure
const formValidation = values => {
    const errors = {};
    if (_.isEmpty(values.categories)) {
        errors.categories = 'Category is required';
    }
    if (!_.isEmpty(values.offenders)) {
        const offenderErrors = [];
        values.offenders.forEach((offender, index) => {
            if (offender) {
                const offenderError = {};
                if (!offender.name) {
                    offenderError.name = 'Name is required';
                }
                if (!offender.relation) {
                    offenderError.relation = 'Relation is required';
                }
                offenderErrors[index] = offenderError;
            }
        });
        errors.offenders = offenderErrors;
    }
    return errors;
};

const SelectIssue = memo(function SelectIssue () {
    const { initialValues, disabled } = useControllerData(controller);
    const { updateCtrl } = useControllerActions(controller);
    const [categories, setCategories] = useState([]);

    const getCategories = async () => {
        const categories = await loadCategories();
        setCategories(categories);
    };

    useEffect(() => getCategories(), []);

    const toNextStep = useCallback(
        () => updateCtrl({ stepForm: REPORT_STEP_TYPE.ADDITIONAL_DETAILS }),
        [updateCtrl]
    );
    const toPreviouslyStep = useCallback(
        () => updateCtrl({ stepForm: REPORT_STEP_TYPE.SUMMARY }),
        [updateCtrl]
    );

    return (
        <ReduxFormWizard
            form={FORM_NAME}
            onSubmit={toNextStep}
            validate={formValidation}
            initialValues={initialValues}
        >
            {categories?.length ? (
                <Row>
                    <Col xs="12">
                        <Row>
                            <Col
                                xs="12"
                                tag="h1"
                                className="d-inline d-lg-none fw-500 fz-18 text-secondary mb-4"
                            >
                                Select Complaint Category
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" className="mb-3">
                                <Field
                                    isMulti
                                    isSearchable
                                    cacheOptions
                                    defaultOptions
                                    label="Category"
                                    name="categories"
                                    disabled={disabled}
                                    closeMenuOnSelect={false}
                                    component={RFSelect}
                                    options={categories}
                                    getOptionValue={getOptionId}
                                    getOptionLabel={getOptionName}
                                    placeholder="Select Category..."
                                    prepareValue={prepareMultiValues}
                                    styles={
                                        prepareBadgeInternalCategoryForReactSelect
                                    }
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" tag="p" className="fz-14 mb-2">
                                Name of offender(s)
                            </Col>
                        </Row>
                        <FieldArray
                            name="offenders"
                            disabled={disabled}
                            component={Offenders}
                        />
                        <Row>
                            <Col xs="12" className="mb-3">
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="font-family-alternative text-white fz-16 fw-500 text-center text-secondary rounded-5 w-100"
                                >
                                    Next
                                </Button>
                            </Col>
                            <Col xs="12" className="mb-3">
                                <Button
                                    outline
                                    color="primary-white"
                                    onClick={toPreviouslyStep}
                                    className="font-family-alternative fz-16 fw-500 text-center btn-extended rounded-5 w-100"
                                >
                                    Back
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            ) : (
                <div style={{ height: '350px' }}>
                    <BoxLoader active={true} />
                </div>
            )}
        </ReduxFormWizard>
    );
});

export default SelectIssue;

const Offenders = memo(function Offenders (props) {
    const { isUnknown, unknownIndex } = useControllerData(controller);
    const { updateCtrl } = useControllerActions(controller);
    const { fields, disabled } = props;


    const items = useMemo(() => fields.map((mKey, index) => ({
        mKey,
        index,
        value: fields.get(index),
        remove: () => {
            fields.remove(index);
            if (index < unknownIndex) {
                updateCtrl({ unknownIndex: unknownIndex - 1 });
            }
        },
    })), [fields, unknownIndex, updateCtrl]);

    const add = useCallback(() => {
        fields.push({});
        updateCtrl({ isUnknown: false });
    }, [fields, updateCtrl]);

    const handleUnknownChange = useCallback((value, index) => {
        if (value) {
            fields.splice(index, 1);
            fields.push({ name: 'Unknown', relation: 'Unknown' });
            updateCtrl({ isUnknown: value, unknownIndex: _.size(items) - 1 });
        } else {
            fields.splice(index, 1);
            fields.push({});
            updateCtrl({ isUnknown: false });
        }
    }, [fields, items, updateCtrl]);

    return items.map(({ mKey, index, remove, value }) => <Row key={mKey} className="justify-content-between mb-2 border-1 border-secondary rounded-1 p-1 p-lg-0 mb-3">
        <Col xs="10" lg="5" className="mb-2">
            <Field
                type="text"
                label="Name"
                component={RFInput}
                name={`${mKey}.name`}
                placeholder="Enter Name..."
                disabled={value.name === 'Unknown' }
            />
        </Col>
        <Col xs="10" lg="5" className="mb-2">
            <Field
                type="text"
                label="Relation"
                component={RFInput}
                disabled={disabled}
                name={`${mKey}.relation`}
                placeholder="Colleague/Mid or Top Management"
            />
        </Col>
        { _.findLastIndex(items) === index && <>
            <Col xs="2" sm="auto">
                <div
                    style={{ height: 28 }}
                />
                <Button
                    onClick={add}
                    color="primary"
                    style={{ height: 46 }}
                    disabled={disabled}
                    className="d-flex align-items-center font-family-alternative text-white rounded-6 py-0 px-3"
                >
                    <FasIcon icon="plus" className="fz-18" />
                </Button>
            </Col>
            <Col xs="12">
                <CheckboxCustom
                    name={mKey}
                    label="Unknown"
                    value={isUnknown}
                    className="mt-0 mb-1"
                    onChange={(val) => handleUnknownChange(val, index)}
                    classNameGroup="d-flex align-items-center"
                />
            </Col>
        </> }
        { (index >= 0 && index < _.findLastIndex(items)) && <Col xs="auto">
            <div style={{ height: 28 }} />
            <Button
                color="primary"
                onClick={remove}
                style={{ height: 46 }}
                className="d-flex align-items-center font-family-alternative text-white rounded-6 py-0 px-3"
            >
                <FasIcon icon="times" className="fz-20" />
            </Button>
        </Col> }
    </Row>);
});

const prepareBadgeInternalCategoryForReactSelect = {
    multiValue: base => ({
        ...base,
        borderRadius: '0.4em',
        margin: 5,
        padding: '0.35em',
        backgroundColor: 'rgba(125, 198, 251, 0.2)',
    }),
    multiValueLabel: base => ({
        ...base,
        padding: 0,
        paddingLeft: 0,
        fontSize: '0.8em',
        color: 'rgb(86, 86, 86)',
    }),
    multiValueRemove: base => ({
        ...base,
        color: 'rgb(6, 115, 193)',
        '&:hover': {
            color: 'rgb(6, 115, 193)',
            backgroundColor: 'rgba(86, 86, 86, 0.2)',
        }
    }),
    input: base => ({
        ...base,
        fontSize: '0.8em',
        paddingTop: 0,
        paddingBottom: 0,
        input: {
            padding: '0 !important',
        }
    }),
};
