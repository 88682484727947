// outsource dependencies
import _ from 'lodash';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Filler } from 'chart.js';

// local dependencies

// configure
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
        easing: 'easeInOutQuad',
        duration: 520
    },
    plugins: {
        legend: {
            display: true,
            position: 'top',
            align: 'end',
            title: {
                font: {
                    family: 'Roboto',
                    weight: 'normal',
                    style: 'normal',
                    size: 12
                },
            },
            labels: {
                boxWidth: 20,
                usePointStyle: false,
                pointStyle: 'rectRounded',
            }
        },
        title: {
            display: false,
        },
        tooltip: {
            callbacks: {
                title: (context) => {
                    const parsed = moment.localeData().monthsParse(_.get(_.first(context), 'label', 0)) + 1;
                    return moment(parsed, 'M').format('MMMM');
                }
            }
        }
    },
    scales: {
        x: {
            title: {
                display: false,
            },
            grid: {
                offset: true,
                color: 'transparent',
                tickColor: '#929292',
                borderColor: '#929292',
                borderWidth: 2,
                tickLength: 8,
                tickWidth: 2,
            },
            ticks: {
                display: true,
                font: {
                    family: 'Roboto',
                    weight: 'normal',
                    style: 'normal',
                    size: 12
                },
                // color: 'red'
            }
        },
        y: {
            beginAtZero: true,
            title: {
                display: false,
            },
            grid: {
                color: 'transparent',
                tickColor: '#929292',
                borderColor: '#929292',
                borderWidth: 2,
                tickLength: 8,
                tickWidth: 2,
            },
            ticks: {
                // forces step size to be 1000 units
                stepSize: 1000,
                display: true,
                font: {
                    family: 'Roboto',
                    weight: 'normal',
                    style: 'normal',
                    size: 12
                },
                // color: 'red'
            }
        }
    },
    elements: {
        bar: {
            borderRadius: 2,
            inflateAmount: 'auto',
            // pointStyle: 'rectRounded'
        }
    },
    /*point: {
        backgroundColor: 'white'
    },
    tooltips: {
        titleFontFamily: 'Open Sans',
        backgroundColor: 'rgba(0,0,0,0.3)',
        titleFontColor: 'red',
        caretSize: 5,
        cornerRadius: 2,
        xPadding: 10,
        yPadding: 10
    }*/
};

export const IssuesSuggestionsChart = memo(function IssuesSuggestionsChart ({ className }) {
    const chartRef = useRef(null);
    const [chartData, setChartData] = useState({
        datasets: []
    });

    const data = useMemo(() => ({
        labels: moment.monthsShort(),
        datasets: [
            {
                label: 'Complaints',
                data: [1400, 1500, 2000, 2300, 2400, 2350, 2300, 2400, 2800, 3250, 3750, 3999],
                // borderColor: 'rgb(255, 99, 132)',
                // backgroundColor: 'rgba(255, 99, 132, 0.5)',
                borderColor: 'rgba(133, 92, 248, 0.8)',
                backgroundColor: 'rgba(133, 92, 248, 0.8)',
                borderWidth: 0,
                pointBorderWidth: 1,
                pointHoverRadius: 3,
                pointHoverBorderWidth: 1,
            },
            {
                label: 'Ideas',
                data: [1950, 1500, 2000, 2300, 2400, 2350, 2300, 2400, 2800, 3250, 3750, 3999],
                // borderColor: 'rgb(255, 99, 132)',
                // backgroundColor: 'rgba(255, 99, 132, 0.5)',
                borderColor: 'rgba(218, 206, 252, 1)',
                backgroundColor: 'rgba(218, 206, 252, 1)',
                borderWidth: 1,
                pointBorderWidth: 1,
                pointHoverRadius: 3,
                pointHoverBorderWidth: 1,
            }
        ]
    }), []);

    useEffect(() => {
        const chart = chartRef.current;

        if (!chart) {
            return;
        }

        const chartData = {
            ...data,
            datasets: data.datasets.map((dataset) => ({
                ...dataset,
                tension: 0.4,
                fill: true
                // borderColor: createGradient(chart.ctx, chart.chartArea),
                // backgroundColor: createGradient(chart.ctx, chart.chartArea),
                // fill: true
            }))
        };

        setChartData(chartData);
    }, [data]);

    return <div className={cx('', className)}>
        <Bar ref={chartRef} options={chartOptions} data={chartData} />
    </div>;
});
IssuesSuggestionsChart.propTypes = {
    className: PropTypes.string,
};

IssuesSuggestionsChart.defaultProps = {
    className: '',
};
