
// outsource dependencies
import { Field } from 'redux-form';
import { useHistory } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import React, { memo, useCallback } from 'react';
import { useControllerActions, useControllerData } from 'redux-saga-controller';

// components
import { ReduxFormWizard, RFTextarea } from 'components';

// local dependencies
import { FORM_NAME } from './index';
import controller, { SUGGESTION_STEP_TYPE } from './controller';

// configure
const formValidation = values => {
    const errors = {};
    if (!values.manual_category) {
        errors.manual_category = 'Category is required';
    }
    if (!values.description) {
        errors.description = 'Description is required';
    }
    return errors;
};

const Summary = memo(function Summary () {
    const { goBack } = useHistory();
    const { initialValues, disabled } = useControllerData(controller);
    const { updateCtrl } = useControllerActions(controller);

    const toNextStep = useCallback(() => (
        updateCtrl({ stepForm: SUGGESTION_STEP_TYPE.ADDITIONAL_DETAILS })
    ), [updateCtrl]);

    return <ReduxFormWizard
        form={FORM_NAME}
        onSubmit={toNextStep}
        validate={formValidation}
        initialValues={initialValues}
    >
        <Row>
            <Col xs="12" tag="h1" className="d-inline d-lg-none fw-500 fz-18 text-secondary mb-4">
                Summary
            </Col>
            <Col xs="12" className="mb-3">
                <Field
                    rows={1}
                    size={100}
                    label="Category"
                    disabled={disabled}
                    classNameGroup="mb-3"
                    name="manual_category"
                    component={RFTextarea}
                    placeholder="Category..."
                />
                <Field
                    rows={10}
                    size={2000}
                    name="description"
                    disabled={disabled}
                    label="Description"
                    component={RFTextarea}
                    placeholder="Share your idea here..."
                />
            </Col>
            <Col xs="12" className="mb-3">
                <Button
                    type="submit"
                    color="primary"
                    className="font-family-alternative text-white fz-16 fw-500 text-center text-secondary rounded-5 w-100"
                >
                    Next
                </Button>
            </Col>
            <Col xs="12" className="mb-3">
                <Button
                    outline
                    onClick={goBack}
                    color="primary-white"
                    className="font-family-alternative fz-16 fw-500 text-center btn-extended rounded-5 w-100"
                >
                    Back
                </Button>
            </Col>
        </Row>
    </ReduxFormWizard>;
});

export default Summary;
