
// outsource dependencies
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

// local dependencies
import FilterService from 'services/filter.service';

export const Filter = memo(({ type, children, options, value, tag, ...attr }) => {
    const Tag = tag;
    const val = useMemo(() => (_.isString(children) ? children : value), [children, value]);
    const filter = useMemo(() => ((_.isFunction(type)) ? type(val, options) : `Incorrect filter ${String(type)}`), [type, val, options]);
    return <Tag {...attr}> { filter } </Tag>;
});

Filter.propTypes = {
    tag: PropTypes.string,
    options: PropTypes.object,
    children: PropTypes.string,
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Filter.defaultProps = {
    type: FilterService.humanize,
    children: null,
    options: {},
    tag: 'span',
    value: ''
};

// filter shortcuts
export const Humanize = props => <Filter {...props} />;
export const Enum = props => <Filter {...props} type={FilterService.toEnum} />;
export const Truncate = props => <Filter {...props} type={FilterService.truncate} />;
export const Duration = props => <Filter {...props} type={FilterService.duration} />;
export const TitleCase = props => <Filter {...props} type={FilterService.titleCase} />;
export const EscapeHtml = props => <Filter {...props} type={FilterService.escapeHtml} />;

// as part of filters
export const UnsafeHtml = memo(({ value, tag, ...attr }) => {
    const Tag = tag;
    return <Tag {...attr} dangerouslySetInnerHTML={{ __html: value || '' }} />;
});

UnsafeHtml.propTypes = {
    tag: PropTypes.string,
    value: PropTypes.string.isRequired,
};

UnsafeHtml.defaultProps = {
    tag: 'span'
};
