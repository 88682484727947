
// outsource dependencies
import PropTypes from 'prop-types';
import { Input, Button, Label } from 'reactstrap';
import React, { memo, useCallback, useState } from 'react';

// local dependencies
import { useSelf } from 'hooks';
import { AppIcon, FasIcon } from 'components/icon';

// configure

export const CommentField = memo(function CommentField ({ onChange, ...props }) {
    const user = useSelf();
    const [value, setValue] = useState(props.value);
    const uid = 'comment-field';

    const handleAttach = useCallback(() => console.log('Attach file'), []);
    const handleSubmit = useCallback(() => {
        onChange({ comment: value, date: new Date(), first_name: user.first_name, last_name: user.last_name });
        setValue('');
    }, [onChange, value, user.first_name, user.last_name]);
    const handleType = useCallback(({ target }) => setValue(target.value), []);

    return <div className="form-control p-0">
        <div className="bg-primary-25 p-3 rounded-top-6">
            <Label htmlFor={uid} className="font-family-alternative fw-500 text-gray-600 m-0">
                Add Comment...
            </Label>
        </div>
        <div className="d-flex align-items-center">
            <Input
                row={1}
                id={uid}
                value={value}
                type="textarea"
                onChange={handleType}
                className="font-family-alternative border-gray-200 border-0 rounded-top-0 rounded-bottom-6 p-3"
            />
            <Button
                color="transparent"
                onClick={handleAttach}
                className="d-flex align-items-center justify-content-center fz-16 text-white rounded-3 p-2 me-2"
            >
                <FasIcon icon="paperclip" className="text-gray-700" />
            </Button>
            <Button
                color="gray-500"
                onClick={handleSubmit}
                className="d-flex align-items-center justify-content-center fz-16 text-white rounded-3 p-2 me-2"
            >
                <AppIcon icon="paper-plane" />
            </Button>
        </div>
    </div>;
});
CommentField.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};
CommentField.defaultProps = {
    value: '',
};

export default CommentField;
