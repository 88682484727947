
// outsource dependencies
import React, { memo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// local dependencies
import * as ROUTES from 'constants/routes';

// pages
import IssuesPage from './page';
import IssuesList from './list';

// configure

const Issues = memo(function Issues () {
    return <Switch>
        <Route path={ROUTES.ISSUES_LIST.ROUTE} component={IssuesList} />
        <Route path={ROUTES.ISSUES_PAGE.ROUTE} component={IssuesPage} />
        {/*OTHERWISE*/}
        <Redirect to={{ pathname: ROUTES.ISSUES_LIST.LINK() }} />
    </Switch>;
});

export default Issues;
