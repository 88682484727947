// outsource dependencies
import _ from 'lodash';
import cx from 'classnames';
import PropTypes from 'prop-types';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import { Button, Col, Label, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { change, Field, FieldArray, getFormValues } from 'redux-form';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useControllerActions, useControllerData } from 'redux-saga-controller';
import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js';

// components
import { FasIcon, ReduxForm, RFSelect, InputIcon, RFInput } from 'components';

// constants
import { STATUS_USER_TYPE } from 'constants/spec';

// services
import { FilterService, isUrl } from 'services';

// local dependencies
import { controller, FORM_NAME } from './controller';
import OrganizationInfoLogo from './components/organization-info-logo';
import OrganizationInfoBackground from './components/organization-info-background';

// configure
const locationOrigin = window.location.origin;
const FORM_GROUP_NAME = 'access_keywords';
export const statusOptions = Object.values(STATUS_USER_TYPE).map(status => ({
    value: status, label: FilterService.humanize(status)
}));
const normalizePathName = (string = '') => {
    return String(string)
        // replace all symbols
        .replace(/[^\w]/gi, ' ')
        // start&end trim
        .trim()
        // multiple spaces to one
        .replace(/\s+/g, ' ')
        // .replace(/-{2,}/gi, '-')
        .replace(/\s/gi, '-');
};
const simpleTrimPathName = (string = '') => {
    return String(string).trim().replace(/\s+/g, ' ').replace(/\s/gi, '-');
};
const useFormValues = () => useSelector(getFormValues(FORM_NAME)) || {};
const useFormFieldUpdate = (field) => {
    const dispatch = useDispatch();
    return useCallback(value => dispatch(change(FORM_NAME, field, value, false)), [dispatch, field]);
};
const normalizeOrganizationUrlField = (value) => {
    const trimmed = simpleTrimPathName(value);
    const isProperUrl = isUrl(trimmed);
    // console.log(isProperUrl, trimmed)
    const res = isProperUrl
        ? normalizePathName(decodeURIComponent(new URL(trimmed).pathname))
        // eslint-disable-next-line
        : normalizePathName(decodeURIComponent(new URL(trimmed, locationOrigin).pathname))
    // console.log('normalize', res)
    return !_.isEmpty(res) ? `/${res}` : '';
};
const formatOrganizationUrlField = (value) => {
    const currentValue = !_.isEmpty(value) ? value : '';
    const actualPathname = isUrl(currentValue)
        ? normalizePathName(new URL(currentValue).pathname)
        : normalizePathName(currentValue);
    return new URL(actualPathname, locationOrigin).toString();
};
const formValidation = values => {
    const errors = {};
    if (!values.message) {
        errors.message = 'Welcome message is required';
    }
    if (!values.description) {
        errors.description = 'Description is required';
    }

    if (!values.url) {
        errors.url = `Organization Url address is required. e.g. ${new URL('/example', locationOrigin).toString()}`;
    } else if (!isUrl(new URL(values.url, locationOrigin).toString())) {
        errors.url = `Invalid Organization Url address. e.g. ${new URL('/example', locationOrigin).toString()}`;
    }

    errors[FORM_GROUP_NAME] = [];
    if (_.size(values[FORM_GROUP_NAME])) {
        const list = values[FORM_GROUP_NAME] || [];
        list.forEach((item, index) => {
            if (!item) {
                errors[FORM_GROUP_NAME][index] = 'Access Key Words Set shouldn\'t be empty';
            }
        });
    }
    return errors;
};
const OrganizationInfoForm = memo(function OrganizationInfoForm ({ className }) {
    const handleOrganizationKeywordChange = useFormFieldUpdate('keyword');
    const { initialValues, disabled, successSubmitTrigger } = useControllerData(controller);
    const { submitData, uploadOrganizationLogo, uploadOrganizationBackground } = useControllerActions(controller);

    const organizationFormValues = useFormValues();
    const { logo, background } = organizationFormValues;
    const name = _.get(organizationFormValues, 'name', 'Organization Name');
    const [files, setFiles] = useStateWithCallbackLazy({});

    useEffect(() => {
        if (successSubmitTrigger && !_.isEmpty(files)) {
            setFiles({});
        }
    }, [files, setFiles, successSubmitTrigger]);

    // NOTE actions
    const handleOrganizationUrlChange = useCallback((value) => {
        const normalized = normalizeOrganizationUrlField(value);
        const newKeyword = normalized.startsWith('/') ? normalized.slice(1) : null;
        handleOrganizationKeywordChange(newKeyword);
    }, [handleOrganizationKeywordChange]);

    const handleOrganizationFormSubmit = useCallback((formValues) => {
        submitData({ formValues, files });
    }, [files, submitData]);

    const handleOnBackgroundChange = useCallback((fileData) => {
        setFiles((prevFiles) => ({ ...prevFiles, background: fileData }));
    }, [setFiles]);

    const handleOnBackgroundSave = useCallback((fileData) => {
        setFiles(
            (prevFiles) => ({ ...prevFiles, background: fileData }),
            () => {
                uploadOrganizationBackground(fileData);
            }
        );
    }, [setFiles, uploadOrganizationBackground]);

    const handleOnLogoChange = useCallback((fileData) => {
        setFiles((prevFiles) => ({ ...prevFiles, logo: fileData }));
    }, [setFiles]);

    const handleOnLogoSave = useCallback((fileData) => {
        setFiles(
            (prevFiles) => ({ ...prevFiles, logo: fileData }),
            () => {
                uploadOrganizationLogo(fileData);
            }
        );
    }, [setFiles, uploadOrganizationLogo]);

    return <Row tag="section" className={cx('organization-info-form', className)}>
        <ReduxForm
            form={FORM_NAME}
            enableReinitialize
            validate={formValidation}
            initialValues={initialValues}
            onSubmit={handleOrganizationFormSubmit}
        >
            <Row className="mb-2">
                <Col xs="12" className="d-flex mb-4 align-items-center">
                    <OrganizationInfoLogo
                        src={logo}
                        width={120}
                        height={120}
                        viewWidth={500}
                        viewHeight={500}
                        disabled={disabled}
                        organizationName={name}
                        onFileSave={handleOnLogoSave}
                        onFileChange={handleOnLogoChange}
                        className="organization-logo me-5"
                    />
                    <h1 className="fz-24 fw-600 text-secondary">
                        {name}
                    </h1>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs="12" lg="5" className="mb-3">
                    <Field
                        name="url"
                        type="text"
                        disabled={disabled}
                        component={RFInput}
                        icon={<InputIcon />}
                        classNameGroup="mb-3"
                        label="Organization URL"
                        format={formatOrganizationUrlField}
                        normalize={normalizeOrganizationUrlField}
                        onChange={handleOrganizationUrlChange}
                        placeholder={`${new URL('/example', locationOrigin)}`}
                    />
                    <OrganizationInfoBackground
                        src={background}
                        disabled={disabled}
                        organizationName={name}
                        onFileSave={handleOnBackgroundSave}
                        onFileChange={handleOnBackgroundChange}
                        className="organization-image-background mb-3 rounded-6 w-100"
                    />
                    {/*<DefaultImage
                                width={488}
                                height={360}
                                src={background}
                                alt={`University ${name}`}
                                title={`University ${name}`}
                                style={{ objectFit: 'cover' }}
                                className="w-100 rounded-6 mb-3"
                            />*/}
                    <Field
                        name="status"
                        label="Status"
                        disabled={disabled}
                        placeholder="Status"
                        component={RFSelect}
                        options={statusOptions}
                    />
                </Col>
                <Col xs="12" lg="7">
                    <FieldArray
                        disabled={disabled}
                        name={FORM_GROUP_NAME}
                        component={AccessFields}
                    />
                    {/*<Field
                        rows={2}
                        name="message"
                        type="textarea"
                        disabled={disabled}
                        component={RFInput}
                        icon={<InputIcon />}
                        classNameGroup="mb-3"
                        label="Welcome message"
                        placeholder="Enter welcome message"
                    />*/}
                    {/*<Field
                        rows={9}
                        type="textarea"
                        name="description"
                        label="Description"
                        disabled={disabled}
                        component={RFInput}
                        icon={<InputIcon />}
                        classNameGroup="mb-3"
                        placeholder="Enter welcome message"
                    />*/}
                    <Field
                        rows={9}
                        name="description"
                        label="Description"
                        disabled={disabled}
                        wrapperClassName="mb-3"
                        editorClassName="fz-16 px-1"
                        component={TextToHtmlEditor}
                    />
                    <Col xs="12" className="d-flex justify-content-end mb-5">
                        <Button
                            type="submit"
                            color="primary"
                            disabled={disabled}
                            className="text-white px-5"
                        >
                            Save Updates
                        </Button>
                    </Col>
                </Col>
            </Row>
        </ReduxForm>
    </Row>;
});

OrganizationInfoForm.propTypes = {
    className: PropTypes.string,
};

OrganizationInfoForm.defaultProps = {
    className: ''
};

export default OrganizationInfoForm;

const TextToHtmlEditor = memo(function TextToHtmlEditor (props) {
    const { input, label, classNameLabel, name, wrapperClassName, editorClassName, disabled } = props;
    const [editorState, onEditorStateChange] = useState(
        EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(input.value)))
    );
    const data = useMemo(() => draftToHtml(convertToRaw(editorState.getCurrentContent())), [editorState]);
    const handleChange = useCallback(() => input.onChange(data), [data, input]);
    return <>
        { label && <Label className={cx(classNameLabel)}>
            { label }
        </Label> }
        <Editor
            name={name}
            onChange={handleChange}
            toolbar={toolbarOptions}
            editorState={editorState}
            editorClassName={editorClassName}
            onEditorStateChange={onEditorStateChange}
            wrapperClassName={cx(
                wrapperClassName,
                'form-control',
                {
                    'opacity-50': disabled,
                    'pe-none': disabled
                })}
        />
    </>;
});

const toolbarOptions = {
    options: ['inline'],
    inline: {
        inDropdown: false,
        options: ['bold', 'italic', 'underline'],
    },
};


const AccessFields = memo(function AccessFields ({ fields, disabled }) {
    const addField = useCallback(() => fields.push(''), [fields]);

    const items = useMemo(() => fields.map((mKey, index) => ({ value: fields.get(index), mKey, index })), [fields]);

    return <Row className="g-0">
        <Col xs="12">
            <label className="form-label fw-500 fz-14">Access Key Words Set</label>
        </Col>
        { items.map(({ index, mKey }) => <Col xs="12" key={index} className="mb-3">
            <Field
                name={mKey}
                type="text"
                component={RFInput}
                disabled={disabled}
                icon={<div className="d-flex bg-white">
                    <InputIcon className="me-1" />
                    <RemoveIcon fields={fields} index={index} />
                </div>}
                placeholder="Enter Key Words Set"
            />
        </Col>) }
        <Col xs="12" className="d-flex justify-content-end mb-3">
            <Button
                outline
                onClick={addField}
                disabled={disabled}
                color="primary-white"
                className="btn-extended px-5"
            >
                Add Input
            </Button>
        </Col>
    </Row>;
});

export const RemoveIcon = memo(function RemoveIcon ({ index, fields }) {
    const handleRemove = useCallback(() => fields.remove(index), [fields, index]);

    return <Button
        disabled={!index}
        onClick={handleRemove}
        style={{ width: 20, height: 20 }}
        className="d-flex justify-content-center align-items-center text-white border-transparent bg-primary rounded-circle p-0"
    >
        <FasIcon icon="trash" className="fz-12" />
    </Button>;
});
RemoveIcon.propTypes = {
    index: PropTypes.number,
    fields: PropTypes.object,
};
RemoveIcon.defaultProps = {
    index: null,
    fields: null,
};
