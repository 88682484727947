// outsource dependencies
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import React, { memo } from 'react';

// local dependencies
import { InputCustom } from './input';

const TextPreview = memo(function TextPreview ({ classNameGroup, label, input }) {
    const isValue = _.size(input.value) > 0;

    return <div className={classNameGroup}>
        <Label className="fw-400">
            { label }
        </Label>
        { isValue
            ? <div style={{ borderRadius: 10 }} className="fz-14 bg-platform border border-gray-300 p-3 w-100">
                <span className="text-break">{ isValue ? input.value : '&shy;' }</span>
            </div>
            : <InputCustom disabled />}
    </div>;
});
TextPreview.propTypes = {
    label: PropTypes.string,
    classNameGroup: PropTypes.string,
    input: PropTypes.object.isRequired,
};
TextPreview.defaultProps = {
    label: '',
    classNameGroup: '',
};

export default TextPreview;
