
// outsource dependencies
import { Field } from 'redux-form';
import { Button, Col, Row } from 'reactstrap';
import React, { memo, useCallback } from 'react';
import { useControllerActions, useControllerData } from 'redux-saga-controller';

// components
import { ReduxFormWizard, InputDropAreaFileList } from 'components';

// local dependencies
import { FORM_NAME } from './index';
import controller, { REPORT_STEP_TYPE } from './controller';

// configure
const AdditionalDetails = memo(function AdditionalDetails () {
    const { initialValues, disabled } = useControllerData(controller);
    const { updateCtrl } = useControllerActions(controller);

    const toNextStep = useCallback(() => (
        updateCtrl({ stepForm: REPORT_STEP_TYPE.PREVIEW })
    ), [updateCtrl]);
    const toPreviouslyStep = useCallback(() => (
        updateCtrl({ stepForm: REPORT_STEP_TYPE.SELECT_CATEGORY })
    ), [updateCtrl]);

    return <ReduxFormWizard
        form={FORM_NAME}
        onSubmit={toNextStep}
        initialValues={initialValues}
    >
        <Row>
            <Col xs="12" tag="h1" className="d-inline d-lg-none fw-500 fz-18 text-secondary mb-0">
                Additional Details
            </Col>
            <Col xs="12" className="d-lg-block mb-4">
                <Field
                    editable
                    name="files"
                    disabled={disabled}
                    classNameGroup="mb-3"
                    component={InputDropAreaFileList}
                    label={<span className="fz-14 fw-500 text-gray-600">
                        <span className="d-block mb-1 fw-700">Upload New Attachment</span>
                        <span className="d-block fst-italic mb-lg-2">*JPEG, SVG, PNG are allowed (up to 15 MB)</span>
                        <span className="d-block fst-italic mb-lg-2">*MP4, MOV are allowed (up to 500 MB)</span>
                        <span className="fz-12 mb-0">*Optional</span>
                    </span>}
                />
            </Col>
            {/*<Col xs="12" className="mb-4">
                <FileItemView fileName="903412132135.pdf" disabled={disabled} fileSize="1,2 MB" data={{ isAccessed: true }} />
            </Col>*/}
            <Col xs="12" className="mb-3">
                <Button
                    type="submit"
                    color="primary"
                    className="font-family-alternative text-white fz-16 fw-500 text-center text-secondary rounded-5 w-100"
                >
                    Next
                </Button>
            </Col>
            <Col xs="12" className="mb-3">
                <Button
                    outline
                    color="primary-white"
                    onClick={toPreviouslyStep}
                    className="font-family-alternative fz-16 fw-500 text-center btn-extended rounded-5 w-100"
                >
                    Back
                </Button>
            </Col>
        </Row>
    </ReduxFormWizard>;
});

export default AdditionalDetails;
