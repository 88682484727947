
// outsource dependencies
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import React, { memo } from 'react';

// constants
import { SEVERITY } from 'constants/spec';

// configure

export const BadgeSeverity = memo(function BadgeSeverity ({ type }) {
    switch (type) {
        default: return <Badge className="border fw-400 border-gray-700 bg-gray-700 bg-opacity-25 text-gray-700">
            None Selected
        </Badge>;
        case '1': return <Badge className="border fw-400 border-info bg-info bg-opacity-25 text-info">
            { SEVERITY[type] }
        </Badge>;
        case '2': return <Badge className="border fw-400 border-secondary bg-secondary bg-opacity-25 text-secondary">
            { SEVERITY[type] }
        </Badge>;
        case '3': return <Badge className="border border-purple bg-purple bg-opacity-25 text-purple fw-400">
            { SEVERITY[type] }
        </Badge>;
    }
});
BadgeSeverity.propTypes = {
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
BadgeSeverity.defaultProps = {
    type: '',
};

export default BadgeSeverity;

export function prepareBadgeSeverityStyleForReactSelect (base, { data }) {
    const BASE_STYLE = {
        ...base,
        width: 'fit-content',
        fontWeight: 700,
        fontSize: '0.75em',
        borderWidth: 1,
        borderRadius: '0.5em',
        borderStyle: 'solid',
        padding: '0.35em 0.65em',
    };

    switch (_.get(data, 'value', '')) {
        default: return {
            ...BASE_STYLE,
            color: 'rgb(123,123,123)',
            borderColor: 'rgb(123,123,123)',
            backgroundColor: 'rgba(123,123,123,0.25)',
        };
        case '1': return {
            ...BASE_STYLE,
            color: 'rgb(35,183,229)',
            borderColor: 'rgb(35,183,229)',
            backgroundColor: 'rgb(35,183,229,0.25)',
        };
        case '2': return {
            ...BASE_STYLE,
            color: 'rgb(6,115,193)',
            borderColor: 'rgb(6,115,193)',
            backgroundColor: 'rgba(6,115,193,0.25)',
        };
        case '3': return {
            ...BASE_STYLE,
            color: 'rgb(140,120,219)',
            borderColor: 'rgb(140,120,219)',
            backgroundColor: 'rgba(140,120,219,0.25)',
        };
    }
}
