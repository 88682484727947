
// outsource dependencies
import _ from 'lodash';
import { Field, FieldArray } from 'redux-form';
import React, { forwardRef, memo, useCallback, useMemo } from 'react';
import { useControllerActions, useControllerData } from 'redux-saga-controller';
import { Button, Col, Input, InputGroup, InputGroupText, Row } from 'reactstrap';

// components
import TextPreview from 'components/text-preview';
import {
    RFInput,
    AppIcon,
    RFTextarea,
    RFCheckbox,
    getOptionId,
    RFDatePicker,
    getOptionName,
    RFAsyncSelect,
    ReduxFormWizard,
    prepareMultiValues,
    InputDropAreaFileList,
} from 'components';

// local dependencies
import { FORM_NAME, loadCategories } from './index';
import controller, { REPORT_STEP_TYPE } from './controller';

// configure
const formValidation = values => {
    const errors = {};
    if (!values.legal_disclaimer) {
        errors.legal_disclaimer = 'This field is required';
    }
    return errors;
};

const DatePickerInput = forwardRef(({ value, onClick }, ref) => <InputGroup
    className="border border-gray-300 cursor-pointer"
    style={{ borderRadius: 10 }}
    onClick={onClick}
>
    <InputGroupText style={{ borderRadius: 10 }} className="border-0 bg-platform">
        <AppIcon icon="calendar" className="text-gray-500" />
    </InputGroupText>
    <Input
        ref={ref}
        value={value}
        onChange={e => e}
        placeholder="MM/DD/YYYY"
        className="bg-platform border-transparent border-0 rounded-0"
    />
    <InputGroupText style={{ borderRadius: 10 }} className="border-0 bg-platform">
        <AppIcon icon="angle-down" className="fz-18 text-gray-500" />
    </InputGroupText>
</InputGroup>);

const Preview = memo(function Preview () {
    const { initialValues, disabled } = useControllerData(controller);
    const { updateCtrl, submitData } = useControllerActions(controller);

    const toPreviouslyStep = useCallback(() => (
        updateCtrl({ stepForm: REPORT_STEP_TYPE.ADDITIONAL_DETAILS })
    ), [updateCtrl]);

    return <ReduxFormWizard
        form={FORM_NAME}
        onSubmit={submitData}
        validate={formValidation}
        initialValues={initialValues}
    >
        <Row>
            <Col xs="12" tag="h1" className="d-inline d-lg-none fw-500 fz-18 text-secondary mb-4">
                Preview
            </Col>
            <Col xs="12" className="mb-3">
                <Field
                    name="brief_summary"
                    label="Brief Summary"
                    classNameGroup="mb-3"
                    component={TextPreview}
                />
                <Field
                    disabled
                    name="date"
                    label="Date"
                    classNameGroup="mb-3"
                    component={RFDatePicker}
                    customInput={<DatePickerInput />}
                />
                <Field
                    name="description"
                    label="Description"
                    classNameGroup="mb-3"
                    component={TextPreview}
                />
                <Field
                    rows={3}
                    disabled
                    showSize={false}
                    classNameGroup="mb-3"
                    component={RFTextarea}
                    name="secret_description"
                    label="Sealed Description"
                    className="form-control-sealed"
                    placeholder="Enter any information you would like to be revealed only in case of the legal reason..."
                />
                <Field
                    isMulti
                    disabled
                    isSearchable
                    cacheOptions
                    defaultOptions
                    label="Category"
                    className="mb-3"
                    name="categories"
                    closeMenuOnSelect={false}
                    component={RFAsyncSelect}
                    loadOptions={loadCategories}
                    getOptionValue={getOptionId}
                    getOptionLabel={getOptionName}
                    placeholder="Select Category..."
                    prepareValue={prepareMultiValues}
                    styles={prepareBadgeInternalCategoryForReactSelect}
                />
                <FieldArray
                    name="offenders"
                    disabled={disabled}
                    component={Offenders}
                />
                <div className="mb-3">
                    {/*<DownloadAttachFile name="IMG53412132135.jpeg" size="450 KB" />*/}
                    <Field
                        name="files"
                        editable={false}
                        disabled={disabled}
                        classNameGroup="mb-3"
                        allowSealFile={false}
                        dropAreaVisible={false}
                        component={InputDropAreaFileList}
                    />
                </div>
                <Field
                    disabled={disabled}
                    component={RFCheckbox}
                    classNameGroup="d-flex"
                    name="legal_disclaimer"
                    className="flex-shrink-0"
                    label="This is a legal disclaimer that will be changed prior to the release."
                />
            </Col>
            <Col xs="12" className="mb-3">
                <Button
                    type="submit"
                    color="primary"
                    className="font-family-alternative text-white fz-16 fw-500 text-center text-secondary rounded-5 w-100"
                >
                    Submit
                </Button>
            </Col>
            <Col xs="12" className="mb-3">
                <Button
                    outline
                    onClick={toPreviouslyStep}
                    color="primary-white"
                    className="font-family-alternative fz-16 fw-500 text-center btn-extended rounded-5 w-100"
                >
                    Back
                </Button>
            </Col>
        </Row>
    </ReduxFormWizard>;
});

export default Preview;

const Offenders = memo(function Offenders (props) {
    const { fields } = props;

    const items = useMemo(() => fields.map((mKey, index) => ({
        mKey,
        index,
        value: fields.get(index),
    })), [fields]);

    return <>
        {!_.isEmpty(items) && <Row>
            <Col xs="12" tag="p" className="fz-14 mb-2">
                Name of offender(s)
            </Col>
        </Row>}
        {items.map(({ mKey }) => <Row
            key={mKey}
            className="justify-content-between mb-2 border-1 border-secondary rounded-1 p-1 p-lg-0 mb-3"
        >
            <Col xs="12" lg="6" className="mb-2">
                <Field
                    disabled
                    type="text"
                    label="Name"
                    component={RFInput}
                    name={`${mKey}.name`}
                    placeholder="Name of offender"
                />
            </Col>
            <Col xs="12" lg="6" className="mb-2">
                <Field
                    disabled
                    type="text"
                    label="Relation"
                    component={RFInput}
                    name={`${mKey}.relation`}
                    placeholder="Colleague/Mid or Top Management"
                />
            </Col>
        </Row>)}
    </>;
});

const prepareBadgeInternalCategoryForReactSelect = {
    multiValue: base => ({
        ...base,
        borderRadius: '0.5em',
        margin: 5,
        padding: '0.35em',
        backgroundColor: 'rgba(125, 198, 251, 0.2)',
    }),
    multiValueLabel: base => ({
        ...base,
        padding: 0,
        paddingLeft: 0,
        fontSize: '0.8em',
        textAlign: 'center',
        color: 'rgb(86, 86, 86)',
    }),
    multiValueRemove: base => ({
        ...base,
        display: 'none'
    }),
};
