
// outsource dependencies
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { create } from 'redux-saga-controller';
import { takeEvery, put, call } from 'redux-saga/effects';

// local dependencies
import { instanceAPI } from 'services';

// configure

export const controller = create({
    prefix: 'cases-page',
    actions: {
        initialize: 'INITIALIZE',
    },
    initial: {
        disabled: false,
        initialized: false,
        errorMessage: null,

        initialValues: {},
    },
    subscriber: function * () {
        yield takeEvery(controller.action.initialize.TYPE, initializeSaga);
    }
});

export default controller;

function * initializeSaga ({ payload }) {
    // console.log(`%c ${type} `, 'color: #FF6766; font-weight: bolder; font-size: 12px;'
    //   , '\n payload:', payload
    // );
    try {
        const id = _.get(payload, 'id', null);
        const data = yield call(instanceAPI, { method: 'GET', url: `/incident/${id}` });
        yield put(controller.action.updateCtrl({ initialValues: data }));
    } catch ({ message }) {
        yield call(toastr.error, 'Cases Page Initialize', message);
        yield put(controller.action.updateCtrl({ errorMessage: message }));
    }
    yield put(controller.action.updateCtrl({ initialized: true }));
}
